// Libraries
import { useNavigate } from 'react-router-dom';

// UI components
import SuccessIcon from '../../../assets/icon/successIcon.png';
import linkIcon from '../../../assets/icon/linkIcon.png';
import Loader from '../../Shared/Spinner/Loader';

// Zustand - Global State
import useUserStore from '../../../store/useUser.store';

const SuccessSubmitting = ({
  title,
  subtitle,
  buttonText,
  redirectionPath,
  withUrlIcon = false,
  isAuth = false,
  advice = '',
}: {
  title: string;
  subtitle: string;
  buttonText?: string;
  redirectionPath?: string;
  withUrlIcon?: boolean;
  isAuth?: boolean;
  advice?: string;
}) => {
  const navigate = useNavigate();
  const backToHome = () => {
    useUserStore.setState({ isSignUpSubmitSuccess: false });
    useUserStore.setState({ isResetSuccess: false });
    useUserStore.setState({ isForgotPasswordEmailSend: false });
    navigate(`${redirectionPath}`);
  };

  return (
    <div className="flex flex-col h-screen px-4 mx-auto mt-8 align-middle md:px-0 md:mt-24 md:w-2/3">
      <section className="flex-col justify-center my-4 text-center">
        {!isAuth ? <img src={SuccessIcon} alt="success icon" className="inline w-16" /> : <Loader />}

        <p className={`${buttonText ? 'md:text-4xl' : 'md:text-2xl'} text-lg font-semibold mt-4`}>{title}</p>
        <div className="flex flex-row items-center justify-center mt-4">
          <p className={`flex justify-center text-sm text-center md:text-base`}>
            {withUrlIcon && <img src={linkIcon} alt="link icon" className="hidden md:flex w-5 h-3 mr-2 mt-1.5" />}
            <span className="tracking-wide w-80">{subtitle}</span>
          </p>
        </div>
        <br />
        {advice && <span className="tracking-wide">{advice}</span>}
      </section>

      <div
        className={`${
          buttonText ? 'col-span-6 md:-mt-3 sm:flex sm:items-center sm:justify-center sm:gap-2' : 'hidden'
        }`}
      >
        <button
          onClick={backToHome}
          className="inline-block w-full px-12 py-3 mt-4 text-sm font-medium text-white transition bg-gray-800 cursor-pointer md:w-80 shrink-0 rounded-xl disabled:bg-gray-400 disabled:border-none disabled:hover:text-white hover:bg-gray-700 focus:outline-none focus:ring active:text-blue-500 disabled:cursor-default"
        >
          {buttonText}
        </button>
      </div>
    </div>
  );
};

export default SuccessSubmitting;
