import { useEffect, useCallback } from 'react';

// Libraries
import { useNavigate, Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// Zustand Global State
import useUserStore from '../../../store/useUser.store';
import useAppStore from '../../../store/useApp.store';

// Form Schema
import { logInSchema } from '../schemas/authSchemas';

// Mixpanel Event
import { viewLoginEvent, clickToForgetPasswordEvent } from '../../../utils/mixpanelEvents';

// UI components
import FormHeader from './FormHeader';
import Input from './Input';
import Button from './Button';

function Login() {
  const isLoading = useAppStore((state) => state.isLogInLoading);
  const doLogIn = useUserStore((state) => state.logIn);
  const loginError = useUserStore((state) => state.logInError);
  const setClearErrors = useUserStore((state) => state.setClearErrors);
  const navigate = useNavigate();

  const {
    register,
    formState: { errors, isDirty, isValid },
    handleSubmit,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(logInSchema),
  });

  const onSubmit = async (formData: any) => {
    const isLogged = await doLogIn(formData);
    isLogged && navigate('/dashboard');
  };

  useEffect(() => {
    if (loginError) {
      setClearErrors();
    }
  }, [setClearErrors, loginError]);

  const viewLogin = useCallback(() => {
    viewLoginEvent();
  }, []);
  useEffect(() => {
    viewLogin();
  }, [viewLogin]);

  return (
    <div className="flex flex-col h-screen px-4 mx-auto align-middle sm:px-0 sm:w-2/3 md:w-2/3 lg:w-2/4 xl:w-2/5">
      <FormHeader
        title="Log In"
        subtitle="Automatically convert landscape videos into vertical ones with Big Room AI"
        buttonText="Log In with Google"
        showGoogle={true}
        onSuccess={() => console.info('success')}
        onFail={() => console.info('failure')}
      />

      <form onSubmit={handleSubmit(onSubmit)} className="grid grid-cols-6 mt-2 gap-y-1">
        <div className="col-span-6">
          <Input label="Email address" type="email" inputName="email" errors={errors} validation={register('email')} />
        </div>

        <div className="col-span-6 ">
          <Input
            label="Password"
            type="password"
            inputName="password"
            errors={errors}
            validation={register('password')}
          />
        </div>

        {loginError && <p className="col-span-6 mt-1 mb-4 text-xs text-red-400">{loginError}</p>}

        <div className="flex items-center justify-start col-span-6 -mt-1 sm:justify-center sm:text-center sm:flex sm:gap-4">
          <Link
            to={`/forget-password`}
            onClick={clickToForgetPasswordEvent}
            className="ml-1 text-xs text-gray-800 underline sm:text-sm"
          >
            Forgot password?
          </Link>
        </div>

        <div className="col-span-6 sm:flex sm:items-center sm:gap-2">
          <Button
            disabled={!isDirty || !isValid || isLoading}
            type="submit"
            isLoading={isLoading}
            text="Log In with Email"
          />
        </div>

        <div className="flex justify-center col-span-6 mt-4 sm:flex sm:items-center sm:gap-4">
          <p className="mt-1 text-sm text-center text-gray-500 sm:mt-4">
            Don't have an account?
            <Link to={`/signup`} className="ml-2 font-semibold text-gray-700 underline">
              Sign Up
            </Link>
          </p>
        </div>
      </form>
    </div>
  );
}

export default Login;
