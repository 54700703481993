import { useCallback, useMemo } from 'react';
// Library
import ReactPaginate from 'react-paginate';

//Icons
import previousPageIcon from '../../../../assets/icon/previusPageIcon.svg';
import nextPageIcon from '../../../../assets/icon/nextPageIcon.svg';

// Zustans - Global state
import useVerticalizedStore from '../../../../store/useVerticalized.store';

const MyVideosPagination = () => {
  const paginationInfo = useVerticalizedStore((state) => state.myVideosPagination);
  const doPaginate = useVerticalizedStore((state) => state.setMyVideos);
  const setMyVideosCurrentPage = useVerticalizedStore((state) => state.setMyVideosCurrentPage);
  const myVideosCurrentPage = useVerticalizedStore((state) => state.myVideosCurrentPage);
  const pageCount = paginationInfo.pageCount;
  const hasPagination = pageCount > 1;

  const _handlePagination = useCallback(
    (event) => {
      doPaginate(event.selected + 1);
      setMyVideosCurrentPage(event.selected + 1);
      localStorage.setItem('myVideosCurrentPage', event.selected + 1);
    },
    [doPaginate, setMyVideosCurrentPage]
  );

  const getPageFromStorage = parseInt(localStorage.getItem('myVideosCurrentPage') || '');
  const currentPage = useMemo(
    () => (isNaN(getPageFromStorage) ? myVideosCurrentPage - 1 : getPageFromStorage - 1),
    [getPageFromStorage, myVideosCurrentPage]
  );

  return hasPagination ? (
    <section className="flex justify-center w-40 m-auto md:w-80">
      <ReactPaginate
        containerClassName="flex justify-center gap-2 md:gap-5 text-xs font-base mt-12 w-52 md:w-80 m-auto items-end"
        pageClassName="py-1 px-2.5 w-7 h-7 flex items-center justify-center text-sm md:text-base font-base font-gray-900 rounded-lg"
        activeClassName="text-white bg-gray-900 font-semibold"
        breakLabel="..."
        previousLabel={
          <span className="inline-flex items-center justify-center w-8 px-2 py-1 rounded-lg h-7">
            <img src={previousPageIcon} alt="prev page" />
          </span>
        }
        nextLabel={
          <span className="inline-flex items-center justify-center w-8 px-2 py-1 rounded-lg h-7">
            <img src={nextPageIcon} alt="next page" />
          </span>
        }
        pageCount={pageCount}
        forcePage={currentPage}
        pageRangeDisplayed={2}
        marginPagesDisplayed={2}
        onPageChange={_handlePagination}
        renderOnZeroPageCount={undefined}
      />
    </section>
  ) : null;
};

export default MyVideosPagination;
