// Zustand - Global state
import axios from './base.service';
import instance from 'axios';
import useUserStore from '../store/useUser.store';
import { youtubeResponseValidations } from '../views/Dashboard/utils/youtubeValidations';
import { dashboardUploadErrorEvent } from '../utils/mixpanelEvents';
import { setToken } from '../utils/setToken';

const resource = 'offline-jobs';

export const submitData = async (uploadData: any, options: any) => {
  const config = {
    ...options,
  };
  try {
    const { data, status } = await axios.post(resource, uploadData, config);
    const response = data.data.attributes;
    if (status === 200) {
      if (uploadData.data.youtube_url) {
        return youtubeResponseValidations(response);
      }
      return response;
    }
  } catch (error) {
    console.info(error);
  }
};

export const uploadVideo = async (
  presignedUrl: string,
  video: any,
  options: any,
  jobId: string,
  setSuccess: any,
  retryAttempt: number,
  setErrorPopup: any
) => {
  const config = {
    headers: {
      'Content-Type': video.type,
    },
    ...options,
  };

  try {
    const { status } = await axios.put(presignedUrl, video, config);
    if (status === 200) {
      setSuccess(true);
      // await axios.put(`${resource}/${jobId}`, { upload_done: true });
    }
  } catch (error: any) {
    if (error.message !== 'canceled') {
      const userId = useUserStore.getState()?.user.id;
      dashboardUploadErrorEvent(userId);
    }

    if (error.message !== 'canceled' && retryAttempt < 3) {
      uploadVideo(presignedUrl, video, options, jobId, setSuccess, retryAttempt + 1, setErrorPopup);
    } else if (retryAttempt >= 3) {
      setErrorPopup(true);
    }
  }
};

export const uploadMedia = async (presignedUrl: string, media: any, options: any, setSuccess: any) => {
  const config = {
    headers: {
      'Content-Type': media.type,
    },
    ...options,
  };

  try {
    const { status } = await axios.put(presignedUrl, media, config);
    if (status === 200) {
      setSuccess(true);
    }
  } catch (error: any) {
    if (error.message !== 'canceled') {
      const userId = useUserStore.getState()?.user.id;
      dashboardUploadErrorEvent(userId);
    }
    console.info(error);
  }

  setToken();
};

export const uploadedSuccess = async (jobId: string, hasError = false, errorMessage: string = '') => {
  const uploadDone = { upload_done: true };
  const uploadError = { is_error: true, error_message: errorMessage };

  const request = {
    data: hasError ? uploadError : uploadDone,
  };
  try {
    const { data, status } = await axios.put(`${resource}/${jobId}`, request);
    const response = data.data.attributes;
    if (status === 200) {
      return response;
    }
  } catch (error) {
    console.info(error);
  }
};

export const uploadTrimming = async (jobId: string, trimmingData: any) => {
  const request = {
    data: trimmingData,
  };
  const { data, status } = await axios.put(`${resource}/${jobId}`, request);
  const response = data.data.attributes;
  if (status === 200) {
    return response;
  }
};

export const cancelTrimming = async (jobId: string) => {
  const request = {
    data: {
      delete: true,
      error_message: 'user cancel',
    },
  };
  try {
    const { data, status } = await axios.put(`${resource}/${jobId}`, request);
    const response = data.data.attributes;
    if (status === 200) {
      return response;
    }
  } catch (error) {
    console.info(error);
  }
};

export const uploadMediaWithoutToken = async (presignedUrl: string, media: any, options: any, setSuccess: any) => {
  const config = {
    headers: {
      'Content-Type': media.type,
    },
    ...options,
  };

  try {
    const { status } = await instance.put(presignedUrl, media, config);
    if (status === 200) {
      setSuccess(true);
    }
  } catch (error: any) {
    if (error.message !== 'canceled') {
      const userId = useUserStore.getState()?.user.id;
      dashboardUploadErrorEvent(userId);
    }
    console.info(error);
  }
};
