export const truncateLargeName = (name: string) => {
  if (!name) return '...';
  if (name.length > 20) {
    const first10 = name.slice(0, 10);
    const last10 = name.slice(-10);
    return `${first10}. . .${last10}`;
  }
  return name;
};

export const truncateVideoNames = (name: string) => {
  if (!name) return '...';
  if (name.length > 15) {
    const first10 = name.slice(0, 8);
    const last10 = name.slice(-5);
    return `${first10}. . .${last10}`;
  }
  return name;
};

export const truncateLargeNameForDownloadView = (name: string) => {
  if (!name) return '...';
  if (name.length > 30) {
    const first13 = name.slice(0, 13);
    const last15 = name.slice(-15);
    return `${first13}. . .${last15}`;
  }
  return name;
};

export const truncateLargeNameMulticam = (name: string) => {
  if (!name) return '...';
  if (name.length > 25) {
    const first15 = name.slice(0, 10);
    const last15 = name.slice(-10);
    return `${first15}. . .${last15}`;
  }
  return name;
};

export const truncateMediaNames = (mediaName: string) => {
  if (mediaName.length > 110) {
    const firstPart = mediaName.slice(0, 100);
    const lastPart = mediaName.slice(-10);
    return `${firstPart}${lastPart}`;
  }
  return mediaName;
};
