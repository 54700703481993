import React from 'react';

// Zustand - Global state
import useAppStore from '../../../../store/useApp.store';
import useVerticalizedStore from '../../../../store/useVerticalized.store';
import useUserStore from '../../../../store/useUser.store';

// Mixpanel Events
import { clickDeleteCompletedVideoEvent } from '../../../../utils/mixpanelEvents';

// Components
import Modal from './Modal';
import OutsideClickDetector from '../../../Shared/outsideClickDetector/OutsideClickDetector';

// Icons
import { TfiClose } from 'react-icons/tfi';
import { Transition } from '@headlessui/react';

const PaymentReceivedModal = () => {
  const showPaymentReceivedModal = useAppStore((state) => state.showPaymentReceivedModal);
  const setPaymentReceivedModal = useAppStore((state) => state.setPaymentReceivedModal);
  const getAndSetUserSubscription = useUserStore((state) => state.getAndSetUserSubscription);

  
  const _handleClick = () => {
    setPaymentReceivedModal(false)
    getAndSetUserSubscription()
  };

  return (
    <Modal show={showPaymentReceivedModal}>
      <Transition
        appear={true}
        show={showPaymentReceivedModal}
        className="fixed left-0 transition-all duration-200 translate-x-[calc(50vw-50%)]"
        enterFrom="translate-y-[100vh]"
        enterTo="translate-y-[calc(50vh-50%)]"
        leaveFrom="translate-y-[calc(50vh-50%)]"
        leaveTo="translate-y-[100vh]"
      >
        <OutsideClickDetector callback={() => _handleClick()}>
          <main className={`relative max-w-96 w-80 md:w-[440px]`}>
            <div className="p-6 overflow-hidden bg-gray-100 border shadow-lg rounded-xl md:p-10 border-gray-50">
              <button
                onClick={() => _handleClick()}
                className={`absolute top-2 right-2 p-1 cursor-pointer`}
              >
                <TfiClose className="w-6" />
              </button>
              <section className="pt-2 text-center">
                <p className="font-semibold text-bg">Thank you, payment received</p>
                <div className="flex items-center justify-center gap-6 pt-6">
                  <button
                    className={`border-gray-500 text-white bg-black px-6 py-3 text-sm font-semibold border rounded-xl w-44 hover:text-black hover:bg-white hover:border-black transition-all duration-200`}
                    onClick={() => _handleClick()}
                  >
                    Okay
                  </button>
                </div>
              </section>
            </div>
          </main>
        </OutsideClickDetector>
      </Transition>
    </Modal>
  );
};

export default PaymentReceivedModal;
