/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useCallback, useMemo, useEffect } from 'react';

// Utils
import { specialCharArray } from '../../../utils/specialCharArray';
import { padTo2Digits, bytesToMegabytes } from '../utils/math.utils';

// Zustand - Global state
import useVerticalizedStore from '../../../store/useVerticalized.store';
import useUserStore from '../../../store/useUser.store';
import useAppStore from '../../../store/useApp.store';
import useDynamicZoomStore from '../../../store/useDynamicZoom.store';

// Mixpanel Events
import { dashboardUploadFailedEvent } from '../../../utils/mixpanelEvents';

// File Validations
import {
  minimumResolution,
  videoDurationLimit,
  maximunSizeAllowed,
  durationErrorMsg,
  widthErrorMsg,
  sizeErrorMsg,
  urlErrorMsg,
  youtubeUrlErrorMsg,
  nonValidUrlErrorMsg,
  resolutionErrorMsg,
  youtubeApiDownErrorMsg,
  liveVideosUnsopportedMsg,
} from '../utils/fileValidation';

interface Props {
  functionalityStore: typeof useVerticalizedStore | typeof useDynamicZoomStore;
}

export const useFile = ({ functionalityStore }: Props) => {
  const user = useUserStore((state) => state.user);
  const setFileName = functionalityStore((state) => state.setFileName);
  const setYoutubeUrl = functionalityStore((state) => state.setYoutubeUrl);
  const youtubeUrlData = functionalityStore((state) => state.youtubeUrlData);
  const youtubeUrl = functionalityStore((state) => state.youtubeUrl);
  const submitData = functionalityStore((state) => state.submitFile);
  const submitResponse = functionalityStore((state) => state.submitResponse);
  const uploadFile = functionalityStore((state) => state.uploadFile);
  const uploadDone = functionalityStore((state) => state.uploadDone);
  const setUploadDone = functionalityStore((state) => state.setUploadDone);
  const setUploadedSuccess = functionalityStore((state) => state.setUploadedSuccess);
  const setUploadStep = functionalityStore((state) => state.setUploadStep);
  const uploadStep = functionalityStore((state) => state.uploadStep);
  const setLocalVideoUrl = functionalityStore((state) => state.setLocalVideoUrl);
  const setHasUploadedInterval = functionalityStore((state) => state.setHasUploadedInterval);
  const hasUploadedInterval = functionalityStore((state) => state.hasUploadedInterval);
  const isDurationUrlInvalid = functionalityStore((state) => state.isDurationUrlInvalid);
  const setIsDurationUrlInvalid = functionalityStore((state) => state.setDurationUrlInvalid);
  const isResolutionUrlInvalid = functionalityStore((state) => state.isResolutionUrlInvalid);
  const setResolutionUrlInvalid = functionalityStore((state) => state.setResolutionUrlInvalid);
  const isYoutubeUrlInvalid = functionalityStore((state) => state.isUrlInvalid);
  const isNonValidYoutubeUrl = functionalityStore((state) => state.isYoutubeUrlInvalid);
  const isYoutubeApiDown = functionalityStore((state) => state.isYoutubeApiDown);
  const setYoutubeUrlInvalid = functionalityStore((state) => state.setYoutubeUrlInvalid);

  const isLiveVideo = functionalityStore((state) => state.isLiveVideo);
  const setIsLiveVideo = functionalityStore((state) => state.setIsLiveVideo);

  const setUrlInvalid = functionalityStore((state) => state.setUrlInvalid);
  const fileUploaded = functionalityStore((state) => state.fileUploaded);
  const setIsYoutubeApiDown = functionalityStore((state) => state.setIsYoutubeApiDown);
  const setVideoTrimmingDuration = functionalityStore((state) => state.setVideoTrimmingDuration);

  const setUploadTrimming = functionalityStore((state) => state.setUploadTrimming);
  const cancelTrimming = functionalityStore((state) => state.setCancelTrimming);
  const isCancelTrimmingModalActive = useAppStore((state) => state.cancelTrimmingModal);
  const setCancelTrimmingModal = useAppStore((state) => state.setCancelTrimmingModal);
  const isInProgressTrimmed = functionalityStore((state) => state.isInProgressTrimmed);

  const [file, setFile] = useState<File>();
  const [controller, setController] = useState(new AbortController());
  const [progress, setProgress] = useState(0);
  // const [uploadDone, setUploadDone] = useState(false);

  // TODO: should be applied in local videoLoaded?
  interface UploadDataProgress {
    loaded: number;
    total: number;
  }

  const options = {
    onUploadProgress: (data: UploadDataProgress) => {
      let dataLoaded = Math.round((100 * data.loaded) / data.total);
      //Set the progress value to show the progress bar
      setProgress(dataLoaded);
    },
    signal: controller.signal,
  };

  // file state validators
  const [isWidthInvalid, setIsWidthInvalid] = useState(false);
  const [isResolutionInvalid, setIsResolutionInvalid] = useState(false);
  const [isDurationInvalid, setIsDurationInvalid] = useState(false);
  const [isUrlInvalid, setIsUrlInvalid] = useState(false);
  const [videoDuration, setVideoDuration] = useState<string>();

  const fileName = useMemo(() => {
    let _fileName = file ? file.name : undefined;
    if (_fileName) {
      specialCharArray.forEach((char) => {
        _fileName = _fileName!.replaceAll(char, '_');
      });
    }
    setFileName(_fileName ?? null);
    return _fileName;
  }, [file]);

  const fileSize = useMemo(() => (file ? bytesToMegabytes(file.size) : null), [file]);
  const isSizeInvalid = useMemo(() => fileSize && fileSize > maximunSizeAllowed, [fileSize, file]);

  const hasError =
    isWidthInvalid ||
    isResolutionInvalid ||
    isDurationInvalid ||
    isSizeInvalid ||
    isUrlInvalid ||
    isDurationUrlInvalid ||
    isResolutionUrlInvalid ||
    isYoutubeUrlInvalid ||
    isNonValidYoutubeUrl ||
    isYoutubeApiDown ||
    isLiveVideo;

  const canShowButton = useMemo(() => hasError || !file, [hasError, file]);
  const canBeUploaded = useMemo(() => Boolean(!hasError && file), [hasError, file]);

  const getVideoDurationFormatted = useCallback((duration) => {
    if (!duration) {
      return 'Not available';
    }
    const durationNum = duration * 1;
    let seconds = Math.floor(durationNum);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);

    seconds = seconds % 60;
    minutes = minutes % 60;
    hours = hours % 24;

    const hasSecondsOrMinutes = minutes ? 'minutes' : 'seconds';
    const durationFormmated = `${padTo2Digits(hours)}:${padTo2Digits(minutes)}:${padTo2Digits(
      seconds
    )} ${hasSecondsOrMinutes}`;
    setVideoDuration(durationFormmated);
    return durationFormmated;
  }, []);

  const handleVideo = (e: any, file: File) => {
    setIsWidthInvalid(false);
    setIsResolutionInvalid(false);
    setIsDurationInvalid(false);

    const { target } = e;
    const durationValidation = target?.duration > videoDurationLimit * 60;
    const widthValidation = target?.videoWidth < target?.videoHeight;
    const resolutionValidation = target?.videoHeight < minimumResolution;

    widthValidation && setIsWidthInvalid(true);
    durationValidation && setIsDurationInvalid(true);
    resolutionValidation && setIsResolutionInvalid(true);

    setVideoTrimmingDuration(target.duration);
    getVideoDurationFormatted(target.duration);

    file && setFile(file);
  };

  const fileValidator = (file: File) => {
    setFile(undefined);
    setYoutubeUrl('');
    const video = document.createElement('video');
    video.src = URL.createObjectURL(file);
    video.preload = 'metadata';
    video.onloadedmetadata = function (e) {
      handleVideo(e, file);
    };
    return null;
  };

  const urlValidator = async (url: string) => {
    setIsWidthInvalid(false);
    setIsResolutionInvalid(false);
    setIsDurationInvalid(false);
    setIsDurationUrlInvalid(false);
    setResolutionUrlInvalid(false);
    setYoutubeUrlInvalid(false);
    setUrlInvalid(false);
    setIsLiveVideo(false);

    setIsYoutubeApiDown(false);

    if (!url) {
      setIsUrlInvalid(false);
      return;
    }
    // const urlRgx = /(youtu.*be.*)\/(watch\?v=|embed\/|v|shorts|)(.*?((?=[&#?])|$))/
    const urlRgx =
      /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|v\/)?)([\w-]+)(\S+)?$/;
    const isUrlValid = urlRgx.test(url);

    if (!isUrlValid) {
      setIsUrlInvalid(true);
      return;
    }

    if (url.includes('/shorts/')) {
      setIsWidthInvalid(true);
      return;
    }

    if (isUrlValid) {
      setIsUrlInvalid(false);
      setFileName(null);
      setYoutubeUrl(url);
      return;
    }
  };

  const removeFile = () => {
    setFile(undefined);
    setIsWidthInvalid(false);
    setIsDurationInvalid(false);
    setIsResolutionInvalid(false);
  };

  const cancelUpload = () => {
    removeFile();
    setUploadStep(0);
    dashboardUploadFailedEvent(user.id);
    controller.abort();
    setController(new AbortController());
  };

  /* This useEffect cancels jobs without trimming if 10min have passed */
  // useEffect(() => {
  //   if (uploadStep === 3 && (youtubeUrl || uploadDone)) {
  //     const timeOut = setTimeout(() => {
  //       setUploadStep(4);
  //       setUploadTrimming();
  //     }, 600000)
  //     return () => clearTimeout(timeOut)
  //   }
  // }
  //   , [uploadStep, youtubeUrl, uploadDone])

  // Handle connection logic
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const hasConnectionError = useMemo(() => !isOnline, [isOnline]);

  const updateConnectionStatus = useCallback(() => {
    setIsOnline(() => navigator.onLine);
  }, [isOnline]);

  useEffect(() => {
    isOnline && window.addEventListener('offline', updateConnectionStatus);
    !isOnline && window.addEventListener('online', updateConnectionStatus);

    //Posibble retry logic
    // _checkForConnectionAndUpload(uploadedData, isOnline);

    return () => {
      window.removeEventListener('offline', updateConnectionStatus);
      window.removeEventListener('online', updateConnectionStatus);
    };
  }, [isOnline]);

  const handleTabClosing = useCallback(() => {
    if (uploadStep === 3 && (youtubeUrl || uploadDone)) {
      // cancelTrimming()
      // setUploadTrimming
    }
  }, [uploadStep, youtubeUrl, uploadDone]);

  const alertUser = useCallback(
    (ev) => {
      ev.preventDefault();
      if (uploadStep === 3 && (youtubeUrl || uploadDone)) {
        // cancelTrimming()
        // setUploadTrimming()
      }
    },
    [uploadStep, youtubeUrl, uploadDone]
  );

  useEffect(() => {
    window.addEventListener('beforeunload', alertUser);
    setTimeout(() => {
      window.addEventListener('unload', handleTabClosing);
    }, 1000);
    return () => {
      window.removeEventListener('beforeunload', alertUser);
      window.removeEventListener('unload', handleTabClosing);
    };
  }, [uploadStep, youtubeUrl, uploadDone]);

  const uploadState = useMemo(() => {
    if (hasConnectionError) {
      return "Oops...Couldn't upload that";
    }
    if (file && uploadStep === 4) {
      return 'Upload complete!';
    }
    if (uploadStep === 4 && youtubeUrl) {
      return 'YouTube video uploaded';
    }
    if (uploadStep === 4 && isInProgressTrimmed) {
      return 'Video trimmed';
    }
    if (canBeUploaded) {
      return 'Step 1 of 2';
    }
    return 'Drag & Drop or';
  }, [canBeUploaded, hasConnectionError, uploadDone, submitResponse, uploadStep, youtubeUrl, isInProgressTrimmed]);

  const getVideoSrc = (file: File) => {
    const videoUrl = URL.createObjectURL(file);
    setLocalVideoUrl(videoUrl);
    return `${videoUrl}#t=2.8`;
  };

  const Thumbnail = useMemo(() => {
    if (!file) return null;
    return (
      <div className="relative w-12 h-12 overflow-hidden border border-red-900 rounded-xl">
        <video src={file ? getVideoSrc(file) : submitResponse?.thumbnail} className="absolute w-24 max-w-none" />
      </div>
    );
  }, [file]);

  const ThumbnailYoutube = useMemo(() => {
    return (
      <div className="relative w-16 h-12 overflow-hidden rounded-xl">
        <img
          src={submitResponse.youtube_info?.thumbnail ?? submitResponse?.thumbnail}
          className="absolute w-20 max-w-none"
          alt="youtube thumbail"
        />
      </div>
    );
  }, [submitResponse]);

  const FileErrors = useMemo(
    () => (
      <section className="mt-4 text-xs text-center text-error-message">
        <p>{isWidthInvalid && widthErrorMsg}</p>
        <p>{isDurationInvalid && durationErrorMsg}</p>
        <p>{isResolutionInvalid && resolutionErrorMsg}</p>
        <p>{isSizeInvalid && sizeErrorMsg}</p>
        <p>{isUrlInvalid && urlErrorMsg}</p>
        {hasError && !youtubeUrl && <p className="font-medium text-error-message">Please pick a different video.</p>}
      </section>
    ),
    [hasError]
  );

  const UrlErrors = useMemo(
    () => (
      <section className="mt-2 text-xs text-error-message">
        <p>{isWidthInvalid && widthErrorMsg}</p>
        <p>{isDurationUrlInvalid && durationErrorMsg}</p>
        <p>{isResolutionUrlInvalid && resolutionErrorMsg}</p>
        <p>{isSizeInvalid && sizeErrorMsg}</p>
        <p>{isUrlInvalid && nonValidUrlErrorMsg}</p>
        <p>{isNonValidYoutubeUrl && youtubeUrlErrorMsg}</p>
        <p>{isYoutubeApiDown && youtubeApiDownErrorMsg}</p>
        <p>{isLiveVideo && liveVideosUnsopportedMsg}</p>
      </section>
    ),
    [
      hasError,
      isDurationUrlInvalid,
      isResolutionUrlInvalid,
      isYoutubeUrlInvalid,
      isNonValidYoutubeUrl,
      isYoutubeApiDown,
      isLiveVideo,
    ]
  );

  const FilesAllowedToUpload = () => {
    return (
      !file &&
      !hasConnectionError && (
        <p className="mt-6 text-xs text-gray-800">
          You can upload <b>.mp4</b> and <b>.mov</b> files only
        </p>
      )
    );
  };

  const ConnectionError = () => {
    return hasConnectionError ? (
      <section className="-mt-1 text-xs font-normal text-center text-error-message mb-7">
        <p>Check your internet connection before</p>
        <p>giving it another go.</p>
      </section>
    ) : null;
  };

  const UploadingSection = () => {
    return canBeUploaded && !hasConnectionError && !uploadDone ? (
      <section className="text-center">
        <p className="-mt-1 text-xs font-light text-gray-700 md:text-sm">
          Don't close the page or you'll lose your upload.
        </p>
        <p onClick={cancelUpload} className="text-xs font-medium text-gray-800 underline cursor-pointer md:text-sm">
          Cancel upload
        </p>
      </section>
    ) : null;
  };

  const UploadedSection = () => {
    return uploadDone && !hasConnectionError ? (
      <section className="text-center">
        <p className="px-10 pb-4 -mt-1 text-xs font-light text-gray-800 md:text-bg-sm">
          Check your dashboard or email - you'll be notified when your video is complete. Edits can take up to a few
          hours.
        </p>
      </section>
    ) : null;
  };

  const uploadSubmit = useCallback(async () => {
    canBeUploaded && (await submitData());
    if (!youtubeUrl) {
      setUploadDone(false);
      setProgress(0);
    }
  }, [canBeUploaded]);

  useEffect(() => {
    setProgress(0);
    canBeUploaded && uploadSubmit();
  }, [canBeUploaded]);

  useEffect(() => {
    // TODO: improve retry logic
    if (isOnline) {
      if (submitResponse && file && fileName) {
        const media = new File([file], fileName);
        uploadFile(
          submitResponse?.presigned_video_url ? submitResponse?.presigned_video_url[0] : submitResponse?.presigned_url,
          media,
          options,
          setUploadDone
        );
        return;
      }
    } else {
      setProgress(0);
      controller.abort();
      setController(new AbortController());
    }
  }, [submitResponse, isOnline]);

  const submitUploadSuccess = useCallback(() => {
    if (uploadDone && file) {
      setUploadedSuccess(submitResponse.uid);
    }
  }, [uploadDone]);

  useEffect(() => {
    //TODO: fileUploaded and file are just for upload file, we need it for youtube as well
    // if (uploadDone && !!fileUploaded && !!file) {
    if (uploadDone && uploadStep !== 4) {
      setUploadStep(3);
    }
    submitUploadSuccess();
  }, [uploadDone]);

  useEffect(() => {
    if (hasUploadedInterval) {
      const interval = setInterval(() => {
        setHasUploadedInterval(false);
      }, 180000);
      return () => clearInterval(interval);
    }
  }, [hasUploadedInterval]);

  return {
    // Properties
    file,
    fileName,
    youtubeUrl,
    videoDuration,
    canShowButton,
    canBeUploaded,
    hasError,
    hasConnectionError,
    progress,
    uploadDone,
    youtubeUrlData,
    // Methods
    uploadState,
    removeFile,
    setUploadDone,
    fileValidator,
    urlValidator,
    getVideoDurationFormatted,
    // Components
    Thumbnail,
    ThumbnailYoutube,
    FileErrors,
    UrlErrors,
    ConnectionError,
    UploadingSection,
    UploadedSection,
    FilesAllowedToUpload,
    cancelUpload,
  };
};
