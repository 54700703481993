import React, { useCallback } from 'react';

// UI Components
import VerticalizedTab from './components/Verticalized';
import MulticameraTab from './components/Multicamera';
import DynamicZoomTab from './components/DynamicZoom';

// Layout
import DashboardLayout from './layout/DashboardLayout';

// Zustand - Global state
import useAppStore from '../../store/useApp.store';

const DashboardExperience = () => {
  const currentTab = useAppStore((state) => state.currentTab);

  const ShowTab = useCallback(() => {
    if (currentTab === 1) return <MulticameraTab />;
    if (currentTab === 2) return <DynamicZoomTab />;
    else return <VerticalizedTab />;
  }, [currentTab]);

  return (
    <DashboardLayout>
      {/* <Tab.Group defaultIndex={0} selectedIndex={currentTab} onChange={setCurrentTab}>
        <Tab.List
          className={`hidden md:flex flex-row justify-between w-screen h-10 items-center text-sm absolute left-0 top-0 focus:ring-transparent ring-transparent z-10`}
        >
          <Tab
          className={`
          flex w-1/3 focus:ring-transparent hover:bg-black hover:text-white h-full text-center  justify-center items-center
          ${currentTab === 0 ? 'bg-teal-green  font-semibold' : 'bg-gray-400'}
          `}>
            Landscape to Vertical
          </Tab>
          <Tab
          disabled={!flags.Multicamera}
          className={`
          flex w-1/3 focus:ring-transparent flex-row items-center justify-center gap-4 h-full text-center group hover:bg-black hover:text-white
          ${currentTab === 1 ? 'bg-teal-green  font-semibold' : 'bg-gray-300'}`}>
            Multicamera AI
            {!flags.Multicamera && <Badge />}
          </Tab>
          <Tab
          disabled={!flags.DynamicZoom}
          className={`
          flex w-1/3 focus:ring-transparent flex-row items-center justify-center gap-4 h-full text-center group hover:bg-black hover:text-white
          ${currentTab === 2 ? 'bg-teal-green  font-semibold' : 'bg-gray-200'}`}>
            Dynamic Zoom AI
            {!flags.DynamicZoom && <Badge />}
          </Tab>
        </Tab.List>

        <Tab.Panels className={`mt-12`}>
          <Tab.Panel>
            <VerticalizedTab />
          </Tab.Panel>

          <Tab.Panel>
            <MulticameraTab />
          </Tab.Panel>

          <Tab.Panel>
            <DynamicZoomTab />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group> */}

      <ShowTab />
    </DashboardLayout>
  );
};

export default DashboardExperience;
