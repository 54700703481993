import create from 'zustand';

// Service
import { getFlags as doGetFlags } from '../services/app.service';

// Utils
import { setFlagsToLocalStorage } from '../utils/flags';
import useDynamicZoomStore from './useDynamicZoom.store';
import useVerticalizedStore from './useVerticalized.store';

export interface Flags {
  Login: boolean;
  Verticalized: boolean;
  Dashboard: boolean;
  MultiFiles: boolean;
  Subscription: boolean;
  Subtitles: boolean;
  Multicamera: boolean;
  DynamicZoom: boolean;
}

interface AppStore {
  flags: Flags;
  isLogInLoading: boolean;
  isUploadByUrlLoading: boolean;
  isSignUpLoading: boolean;
  isResetPasswordLoading: boolean;
  isForgotPasswordLoading: boolean;
  uploadModal: boolean;
  uploadDynamicZoomModal: boolean;
  uploadMulticameraModal: boolean;
  downloadModal: boolean;
  downloadDynamicModal: boolean;
  deleteModal: boolean;
  featuresModal: boolean;
  cancelTrimmingModal: boolean;
  sideMenu: boolean;
  currentTab: number;
  createMulticameraProjectModal: boolean;
  confirmGenerateCaptionsModal: boolean;
  chooseAnotherMasterAudioModal: boolean;

  currentFunctionality: typeof useVerticalizedStore | typeof useDynamicZoomStore;

  showPaymentReceivedModal: boolean;

  generatingCaptionModal: boolean;
  cancelCaptionModal: boolean;
  confirmMixModal: boolean;
  showRemoveWatermarkModal: boolean;
  removeWatermarkJobId: any;
  uploadAudioModal: boolean;

  setDownloadDynamicModal: (status: boolean) => void;
  setUploadDynamicZoomModal: (status: boolean) => void;
  setPaymentReceivedModal: (status: boolean) => void;
  getFlags: () => Promise<void>;
  setLoginLoading: (value: boolean) => void;
  setUrlUploadingLoading: (value: boolean) => void;
  setSignUpLoading: (value: boolean) => void;
  setResetPasswordLoading: (value: boolean) => void;
  setForgotPasswordLoading: (value: boolean) => void;
  setUploadModal: (value: boolean) => void;
  setUploadMulticameraModal: (value: boolean) => void;
  setChooseAnotherMasterAudioModal: (value: boolean) => void;
  setDownloadModal: (value: boolean) => void;
  setDeleteModal: (value: boolean) => void;
  setFeaturesModal: (value: boolean) => void;
  setCancelTrimmingModal: (value: boolean) => void;
  setShowRemoveWatermarkModal: (status: boolean) => void;
  setRemoveWatermarkJobId: (jobId: string) => void;
  setCancelCaptionModal: (status: boolean) => void;
  setGeneratingCaptionModal: (status: boolean) => void;
  setSideMenu: (status: boolean) => void;
  setCurrentTab: (tab: number) => void;
  setCreateMulticameraProjectModal: (status: boolean) => void;
  setConfirmGenerateCaptionsModal: (status: boolean) => void;
  setCurrentFunctionality: (func: typeof useVerticalizedStore | typeof useDynamicZoomStore) => void;
  setConfirmMixModal: (status: boolean) => void;
  setUploadAudioModal: (status: boolean) => void;
}

const defaultFlags: Flags = {
  Login: true,
  Verticalized: true,
  Dashboard: true,
  MultiFiles: true,
  Subscription: false,
  Subtitles: false,
  Multicamera: true,
  DynamicZoom: true,
};

const useAppStore = create<AppStore>(
  (set): AppStore => ({
    // state
    flags: defaultFlags,
    isLogInLoading: false,
    isUploadByUrlLoading: false,
    isSignUpLoading: false,
    isResetPasswordLoading: false,
    isForgotPasswordLoading: false,
    uploadModal: false,
    uploadDynamicZoomModal: false,
    uploadMulticameraModal: false,
    downloadModal: false,
    deleteModal: false,
    featuresModal: false,
    cancelTrimmingModal: false,
    sideMenu: false,
    currentTab: 0,
    createMulticameraProjectModal: false,
    downloadDynamicModal: false,
    chooseAnotherMasterAudioModal: false,
    confirmMixModal: false,

    currentFunctionality: useVerticalizedStore,
    showPaymentReceivedModal: false,

    generatingCaptionModal: false,
    cancelCaptionModal: false,
    showRemoveWatermarkModal: false,
    removeWatermarkJobId: null,
    confirmGenerateCaptionsModal: false,
    uploadAudioModal: false,

    // actions
    setDownloadDynamicModal: (status: boolean) => {
      set((state: AppStore) => ({ ...state, downloadDynamicModal: status }));
    },
    setCurrentFunctionality: (func: typeof useVerticalizedStore | typeof useDynamicZoomStore) => {
      set((state: AppStore) => ({ ...state, currentFunctionality: func }));
    },
    setUploadDynamicZoomModal: (status: boolean) => {
      set((state: AppStore) => ({ ...state, uploadDynamicZoomModal: status }));
    },
    setPaymentReceivedModal: (status: boolean) => {
      set((state: AppStore) => ({ ...state, showPaymentReceivedModal: status }));
    },
    getFlags: async () => {
      try {
        const flags = await doGetFlags();
        if (Boolean(Number(process.env.REACT_APP_LOCAL_FLAGS))) {
          set((state: AppStore) => ({ ...state, flags: defaultFlags }));
          setFlagsToLocalStorage(defaultFlags);
        } else {
          set((state: AppStore) => ({ ...state, flags: flags }));
          setFlagsToLocalStorage(flags);
        }
      } catch (error) {
        set((state: AppStore) => ({ ...state, flags: defaultFlags }));
        setFlagsToLocalStorage(defaultFlags);
        console.info(error);
      }
    },
    setLoginLoading: (status: boolean) => {
      set((state: AppStore) => ({ ...state, isLogInLoading: status }));
    },
    setSignUpLoading: (status: boolean) => {
      set((state: AppStore) => ({ ...state, isSignUpLoading: status }));
    },
    setUrlUploadingLoading: (status: boolean) => {
      set((state: AppStore) => ({ ...state, isUploadByUrlLoading: status }));
    },
    setResetPasswordLoading: (status: boolean) => {
      set((state: AppStore) => ({ ...state, isResetPasswordLoading: status }));
    },
    setForgotPasswordLoading: (status: boolean) => {
      set((state: AppStore) => ({ ...state, isForgotPasswordLoading: status }));
    },
    setUploadModal: (status: boolean) => {
      set((state: AppStore) => ({ ...state, uploadModal: status }));
    },
    setUploadMulticameraModal: (status: boolean) => {
      set((state: AppStore) => ({ ...state, uploadMulticameraModal: status }));
    },
    setDownloadModal: (status: boolean) => {
      set((state: AppStore) => ({ ...state, downloadModal: status }));
    },
    setDeleteModal: (status: boolean) => {
      set((state: AppStore) => ({ ...state, deleteModal: status }));
    },
    setFeaturesModal: (status: boolean) => {
      set((state: AppStore) => ({ ...state, featuresModal: status }));
    },
    setCancelTrimmingModal: (status: boolean) => {
      set((state: AppStore) => ({ ...state, cancelTrimmingModal: status }));
    },
    setShowRemoveWatermarkModal: (status) => {
      set((state) => ({ ...state, showRemoveWatermarkModal: status }));
    },
    setRemoveWatermarkJobId: (jobId) => {
      set((state) => ({ ...state, removeWatermarkJobId: jobId }));
    },
    setCancelCaptionModal: (status) => {
      set((state) => ({ ...state, cancelCaptionModal: status }));
    },
    setGeneratingCaptionModal: (status) => {
      set((state) => ({ ...state, generatingCaptionModal: status }));
    },
    setSideMenu: (status) => {
      set((state) => ({ ...state, sideMenu: status }));
    },
    setCurrentTab: (tab: number) => {
      set((state) => ({ ...state, currentTab: tab }));
    },
    setCreateMulticameraProjectModal: (status: boolean) => {
      set((state) => ({ ...state, createMulticameraProjectModal: status }));
    },
    setConfirmGenerateCaptionsModal: (status: boolean) => {
      set((state) => ({ ...state, confirmGenerateCaptionsModal: status }));
    },
    setChooseAnotherMasterAudioModal: (status: boolean) => {
      set((state) => ({ ...state, chooseAnotherMasterAudioModal: status }));
    },
    setConfirmMixModal: (status: boolean) => {
      set((state) => ({ ...state, confirmMixModal: status }));
    },
    setUploadAudioModal: (status: boolean) => {
      set((state) => ({ ...state, uploadAudioModal: status }));
    },
  })
);

export default useAppStore;
