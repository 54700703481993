import React from 'react';
import useMulticameraStore from '../../../../../store/useMulticamera.store';
import uploadVideoIcon from '../../../../../assets/icon/uploadVideoIcon.svg';
import VideoCard from './VideoCard';
import useAppStore from '../../../../../store/useApp.store';
const VideoList = () => {
  const currentProject: any = useMulticameraStore((state) => state.currentProject);
  const setUploadMulticameraModal: any = useAppStore((state) => state.setUploadMulticameraModal);
  const videoFiles = currentProject?.files?.filter((file:any) => file.type === 'video')
	const hasTotalVideos = videoFiles?.length >= 4



  return (
    <article className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-8 md:gap-20 lg:gap-8 justify-between">
      {videoFiles?.map((file: any) => (
        <VideoCard key={file.uid} file={file} />
      ))}

      <section className={`${hasTotalVideos ? 'opacity-30' : 'cursor-pointer'} rounded-xl max-w-[300px] mx-auto flex items-center justify-center w-full min-w-[200px] h-44`}>
        <div 
        onClick={() => !hasTotalVideos && setUploadMulticameraModal(true)}
        className="relative flex justify-center flex-col items-center border border-gray-600 w-full h-full rounded-2xl">
          <img src={uploadVideoIcon} alt="" className="rounded-2xl object-fill aspect-video w-12" />
          <div className="text-xs">
            <p className="text-sm pt-0 font-semibold truncate">Upload new video</p>
          </div>
        </div>
      </section>
    </article>
  );
};

export default VideoList;
