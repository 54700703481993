import { useEffect, useState } from 'react';

// Zustand - Global state
import useAppStore from '../../../../store/useApp.store';
import useVerticalizedStore from '../../../../store/useVerticalized.store';

// Libraries
import { useDropzone } from 'react-dropzone';

// Icons
import { TfiClose } from 'react-icons/tfi';
import uploadFileIcon from '../../../../assets/icon/uploadFileIcon.svg';

import CancelTrimmingModal from '../../components/shared/CancelTrimming';

// Custom Hooks
import { useFile } from '../../hooks/useFile';

// Components
import Modal from '../shared/Modal';
import VideoLoaded from './VideoLoaded';
import UploadUrl from './UploadUrl';
import StepOne from '../Upload/StepOne';
import StepTwo from '../Upload/StepTwo';
import { Transition } from '@headlessui/react';
import useUserStore from '../../../../store/useUser.store';
import { SS_ProductCheckout } from '../../../../services/stripe.service';
import SubscriptionModal from '../shared/SubscriptionModal';

const Upload = () => {
  const [controller, setController] = useState(new AbortController());
  const setUploadModal = useAppStore((state) => state.setUploadModal);

  const flags = useAppStore((state) => state.flags);

  const getAndSetUserSubscription = useUserStore((state) => state.getAndSetUserSubscription);

  const verticalVideosLeft = useUserStore((state) => state.verticalVideosLeft);
  const numberOfMinutesLeft = useUserStore((state) => state.numberOfMinutesLeft);
  const maxAllowedMinutes = useUserStore((state) => state.maxAllowedMinutes);

  const paid = useUserStore((state) => state.paid);

  const isUploadModalActive = useAppStore((state) => state.uploadModal);
  const setUploadDone = useVerticalizedStore((state) => state.setUploadDone);
  const setUploadStep = useVerticalizedStore((state) => state.setUploadStep);
  const uploadStep = useVerticalizedStore((state) => state.uploadStep);
  const setCancelTrimmingModal = useAppStore((state) => state.setCancelTrimmingModal);
  const setUrlUploadingLoading = useAppStore((state) => state.setUrlUploadingLoading);
  const isUploadByUrlLoading = useAppStore((state) => state.isUploadByUrlLoading);

  const setResolutionUrlInvalid = useVerticalizedStore((state) => state.setResolutionUrlInvalid);
  const setUrlInvalid = useVerticalizedStore((state) => state.setUrlInvalid);
  const setIsDurationUrlInvalid = useVerticalizedStore((state) => state.setDurationUrlInvalid);
  const setFileUploaded = useVerticalizedStore((state) => state.setFileUploaded);
  const setLocalVideoUrl = useVerticalizedStore((state) => state.setLocalVideoUrl);
  const setYoutubeUrlInvalid = useVerticalizedStore((state) => state.setYoutubeUrlInvalid);
  const setUploadTrimming = useVerticalizedStore((state) => state.setUploadTrimming);
  const youtubeUrl = useVerticalizedStore((state) => state.youtubeUrl);
  const setIsYoutubeApiDown = useVerticalizedStore((state) => state.setIsYoutubeApiDown);
  const videoTrimmingDuration = useVerticalizedStore((state) => state.videoTrimmingDuration);
  const setVideoTrimming = useVerticalizedStore((state) => state.setVideoTrimming);

  const setVideoTrimmingDuration = useVerticalizedStore((state) => state.setVideoTrimmingDuration);
  const isTrimmingDurationInvalid = useVerticalizedStore((state) => state.isTrimmingDurationInvalid);
  const options = {
    signal: controller.signal,
  };

  const {
    // Properties
    file,
    fileName,
    hasError,
    canShowButton,
    canBeUploaded,
    videoDuration,
    hasConnectionError,
    uploadState,
    uploadDone,
    progress,
    // Methods
    removeFile,
    fileValidator,
    // Components
    FileErrors,
    ConnectionError,
    UploadingSection,
    UploadedSection,
    Thumbnail,
    cancelUpload,
  } = useFile({
    functionalityStore: useVerticalizedStore,
  });

  useEffect(() => {
    if (isUploadModalActive && uploadStep === 0) {
      getAndSetUserSubscription();
    }
  }, [isUploadModalActive, uploadDone]);

  const _closeModal = () => {
    setUploadModal(false);
    setUploadDone(false);
    setIsDurationUrlInvalid(false);
    setResolutionUrlInvalid(false);
    setUploadStep(0);
    if (canBeUploaded || isUploadByUrlLoading) {
      controller.abort();
      setController(new AbortController());
      cancelUpload();
    }
    setVideoTrimming([]);
    setVideoTrimmingDuration(0);
    setUrlUploadingLoading(false);
    setUrlInvalid(false);
    setFileUploaded({});
    setLocalVideoUrl('');
    setYoutubeUrlInvalid(false);
    setIsYoutubeApiDown(false);
  };

  const _closeTrimming = () => {
    setCancelTrimmingModal(true);
    setUploadModal(false);
  };

  const { getRootProps, getInputProps, isDragAccept } = useDropzone({
    multiple: false,
    accept: 'video/mp4,video/x-m4v,video/mov,video/quicktime',
    validator: fileValidator,
  });

  const rootProps = canBeUploaded && !hasConnectionError && !uploadDone ? {} : getRootProps();

  return (
    <>
      <CancelTrimmingModal cancel={cancelUpload} />
      <Modal show={isUploadModalActive}>
        <Transition
          appear={true}
          show={isUploadModalActive}
          className={`fixed left-0 transition-all duration-300 translate-x-[calc(50vw-50%)] translate-y-[calc(50vh-50%)]
        ${uploadStep === 3 ? 'w-80 md:w-[580px]' : 'md:w-upload-modal-md lg:w-upload-modal-lg w-96'}`}
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          {/*flags.Subscription && !paid && numberOfMinutesLeft <= 0*/ flags.Subscription && numberOfMinutesLeft <= 0 ? (
            <SubscriptionModal close={_closeModal} />
          ) : (
            <div className="relative px-2">
              <main
                className={`rounded-xl border pb-2 overflow-hidden border-gray-50 shadow-lg bg-gray-100 ${
                  isDragAccept && 'p-2 m-3'
                }`}
              >
                <button
                  onClick={uploadStep === 3 && videoTrimmingDuration !== 0 ? _closeTrimming : _closeModal}
                  className={`absolute top-3 right-6 p-1 cursor-pointer ${isDragAccept && 'hidden'}`}
                >
                  <TfiClose className="w-6" />
                </button>

                {/* {(canBeUploaded && uploadStep === 1) && <StepOne />} */}

                {uploadStep === 3 && <StepTwo functionalityStore={useVerticalizedStore} />}
                {/* {true && <StepTwo />} */}
                {/* {(uploadStep !== 1 && uploadStep !== 3) && */}
                {/* {false && */}
                {uploadStep !== 3 && (
                  <>
                    <p className="px-6 mt-10 text-xs text-gray-600">
                      {flags.Subscription && !paid && uploadStep == 0 && (
                        <>
                          Users can currently process up to {maxAllowedMinutes} minutes of videos [each month].
                          <br />
                          You have {numberOfMinutesLeft} minutes of videos remaining for this month
                        </>
                      )}
                    </p>
                    <section
                      {...rootProps}
                      className={`flex flex-col items-center pb-4 border border-gray-400 border-dashed rounded-xl p-5 mx-5 my-2
                ${(uploadDone || canBeUploaded) && 'pb-4 border-none rounded-xl p-0 mx-0 my-0'}
					      ${isDragAccept && 'mt-0 opacity-40 border border-gray-400 border-dashed rounded-xl p-3'}
              `}
                    >
                      <input type="file" name="file" {...getInputProps()} disabled={canBeUploaded} />

                      <img src={uploadFileIcon} className="w-20 md:w-28" alt="upload icon" />

                      <p className="mt-4 text-sm font-semibold text-gray-800 md:text-lg">{uploadState}</p>

                      {canShowButton && !hasConnectionError && !uploadDone && (
                        <button className="flex items-center justify-center py-3 mt-3 text-xs font-semibold text-gray-800 rounded-xl md:rounded-2xl bg-blue-to-gradient w-72 md:w-40 md:text-sm hover:text-gray-700">
                          Choose file
                        </button>
                      )}

                      {hasError && FileErrors}
                    </section>

                    <UploadingSection />
                    <UploadedSection />
                    <ConnectionError />

                    <div
                      className={`relative w-full transition-all duration-300 ${
                        uploadStep === 3 || uploadDone ? 'h-44' : 'h-44 md:h-[150px]'
                      }`}
                    >
                      <div
                        className={`absolute w-full h-auto transition-all duration-300 ${
                          !file ? 'opacity-100' : 'opacity-0 pointer-events-none'
                        }`}
                      >
                        <UploadUrl options={options} />
                      </div>
                      <div
                        className={`absolute w-full h-auto transition-all duration-100 ${
                          file || (file && uploadDone) ? 'opacity-100' : 'opacity-0 pointer-events-none'
                        }`}
                      >
                        <VideoLoaded
                          fileName={fileName}
                          videoDuration={videoDuration}
                          hasError={hasError}
                          isSuccess={uploadDone}
                          progress={progress}
                          canBeUploaded={canBeUploaded}
                          removeFile={removeFile}
                          cancelUpload={cancelUpload}
                          Thumbnail={Thumbnail}
                          hasConnectionError={hasConnectionError}
                        />
                      </div>
                    </div>
                  </>
                )}

                {/* {true && ( */}
                {uploadStep === 3 && videoTrimmingDuration !== 0 && (
                  <div className="flex justify-end gap-4 px-4 mb-3 text-sm font-semibold">
                    <button className="px-4 py-2 border border-gray-400 rounded-lg w-52" onClick={_closeTrimming}>
                      Cancel
                    </button>
                    <button
                      className="px-4 py-2 text-white bg-black rounded-lg w-52 disabled:bg-gray-500"
                      disabled={isTrimmingDurationInvalid}
                      onClick={() => {
                        setUploadStep(4);
                        setUploadTrimming();
                      }}
                    >
                      Submit
                    </button>
                  </div>
                )}
              </main>
            </div>
          )}
        </Transition>
      </Modal>
    </>
  );
};

export default Upload;
