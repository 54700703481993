import React, { useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// UI Components
import AuthLayout from '../layout/AuthLayout';
import SuccessSubmitting from './SuccessSubmitting';

// Zustand - global store
import useUserStore from '../../../store/useUser.store';

const GoogleAuthentication = () => {
  const getUserByGoogle = useUserStore((state) => state.getUserByGoogle);
  const location = useLocation();
  const authParams = location.search;
  const navigate = useNavigate();

  const onAuthByGoogle = useCallback(async () => {
    if (authParams) {
      const isUserConfirmed = await getUserByGoogle(authParams);
      if (isUserConfirmed) {
        navigate('/dashboard');
      }
    }
  }, [authParams, getUserByGoogle, navigate]);

  useEffect(() => {
    if (authParams) {
      onAuthByGoogle();
    }
  }, [getUserByGoogle, authParams, navigate, onAuthByGoogle]);
  return (
    <div className="h-screen">
      <AuthLayout>
        <SuccessSubmitting title="Just a sec!" subtitle="Logging you in" isAuth />
      </AuthLayout>
    </div>
  );
};
export default GoogleAuthentication;
