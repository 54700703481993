import React, { useCallback, useEffect, useState, useMemo } from 'react';

// Zustand - Global state
import useVerticalizedStore from "../../../../store/useVerticalized.store";
import useAppStore from "../../../../store/useApp.store";

// Icon
import inProgress from "../../../../assets/icon/inProgress.svg";
import error from "../../../../assets/icon/error.svg";
import { HiPlus, HiMinus } from "react-icons/hi";
import { VscChromeClose } from "react-icons/vsc";
import moment from "moment/moment";

const FileListInProgress = () => {
  const setInProgressFiles = useVerticalizedStore((state) => state.setInProgressStatusFile);
  const files = useVerticalizedStore((state) => state.filesInProgress);

  const hasUploadedInterval = useVerticalizedStore((state) => state.hasUploadedInterval);
  const hasCallInterval = useVerticalizedStore((state) => state.hasCallInterval);

  const setDeleteModal = useAppStore((state) => state.setDeleteModal);
  const setUploadModal = useAppStore((state) => state.setUploadModal);
  const setCurrentFile = useVerticalizedStore((state) => state.setCurrentFile);
  const setUploadStep = useVerticalizedStore((state) => state.setUploadStep);
  const setSubmitResponseForTrimming = useVerticalizedStore((state) => state.setSubmitResponseForTrimming);

  const _handleCancelModal = (file:any) => {
    setDeleteModal(true)
    setCurrentFile(file)
  }

  const _inProgressSortedByTrimStatus = useMemo(() => files.sort((a, b) => a.trim_done - b.trim_done), [files])

  const getInProgress = useCallback(() => {
    setInProgressFiles();
  }, [setInProgressFiles]);

  const _handleTrimming = (file:any) => {
    // setCurrentFile(file)
    setSubmitResponseForTrimming(file)
    setUploadModal(true)
    setUploadStep(3)
  }

  useEffect(() => {
    getInProgress();
  }, [getInProgress]);

  // useEffect(() => {
  //   if (hasUploadedInterval || hasCallInterval) {
  //     const interval = setInterval(() => {
  //       getInProgress();
  //     }, 10000);
  //     return () => clearInterval(interval);
  //   }
  // }, [getInProgress, hasCallInterval, hasUploadedInterval]);

  const [seeFull, setSeeFull] = useState(false);
  const showSeeFull = files?.length > 3;

  const _handlerHeight = useMemo(() => {
    if (showSeeFull && !seeFull) {
      return 'h-60';
    } else {
      return '';
    }
  }, [showSeeFull, seeFull]);

  const _handleRemainingTime = useCallback((file) => {
    if (!file.time_remaining && !file.complete) {
      return 'Pending';
    }
    if (file.complete || file.time_remaining <= 0) {
      return `Almost ready`;
    }
    if (file.time_remaining <= 5.0 && file.time_remaining > 0) {
      return `5 seconds left`;
    }
    if (file.time_remaining < 60.0 && file.time_remaining > 5.0) {
      return `${Math.round(file.time_remaining)} seconds left`;
    } else {
      return `${moment.duration(file.time_remaining, 'seconds').humanize()} left`;
    }
  }, []);

  return _inProgressSortedByTrimStatus.length ? (
    <section className="mt-8">
      <article className={`${_handlerHeight} border-2 border-gray-600/30 rounded-xl relative overflow-hidden bg-white`}>
        <h3 className="p-6 pb-1">In progress</h3>
        <div className="p-6 mb-4">
          {/* Progress bar */}
          {_inProgressSortedByTrimStatus.map((file) => (
            <div
              key={file?.uid}
              className="flex flex-col items-center justify-between gap-3 mb-4 md:mb-6 md:flex-row"
            >
              <div className="flex items-center justify-between w-full gap-3">
                <div className="flex gap-2 w-56 md:w-status-bar-name">
                  <div className="flex gap-2 w-56 md:w-status-bar-name">
                    <div className="flex items-center gap-2 truncate">
                      <img src={file.trim_done ? inProgress : error} alt="" className={`${file.trim_done ? "animate-spin w-4" : "w-6"} h-5`} />
                      <span className="text-sm truncate">{file?.name}</span>
                    </div>
                  </div>
                </div>
                <div className="relative hidden w-full h-3 overflow-hidden bg-gray-200 rounded-full md:flex">
                  {/* Shimmer loading */}
                  <div
                    className="absolute 
								bg-gradient-to-r from-transparent via-gray-400 to-transparent 
								top-0 rounded-full w-[110%] h-3 animate-[shimmerbar_3s_infinite]
								isolate shadow-xl shadow-black/5
								border-t border-b border-gray-200/50
								"
                  ></div>

                  <div
                    className={`absolute bg-gradient-to-r from-green-to-gradient to-blue-to-gradient hover:from-blue-to-gradient hover:to-green-to-gradient text-xs rounded-full h-3 font-medium text-blue-100 text-center leading-none transition-all z-100`}
                    style={{ width: `${file?.progress}%` }}
                  ></div>
                </div>

                <div className="sm:w-full md:w-36">
                  <p
                    onClick={() => !file?.trim_done ? _handleTrimming(file) : null}
                    className={`${!file?.trim_done && 'underline cursor-pointer font-medium'} font-medium text-right lg:text-right text-sm text-gray-900`}>
                    {file?.trim_done ? _handleRemainingTime(file) : 'Trim needed'}
                  </p>
                </div>
                <div
                  onClick={() => _handleCancelModal(file)}
                  className="cursor-pointer w-4">
                  <VscChromeClose size={18} />
                </div>
              </div>

              <div className="relative w-full h-4 overflow-hidden bg-gray-200 rounded-full md:hidden">
                {/* Shimmer loading */}
                <div
                  className="absolute 
								bg-gradient-to-r from-transparent via-gray-400 to-transparent 
								top-0 rounded-full w-full h-4 animate-[shimmerbar_3s_infinite]
								isolate shadow-xl shadow-black/5
								border-t border-b border-gray-200/50 z-0
								"
                ></div>

                <div
                  className={`absolute bg-gradient-to-r from-green-to-gradient to-blue-to-gradient hover:from-blue-to-gradient hover:to-green-to-gradient text-xs rounded-full h-4 font-medium text-blue-100 text-center leading-none rounded-l-full transition-all z-100`}
                  style={{ width: `${file?.progress}%` }}
                ></div>
              </div>
            </div>
          ))}
        </div>

        {!seeFull && showSeeFull ? (
          <div className="absolute bottom-0 flex items-center justify-end w-full p-6 rounded-xl md:py-8 bg-gradient-to-t from-white to-white/80">
            <div className="flex items-center justify-end gap-2 cursor-pointer" onClick={() => setSeeFull(!seeFull)}>
              <HiPlus />
              <p className="font-medium text-gray-700 underline transition duration-200 hover:no-underline">See more</p>
            </div>
          </div>
        ) : null}
        {seeFull && showSeeFull ? (
          <div className="absolute bottom-0 flex items-center justify-end w-full px-6 py-6 mt-6 rounded-xl">
            <div className="flex items-center justify-end gap-2 cursor-pointer" onClick={() => setSeeFull(!seeFull)}>
              <HiMinus />
              <p className="font-medium text-gray-700 underline transition duration-200 hover:no-underline">See less</p>
            </div>
          </div>
        ) : null}
      </article>
    </section>
  ) : null;
};

export default FileListInProgress;
