// Icons
import React, { useEffect } from "react";
import { TfiClose } from 'react-icons/tfi';
import { SS_ProductCheckout } from '../../../../services/stripe.service';
import useUserStore from '../../../../store/useUser.store';
import { clickMembershipModal, closeMembershipModal, showMembershipModal } from '../../../../utils/mixpanelEvents';

const SubscriptionModal = ({ close }: { close: () => void }) => {
  const user = useUserStore((state) => state.user);
  const paymentId = useUserStore((state) => state.paymentId);
  const paid = useUserStore((state) => state.paid);

  //const stripePlanId = useUserStore((state) => state.stripePlanId);
  const stripePlan = useUserStore((state) => state.stripePlan);

  const numberOfMinutesLeft = useUserStore((state) => state.numberOfMinutesLeft);
  const maxAllowedMinutes = useUserStore((state) => state.maxAllowedMinutes);

  const _handleSubscribe1 = () => {
    let stripePlanId = stripePlan[0].stripePlanId
    clickMembershipModal(user.id);
    console.log('_handleSubscribe', process.env.REACT_APP_STRAPI_URL, stripePlanId, user.email)
    if (process.env.REACT_APP_STRAPI_URL) {
      SS_ProductCheckout(stripePlanId, process.env.REACT_APP_STRAPI_URL, user.email);
    }
  };

  const _handleSubscribe2 = () => {
    let stripePlanId = stripePlan[1].stripePlanId
    clickMembershipModal(user.id);
    console.log('_handleSubscribe', process.env.REACT_APP_STRAPI_URL, stripePlanId, user.email)
    if (process.env.REACT_APP_STRAPI_URL) {
      SS_ProductCheckout(stripePlanId, process.env.REACT_APP_STRAPI_URL, user.email);
    }
  };

  useEffect(() => {
    if (user?.id) {
      showMembershipModal(user.id);
    }
  }, [user]);

  const _handleClose = () => {
    close();
    closeMembershipModal(user.id);
  };

  return (
    <main className={`relative w-96`}>
      <div className="p-6 overflow-hidden bg-gray-100 border shadow-lg rounded-xl md:p-10 border-gray-50">
        <button onClick={close} className={`absolute top-2 right-2 p-1 cursor-pointer`}>
          <TfiClose className="w-6" />
        </button>
        <section className="pt-2 text-center">
         
            {paid &&
             <p className="text-sm font-medium">
              You have already processed {maxAllowedMinutes} minutes of videos [this month]! Please upgrade your plan to upload more
              videos. 
              </p>
            }

            {!paid &&
             <p className="text-sm font-medium">
               You have already processed five free minutes of video this month! Please choose from the monthly plans below to unlock additional processing time or
               contact support@bigroom.tv to explore an enterprise account.
              </p>
            }

          <div className="flex flex-col items-center justify-between gap-3 pt-6 md:gap-6 md:flex-row">

            <button
              className={`text-white bg-black px-6 py-3 text-sm font-semibold border rounded-xl w-full md:w-44 order-0 md:order-1`}
              onClick={_handleSubscribe1}
            >
              {stripePlan[0].title}
            </button>
            <button
              className={`text-white bg-black px-6 py-3 text-sm font-semibold border rounded-xl w-full md:w-44 order-0 md:order-1`}
              onClick={_handleSubscribe2}
            >
              {stripePlan[1].title}
            </button>
          </div>
          <div className="flex flex-col items-center justify-between gap-3 pt-6 md:gap-6 md:flex-row">
            <button
              className={`border border-black px-6 py-3 text-sm font-semibold rounded-xl w-full md:w-44 order-1 md:order-0`}
              onClick={_handleClose}
            >
              Close
            </button>
          </div>
        </section>
      </div>
    </main>
  );
};

export default SubscriptionModal;
