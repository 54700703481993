import mixpanel from 'mixpanel-browser';
mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);

export const viewLandingPage = (trackingID) => {
  mixpanel.track('View Landing Page', { trackingID });
};

// UploadPart Events
export const internetFailedEvent = (uploadedData, lastProgressLoaded, setShouldFireEvent) => {
  mixpanel.track('Upload Fail - Internet Issue', {
    JobID: uploadedData.id,
    Completion: lastProgressLoaded,
  });
  setShouldFireEvent(() => false);
};

export const userCancelUploadEvent = (uploadedData) => {
  mixpanel.track('Upload Fail - User Cancel', {
    'Job Number': uploadedData.id,
  });
};

export const clickUploadEvent = (trackingID) => {
  mixpanel.track('Click Upload Video - Landing Page', { trackingID });
};

export const uploadSuccessEvent = (postData) => {
  const password = localStorage.getItem('password');
  mixpanel.track('Upload success', {
    password: password,
    email: postData.email,
  });
};

export const uploadErrorEvent = () => {
  mixpanel.track('Upload error');
};

export const clickToGoDownloadPageEvent = () => {
  mixpanel.track('Click View Download Page');
};

// Download Verticalization Events

export const viewDownloadPageEvent = (userData) => {
  mixpanel.track('View Download Page', {
    uid: userData.job_id,
    email: userData.email,
  });
};

export const downloadSequenceFilesEvent = (userData) => {
  mixpanel.track('Click Download Sequence Files - Download Page', {
    Job: userData.job_id,
  });
};

export const downloadVideoEvent = (userData) => {
  mixpanel.track('Click Download Video - Download Page', {
    Job: userData.job_id,
  });
};

// Download Share Events
export const clickToShare = (userData) => {
  mixpanel.track('Click to Share Download Page', {
    Job: userData.job_id,
  });
};

export const clickToCopyLink = (userData) => {
  mixpanel.track('Click to Share Download Page - Copy Link', {
    Job: userData.job_id,
  });
};

export const clickToSendEmail = (userData) => {
  mixpanel.track('Click to Share Download Page - Send Email', {
    Job: userData.job_id,
  });
};

// Upload Events

// Upload Multicam Events
export const clickUploadMulticamEvent = (data, videosLength) => {
  mixpanel.track('Multicam - Click Upload', {
    'Video Count': videosLength,
    'Job Number': data.id,
  });
};

export const uploadSuccessMulticamEvent = (data, videosLength) => {
  mixpanel.track('Multicam - Upload success', {
    'Video Count': videosLength,
    'Job Number': data.id,
  });
};

export const uploadErrorMulticamEvent = (data) => {
  mixpanel.track('Multicam - Upload error', {
    'Job Number': data.id,
  });
};

export const uploadFailMulticamEvent = (data) => {
  mixpanel.track('Multicam - Upload Fail - User Cancel', {
    'Job Number': data.id,
  });
};

export const internetIssuesMulticamEvent = (data) => {
  mixpanel.track('Multicam - Upload Fail - Internet Issue', {
    'Job Number': data.id,
  });
};

export const videoAsAudioSourceMulticamEvent = (data) => {
  mixpanel.track('Multicam - Select Video as Audio', {
    'Job Number': data.id,
  });
};

export const audioAsAudioSourceMulticamEvent = (data) => {
  mixpanel.track('Multicam - Upload Audio File as Audio', {
    'Job Number': data.id,
  });
};

export const clickToDownloadMulticamEvent = (data) => {
  mixpanel.track('Multicam - View Download Page', {
    'Job Number': data.id,
  });
};

export const jobStartMulticamEvent = (data) => {
  mixpanel.track(' Multicam - Job Start', {
    'Job Number': data.id,
  });
};

export const jobEndMulticamEvent = (data) => {
  mixpanel.track('Multicam - Job End', {
    'Job Number': data.id,
  });
};

// Download multicam events

export const viewDownloadMulticamPageEvent = (userData) => {
  mixpanel.track('View Multicam Download Page', {
    uid: userData.job_id,
    email: userData.email,
  });
};

export const downloadSequenceFilesMulticamEvent = (userData) => {
  mixpanel.track('Multicam - Download Sequence File', {
    Job: userData.job_id,
  });
};

export const downloadVideoMulticamEvent = (userData) => {
  mixpanel.track('Multicam - Download Video', {
    Job: userData.job_id,
  });
};

// ================== Dashboard experience events ================

// Landing Events
export const viewLoggedOutEvent = (trackingID) => {
  mixpanel.track('View Logged Out Homepage', { trackingID });
};

// Signup/Login Events
// Signup Events
export const viewSignupEvent = () => {
  mixpanel.track('View Signup Page');
};

export const signupInitiatedEvent = (trackingId) => {
  mixpanel.track('Signup Initiated', { trackingID: trackingId });
};

export const signupSuccessEvent = (trackingId) => {
  mixpanel.track('Signup Success', { trackingID: trackingId });
};

export const signupErrorEvent = () => {
  mixpanel.track('Signup Error');
};

// Login Events
export const viewLoginEvent = () => {
  mixpanel.track('View Login Page');
};

export const loginSuccessEvent = (userID) => {
  mixpanel.track('Login Success', { userID });
};

export const loginErrorEvent = () => {
  mixpanel.track('Login Error');
};

export const viewLoggedInDashboadEvent = (userID) => {
  mixpanel.track('View Logged In Dashboard', { userID });
};

export const viewLoggedInOnboardingEvent = (userID) => {
  mixpanel.track('View Onboarding Dashboard', { userID });
};
// Forget password Event
export const clickToForgetPasswordEvent = () => {
  mixpanel.track('Click Reset Password Link');
};

export const viewForgetPasswordEvent = () => {
  mixpanel.track('View Reset Password Page');
};

export const resetPasswordSuccessEvent = () => {
  mixpanel.track('Reset Password Success');
};

// Dashboard Events
export const clickViewVideoEvent = (userID) => {
  mixpanel.track('View Video - Status Card', { userID });
};

export const clickMyVideoEvent = (userID) => {
  mixpanel.track('View Video - My Videos', { userID });
};

export const clickDeleteFailedVideoEvent = (userID) => {
  mixpanel.track('Delete Failed Video', { userID });
};

export const clickDeleteCompletedVideoEvent = (userID) => {
  mixpanel.track('Delete Completed Video', { userID });
};

// Dashboard Upload
export const clickUploadVideoEvent = (userID) => {
  mixpanel.track('Click Upload Video - Dashboard', { userID });
};
export const newUserStartNewJob = (uid) => {
  mixpanel.track('New User - Upload Video', { JobID: uid });
};
export const newUserEndNewJob = (uid) => {
  mixpanel.track('OfflineCreator - New user end', { JobID: uid });
};
export const userTrimVideo = (userID, uid) => {
  mixpanel.track('Trim Video', { userID, jobID: uid });
};
export const clickUploadFromYoutubeEvent = (userID) => {
  mixpanel.track('Click YouTube Upload Video - Dashboard', { userID });
};
export const clickUploadAnother = (userID) => {
  mixpanel.track('Click Upload Another - Dashboard', { userID });
};
export const dashboardUploadSuccessEvent = (userID) => {
  mixpanel.track('Upload Success', { userID });
};
export const dashboardUploadErrorEvent = (userID) => {
  mixpanel.track('Upload Error', { userID });
};
export const dashboardUploadFailedEvent = (userID) => {
  mixpanel.track('Upload Fail - User Cancel', { userID });
};
export const clickWatermarkOff = (userID, JobID) => {
  mixpanel.track('Click Watermark Off', { userID, JobID });
};

// Dashboard Download
export const clickDownloadSequenceEvent = (userID) => {
  mixpanel.track('Download Sequence Files - Dashboard', { userID });
};
export const clickDownloadVideoEvent = (userID) => {
  mixpanel.track('Download Video - Dashboard', { userID });
};

// Logout Event
export const logoutSuccessEvent = (userID) => {
  mixpanel.track('Log Out Success', { userID });
};

// Click upgrade membership modal event
export const clickMembershipModal = (userID) => {
  mixpanel.track('Click upgrade', { userID });
};

// Show membership modal event
export const showMembershipModal = (userID) => {
  mixpanel.track('Upgrade Modal showed', { userID });
};

// Close membership modal event
export const closeMembershipModal = (userID) => {
  mixpanel.track('Upgrade Modal closed', { userID });
};
