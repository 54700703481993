import React, { useEffect, useCallback } from 'react';

// UI Components
import Onboarding from './Onboarding';
import Dashboard from './Dashboard';

// Zustand - Global state
import useVerticalizedStore from '../../../../store/useVerticalized.store';
import useUserStore from '../../../../store/useUser.store';


const VerticalizedTab = () => {
  const hasVideos = useUserStore((state) => state.hasVideos);
  const hasInProgressVideos = useUserStore((state) => state.hasInProgressVideos);
  const getVideos = useVerticalizedStore((state) => state.setMyVideos);
  const setInProgressFiles = useVerticalizedStore((state) => state.setInProgressStatusFile);
  const myVideosCurrentPage = useVerticalizedStore((state) => state.myVideosCurrentPage);
  const hasUploadedInterval = useVerticalizedStore((state) => state.hasUploadedInterval);
  const hasCallInterval = useVerticalizedStore((state) => state.hasCallInterval);
  const getPageFromStorage = parseInt(localStorage.getItem('myVideosCurrentPage') || '');
  const page = isNaN(getPageFromStorage) ? myVideosCurrentPage : getPageFromStorage;

  const getMyVideos = useCallback(async () => {
    await getVideos(page);
  }, [getVideos, page]);

  const getInProgress = useCallback(async () => {
    await setInProgressFiles();
  }, [setInProgressFiles]);

  useEffect(() => {
    getMyVideos();
    getInProgress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getVideos]);

  useEffect(() => {
    if (hasUploadedInterval || hasCallInterval) {
      const interval = setInterval(() => {
        getVideos(myVideosCurrentPage);
        getInProgress();
      }, 10000);
      return () => clearInterval(interval);
    }
    if (!hasUploadedInterval && !hasCallInterval) {
      const timeOut = setTimeout(() => {
        getVideos(myVideosCurrentPage);
        getInProgress();
      }, 10000);
      return () => clearTimeout(timeOut);
    }
  }, [getInProgress, getVideos, hasCallInterval, hasUploadedInterval, myVideosCurrentPage]);

  return hasVideos || hasInProgressVideos ? <Dashboard /> : <Onboarding />;
};

export default VerticalizedTab;
