import React, { useState } from 'react';

// Icons
import { ReactComponent as InfoIcon } from '../../../../assets/icon/info.svg';
import check from '../../../../assets/icon/onboarding/check.png';
import errorIcon from '../../../../assets/icon/onboarding/error.png';

// Libraries
import { isMobile } from 'react-device-detect';

// Utils
import { featureList, futureFeaturesList } from '../../utils/features';

// Components
import { FeatureListIcon, FeaturesList } from '../shared/FeatureList';

// Zustand - global state
import useAppStore from '../../../../store/useApp.store';
import { Transition } from '@headlessui/react';

const UploadInfoTooltip = () => {
  const [isHover, setIsHover] = useState(false);
  const setFeaturesModal = useAppStore((state) => state.setFeaturesModal);

  return (
    <section className="z-30 flex justify-end mt-6 text-gray-900">
      <div className="relative flex items-center gap-2 cursor-pointer">
        <div
          className="items-center hidden gap-2 md:flex"
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
        >
          <InfoIcon className={`transition-all duration-300 ${isHover ? 'text-green-300' : 'text-white'}`} />
          <span
            className={`underline font-semibold ${
              isHover && 'text-bg-green'
            } hover:no-underline transition-all duration-300 text-sm`}
          >
            What can I upload?{' '}
          </span>
        </div>

        <div className="flex items-center gap-2 md:hidden" onClick={() => (isMobile ? setFeaturesModal(true) : null)}>
          <InfoIcon className={`transition-all duration-300 ${isHover ? 'text-green-300' : 'text-white'}`} />
          <span className={`underline font-semibold`}>What can I upload? </span>
        </div>

        <Transition
          appear={true}
          show={isHover}
          className="transition-opacity duration-300 ease-in-out"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <section className="hidden absolute md:flex min-w-[680px] bg-white top-12 right-28 rounded-xl border-2 border-gray-300 w-1/1 z-10 animate-fadeIn">
            <div className="relative flex p-6 border-r-2 border-gray-300">
              <article className="">
                <p className="flex items-center gap-2 text-sm font-semibold">
                  <FeatureListIcon icon={check} />
                  What works best
                </p>
                <FeaturesList list={featureList} />
              </article>
            </div>

            <div className="flex p-6">
              <article className="">
                <p className="flex items-center gap-2 text-sm font-semibold">
                  <FeatureListIcon icon={errorIcon} />
                  What Doesn’t (…Yet!)
                </p>
                <FeaturesList list={futureFeaturesList} />
              </article>
            </div>

            <div className="absolute w-5 h-5 rotate-45 bg-white border-t-2 border-l-2 border-gray-300 -top-3 right-10"></div>
          </section>
        </Transition>
      </div>
    </section>
  );
};

export default UploadInfoTooltip;
