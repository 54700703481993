import create from 'zustand';
import baseApi from '../services/base.service';
import { persist } from 'zustand/middleware';

import moment from 'moment/moment';

import {
  logIn as DoLogin,
  signUp as DoSignUp,
  resetPassword as DoResetPassword,
  forgotPassword,
  emailVerification,
  getUserByGoogleAuth,
  getUserSubscription,
} from '../services/auth.service';

import { loginSuccessEvent, signupSuccessEvent } from '../utils/mixpanelEvents';

import useAppStore from './useApp.store';
import useVerticalizedStore from './useVerticalized.store';

interface UserStore {
  user: any;
  hasVideos: boolean | null;

  hasInProgressVideos: boolean | null;

  token: string;
  userEmail: string;
  isLoggedIn: boolean;
  isSignUpSubmitSuccess: boolean;
  isResetSuccess: boolean;
  isForgotPasswordEmailSend: boolean;
  hasLoginAndWaitlist: boolean;
  trackingId: string | null;
  logInError: string;
  signUpError: string;
  forgotPasswordError: string;
  resetPasswordError: string;

  paid: boolean;
  verticalVideosLeft: number;
  numberOfMinutesLeft: number;
  maxAllowedMinutes: number;
  paymentId: number;
  stripePlan: any;
  stripePlanId: string;


  logIn: (loginData: any) => Promise<boolean>;
  logOut: () => void;
  signUp: (signupData: any) => Promise<void>;
  forgetPassword: (userEmail: any) => Promise<void>;
  getUserByGoogle: (googleData: any) => Promise<boolean>;
  resetPassword: (resetData: any) => Promise<void>;
  verifyEmail: (token: string) => Promise<void>;
  setTrackingId: (trackingId: string) => void;
  setHasVideo: () => void;
  setClearErrors: () => void;
  setHasInProgressVideo: (hasVideosInProgress: boolean) => void;
  setWatermark: (watermark: boolean) => void;

  setManualPaid: (paid: boolean) => void;

  setVerticalVideosLeft: (verticalVideosLeft: number) => void;
  setNumberOfMinutesLeft: (numberOfMinutesLeft: number) => void;
  setMaxAllowedMinutes:(maxAllowedMinutes: number) => void;
  getAndSetUserSubscription: () => Promise<void>;
}

const _hasLoginAndWaitlistToBool = () => {
  return process.env.REACT_APP_HAS_LOGIN_AND_WAITLIST === 'true';
};

const useUserStore = create<UserStore>()(
  persist(
    (set, get): UserStore => ({
      // state
      user: JSON.parse(localStorage.getItem('user') || '{}') ?? {},
      hasVideos: null,
      hasInProgressVideos: null,
      token: '',
      userEmail: '',
      isLoggedIn: false,
      isSignUpSubmitSuccess: false,
      isResetSuccess: false,
      isForgotPasswordEmailSend: false,
      hasLoginAndWaitlist: _hasLoginAndWaitlistToBool(),
      trackingId: localStorage.getItem('trackingId') ?? null,
      logInError: '',
      signUpError: '',
      forgotPasswordError: '',
      resetPasswordError: '',

      paid: false,
      verticalVideosLeft: 3,
      numberOfMinutesLeft: 0,
      maxAllowedMinutes: 0,
      paymentId: 2,
      stripePlan: [],
      stripePlanId: '',

      // actions
      setVerticalVideosLeft: (verticalVideosLeft) => {
        set((state: UserStore) => ({ ...state, verticalVideosLeft }));
      },
      setNumberOfMinutesLeft: (numberOfMinutesLeft) => {
        set((state: UserStore) => ({ ...state, numberOfMinutesLeft }));
      },
      setMaxAllowedMinutes: (maxAllowedMinutes) => {
        set((state: UserStore) => ({ ...state, maxAllowedMinutes }));
      },
      
      setManualPaid: (paid) => {
        set((state: UserStore) => ({ ...state, paid }));
        useAppStore.getState().setPaymentReceivedModal(true);
      },
      logIn: async (loginData) => {
        const userLoginData = {
          identifier: loginData.email,
          password: loginData.password,
        };

        useAppStore.setState({ isLogInLoading: true });
        set((state: UserStore) => ({ ...state, logInError: '' }));

        try {
          const { user, token } = await DoLogin(userLoginData);
          if (user.confirmed) {
            const videos = await useVerticalizedStore.getState().setMyVideos();
            const inProgressVideos = await useVerticalizedStore.getState().setInProgressStatusFile();
            const hasInProgressVideos = !!inProgressVideos.length;
            const hasVideos = !!videos.length;
            set((state: UserStore) => ({
              ...state,
              user,
              isLoggedIn: true,
              hasVideos,
              hasInProgressVideos,
              userEmail: user.email,
              token,
            }));
            localStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem('email', user.email);
            useAppStore.setState({ isLogInLoading: false });
            return user.confirmed;
          }
          useAppStore.setState({ isLogInLoading: false });
        } catch (error) {
          useAppStore.setState({ isLogInLoading: false });
        }
      },
      logOut: () => {
        set((state: UserStore) => ({ ...state, user: {}, account: {}, isLoggedIn: false }));
        localStorage.clear();
        useVerticalizedStore.setState({
          myVideosCurrentPage: 1,
          filesCompleteCurrentPage: 1,
        });
        delete baseApi.defaults.headers.common['Authorization'];
      },
      signUp: async (signoutData) => {
        const userSignupData = {
          username: `${signoutData.firstName} ${signoutData.lastName}`,
          email: signoutData.email,
          password: signoutData.password,
        };

        useAppStore.setState({ isSignUpLoading: true });

        try {
          const user = await DoSignUp(userSignupData);

          if (user.id) {
            set((state: UserStore) => ({ ...state, isSignUpSubmitSuccess: true, userEmail: signoutData.email }));
          }
          useAppStore.setState({ isSignUpLoading: false });
        } catch (error) {
          useAppStore.setState({ isSignUpLoading: false });
        }
      },
      forgetPassword: async (userEmail) => {
        useAppStore.setState({ isForgotPasswordLoading: true });
        set((state: UserStore) => ({ ...state, forgotPasswordError: '' }));
        try {
          const response = await forgotPassword(userEmail);
          if (response) {
            set((state: UserStore) => ({ ...state, userEmail, isForgotPasswordEmailSend: true }));
          }
          useAppStore.setState({ isForgotPasswordLoading: false });
        } catch (error) {
          useAppStore.setState({ isForgotPasswordLoading: false });
        }
      },
      getUserByGoogle: async (authParams) => {
        useAppStore.setState({ isLogInLoading: true });
        set((state: UserStore) => ({ ...state, logInError: '' }));

        try {
          const { user, jwt: token } = await getUserByGoogleAuth(authParams);
          if (user.confirmed) {
            const videos = await useVerticalizedStore.getState().setMyVideos();
            const inProgressVideos = await useVerticalizedStore.getState().setInProgressStatusFile();
            const hasInProgressVideos = !!inProgressVideos.length;
            const hasVideos = !!videos.length;
            set((state: UserStore) => ({
              ...state,
              user,
              isLoggedIn: true,
              hasVideos,
              hasInProgressVideos,
              userEmail: user.email,
              token,
            }));
            localStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem('email', user.email);
            useAppStore.setState({ isLogInLoading: false });
            if (moment(user.createdAt) > moment().subtract(40, 'seconds')) {
              signupSuccessEvent(get().trackingId);
            } else {
              loginSuccessEvent(user.id);
            }

            return user.confirmed;
          }
          useAppStore.setState({ isLogInLoading: false });
        } catch (error) {
          useAppStore.setState({ isLogInLoading: false });
        }
      },
      resetPassword: async (resetData) => {
        useAppStore.setState({ isResetPasswordLoading: true });
        try {
          const response = await DoResetPassword(resetData);
          if (response) {
            set((state: UserStore) => ({ ...state, isResetSuccess: true }));
          }
          useAppStore.setState({ isResetPasswordLoading: false });
        } catch (error) {
          console.info(error);
          useAppStore.setState({ isResetPasswordLoading: false });
        }
      },
      verifyEmail: async (confirmationCode) => {
        try {
          await emailVerification(confirmationCode);
        } catch (error) {
          console.info(error);
          useAppStore.setState({ isResetPasswordLoading: false });
        }
      },
      setTrackingId: (trackingId) => {
        localStorage.setItem('trackingId', trackingId);
        set((state: UserStore) => ({ ...state, trackingId: trackingId }));
      },
      setHasVideo: () => {
        set((state: UserStore) => ({ ...state, hasVideos: true }));
      },
      setHasInProgressVideo: (hasVideosInProgress) => {
        set((state: UserStore) => ({ ...state, hasInProgressVideos: hasVideosInProgress }));
      },
      setClearErrors: () => {
        setTimeout(() => {
          set((state: UserStore) => ({
            ...state,
            logInError: '',
            signUpError: '',
            forgotPasswordError: '',
            resetPasswordError: '',
          }));
        }, 5000);
      },
      setWatermark: (watermark) => {
        set((state) => ({
          ...state,
          user: {
            ...state.user,
            watermark,
          },
        }));
      },
      getAndSetUserSubscription: async () => {
        const token = get().token;
        const response = await getUserSubscription(token);
        if (response) {
          set((state: UserStore) => ({
            ...state,
            paid: response.paid,
            numberOfMinutesLeft:response.number_of_minutes_left,
            maxAllowedMinutes:response.max_allowed_minutes,
            //verticalVideosLeft: response.number_of_vertical_videos_left,
            stripePlan: response.stripePlan,
            stripePlanId: response.stripePlanId,
            paymentId: response.payment_id,
          }));
        }
      },
    }),
    {
      name: 'userStorage',
      getStorage: () => localStorage,
    }
  )
);

export default useUserStore;
