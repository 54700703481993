import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../../../../assets/logo/logo.svg';
import whiteLogo from '../../../../assets/logo/logo-white.png';
import logoutWhite from '../../../../assets/icon/logoutWhite.svg';
import logoutBlack from '../../../../assets/icon/logout.svg';
import avatar from '../../../../assets/icon/dashboardAvatar.png';

//Zustand Global State
import useUserStore from '../../../../store/useUser.store';

//Mixpanel Events
import { logoutSuccessEvent } from '../../../../utils/mixpanelEvents';

import '../../index.scss';

const Header = () => {
  const navigate = useNavigate();
  const doLogOut = useUserStore((state) => state.logOut);
  const numberOfMinutesLeft = useUserStore((state) => state.numberOfMinutesLeft);
  const maxAllowedMinutes = useUserStore((state) => state.maxAllowedMinutes);

  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const logout = () => {
    logoutSuccessEvent(user.id);
    doLogOut();
    navigate('/login');
  };

  const getInitials = useMemo(() => {
    const rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');
    const initials = [...user.username.matchAll(rgx)] || [];
    return ((initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')).toUpperCase();
  }, [user]);

  return (
    <header className="flex justify-between px-3 py-3 bg-black-mobile-header md:bg-transparent md:px-28 md:border-b md:border-gray-300">
      <section className="flex items-center gap-2 w-18">
        <img className="hidden w-16 cursor-pointer md:flex" src={logo} alt="" />
        <img className="object-contain cursor-pointer md:hidden" src={whiteLogo} alt="" />
        <p className="w-8 pt-3 font-bold text-white text-bg-2xs md:pl-1 md:text-inherit ">EARLY ACCESS</p>
      </section>

      <section className="flex items-center">
        <section className="flex items-center text-sm font-semibold text-gray-800">
          <div className="rounded-full px-1.5">
            {user.username ? (
              <div className="p-2 font-semibold border rounded-full bg-lime-100 text-lime-600 border-lime-600">
                <p className="w-5 text-center">{getInitials}</p>
              </div>
            ) : (
              <img className="w-10" src={avatar} alt="" />
            )}
          </div>
          <div className="hidden md:flex">
            <p className="">{user.username}&nbsp;</p>
          </div>
          <div className="hidden md:flex">
            <p className=""> you have {numberOfMinutesLeft} minutes of video left this month. </p>
          </div>
        </section>

        <section
          className="flex items-center text-sm font-semibold text-gray-800 cursor-pointer md:ml-4 hover:text-gray-600"
          onClick={logout}
        >
          <span className="hidden md:flex">Log out</span>
          <img src={logoutBlack} className="hidden ml-2 md:flex" alt="" />
          <img src={logoutWhite} className="flex ml-2 md:hidden" alt="" />
        </section>
      </section>
    </header>
  );
};

export default Header;
