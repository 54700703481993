import React from 'react';

import ZoomAIWEBM from '../../../assets/Features/ZoomAI/ZoomAI.webm';
import ZoomAIMP4 from '../../../assets/Features/ZoomAI/ZoomAI.mp4';
import ZoomAIMP4Mobile from '../../../assets/Features/ZoomAI/ZoomAIMobile.mp4';
import ZoomAIWEMBMobile from '../../../assets/Features/ZoomAI/ZoomAIMobile.webm';
import ZoomIcon from '../../../assets/Features/Icons/ZoomIcon.svg';

const DynamicZoomFeature = () => {
  return (
    <section className='flex flex-col justify-center items-center relative'>
			<article className='flex items-center justify-center gap-4 md:absolute top-0 lg:top-6'>
				<img src={ZoomIcon} alt="" />
				<p className='text-white font-ptsans text-2xl font-semibold py-2'>Dynamic Zoom AI</p>
			</article>
      
      <article className='hidden md:block'>
      <video muted loop playsInline className={'rounded-lg w-96 md:w-[680px] lg:w-[1200px]'} autoPlay>
        <source src={ZoomAIWEBM} type="video/webm" />
        <source src={ZoomAIMP4} type="video/mp4" />
      </video>
      </article>
      <article className='block md:hidden'>
      <video muted loop playsInline className={'rounded-lg w-full'} autoPlay>
        <source src={ZoomAIWEMBMobile} type="video/webm" />
        <source src={ZoomAIMP4Mobile} type="video/mp4" />
      </video>
      </article>
    </section>
  );
};

export default DynamicZoomFeature;
