// UI Layout
import AuthLayout from './layout/AuthLayout';

// Components
import SignUpComponent from './components/SignUp';
import SuccessSubmitting from './components/SuccessSubmitting';

// Zustand Global State
import useUserStore from '../../store/useUser.store';

const SignUp = () => {
  const isSubmitSuccess = useUserStore((state) => state.isSignUpSubmitSuccess);
  const userEmail = useUserStore((state) => state.userEmail);

  return (
    <AuthLayout>
      {isSubmitSuccess ? (
        <SuccessSubmitting
          title="Check your email!"
          subtitle={`You will receive an email verification link at ${userEmail}.`}
          advice="Check your Promotions and Spam folders too."
          buttonText="Go Back to Home"
          redirectionPath="/"
          withUrlIcon
        />
      ) : (
        <SignUpComponent />
      )}
    </AuthLayout>
  );
};

export default SignUp;
