// Zustand - Global state
import axios from './base.service';
import instance from 'axios';
import useUserStore from '../store/useUser.store';
import { dashboardUploadErrorEvent } from '../utils/mixpanelEvents';

const endPoints = {
  multicameraJobs: 'multicamera-jobs',
  multicameraFiles: 'multicamera-files',
};

export const getMulticameraProjects = async () => {
  try {
    const { data, status } = await axios.get(`${endPoints.multicameraJobs}`);
    if (status === 200) {
      return data;
    }
  } catch (error) {
    console.info(error);
  }
};

export const getMulticameraProject = async (uid: string) => {
  try {
    const { data, status } = await axios.get(`${endPoints.multicameraJobs}/${uid}`);
    if (status === 200) {
      return data;
    }
  } catch (error) {
    console.info(error);
  }
};

export const createMulticameraProject = async (projectData: unknown) => {
  try {
    const { data, status } = await axios.post(endPoints.multicameraJobs, projectData);

    const response = data.data.attributes;

    console.info(response);
    if (status === 200) {
      return response;
    }
  } catch (error) {
    console.info(error);
  }
};

export const submitMedia = async (fileData: any) => {
  const request = { data: fileData };
  try {
    const { data, status } = await axios.post(`${endPoints.multicameraFiles}`, request);

    const response = data.data.attributes;

    if (status === 200) {
      return response;
    }
  } catch (error) {
    console.info(error);
  }
};

export const uploadedSuccess = async (jobId: string) => {
  const uploadDone = { data: { upload_done: true } };
  try {
    const { data, status } = await axios.put(`${endPoints.multicameraFiles}/${jobId}`, uploadDone);
    const response = data.data.attributes;
    if (status === 200) {
      return response;
    }
  } catch (error) {
    console.info(error);
  }
};

export const uploadMedia = async (
  presignedUrl: string,
  video: any,
  options: any,
  jobId: string,
  setSuccess: any,
  retryAttempt: number,
  setErrorPopup: any
) => {
  const config = {
    headers: {
      'Content-Type': video.type,
    },
    ...options,
  };

  try {
    const { status } = await axios.put(presignedUrl, video, config);
    if (status === 200) {
      setSuccess(true);
      // await axios.put(`${resource}/${jobId}`, { upload_done: true });
    }
  } catch (error: any) {
    if (error.message !== 'canceled') {
      const userId = useUserStore.getState()?.user.id;
      dashboardUploadErrorEvent(userId);
    }

    if (error.message !== 'canceled' && retryAttempt < 3) {
      uploadMedia(presignedUrl, video, options, jobId, setSuccess, retryAttempt + 1, setErrorPopup);
    } else if (retryAttempt >= 3) {
      setErrorPopup(true);
    }
  }
};

export const uploadMediaWithoutToken = async (
  presignedUrl: string,
  media: any,
  options: any,
  setSuccess: any,
  fileUid: string
) => {
  const config = {
    headers: {
      'Content-Type': media.type,
    },
    ...options,
  };

  try {
    const { status } = await instance.put(presignedUrl, media, config);
    if (status === 200) {
      setSuccess(true);
      uploadedSuccess(fileUid);
    }
  } catch (error: any) {
    if (error.message !== 'canceled') {
      // const userId = useUserStore.getState()?.user.id;
      // dashboardUploadErrorEvent(userId);
    }
    console.info(error);
  }
};

export const removeMulticameraMedia = async (uid: string) => {
  // const request = {
  //   data: { delete: true,}
  // };

  try {
    const { data, status } = await axios.delete(`${endPoints.multicameraFiles}/${uid}`);

    if (status === 200) {
      return data;
    }
  } catch (error) {
    console.info(error);
  }
};

export const removeMulticameraProject = async (uid: string) => {
  const request = {
    data: { delete: true },
  };

  try {
    const { data, status } = await axios.put(`${endPoints.multicameraJobs}/${uid}`, request);

    if (status === 200) {
      return data;
    }
  } catch (error) {
    console.info(error);
  }
};

export const setMasterAudioFile = async (jobId: string, fileId: string) => {
  const uploadDone = { data: { master_audio_file: fileId } };
  try {
    const { data, status } = await axios.put(`${endPoints.multicameraJobs}/${jobId}`, uploadDone);
    const response = data.data.attributes;
    if (status === 200) {
      return response;
    }
  } catch (error) {
    console.info(error);
  }
};

export const startJob = async (jobId: string) => {
  const request = {
    data: {
      start_job: true,
      cancel: false,
      is_error: false,
      error_message: '',
    },
  };
  try {
    const { data, status } = await axios.put(`${endPoints.multicameraJobs}/${jobId}`, request);
    const response = data.data.attributes;
    if (status === 200) {
      return response;
    }
  } catch (error) {
    console.info(error);
  }
};

export const cancelJob = async (jobId: string, msg: string) => {
  const request = { data: { cancel: true, is_error: true, error_message: msg, start_job: false } };
  try {
    const { data, status } = await axios.put(`${endPoints.multicameraJobs}/${jobId}`, request);
    const response = data.data.attributes;
    if (status === 200) {
      return response;
    }
  } catch (error) {
    console.info(error);
  }
};
