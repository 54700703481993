import React from 'react';

const ProgressBar = ({ progress }: { progress: number }) => (
  <div className="relative w-full h-2 overflow-hidden bg-white rounded-full">
    <div
      className="absolute 
		bg-gradient-to-r from-transparent via-gray-300 to-transparent 
		top-0 rounded-full w-full h-2 animate-[shimmerbar_3s_infinite]
		isolate shadow-xl shadow-black/5
    border-t border-b border-gray-200/50 z-0
		"
    ></div>

    <div
      className={`absolute bg-gray-700 text-xs rounded-full h-2 font-medium text-blue-100 text-center leading-none rounded-l-full transition-all z-100`}
      style={{ width: `${progress}%` }}
    ></div>
  </div>
);

export default ProgressBar;
