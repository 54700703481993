import React, { useState } from 'react';

// Icons
import youtubeIcon from '../../../../assets/icon/youtubeIcon.svg';

// Custom Hooks
import { useFile } from '../../hooks/useFile';

// UI Components
import VideoLoaded from './VideoLoaded';

// Utils
import { clickUploadFromYoutubeEvent } from '../../../../utils/mixpanelEvents';

// Libraries
import { Oval } from 'react-loader-spinner';

import useVerticalizedStore from '../../../../store/useVerticalized.store';
import useAppStore from '../../../../store/useApp.store';
import useUserStore from '../../../../store/useUser.store';

const UploadUrl = ({ options }: { options: any }) => {
  const user = useUserStore((state) => state.user);

  const submitFile = useVerticalizedStore((state) => state.submitFile);
  const uploadDone = useVerticalizedStore((state) => state.uploadDone);
  const setUrlUploadingLoading = useAppStore((state) => state.setUrlUploadingLoading);
  const isUploadByUrlLoading = useAppStore((state) => state.isUploadByUrlLoading);
  const submitResponse = useVerticalizedStore((state) => state.submitResponse);
  // const uploadStep = useVerticalizedStore((state) => state.uploadStep);

  const {
    // Properties
    hasError,
    hasConnectionError,
    // Methods
    urlValidator,
    // Components
    UrlErrors,
    ThumbnailYoutube,
  } = useFile({
    functionalityStore: useVerticalizedStore,
  });

  const [url, setUrl] = useState('');

  const _handleClick = () => {
    submitFile(options);
    setUrlUploadingLoading(true);
    clickUploadFromYoutubeEvent(user.id);
  };

  return (
    <>
      {uploadDone && (submitResponse?.youtube_info?.name || submitResponse?.name) ? (
        <VideoLoaded
          fileName={submitResponse.youtube_info?.name ?? submitResponse?.name}
          hasError={hasError}
          isSuccess={uploadDone}
          Thumbnail={ThumbnailYoutube}
          hasConnectionError={hasConnectionError}
        />
      ) : (
        <section className="px-4 pt-1 pb-6">
          <p className="flex items-center justify-start gap-2 px-1 text-sm font-semibold text-gray-800">
            Or Upload via YouTube URL <img src={youtubeIcon} alt="" />
          </p>
          <p className="px-1 text-xs font-medium text-red-700 w-96">
            Please note that due to high demand, some YouTube videos are currently taking longer to process.
          </p>
          <div className="flex flex-col items-center justify-between gap-2 md:flex-row">
            <div
              className={`
                relative
                mt-1
                rounded-xl
                flex
                md:w-72
                w-full
                justify-center
                shadow-inner
                box-border
                focus-within:bg-gradient-to-r
                ${hasError && 'bg-gradient-to-r from-soft-red-to-gradient to-red-to-gradient shadow-input-error'} 
                ${
                  !hasError &&
                  'focus-within:from-green-to-gradient focus-within:to-blue-to-gradient focus-within:shadow-input-focus'
                }
                `}
            >
              <input
                type="url"
                required
                name="url"
                disabled={isUploadByUrlLoading}
                onChange={async (e) => {
                  urlValidator(e.target.value);
                  setUrl(e.target.value);
                }}
                placeholder="https://www.youtube.com/watch?7852"
                className={`
                  md:w-72
                  rounded-xl 
                  bg-white text-bg-sm
                  p-2
                  w-full
                  disabled:bg-gray-400/50
                  m-0.5
                  text-gray-700
                  border-1 h-10 focus:ring-0 focus:border-transparent
                  ${hasError && 'border-red-400 focus:ring-0 border-0 border-transparent'}`}
              />
            </div>
            <button
              className="px-7 py-2 mt-0.5 text-sm font-medium rounded-xl bg-gray-900 text-white disabled:bg-gray-400 w-full flex justify-center"
              onClick={_handleClick}
              disabled={hasError || !url || isUploadByUrlLoading}
            >
              {isUploadByUrlLoading ? (
                <Oval
                  height={12}
                  width={30}
                  color="white"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="white"
                  strokeWidth={8}
                  strokeWidthSecondary={8}
                />
              ) : (
                'Upload'
              )}
            </button>
          </div>

          {hasError ? (
            UrlErrors
          ) : (
            <p className="px-1 pt-1 text-xs text-gray-800">
              You may only upload videos that you have permission or own the rights to use.
            </p>
          )}
        </section>
      )}
    </>
  );
};

export default UploadUrl;
