import React from 'react';

import useAppStore from '../store/useApp.store';
import OutsideClickDetector from '../views/Shared/outsideClickDetector/OutsideClickDetector';
import { Transition } from '@headlessui/react';
import useDynamicZoomStore from '../store/useDynamicZoom.store';
import useVerticalizedStore from '../store/useVerticalized.store';

const MobileBadge = ({message}:{message?:string}) => (
  <div className="px-3 py-1 ml-2 text-gray-600 lg:group-hover:text-white text-bg-xs rounded-xl bg-gray-300/40">
    {message ? message : 'Coming Soon'}
  </div>
);

const SideMenu = () => {
  const setSideMenu = useAppStore((state) => state.setSideMenu);
  const isSideMenuOpen = useAppStore((state) => state.sideMenu);
  const setCurrentTab = useAppStore((state) => state.setCurrentTab);
  const setCurrentFunctionality = useAppStore((state) => state.setCurrentFunctionality);
  const currentTab = useAppStore((state) => state.currentTab);
  const flags: any = useAppStore((state) => state.flags);

  const _handleSwitchTabs = (tab: number) => {
    setCurrentTab(tab);
    if (tab === 2) {
      setCurrentFunctionality(useDynamicZoomStore);
    } else {
      setCurrentFunctionality(useVerticalizedStore);
    }
    setSideMenu(false);
  };

  return isSideMenuOpen ? (
    <section className="fixed z-50 w-full h-screen bg-gray-700/40">
      <Transition
        appear={true}
        show={isSideMenuOpen}
        className="fixed left-0 transition-all duration-300"
        enterFrom="translate-x-[-60vw]"
      >
        <OutsideClickDetector callback={() => setSideMenu(false)}>
          <section className="fixed h-screen bg-white w-80">
            <article
              onClick={() => _handleSwitchTabs(0)}
              className={`
            ${currentTab === 0 ? 'bg-teal-green font-semibold' : 'bg-white'}
            h-12 cursor-pointer border-b-2 border-gray-300 flex justify-center items-center font-medium lg:hover:bg-gray-900 lg:hover:text-white group`}
            >
              Landscape to Vertical
            </article>
            <article
              onClick={() => (flags.Multicamera ? _handleSwitchTabs(1) : null)}
              className={`
            ${currentTab === 1 ? 'bg-teal-green font-semibold' : 'bg-white'}
            h-12 cursor-pointer border-b-2 border-gray-300 flex justify-center items-center font-medium lg:hover:bg-gray-900 lg:hover:text-white group`}
            >
              Multicamera AI
              {!flags.Multicamera ? <MobileBadge /> : <MobileBadge message='Preview' />}
            </article>
            <article
              onClick={() => (flags.DynamicZoom ? _handleSwitchTabs(2) : null)}
              className={`
            ${currentTab === 2 ? 'bg-teal-green font-semibold' : 'bg-white'}
            h-12 cursor-pointer border-b-2 border-gray-300 flex justify-center items-center font-medium lg:hover:bg-gray-900 lg:hover:text-white group`}
            >
              Dynamic Zoom AI
              {!flags.DynamicZoom ? <MobileBadge /> : <MobileBadge message='Preview' />}
            </article>
          </section>
        </OutsideClickDetector>
      </Transition>
    </section>
  ) : null;
};

export default SideMenu;
