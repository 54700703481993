import { Oval } from 'react-loader-spinner';

const Loader = () => (
  <section className="flex justify-center">
    <Oval
      height={74}
      width={74}
      color="#85FAC6"
      wrapperStyle={{}}
      wrapperClass=""
      visible={true}
      ariaLabel="oval-loading"
      secondaryColor="#C0C3C8"
      strokeWidth={3}
      strokeWidthSecondary={3}
    />
  </section>
);

export default Loader;
