// UI Layout
import AuthLayout from './layout/AuthLayout';

// Components
import ForgetPasswordComponent from './components/ForgetPassword';
import SuccessSubmitting from './components/SuccessSubmitting';

// Zustand Global State
import useUserStore from '../../store/useUser.store';

const ForgetPassword = () => {
  const isForgotPasswordEmailSend = useUserStore((state) => state?.isForgotPasswordEmailSend);

  return (
    <AuthLayout>
      {isForgotPasswordEmailSend ? (
        <SuccessSubmitting
          title="Email sent!"
          subtitle={`If your email is in our system, you'll get an email with a reset password link.`}
          advice="Check your Promotions and Spam folders too."
          buttonText="Go Back to Home"
          redirectionPath="/"
          withUrlIcon
        />
      ) : (
        <ForgetPasswordComponent />
      )}
    </AuthLayout>
  );
};

export default ForgetPassword;
