import { Fragment, useEffect, useMemo, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import useMulticameraStore from '../../../../../store/useMulticamera.store';
import useAppStore from '../../../../../store/useApp.store';

const VideoOptions = ({ file, selected }: any) => {
	const ThumbnailMulticamera = useMemo(() => {
    if (!file) return null;
    return (
      <div className='relative w-11 h-10 overflow-hidden rounded-xl flex items-center'>
        <img src={file?.thumbnail_url} alt='thumbnail' className='absolute w-24 max-w-none' />
      </div>
    );
  },[file])
	
	return(
  <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'} flex items-center gap-2 cursor-pointer`}>
    <div className="relative w-14 h-14 overflow-hidden rounded-xl flex items-center">
      {ThumbnailMulticamera}
    </div>
		<span className='font-medium truncate w-80'>
    {file.name}
		</span>
  </span>
)};


const SelectAudio = () => {
  const currentProject = useMulticameraStore((state) => state.currentProject);
  const setMasterAudioFile = useMulticameraStore((state) => state.setMasterAudioFile);
  const setUploadMulticameraStep = useMulticameraStore((state) => state.setMulticameraUploadStep);
  const setUploadMulticameraModal = useAppStore((state) => state.setUploadMulticameraModal);
  const [selected, setSelected] = useState<any>();

  useEffect(() => {
    if(selected){
      setMasterAudioFile(currentProject.uid, selected.uid)
      return
    }
  },[currentProject.uid, selected, setMasterAudioFile])


  return (
    <div className="px-5 mt-2">
      <Listbox value={selected} onChange={setSelected}>
        <div className="relative mt-1 rounded-xl w-80">
          <Listbox.Button className="relative w-full cursor-pointer rounded-xl h-14 bg-gradient-to-r from-green-to-gradient to-blue-to-gradient py-2 px-3 text-left sm:text-sm flex items-center justify-center gap-2">
            <span className="block truncate font-semibold text-center">
              {selected?.name ? selected?.name : 'Select master audio'}
            </span>
						{/* absolute inset-y-0 right-0 */}
            <span className="pointer-events-none flex items-center pr-2">
              <ChevronDownIcon className="h-6 w-6 text-gray-900" aria-hidden="true" />
            </span>
          </Listbox.Button>
          <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Listbox.Options className="absolute mt-3 max-h-96 w-full overflow-auto rounded-xl bg-white border border-gray-400 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {currentProject?.files?.map((file:any, fileIdx:number) => (
                <Listbox.Option
                  key={fileIdx}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-4 pr-4 ${
                      active ? 'bg-blue-to-gradient/50 rounded-xl' : 'text-gray-900'
                    }`
                  }
                  value={file}
                >
                  {({ selected }) => (
                    <>
										<VideoOptions selected={selected} file={file} />
                    </>
                  )}
                </Listbox.Option>
              ))}
							<div className='flex justify-center items-center p-2 px-4 mb-2'>
						<button 
            className='border border-black rounded-xl px-4 py-2 mx-auto w-72'
            onClick={()=>{
              setUploadMulticameraStep(2)
              setTimeout(() => {
                setUploadMulticameraModal(true)
              }, 300)
            }}>Upload new file</button>
							</div>
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
};

export default SelectAudio;
