/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';

// Libraries
import { useNavigate, useSearchParams, Link } from 'react-router-dom';
import Slider from 'react-slick';

// Components
import Banner from './components/Banner';
import NavHeader from './components/NavHeader';
import Carousel from './components/Carrousel';
import CarouselPreview from './components/CarouselPreview';
import FAQ from './components/FAQ';

// Icons / images
import greenLines from '../../assets/icon/landing/green-lines.svg';
import blackStar from '../../assets/icon/landing/black-star.svg';
import greenArrow from '../../assets/icon/landing/green-filled-arrow.svg';
import cameraIcon from '../../assets/icon/cameraIcon.svg';
import featuresDivider from '../../assets/Features/Illustrations/featuresDivider.svg';

// Videos assets
import HeroVideo from '../../assets/HeroVideos/Hero.mp4';
import HeroVideoWebm from '../../assets/HeroVideos/Hero.webm';

import MobileHeroVideo from '../../assets/HeroVideos/Mobile.mp4';
import MobileHeroVideoWebm from '../../assets/HeroVideos/Mobile.webm';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../../index.scss';

// Zustand Global State
import useUserStore from '../../store/useUser.store';

// Mixpanel Events
import { viewLoggedOutEvent } from '../../utils/mixpanelEvents';
import MulticameraFeature from './components/MulticameraFeature';
import DynamicZoomFeature from './components/DynamicZoomFeature';
import VerticalizationFeature from './components/VerticalizationFeature';
import WhosUsingBR from './components/WhosUsingBR';

const words = [
  'presentations',
  'unboxing',
  'interviews',
  'podcast',
  'comedy',
  'faith',
  'corporate',
  'cooking',
  'fitness',
  'marketing',
];

const settings = {
  infinite: true,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
  vertical: true,
  autoplay: true,
  autoplaySpeed: 3000,
  arrows: false,
  fade: true,
  style: {
    width: '500px',
    height: '50px',
    pointerEvents: 'none',
    position: 'absolute',
    left: '0',
  },
};

const FeaturesDivider = () => (
  <div className="w-full flex items-center justify-center my-12 md:my-20 lg:my-28">
    <img src={featuresDivider} alt="w-96" />
  </div>
);

export default () => {
  const repeatedWords = [...words, ...words, ...words, ...words, ...words];
  const [loadCarousel, setLoadCarousel] = useState(false);
  const setTrackingId = useUserStore((state) => state.setTrackingId);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const trackingId = searchParams.get('trackingid')
    ? searchParams.get('trackingid') ?? localStorage.getItem('trackingId')
    : null;

  const user = useUserStore((state) => state.user);
  const [videosLoaded, setVideosLoaded] = useState(0);

  useEffect(() => {
    setTrackingId(trackingId || 'null');
    viewLoggedOutEvent(trackingId);
    if (user.confirmed) {
      navigate('/dashboard');
      return;
    }
    navigate('/');

    const interval = setInterval(() => {
      setLoadCarousel(true);
    }, 1000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (videosLoaded >= 3) {
      setLoadCarousel(true);
    }
  }, [videosLoaded]);

  return (
    <>
      <div id="tailwind-base" className="relative">
        <section className="absolute top-0 z-50 flex items-center justify-center w-full gap-2 text-gray-900 bg-white cursor-default h-14 bg-gradient-to-r from-green-to-gradient to-blue-to-gradient hover:from-blue-to-gradient hover:to-green-to-gradient font-ptsans ">
          <img src={cameraIcon} alt="" className="w-5 hidden md:flex" />
          <div className="px-6 text-center text-sm md:text-base md:px-2">
            New Products Coming Soon:
            <span className="font-semibold">Multicamera AI and Dynamic Zoom AI</span>
          </div>
        </section>
        <div className="pt-4 bg-black">
          <div className="md:max-h-[67vw]">
            <video
              autoPlay
              playsInline
              muted
              loop
              onPlay={() => setVideosLoaded(videosLoaded + 1)}
              className="absolute hidden w-full md:block top-[clamp(-250px,calc(-11vw+45px),0px)] max-w-[2400px] mx-auto left-0 right-0 pt-14"
            >
              <source src={HeroVideoWebm} type="video/webm" />
              <source src={HeroVideo} type="video/mp4" />
            </video>
          </div>
          <div className="relative z-10">
            <div className="md:max-h-[70vw]">
              <div className=" md:max-h-[1700px]">
                <section className="overflow-y-hidden h-full md:h-screen md:min-h-[600px]  max-w-[2000px] p-4 mx-auto md:w-5/6 flex flex-col justify-between md:max-h-[70vw] pt-12">
                  <NavHeader />
                  <article
                    className={
                      'flex flex-col md:flex-row justify-between gap-4 items-center md:-mt-24 h-full overflow-hidden md:overflow-auto pt-12'
                    }
                  >
                    <div className="relative mb-14 md:mt-14">
                      <div
                        className={
                          'hidden relative md:flex overflow-x-visible text-white md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl md:leading-none'
                        }
                      >
                        <span className="float-left font-semibold leading-tight whitespace-nowrap">
                          {' '}
                          {/* Auto-convert videos from <br /> landscape to vertical for */}
                          Convert landscape videos to
                          <br />
                          vertical clips 10x faster for
                        </span>
                        {/* <img
                          src={greenLines}
                          alt={'Green lines'}
                          className={'float-left mt-6 w-8 md:w-8 xl:w-14 xl:mt-10 2xl:w-20'}
                        /> */}
                      </div>
                      <div
                        className={
                          'md:hidden flex mx-auto w-fit mt-8 md:mt-28 leading-tight text-white text-xl 3xs:text-2xl sm:text-4xl'
                        }
                      >
                        <span className="float-left font-semibold whitespace-nowrap">
                          Convert landscape videos to <br />
                          vertical clips 10x faster for{' '}
                        </span>
                      </div>

                      <div className={`flex relative gap-2`}>
                        <div>
                          <Slider {...settings}>
                            <div className="my-2 text-xl 3xs:ml-0 whitespace-nowrap sm:my-2 md:my-4 xs:text-4xl sm:text-5xl md:text-4xl xl:text-5xl custom-class">
                              TIKTOK
                            </div>
                            <div className="my-2 text-xl 3xs:ml-0 whitespace-nowrap sm:my-2 md:my-4 xs:text-4xl sm:text-5xl md:text-4xl xl:text-5xl custom-class">
                              INSTAGRAM
                            </div>
                            <div className="my-2 text-xl 3xs:ml-0 whitespace-nowrap sm:my-2 md:my-4 xs:text-4xl sm:text-5xl md:text-4xl xl:text-5xl custom-class">
                              YOUTUBE SHORTS
                            </div>
                            <div className="my-2 text-xl 3xs:ml-0 whitespace-nowrap sm:my-2 md:my-4 xs:text-4xl sm:text-5xl md:text-4xl xl:text-5xl custom-class">
                              FACEBOOK
                            </div>
                          </Slider>
                        </div>

                        <img
                          src={greenLines}
                          alt={'Green lines'}
                          className={'sm:mt-2 ml-2 w-10 xs:w-14 absolute -right-1 sm:-right-6 rotate-90'}
                        />
                      </div>

                      <div className="justify-between hidden mt-20 w-fit md:flex">
                        <Link to={`/signup`}>
                          <button className="inline w-24 text-lg text-gray-900 rounded-xl bg-gradient-to-r from-green-to-gradient to-blue-to-gradient hover:from-blue-to-gradient hover:to-green-to-gradient hover:text-gray-700 h-14 lg:w-40">
                            Sign up
                          </button>
                        </Link>
                        <a
                          className="flex-row items-center hidden mx-6 text-base cursor-pointer w-fit md:flex xl:text-lg custom-class"
                          href="#demos"
                        >
                          <p className="mr-4 transition-all duration-300 hover:mr-8 gradient-text">See it in action</p>
                          <img src={greenArrow} />
                        </a>
                      </div>
                    </div>
                    <div className="md:relative md:h-full md:w-1/2">
                      <div className={'mb-6 md:hidden'}>
                        <video
                          autoPlay
                          muted
                          loop
                          playsInline
                          className="rounded-2xl"
                          preload="metadata"
                          onPlay={() => setVideosLoaded(videosLoaded + 1)}
                        >
                          <source src={MobileHeroVideoWebm} type="video/webm" />
                          <source src={MobileHeroVideo} type="video/mp4" />
                        </video>
                      </div>
                      <Link to={`/signup`}>
                        <button
                          className={
                            'rounded-xl bg-gradient-to-r from-green-to-gradient to-blue-to-gradient hover:from-blue-to-gradient hover:to-green-to-gradient hover:text-gray-700 min-w-[108.5px] min-h-[2.75rem] font-bold text-gray-900 sm:visible md:invisible mb-5 w-full'
                          }
                        >
                          Sign up
                        </button>
                      </Link>
                    </div>
                  </article>
                </section>
              </div>
            </div>
            <section>
              <Banner>
                <div className={'w-full overflow-x-hidden flex text-black text-2xl font-semibold'}>
                  {repeatedWords.map((word, index) => (
                    <div className="flex flex-row mr-2" key={index}>
                      {word} &nbsp; <img src={blackStar} alt="Star" />
                      &nbsp;
                    </div>
                  ))}
                </div>
              </Banner>
            </section>
            {/*<section
              id="description"
              className="relative min-h-full p-4 pt-8 bg-white"
            >
              <h2
                className={
                  "text-black text-4xl font-bold text-center pt-5 max-w-3xl mx-auto leading-normal"
                }
              >
                Shave hours off your video editing process using Big Room AI
              </h2>
              <p className="max-w-5xl px-0 mx-auto my-3 text-2xl leading-9 text-center md:px-16">
                Repurposing video content just got so much easier. Upload your
                videos and automatically get back vertical content ready to
                post. Or add finishing touches via your favorite video editor,
                like Adobe Premiere or Final Cut.
              </p>
              <div
                className={
                  "grid md:grid-flow-col grid-flow-row content-start gap-5 mt-14 w-full max-w-5xl mx-auto"
                }
              >
                <CircleIcon
                  src={greenArrowUp}
                  label={"Upload video to Bigroom"}
                />
                <CircleIcon src={greenClock} label={"Do you for a bit"} />
                <CircleIcon
                  src={greenDownload}
                  label={"Download vertical videos and files"}
                />
              </div>
              <div
                className={
                  "flex flex-col md:flex-row items-center justify-between mx-auto gap-3 my-10 md:w-96 w-full"
                }
              >
                <Link to={`/signup`}>
                  <button
                    className={
                      "bg-black text-white w-44 h-14 rounded-2xl text-lg  font-semibold mt-5"
                    }
                  >
                    Sign up
                  </button>
                </Link>
                <button
                  className={
                    "bg-transparent text-black min-w-[108.5px] min-h-[2.75rem] mt-5"
                  }
                  type={"black-link"}
                >
                  <a
                    className={"flex justify-center gap-2 font-semibold"}
                    href="#demos"
                  >
                    See it in action
                    <img src={whiteArrow} alt="White arrow" />
                  </a>
                </button>
              </div>
                </section>*/}

            {/* Video features */}
            <section id="demos" className="relative w-full bg-black pb-40">
              {/* <div className="relative flex items-center w-full min-h-full px-2 md:py-10 pt-10 overflow-hidden bg-black max-w-[2000px] mx-auto">
                <CarouselPreview videosLoaded={videosLoaded} setVideosLoaded={setVideosLoaded} />
              </div> */}

              <VerticalizationFeature />
              <FeaturesDivider />
              <MulticameraFeature />
              <FeaturesDivider />
              <DynamicZoomFeature />
            </section>

            {/* Scroll features */}
            <section id="features" className="h-full py-4 bg-gray-100 sm:pl-0 md:pl-4 md:py-0 2xl:py-8">
              {loadCarousel && <Carousel />}
            </section>
            <WhosUsingBR/>
            
            <FAQ />
          </div>
        </div>
      </div>
    </>
  );
};
