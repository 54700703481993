// File validation limits
export const minimumResolution = process.env.REACT_APP_MINIMUM_RESOLUTION_ALLOWED
  ? 720
  : Number(process.env.REACT_APP_MINIMUM_RESOLUTION_ALLOWED);
export const videoDurationLimit = process.env.REACT_APP_VIDEO_DURATION
  ? 240
  : Number(process.env.REACT_APP_VIDEO_DURATION);
export const maximunSizeAllowed = process.env.REACT_APP_MAX_WEIGHT_ALLOWED_MB
  ? 3200
  : Number(process.env.REACT_APP_MAX_WEIGHT_ALLOWED_MB);

// File validation messages
export const durationErrorMsg = `Video must be shorter than ${videoDurationLimit} minutes.`;
export const widthErrorMsg = `Your video is not landscape or square.`;
export const sizeErrorMsg = `Your video is larger than ${maximunSizeAllowed / 1000}GB.`;
export const resolutionErrorMsg = `Video resolution must be at least ${minimumResolution}p.`;

// YouTube validations messages
export const urlErrorMsg = `Hmm, looks like that isn't a valid URL.`;
export const youtubeUrlErrorMsg = `Hmm, looks like that isn't a valid YouTube URL.`;
export const nonValidUrlErrorMsg = `We only support YouTube links right now.`;
export const youtubeApiDownErrorMsg = `Sorry! We're having technical issues. Please try later or use local upload.`;
export const liveVideosUnsopportedMsg = `Sorry! We don’t support livestreams yet.`;

// YouTube error cases
export const isYoutubeApiDown = 'YOUTUBE_API';
export const isYoutubeVideoUnavailable = 'YOUTUBE_VIDEO_UNAVAILABLE';
