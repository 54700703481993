import instance from 'axios';

const baseURL = process.env.REACT_APP_API;
const resource = 'feature-flag';

export const getFlags = async () => {
  try {
    const { data, status } = await instance.get(`${baseURL}${resource}`);
    const response = data.data.attributes;
    if (status === 200) {
      return response;
    }
  } catch ({ response }) {
    console.info(response);
  }
};
