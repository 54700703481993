import { useState } from "react";
import { Switch as HeadlessSwitch } from "@headlessui/react";

export default function Switch({ checked, onChange, label }) {
  const [enabled, setEnabled] = useState(checked);

  const handleChange = () => {
    setEnabled(!enabled);
    onChange(!enabled);
  };

  return (
    <div className='flex flex-row'>
      <HeadlessSwitch
        checked={enabled}
        onChange={handleChange}
        className={`
        border-tranparent ring-2 ring-black relative inline-flex h-5 w-8 shrink-0 cursor-pointer rounded-full border-2 transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
      >
        <span
          aria-hidden='true'
          className={`${
            enabled ? "bg-green-200 translate-x-3" : "bg-black translate-x-0"
          }
          mt-0.5 ml-0.5 border-2 border-black pointer-events-none inline-block h-3 w-3 transform rounded-full shadow-lg ring-0 transition duration-200 ease-in-out`}
        />
      </HeadlessSwitch>
      <span className='ml-2 text-sm font-medium text-gray-900'>{label}</span>
    </div>
  );
}
