import React, { useCallback, useEffect, useState, useMemo } from 'react';

// Zustand - Global state
import useDynamicZoomStore from '../../../../store/useDynamicZoom.store';
import useUserStore from '../../../../store/useUser.store';

// Mixpanel Events
import { clickDeleteFailedVideoEvent } from '../../../../utils/mixpanelEvents';

// Icon
import error from '../../../../assets/icon/error.svg';
import deleteIcon from '../../../../assets/icon/delete.svg';
import { HiPlus, HiMinus } from 'react-icons/hi';

const FileListError = () => {
  const user = useUserStore((state) => state.user);
  const setFileDeleted = useDynamicZoomStore((state) => state.setFileDeleted);
  const setFailedFiles = useDynamicZoomStore((state) => state.setFailedStatusFile);
  const files = useDynamicZoomStore((state) => state.filesFailed);

  const hasUploadedInterval = useDynamicZoomStore((state) => state.hasUploadedInterval);
  const hasCallInterval = useDynamicZoomStore((state) => state.hasCallInterval);

  const getFailed = useCallback(() => {
    setFailedFiles();
  }, [setFailedFiles]);

  useEffect(() => {
    getFailed();
  }, [getFailed]);

  useEffect(() => {
    if (hasUploadedInterval || hasCallInterval) {
      const interval = setInterval(() => {
        getFailed();
      }, 30000);
      return () => clearInterval(interval);
    }
  }, [getFailed, hasCallInterval, hasUploadedInterval]);

  const _handleDeleteFile = (file: any) => {
    clickDeleteFailedVideoEvent(user.id);
    setFileDeleted(file.uid);
  };

  const [seeFull, setSeeFull] = useState(false);
  const showSeeFull = files?.length > 4;

  const _handlerHeight = useMemo(() => {
    if (showSeeFull && !seeFull) {
      return 'h-52';
    } else {
      return '';
    }
  }, [showSeeFull, seeFull]);

  return files.length ? (
    <section className="mt-8">
      <article
        className={`${_handlerHeight} flex flex-col gap-4 border-2 border-red-600/30 text-failed-video rounded-xl relative overflow-hidden pt-4 bg-red-50/20`}
      >
        <div className={`${showSeeFull ? 'p-6 pt-3' : 'px-6 py-3'} ${seeFull ? 'pb-10' : ''}`}>
          {files.map((file) => (
            <div
              className={`flex flex-col md:flex-row md:items-center ${files.length === 1 ? 'mb-4' : 'mb-4 md:mb-6'}`}
              key={file.ui}
            >
              <div className="flex items-center justify-between w-full gap-3">
                <div className="flex gap-2 w-36 md:w-status-bar-name">
                  <div className="flex items-center gap-2 truncate w-96">
                    <img src={error} alt="" />
                    <span className="text-sm truncate">{file.name}</span>
                  </div>
                </div>
                <div className="hidden pl-4 text-sm font-medium md:flex">
                  {file.error_message ? (
                    file.error_message
                  ) : (
                    <span>
                      Hmm, something went wrong. Try uploading again or contact{' '}
                      <a
                        href="mailto:support@bigroom.tv"
                        target="_blank"
                        rel="noreferrer"
                        className="underline transition duration-200 hover:no-underline"
                      >
                        support@bigroom.tv
                      </a>
                    </span>
                  )}
                </div>
                <div className="flex items-center justify-end w-32 gap-4 ml-auto">
                  {/* <p className='font-medium underline cursor-pointer' onClick={() => setUploadModal(true)}>Try again</p> */}
                  <img
                    onClick={() => _handleDeleteFile(file)}
                    src={deleteIcon}
                    alt=""
                    className="transition duration-200 rounded-full cursor-pointer hover:bg-red-300"
                  />
                </div>
              </div>
              <div className="flex mt-4 text-sm md:hidden">
                <p>
                  {file.error_message ? (
                    file.error_message
                  ) : (
                    <span>
                      Hmm, something went wrong. Try uploading again or contact{' '}
                      <a href="mailto:support@bigroom.tv" target="_blank" rel="noreferrer" className="underline">
                        support@bigroom.tv
                      </a>
                    </span>
                  )}
                </p>
              </div>
            </div>
          ))}
        </div>

        {!seeFull && showSeeFull ? (
          <div className="absolute bottom-0 flex items-center justify-end w-full p-6 rounded-xl md:py-8 bg-gradient-to-t from-red-50/95 to-red-50/50">
            <div className="flex items-center justify-end gap-2 cursor-pointer" onClick={() => setSeeFull(!seeFull)}>
              <HiPlus />
              <p className="font-medium underline transition duration-200 text-red-700/70 hover:no-underline">
                See more
              </p>
            </div>
          </div>
        ) : null}
        {seeFull && showSeeFull ? (
          <div className="absolute bottom-0 flex items-center justify-end w-full px-6 py-6 mt-6 rounded-xl">
            <div className="flex items-center justify-end gap-2 cursor-pointer" onClick={() => setSeeFull(!seeFull)}>
              <HiMinus />
              <p className="font-medium underline transition duration-200 text-red-700/70 hover:no-underline">
                See less
              </p>
            </div>
          </div>
        ) : null}
      </article>
    </section>
  ) : null;
};

export default FileListError;
