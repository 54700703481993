import axios from '../services/base.service';

export const setToken = (token?: string) => {
  localStorage.setItem('jwt', token || '');
  const tokenFromLocalStorage = localStorage.getItem('jwt');
  axios.defaults.headers.common['Authorization'] = `Bearer ${token ?? tokenFromLocalStorage}`;
};

export const getToken = () => {
  return localStorage.getItem('jwt');
};

export const getUser = () => {
  return JSON.parse(localStorage.getItem('user') || '{}');
};
