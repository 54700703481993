import { useState } from 'react';

// Zustand - Global state
import useDynamicZoomStore from '../../../../store/useDynamicZoom.store';

// Libraries
import { useDropzone } from 'react-dropzone';

// Icons
import uploadFileIcon from '../../../../assets/icon/uploadFileIcon.svg';
import { submitAudioData, submitWithoutAudio, uploadAudio } from '../../../../services/dynamicZoom.service';
import VideoLoaded from './VideoLoaded';

import { ReactComponent as AudioIcon } from '../../../../assets/icon/audioIcon.svg';
import UploadDynamicAudioUrl from './components/UploadDynamicAudioUrl';

const UploadAudio = () => {
  const [controller, setController] = useState(new AbortController());
  const uploadStep = useDynamicZoomStore((state) => state.uploadStep);
  const [file, setFile] = useState<any>(null);
  const submittedResponse = useDynamicZoomStore((state) => state.submitResponse);
  const [progress, setProgress] = useState(0);

  const options = {
    signal: controller.signal,
    onUploadProgress: (event: any) => {
      const progress = Math.round((event.loaded / event.total) * 100);
      setProgress(progress);
    },
  };

  const onDrop = async (acceptedFiles: any, rejectedFiles: any) => {
    if (acceptedFiles.length == 0) {
      return;
    }

    setFile(acceptedFiles[0]);

    const file = new File([acceptedFiles[0]], acceptedFiles[0].name);

    const audioUploaded = await uploadAudio(submittedResponse.presigned_audio_url, file, options);

    if (audioUploaded) {
      submitAudioData(submittedResponse.uid, true);
      useDynamicZoomStore.setState({ uploadStep: uploadStep + 1 });
    }
  };

  const { getRootProps, getInputProps, isDragAccept } = useDropzone({
    multiple: false,
    accept: 'audio/*',
    onDrop,
  });

  const rootProps = file ? {} : getRootProps();

  const handleSubmitWithoutAudio = async () => {
    submitWithoutAudio(submittedResponse.uid, true);
    useDynamicZoomStore.setState({ uploadStep: uploadStep + 1 });
  };

  return (
    <>
      <p className="absolute font-semibold top-3 left-8">2. Select audio</p>
      {!file ? (
        <div className="w-11/12 p-4 mx-auto mt-10 border border-green-500 rounded bg-green-100/50">
          <p className="text-sm">
            <span className="font-semibold">You can choose a special file to be the audio for your video.</span>
            <br />
            Make sure the file sound has good quality and that includes all the audio of the speaking participants.
          </p>
        </div>
      ) : null}
      <section
        {...rootProps}
        className={`mt-4 flex flex-col items-center pb-4 ${
          file ? 'mt-16' : 'mx-5 my-2 border border-gray-400 border-dashed rounded-xl p-3'
        }`}
      >
        <input type="file" name="file" {...getInputProps()} />

        <img src={uploadFileIcon} className="w-20 md:w-28" alt="upload icon" />

        <p className="mt-4 text-sm font-semibold text-gray-800 md:text-lg"></p>

        {!file ? (
          <>
            <button className="flex items-center justify-center py-3 mt-3 text-xs font-semibold text-gray-800 rounded-xl md:rounded-2xl bg-blue-to-gradient w-72 md:w-40 md:text-sm hover:text-gray-700">
              Choose file
            </button>
            <p className="mt-4 text-sm">You can upload mp3, m4a, aif, wav</p>
          </>
        ) : (
          <div className={`relative w-full transition-all duration-300 h-24`}>
            <div
              className={`absolute w-full h-auto transition-all duration-100 ${
                file ? 'opacity-100' : 'opacity-0 pointer-events-none'
              }`}
            >
              <VideoLoaded
                fileName={file?.name}
                videoDuration={''}
                hasError={false}
                isSuccess={progress === 100}
                progress={progress}
                canBeUploaded={true}
                removeFile={() => {
                  setFile(undefined);
                  setProgress(0);
                }}
                cancelUpload={() => {
                  setFile(undefined);
                  setProgress(0);
                }}
                Thumbnail={<AudioIcon />}
                hasConnectionError={false}
                showButton={false}
              />
            </div>
          </div>
        )}
      </section>

      {!file && (
        <>
          <UploadDynamicAudioUrl isAudio jobUid={submittedResponse?.uid} />
          <p className="mx-auto mb-2 text-sm text-center cursor-pointer w-fit" onClick={handleSubmitWithoutAudio}>
            Skip with original audio
          </p>
        </>
      )}
    </>
  );
};

export default UploadAudio;
