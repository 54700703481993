import React from 'react';

// Zustand - Global state
import useAppStore from '../../../store/useApp.store';
import useUserStore from '../../../store/useUser.store';

// Components
import Modal from '../../Dashboard/components/shared/Modal';
// import OutsideClickDetector from "../../Shared/outsideClickDetector/OutsideClickDetector";

// Icons
import { Transition } from '@headlessui/react';
import generatingCaptionIllustration from '../../../assets/image/generatingCaptionIllustration.svg';

// import { useNavigate } from 'react-router-dom';

const ConfirmGenerateCaptionModal = ({ generateCaptions }: any) => {
  // const navigate = useNavigate()
  const userEmail = useUserStore((state) => state.userEmail);
  const setConfirmGenerateCaptionsModal = useAppStore((state) => state.setConfirmGenerateCaptionsModal);
  const isConfirmGenerateCaptionsModalOpen = useAppStore((state) => state.confirmGenerateCaptionsModal);

  const _handleCloseCaptionModal = () => {
    setConfirmGenerateCaptionsModal(false);
  };

  const _handleDoneCaptionsEdition = () => {
    generateCaptions();
    setConfirmGenerateCaptionsModal(false);
  };

  return (
    <Modal show={isConfirmGenerateCaptionsModalOpen}>
      <Transition
        appear={true}
        show={isConfirmGenerateCaptionsModalOpen}
        className="fixed left-0 transition-all duration-200 translate-x-[calc(50vw-50%)]"
        enterFrom="translate-y-[100vh]"
        enterTo="translate-y-[calc(50vh-50%)]"
        leaveFrom="translate-y-[calc(50vh-50%)]"
        leaveTo="translate-y-[100vh]"
      >
        {/* <OutsideClickDetector callback={() => _handleCloseCaptionModal()}> */}
        <main className={`relative w-96 md:w-[600px] z-50`}>
          <div className="p-6 overflow-hidden bg-gray-100 border shadow-lg rounded-xl md:p-10 border-gray-50">
            <section className="flex flex-col gap-4 pt-2 text-center">
              <img src={generatingCaptionIllustration} alt="" className="m-auto w-28" />
              <p className="font-bold ">We’re on it!</p>

              <p className="text-sm font-semibold">
                {`We're generating your captioned video. Once it's ready, you'll get an email at  ${userEmail}.`}
              </p>
              <p className="text-sm">This is a Beta feature so the process is a little clunky!</p>
              {/* <p className="text-sm">
								Please remember, this is an <b>English-focused BETA</b> feature so the process is a little clunky! We will be expanding this feature very soon.
								</p> */}
              <p className="text-sm">
                We’d love your feedback - shoot us a note at{' '}
                <a
                  href="mailto:support@bigroom.tv"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="font-semibold underline"
                >
                  support@bigroom.tv.
                </a>
              </p>
              <div className="flex flex-col items-center justify-between gap-3 pt-6 md:gap-6 md:flex-row">
                <button
                  className={`text-white bg-black px-4 py-2 text-sm font-semibold border rounded-xl w-full order-0 md:order-1`}
                  onClick={_handleDoneCaptionsEdition}
                >
                  Go back to dashboard
                </button>
              </div>
            </section>
          </div>
        </main>
        {/* </OutsideClickDetector> */}
      </Transition>
    </Modal>
  );
};

export default ConfirmGenerateCaptionModal;
