// UI Layout
import AuthLayout from './layout/AuthLayout';

// Components
import ResetPasswordComponent from './components/ResetPassword';
import SuccessSubmitting from './components/SuccessSubmitting';

// Zustand Global State
import useUserStore from '../../store/useUser.store';

const ResetPassword = () => {
  const isResetSuccess = useUserStore((state) => state.isResetSuccess);

  return (
    <AuthLayout>
      {isResetSuccess ? (
        <SuccessSubmitting
          title="Password changed!"
          subtitle="Your password has been changed successfully"
          buttonText="Go Back to Log In"
          redirectionPath="/login"
        />
      ) : (
        <ResetPasswordComponent />
      )}
    </AuthLayout>
  );
};

export default ResetPassword;
