import React from "react";

// Zustand - Global state
import { ReactComponent as GreenCheck } from "../../../../../assets/icon/greenCheck.svg";
// Icons
import { TfiClose } from "react-icons/tfi";
const SurveyConfirmation = ({ close, nextStep }) => {
  return (
    <main className={`relative max-w-96 w-80 md:w-[600px]`}>
      <div className='p-6 overflow-hidden bg-gray-100 border shadow-lg rounded-xl md:p-10 border-gray-50'>
        <button
          onClick={close}
          className={`absolute top-2 right-2 p-1 cursor-pointer`}
        >
          <TfiClose className='w-6' />
        </button>
        <section className='pt-2 text-center'>
          <GreenCheck className='mx-auto mb-6' />
          <p className='text-sm'>
            Confirmed! We'll remove the watermark from this video and all future
            videos. Check your dashboard for when this video will be available
            without a watermark.
          </p>
          <div className='flex items-center justify-center gap-6 pt-6'>
            <button
              className='px-6 py-3 text-sm font-semibold text-white bg-black rounded-xl'
              onClick={nextStep}
            >
              Go to Dashboard
            </button>
          </div>
        </section>
      </div>
    </main>
  );
};

export default SurveyConfirmation;
