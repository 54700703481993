const errorMessages = {
  'Invalid identifier or password': () => `Email and password don’t match.`,
  EMAIL_DOES_NOT_EXIST: () => `Hmm, we can't find that email in our system.`,
  'Your account email is not confirmed': () =>
    `Oops! Please verify your email first. You should have received a link via email.`,
  'Email or Username are already taken': () => `Hmm, that email is taken.`,
  'Internal Server Error': (authEnv?: string) =>
    authEnv === 'signup' ? 'You already have an account! Log in instead.' : `Email and password don’t match.`,
};

export const ErrorValidationMessages = (error: keyof typeof errorMessages, authEnv?: 'signup' | 'login') => {
  return errorMessages[error](authEnv) || error;
};
