import axios from 'axios';

import useUserStore from '../store/useUser.store';
import { getToken } from '../utils/setToken';

const baseURL = process.env.REACT_APP_API;
const baseApi = axios.create({ baseURL });

baseApi.interceptors.request.use(
  async (config) => {
    config.headers = {
      Authorization: `Bearer ${ getToken() ?? ''}`,
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export default baseApi;
