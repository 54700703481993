import React, { useCallback, useEffect, useMemo, useState } from 'react';

// Icon
import checked from '../../../../assets/icon/checked.svg';
import { HiPlus, HiMinus } from 'react-icons/hi';

// Zustand - Global state
import useVerticalizedStore from '../../../../store/useVerticalized.store';
import useAppStore from '../../../../store/useApp.store';
import useUserStore from '../../../../store/useUser.store';
import FileListReadyPagination from './FileListReadyPagination';

// Mixpanel Event
import { clickViewVideoEvent } from '../../../../utils/mixpanelEvents';

const FileListReady = () => {
  const user = useUserStore((state) => state.user);
  const setCompletedFiles = useVerticalizedStore((state) => state.setCompleteStatusFile);
  const setDownloadModal = useAppStore((state) => state.setDownloadModal);
  const setCurrentFile = useVerticalizedStore((state) => state.setCurrentFile);
  const files = useVerticalizedStore((state) => state.filesCompleted);
  const setFileViewed = useVerticalizedStore((state) => state.setFileViewed);
  const paginationInfo = useVerticalizedStore((state) => state.filesCompletedPagination);
  const hasPagination = paginationInfo.pageCount > 1;

  const hasUploadedInterval = useVerticalizedStore((state) => state.hasUploadedInterval);
  const hasCallInterval = useVerticalizedStore((state) => state.hasCallInterval);

  const getCompleted = useCallback(() => {
    setCompletedFiles();
  }, [setCompletedFiles]);

  useEffect(() => {
    getCompleted();
  }, [getCompleted]);

  useEffect(() => {
    if (hasUploadedInterval || hasCallInterval) {
      const interval = setInterval(() => {
        getCompleted();
      }, 10000);
      return () => clearInterval(interval);
    }
    if (!hasUploadedInterval && !hasCallInterval) {
      const timeOut = setTimeout(() => {
        getCompleted();
      }, 5000);
      return () => clearTimeout(timeOut);
    }
  }, [getCompleted, hasCallInterval, hasUploadedInterval]);

  const [seeFull, setSeeFull] = useState(false);
  const showSeeFull = files?.length > 3;

  const _handleDownloadModal = (file: any) => {
    clickViewVideoEvent(user.id);
    setDownloadModal(true);
    setCurrentFile(file);
    if (!file.viewed) {
      setFileViewed(file.uid);
    }
  };

  const _handlerHeight = useMemo(() => {
    if (showSeeFull && !seeFull) {
      return 'h-60';
    } else {
      return '';
    }
  }, [showSeeFull, seeFull]);

  return files.length ? (
    <section className="mt-8 md:mt-12">
      <article className={`${_handlerHeight} border-2 border-gray-600/30 rounded-xl relative overflow-hidden bg-white`}>
        <h3 className="p-6 pb-1">Ready</h3>
        <div className="p-6 mb-4">
          {files.map((file) => (
            <div
              key={file.uid}
              className="flex flex-col items-center justify-between gap-3 mb-3 md:gap- md:mb-6 md:flex-row"
            >
              <div className="flex items-center justify-between w-full gap-3">
                <div className="flex gap-2 w-36 md:w-status-bar-name">
                  <div className="flex items-center gap-2 truncate w-96">
                    <img src={checked} alt="" />
                    <span className="text-sm truncate">{file.name}</span>
                  </div>
                </div>
                <div className="relative hidden w-full h-3 overflow-hidden bg-gray-200 rounded-full md:flex">
                  <div
                    className={`absolute bg-blue-to-gradient text-xs rounded-full h-3 font-medium text-blue-100 text-center leading-none rounded-l-full transition-all z-100`}
                    style={{ width: `${100}%` }}
                  ></div>
                </div>
                <div className="md:w-12">
                  <p
                    onClick={() => _handleDownloadModal(file)}
                    className="font-medium text-right text-gray-900 underline transition duration-200 cursor-pointer md:text-start hover:no-underline"
                  >
                    View
                  </p>
                </div>
              </div>

              {/* Mobile bar */}
              <div className="relative flex w-full h-4 overflow-hidden bg-gray-200 rounded-full md:hidden">
                <div
                  className={`absolute bg-blue-to-gradient text-xs rounded-full h-4 font-medium text-blue-100 text-center leading-none rounded-l-full transition-all z-100`}
                  style={{ width: `${100}%` }}
                ></div>
              </div>
            </div>
          ))}
        </div>
        {!seeFull && showSeeFull ? (
          <div className="absolute bottom-0 flex items-center justify-end w-full p-6 rounded-xl md:py-8 bg-gradient-to-t from-white to-white/80">
            <div className="flex items-center justify-end gap-2 cursor-pointer" onClick={() => setSeeFull(!seeFull)}>
              <HiPlus />
              <p className="font-medium text-gray-700 underline transition duration-200 hover:no-underline">See more</p>
            </div>
          </div>
        ) : null}
        {seeFull && showSeeFull ? (
          <div
            className={`${
              hasPagination ? 'justify-between' : 'justify-end'
            } flex items-center rounded-xl absolute bottom-0 w-full px-6 py-3`}
          >
            <FileListReadyPagination />
            <div className="flex items-center gap-2 cursor-pointer w-4/1" onClick={() => setSeeFull(!seeFull)}>
              <HiMinus />
              <p className="font-medium text-gray-700 underline transition duration-200 hover:no-underline">See less</p>
            </div>
          </div>
        ) : null}
      </article>
    </section>
  ) : null;
};

export default FileListReady;
