import React, {useEffect, useState} from 'react';

import useAppStore from '../../../../../store/useApp.store';
import useMulticameraStore from '../../../../../store/useMulticamera.store';

import Modal from '../../shared/Modal';
import { TfiClose } from 'react-icons/tfi';
import OutsideClickDetector from '../../../../Shared/outsideClickDetector/OutsideClickDetector';
import { Transition } from '@headlessui/react';

import AudioDropzone from '../components/AudioDropzone';



const UploadAudioModal = () => {
  const setUploadAudioModal = useAppStore((state) => state.setUploadAudioModal);
  const uploadAudioModal = useAppStore((state) => state.uploadAudioModal);
	const [controller, setController] = useState(new AbortController());

  const resetFiles = useMulticameraStore((state) => state.resetFiles);
  const resetAudio = useMulticameraStore((state) => state.resetAudio);
  const setIsAudioUploaded = useMulticameraStore((state) => state.setIsAudioUploaded);
  const setVideosUploaded = useMulticameraStore((state) => state.setVideosUploaded);

	
  const audioFile = useMulticameraStore((state) => state.audioFile);
  const setAudioFile = useMulticameraStore((state) => state.setAudioFile);

  const _handleCancelUploadModalModal = () => {
    setUploadAudioModal(false);
  };

  const _handleCreateProyect = (data:unknown) => {
    setUploadAudioModal(false)
  };

	useEffect(() => {
		resetAudio()
	}, [])

	const _cancelUpload = () => {
    setUploadAudioModal(false);
    resetFiles();
    resetAudio();
    setIsAudioUploaded(false);
    // setIsUploadable(false)
    controller.abort();
    setController(new AbortController());
    setTimeout(() => {
      setUploadAudioModal(true);
    }, 200);
    setVideosUploaded(0);
  };

  return (
    <Modal show={uploadAudioModal}>
      <Transition
        appear={true}
        show={uploadAudioModal}
        className="fixed left-0 transition-all duration-200 translate-x-[calc(50vw-50%)] translate-y-[calc(50vh-50%)]"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <OutsideClickDetector callback={() => _handleCancelUploadModalModal()}>
          <main className="relative max-w-96 w-80 md:w-[480px]">
            <div className="p-2 overflow-hidden bg-gray-100 border shadow-lg rounded-xl md:p-2 border-gray-50">
              <button onClick={_handleCancelUploadModalModal} className={`absolute top-2 right-2 p-1 cursor-pointer`}>
                <TfiClose className="text-gray-700" size={20} />
              </button>

							<AudioDropzone cancelUpload={_cancelUpload} signal={controller} />
            </div>
          </main>
        </OutsideClickDetector>
      </Transition>
    </Modal>
  );
};

export default UploadAudioModal;
