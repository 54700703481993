import create from 'zustand';

// Service
import {
  getMulticameraProject,
  getMulticameraProjects,
  createMulticameraProject,
  // uploadMedia,
  // uploadMediaWithoutToken,
  removeMulticameraProject,
  removeMulticameraMedia,
  setMasterAudioFile,
  startJob,
  cancelJob
} from '../services/multicamera.service';

// Zustand - Global state
// import useAppStore from './useApp.store';
// import useUserStore from './useUser.store';

// const abort = new AbortController()

interface MulticameraStore {
  projectId: string;
  projectName: string;
  uploadStep: number;
  currentProject: any;
  isAudioUploaded: boolean;

  projects: unknown[];
  hasProjects: boolean;
  project: unknown;

  multicameraUploadStep: number;

  files: File[];
  audioFile: any;
  file: any;
  hasFile: boolean;
  videosUploaded: number;
  isJustUploadAudio: boolean;
  hasProjectInProgress: boolean;
  allowContinue: boolean;

  // actions
  getProjects: () => void;
  getProject: (uid: string) => void;
  setCurrentProject: (project: any) => void;
  createProject: (projectData: unknown) => void;
  removeProject: (uid: string) => void;
  removeMedia: (uid:string) => void;

  setFiles: (file: File) => void;
  setAudioFile: (file: File) => void;
  setMasterAudioFile: (jobId:string, fileUid:string) => void;
  resetFiles: () => void;
  resetAudio: () => void;
  setMulticameraUploadStep: (step: number) => void;
  setIsAudioUploaded: (status: boolean) => void;
  setVideosUploaded: (videoNumber:number) => void;
  removeFile: (file:File) => void
  startJob: (uid:string) => void
  cancelJob: (uid:string) => void
  setIsJustUploadAudio: (status:boolean) => void;
  setHasProjectInProgress: (status: boolean) => void;
  setAllowContinue: (status: boolean) => void;
}

const useMulticameraStore = create<MulticameraStore>(
  // persist(
  (set, get): MulticameraStore => ({
    // state
    projectId: '',
    projectName: '',
    uploadStep: 0,

    files: [],
    audioFile: null,
    file: {},
    hasFile: false,

    multicameraUploadStep: 0,

    projects: [],
    hasProjects: false,
    project: null,
    currentProject: {},
    isAudioUploaded: false,
    videosUploaded: 0,
    isJustUploadAudio: false,
    hasProjectInProgress: false,
    allowContinue:false,

    // actions
    getProjects: async () => {
      const setHasProjectInProgress = get().setHasProjectInProgress
      try {
        const { data: projects } = await getMulticameraProjects();

        const formattedProjects = projects.map((project: any) => ({ ...project.attributes, id: project.id }));
        const inProgressProjects = formattedProjects.filter((project:any) => !project.job_end_time && !project.complete && project.started)
        const hasInProgress = !!inProgressProjects.length
        setHasProjectInProgress(hasInProgress);
    
        set((state: MulticameraStore) => ({ ...state, projects: formattedProjects, hasProjects: !!projects.length }));
        return projects;
      } catch (error) {
        console.info(error);
      }
    },
    getProject: async (uid) => {
      try {
        const { data: project } = await getMulticameraProject(uid);
        const formattedProject = {...project.attributes, id: project.id}
        set((state: MulticameraStore) => ({ ...state, currentProject: formattedProject}));
        return project;
      } catch (error) {
        console.info(error);
      }
    },
    setCurrentProject: (project: any) => {
      set((state: MulticameraStore) => ({ ...state, currentProject: project }));
    },
    createProject: async (projectData: unknown) => {
      const getProjects = get().getProjects;

      const request = { data: projectData };

      try {
        const data = await createMulticameraProject(request);
        set((state: MulticameraStore) => ({ ...state, currentProject: data}));
        console.info(data);
        if (data) getProjects();
      } catch (error) {
        console.info(error);
      }
    },
    removeProject: async (uid: string) => {
      const getProjects = get().getProjects;
      try {
        const { data } = await removeMulticameraProject(uid);
        if (data) getProjects();
      } catch (error) {
        console.info(error);
      }
    },
    removeMedia: async (uid: string) => {
      const getProject = get().getProject;
      const currentProject = get().currentProject
      const isMasterAudio = currentProject?.master_audio_file === uid
      const setMasterAudioFile = get().setMasterAudioFile

      try {
        const { data } = await removeMulticameraMedia(uid);
        if(isMasterAudio) {
          setMasterAudioFile(currentProject.uid, '')
          return
        }
        if (data) {
          getProject(currentProject.uid)
        };
      } catch (error) {
        console.info(error);
      }
    },
    removeFile: (file:File) => {
      const currentFiles = get().files
      const setMulticameraStep = get().setMulticameraUploadStep
    const filesWithoutRemovedOne = currentFiles.filter(f => f.size !== file.size)

    if(currentFiles.length === 1) {
      setMulticameraStep(0)
    }
    set((state: MulticameraStore) => ({ ...state, files: filesWithoutRemovedOne }));

    },
    setFiles: (file: File) => {
      const files = get().files;

      set((state: MulticameraStore) => ({ ...state, files: [...files, file] }));
    },
    resetFiles: () => {
      set((state: MulticameraStore) => ({ ...state, files: [] }));
    },
    resetAudio: () => {
      set((state: MulticameraStore) => ({ ...state, audioFile: null }));
    },
    setMasterAudioFile: async (jobUid, fileUid) => {
      const getProject = get().getProject;
      const getProjects= get().getProjects;
      const currentProject = get().currentProject
      try {
        const response = await setMasterAudioFile(jobUid, fileUid);
        if (response) {
          getProjects();
          getProject(currentProject.uid)};
      } catch (error) {
        console.info(error);
      }
      // set((state: MulticameraStore) => ({ ...state, audioFile: file }));
    },
    setAudioFile: (file: File) => {
      set((state: MulticameraStore) => ({ ...state, audioFile: file }));
    },
    setMulticameraUploadStep: (step: number) => {
      set((state: MulticameraStore) => ({ ...state, multicameraUploadStep: step }));
    },
    setIsAudioUploaded: (status) => {
      set((state: MulticameraStore) => ({ ...state, isAudioUploaded: status }));
    },
    setVideosUploaded: (num) => {
      set((state: MulticameraStore) => ({ ...state, videosUploaded: num }));
    },
    startJob: async (jobUid) => {
      const getProject = get().getProject;
      const getProjects = get().getProjects;
      const currentProject = get().currentProject
      try {
        const response = await startJob(jobUid);
        if (response) {
          getProject(currentProject.uid);
          getProjects();
        }
      } catch (error) {
        console.info(error);
      }
    },
    cancelJob: async (jobUid) => {
      const getProject = get().getProject;
      const getProjects = get().getProjects;
      const currentProject = get().currentProject
      const msg = 'User cancel job'
      try {
        const response = await cancelJob(jobUid, msg);
        if (response) {
          getProject(currentProject.uid);
          getProjects();
        }
      } catch (error) {
        console.info(error);
      }
    },
    setIsJustUploadAudio: (status) => {
      set((state: MulticameraStore) => ({ ...state, isJustUploadAudio: status }));
    },
    setHasProjectInProgress: (status) => {
      set((state: MulticameraStore) => ({ ...state, hasProjectInProgress: status }));
    },
    setAllowContinue: (status) => {
      set((state: MulticameraStore) => ({ ...state, allowContinue: status }));
    },
  })
);

export default useMulticameraStore;
