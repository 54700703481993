import { useMemo, useEffect, useCallback } from 'react';

// Libraries
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// Zustand Global State
import useUserStore from '../../../store/useUser.store';
import useAppStore from '../../../store/useApp.store';

// Form Schema
import { signUpSchema } from '../schemas/authSchemas';

// Mixpanel Events
import { viewSignupEvent } from '../../../utils/mixpanelEvents';

// UI componets
import Input from './Input';
import FormHeader from './FormHeader';
import Button from './Button';

const SignUp = () => {
  const isLoading = useAppStore((state) => state.isSignUpLoading);
  const doSignUp = useUserStore((state) => state.signUp);
  const signUpError = useUserStore((state) => state.signUpError);
  const setClearErrors = useUserStore((state) => state.setClearErrors);

  const {
    register,
    formState: { errors, isDirty, isValid },
    handleSubmit,
    watch,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(signUpSchema),
  });

  const onSubmit = async (data: any) => {
    await doSignUp(data);
  };

  useEffect(() => {
    if (signUpError) {
      setClearErrors();
    }
  }, [setClearErrors, signUpError]);

  const pass = watch('password');
  const confirm = watch('passwordConfirmation');
  const validateMatchPassword = useMemo(() => (confirm?.length ? pass === confirm : true), [confirm, pass]);

  const viewSignup = useCallback(() => {
    viewSignupEvent();
  }, []);
  useEffect(() => {
    viewSignup();
  }, [viewSignup]);

  return (
    <div className="flex flex-col h-screen px-4 mx-auto align-middle sm:px-0 sm:w-2/3 md:w-2/3 lg:w-2/3 xl:w-2/4">
      <FormHeader
        title="Join Big Room today!"
        subtitle="Automatically convert landscape videos into vertical ones with Big Room AI"
        buttonText="Sign Up with Google"
        showGoogle={true}
      />

      <form onSubmit={handleSubmit(onSubmit)} className="grid grid-cols-6 mt-2 gap-x-8">
        <div className="col-span-6 sm:col-span-3">
          <Input
            label="First Name"
            type="text"
            inputName="firstName"
            errors={errors}
            validation={register('firstName')}
          />
        </div>

        <div className="col-span-6 sm:col-span-3">
          <Input label="Last Name" type="text" inputName="lastName" errors={errors} validation={register('lastName')} />
        </div>

        <div className="col-span-6">
          <Input label="Email address" type="email" inputName="email" errors={errors} validation={register('email')} />
        </div>

        <div className="col-span-6 ">
          <Input
            label="Password"
            type="password"
            inputName="password"
            errors={errors}
            validation={register('password')}
          />
        </div>

        <div className="col-span-6">
          <Input
            label="Re-enter password"
            type="password"
            inputName="passwordConfirmation"
            errors={errors}
            validation={register('passwordConfirmation')}
            arePasswordMatch={validateMatchPassword}
          />
        </div>

        {signUpError && <p className="col-span-6 mt-1 mb-4 text-xs text-red-400">{signUpError}</p>}

        <div className="col-span-6 sm:flex sm:items-center sm:gap-4">
          <Button
            disabled={!isDirty || !isValid || isLoading}
            type="submit"
            isLoading={isLoading}
            text="Sign Up with Email"
          />
        </div>

        <div className="flex justify-center col-span-6 mt-2 mb-2 sm:flex sm:items-center sm:gap-4">
          <p className="text-xs text-gray-800">
            By signing up, you agree to Big Room’s{' '}
            <a
              href="https://bigroom-cms.s3.us-east-2.amazonaws.com/BigRoomSoftwareLicense.pdf"
              className="font-semibold text-gray-900 underline"
              target="_blank"
              rel="noreferrer"
            >
              End-User License Agreement
            </a>
          </p>
        </div>

        <div className="flex justify-center col-span-6 mt-4 mb-4 sm:flex sm:items-center sm:gap-4">
          <p className="mt-4 text-sm text-center text-gray-500 sm:mt-0">
            Already have an account?
            <Link to={`/login`} className="ml-2 font-semibold text-gray-700 underline">
              Log In
            </Link>
          </p>
        </div>
      </form>
    </div>
  );
};

export default SignUp;
