import React from "react";

// Icons
import { TfiClose } from "react-icons/tfi";

const SurveyClosingPopup = ({ close, nextStep }) => {
  return (
    <main className={`relative max-w-96 w-80 md:w-[500px]`}>
      <div className='p-6 overflow-hidden bg-gray-100 border shadow-lg rounded-xl border-gray-50'>
        <button
          onClick={close}
          className={`absolute top-2 right-2 p-1 cursor-pointer`}
        >
          <TfiClose className='w-6' />
        </button>
        <section className='text-center'>
          <p>
            Are you sure you want to close out?
            <br />
            <br />
            The watermarks won't be removed.
          </p>
          <div className='flex items-center justify-center w-full gap-6 pt-6'>
            <button
              className='w-full px-6 py-3 text-sm font-semibold text-black border border-black rounded-xl'
              onClick={nextStep}
            >
              Go back to survey
            </button>
            <button
              className='w-full px-6 py-3 text-sm font-semibold text-white bg-black rounded-xl'
              onClick={close}
            >
              Keep watermarks
            </button>
          </div>
        </section>
      </div>
    </main>
  );
};

export default SurveyClosingPopup;
