import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../assets/logo/logo.svg';
import NotFoundImage from '../../assets/image/notFound_404.png';
import leftBottomIllustration from '../../assets/image/illustration_404_leftBottom.svg';
import rightTopIllustration from '../../assets/image/illustration_404_rightTop.png';

const NotFound = () => {
  const navigate = useNavigate();

  const _goToHome = () => {
    navigate('/');
  };

  return (
    <div className="relative h-screen overflow-hidden bg-gray-50">
      <img
        src={rightTopIllustration}
        alt=""
        className="absolute right-4 -top-8 w-64 md:right-0 md:-top-16 md:w-[50%] z-0"
      />
      <img src={logo} alt="" className="absolute left-0 z-20 w-24 p-8 cursor-pointer md:right-0" onClick={_goToHome} />
      <div className="z-40 flex flex-col justify-center h-full">
        <img src={NotFoundImage} className="w-56 mx-auto md:w-64 rounded-xl" alt="not found" />
        <p className="w-full mt-0 mb-0 text-2xl font-bold text-center text-black">Oops! Page not found</p>
        <p className="flex flex-col items-center justify-center mt-4 font-medium leading-6 text-center text-black text-bg-sm w-90">
          Well, that's a wrap on this page! You might be looking for an <br />
          old project link or page that is no longer supported.
          <Link className="z-40 w-40 text-sm font-semibold text-white no-underline" to={'/'}>
            <div className="p-2 mt-4 bg-black rounded-lg">Go back home</div>
          </Link>
        </p>
      </div>
      <img
        src={leftBottomIllustration}
        alt=""
        className="absolute left-4 bottom-2 w-64 md:left-0 md:-bottom-6 md:w-[50%] z-0"
      />
    </div>
  );
};

export default NotFound;
