import React from 'react'
import useMulticameraStore from '../../../../../store/useMulticamera.store'
import ProjectCard from './ProjectCard'
const ProjectList = () => {
	const projects = useMulticameraStore(state => state.projects)
	return (
		<article className='grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-8 justify-between'>
		{projects?.map((project:any) => (
			<ProjectCard key={project.uid} project={project} />
		))}
		</article>
	)
}

export default ProjectList