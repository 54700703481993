import React, { useCallback } from 'react';

// Icons
import { TfiClose } from 'react-icons/tfi';
import checkUpdate from '../../../../assets/icon/checkUpdate.svg';

// Components
import ProgressBar from '../shared/ProgressBar';

// Mixpanel Events
import { clickUploadAnother } from '../../../../utils/mixpanelEvents';

// Zustand - Global state
import useUserStore from '../../../../store/useUser.store';
import useVerticalizedStore from '../../../../store/useVerticalized.store';
import useMulticameraStore from '../../../../store/useMulticamera.store';

// Utils
import { truncateVideoNames } from '../../../../utils/truncateLargeName';

const VideoLoaded = ({
  fileName,
  videoDuration,
  hasError,
  hasConnectionError,
  progress,
  canBeUploaded,
  removeFile,
  cancelUpload,
  Thumbnail,
  isSuccess,
  isMulticamera,
  isAudio,
  multicamSetUploadDone,
  multicameraSubmitResponse,
}: {
  fileName?: string;
  videoDuration?: string;
  hasError: boolean;
  hasConnectionError: boolean;
  progress?: number;
  canBeUploaded?: boolean;
  removeFile?: () => void;
  cancelUpload?: () => void;
  multicamSetUploadDone?: (status: boolean) => void;
  multicameraSubmitResponse?: (data: unknown) => void;
  isMulticamera?: boolean;
  Thumbnail: any;
  isSuccess: boolean;
  isAudio?:boolean
}) => {
  const user = useUserStore((state) => state.user);
  const setHasVideo = useUserStore((state) => state.setHasVideo);
  const setUploadDone = useVerticalizedStore((state) => state.setUploadDone);
  const setYoutubeUrl = useVerticalizedStore((state) => state.setYoutubeUrl);
  const setYoutubeUrlData = useVerticalizedStore((state) => state.setYoutubeUrlData);
  const setHasUploadedInterval = useVerticalizedStore((state) => state.setHasUploadedInterval);
  const setHasCallInterval = useVerticalizedStore((state) => state.setHasCallInterval);
  const getInProgress = useVerticalizedStore((state) => state.setInProgressStatusFile);
  const setUploadStep = useVerticalizedStore((state) => state.setUploadStep);
  const uploadStep = useVerticalizedStore((state) => state.uploadStep);
  const setFileUploaded = useVerticalizedStore((state) => state.setFileUploaded);
  const setLocalVideoUrl = useVerticalizedStore((state) => state.setLocalVideoUrl);
  const setVideoTrimming = useVerticalizedStore((state) => state.setVideoTrimming);
  const setVideoTrimmingDuration = useVerticalizedStore((state) => state.setVideoTrimmingDuration);
  const setIsInProgressTrimmed = useVerticalizedStore((state) => state.setIsInProgressTrimmed);
  const isInProgressTrimmed = useVerticalizedStore((state) => state.isInProgressTrimmed);
  const setAllowContinue = useMulticameraStore((state) => state.setAllowContinue)

  const _handleUploadSubmit = () => {
    //Mixpanel Event
    clickUploadAnother(user.id);

    setHasUploadedInterval(true);
    setHasCallInterval(true);
    setUploadStep(0);
    setVideoTrimming([]);
    setVideoTrimmingDuration(0);
    setHasVideo();
    setUploadDone(false);
    setIsInProgressTrimmed(false);
    getInProgress();
    setYoutubeUrl('');
    setLocalVideoUrl('');
    setYoutubeUrlData({});
    setFileUploaded({});
    canBeUploaded && removeFile && removeFile();
  };

  const _handleUploadAnotherInMulticamera = useCallback(() => {
    multicamSetUploadDone && multicamSetUploadDone(false);
    multicameraSubmitResponse && multicameraSubmitResponse({});
    setAllowContinue(false)
  },[multicamSetUploadDone, multicameraSubmitResponse, setAllowContinue]);

  // useEffect(() => {
  //   (multicameraUploadStep === 2 && !currentProject?.master_audio_file) && _handleUploadAnotherInMulticamera()
  //   console.info(currentProject.master_audio_file)
  // }, [_handleUploadAnotherInMulticamera, currentProject?.master_audio_file, multicameraUploadStep])

  return (
    <>
      <section
        className={`relative w-full h-18 bg-blue-to-gradient px-2 py-3 md:p-4 flex justify-between mt-4 items-center ${
          (hasError || hasConnectionError) && 'grayscale opacity-70 bg-slate-200'
        } gap-2 md:gap-3 overflow-hidden`}
      >
        {/* <div className='absolute 
							bg-gradient-to-r from-transparent via-shimmer-color to-transparent 
							top-0 w-full rounded-sm h-full delay-0 animate-[shimmer_3s_infinite] isolate'></div> */}

        <article className="z-50 flex items-center">
          {Thumbnail}
          <div className={`ml-2 ${isSuccess ? 'w-full' : 'w-28 md:w-32'}`}>
            <div className={`${isSuccess ? 'flex w-full items-center gap-2' : 'w-28 md:w-32'}`}>
              <p
                className={`${
                  isSuccess ? 'text-xs md:text-sm font-medium text-gray-800' : 'text-xs md:text-bg-sm font-medium'
                }`}
              >
                {truncateVideoNames(fileName || '')}
              </p>
              <p className={`${isSuccess ? 'text-xs font-medium text-gray-800' : 'text-bg-xs text-gray-700'}`}>
                {videoDuration}
              </p>
            </div>
          </div>
        </article>

        {canBeUploaded && !isSuccess && <ProgressBar progress={progress || 0} />}

        {!isSuccess ? (
          <button onClick={canBeUploaded ? cancelUpload : removeFile} className={`cursor-pointer z-50`}>
            <TfiClose className="w-3" />
          </button>
        ) : (
          <button className={`z-50`}>
            <img src={checkUpdate} alt="check update" />
          </button>
        )}
      </section>

      {!isAudio && <section className={`flex justify-center ${uploadStep !== 0 ? 'px-5 pt-6 pb-4' : 'p-4'} `}>
        {!hasConnectionError ? (
          (uploadStep !== 0 || !videoDuration || isInProgressTrimmed) && (
            <button
              onClick={isMulticamera ? _handleUploadAnotherInMulticamera : _handleUploadSubmit}
              disabled={hasError}
              className="w-full py-3 text-sm font-medium text-center text-white bg-blue-submit rounded-xl disabled:bg-gray-300"
            >
              Upload another
            </button>
          )
        ) : (
          <button className="w-full py-3 text-sm font-medium text-center text-white bg-blue-submit rounded-xl disabled:bg-gray-300">
            Retry
          </button>
        )}
      </section>}
    </>
  );
};

export default VideoLoaded;
