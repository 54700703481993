// Icons
import check from '../../../../assets/icon/onboarding/check.png';
import error from '../../../../assets/icon/onboarding/error.png';

import { featureList, futureFeaturesList } from '../../utils/features';

import { FeatureListIcon, FeaturesList } from '../shared/FeatureList';

const Features = () => (
  <section className="flex flex-col justify-between p-6 border border-gray-300 rounded-xl md:p-8 md:flex-row">
    <h2 className="flex mb-4 text-lg font-semibold text-gray-800 md:hidden">Pro tips before you upload</h2>
    <article>
      <p className="flex items-center gap-2 font-semibold">
        <FeatureListIcon icon={check} />
        What works best
      </p>
      <FeaturesList list={featureList} />
    </article>
    <article>
      <p className="flex items-center gap-2 mt-8 font-semibold md:mt-0">
        <FeatureListIcon icon={error} />
        What Doesn't (...Yet)
      </p>
      <FeaturesList list={futureFeaturesList} />
    </article>
  </section>
);

export default Features;
