import { useCallback, useEffect } from 'react';

import FileListReady from './FileListReady';
import FileListInProgress from './FileListInProgress';
import FileListError from './FileListError';
import FileStatus from './FileStatus';
import MyVideos from './MyVideos';

// Zustand - Global state
import useUserStore from '../../../../store/useUser.store';

// Mixpanel Event
import { viewLoggedInDashboadEvent } from '../../../../utils/mixpanelEvents';

const Dashboard = () => {
  const user = useUserStore((state) => state.user);
  const viewDashboard = useCallback(() => {
    viewLoggedInDashboadEvent(user.id);
  }, [user]);
  useEffect(() => {
    viewDashboard();
  }, [viewDashboard]);

  return (
    <>
      <FileStatus />

      {/* Success section */}
      <FileListReady />

      {/* Failed section */}
      <FileListError />

      {/* In progress section */}
      <FileListInProgress />

      {/* Upload tooltip */}
      {/* <UploadInfoTooltip /> */}

      {/* My videos */}
      <MyVideos />
    </>
  );
};

export default Dashboard;
