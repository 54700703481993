// Zustand - Global State
import useAppStore from '../../../store/useApp.store';
import useVerticalizedStore from '../../../store/useVerticalized.store';

// Services
import { uploadedSuccess } from '../../../services/upload.service';

// File validations
import {
  // Validations
  videoDurationLimit,
  resolutionErrorMsg,
  // Error status
  isYoutubeApiDown,
  isYoutubeVideoUnavailable,
  // Error messages
  youtubeUrlErrorMsg,
  minimumResolution,
  durationErrorMsg,
  liveVideosUnsopportedMsg,
} from './fileValidation';

interface YoutubeResponse {
  youtube_info: {
    error: string;
    height: number;
    duration?: number;
  };
  uid: string;
}

export const youtubeResponseValidations = (response: YoutubeResponse) => {
  const setUploadedSuccess = useVerticalizedStore.getState().setUploadedSuccess;

  if (response.youtube_info.duration === undefined) {
    useAppStore.setState({ isUploadByUrlLoading: false });
    useVerticalizedStore.setState({ isLiveVideo: true });
    uploadedSuccess(response.uid, true, liveVideosUnsopportedMsg);
    return;
  }

  if (response.youtube_info.error === isYoutubeApiDown) {
    useAppStore.setState({ isUploadByUrlLoading: false });
    useVerticalizedStore.setState({ isYoutubeApiDown: true });
    uploadedSuccess(response.uid, true, 'youtube no data');
    return;
  }
  if (response.youtube_info.error === isYoutubeVideoUnavailable) {
    useAppStore.setState({ isUploadByUrlLoading: false });
    useVerticalizedStore.setState({ isYoutubeUrlInvalid: true });
    uploadedSuccess(response.uid, true, youtubeUrlErrorMsg);
    return;
  }
  if (response.youtube_info.height < minimumResolution) {
    useAppStore.setState({ isUploadByUrlLoading: false });
    useVerticalizedStore.setState({ isResolutionUrlInvalid: true });
    uploadedSuccess(response.uid, true, resolutionErrorMsg);
    return;
  }
  if (response.youtube_info.duration! / 60 >= videoDurationLimit) {
    useAppStore.setState({ isUploadByUrlLoading: false });
    useVerticalizedStore.setState({ isDurationUrlInvalid: true });
    uploadedSuccess(response.uid, true, durationErrorMsg);
    return;
  }
  setUploadedSuccess(response.uid);
  return response;
};
