export const toTimeString = (sec: string | number, showMilliSeconds = true, showHours = true) => {
  const secInt = parseFloat(sec as string);
  let hours = Math.floor(secInt / 3600);
  let minutes = Math.floor((secInt - hours * 3600) / 60);
  let seconds = secInt - hours * 3600 - minutes * 60;
  // add 0 if value < 10; Example: 2 => 02
  let hoursStr = hours < 10 ? '0' + hours : hours.toString();
  let minutesStr = minutes < 10 ? '0' + minutes : minutes.toString();
  let secondsStr = seconds < 10 ? '0' + seconds : seconds.toString();

  let maltissaRegex = /\..*$/; // matches the decimal point and the digits after it e.g if the number is 4.567 it matches .567
  let millisec = secondsStr.match(maltissaRegex);
  return `${showHours && hoursStr + ':'}${minutesStr}:${secondsStr.replace(maltissaRegex, '')}${
    showMilliSeconds ? (millisec ? `${millisec[0]}0` : '.00') : ''
  }`;
};
