// Auth tailwind enabled css
import '../index.scss';

// UI component
import SideAuth from '../components/SideAuth';

const AuthLayout = ({ children }: { children: React.ReactNode }) => (
  <div className="flex flex-col md:flex-row" id="tailwind-base">
    <SideAuth />
    <div className="justify-center flex-auto w-screen mt-4 mb-2 md:w-2/3 xl:w-2/4 md:mt-8 lg:mt-24">{children}</div>
  </div>
);

export default AuthLayout;
