// UI Layout
import AuthLayout from './layout/AuthLayout';

// Components
import LogInComponent from './components/LogIn';

const LogIn = () => (
  <AuthLayout>
    <LogInComponent />
  </AuthLayout>
);

export default LogIn;
