import { Feature } from '../../utils/features';

// Internal Components
export const FeaturesList = ({ list }: { list: Feature[] }) => (
  <div className="flex flex-col items-start justify-center gap-4 mt-4 ">
    {list?.map((item, index) => (
      <section className="flex gap-3" key={index}>
        <div className="mt-1">
          <FeatureListIcon icon={item.icon} />
        </div>
        <div className="flex flex-col w-full p-0">
          <p className="p-0 m-0 text-sm font-semibold">{item.title}</p>
          <p className="text-xs text-gray-900 font-base">{item.subtitle}</p>
        </div>
      </section>
    ))}
  </div>
);

export const FeatureListIcon = ({ icon }: { icon: string }) => <img src={icon} alt="" srcSet="" />;
