import React from 'react';

// Icons
import { TfiClose } from 'react-icons/tfi';
import checkUpdate from '../../../../assets/icon/checkUpdate.svg';

// Components
import ProgressBar from '../shared/ProgressBar';

// Mixpanel Events
import { clickUploadAnother } from '../../../../utils/mixpanelEvents';

// Zustand - Global state
import useUserStore from '../../../../store/useUser.store';
import useDynamicZoomStore from '../../../../store/useDynamicZoom.store';

// Utils
import { truncateVideoNames } from '../../../../utils/truncateLargeName';

const VideoLoaded = ({
  fileName,
  videoDuration,
  hasError,
  hasConnectionError,
  progress,
  canBeUploaded,
  removeFile,
  cancelUpload,
  Thumbnail,
  isSuccess,
  showButton,
}: {
  fileName?: string;
  videoDuration?: string;
  hasError: boolean;
  hasConnectionError: boolean;
  progress?: number;
  canBeUploaded?: boolean;
  removeFile?: () => void;
  cancelUpload?: () => void;
  Thumbnail: any;
  isSuccess: boolean;
  showButton?: boolean;
}) => {
  const user = useUserStore((state) => state.user);
  const setHasVideo = useUserStore((state) => state.setHasVideo);
  const setUploadDone = useDynamicZoomStore((state) => state.setUploadDone);
  const setYoutubeUrl = useDynamicZoomStore((state) => state.setYoutubeUrl);
  const setYoutubeUrlData = useDynamicZoomStore((state) => state.setYoutubeUrlData);
  const setHasUploadedInterval = useDynamicZoomStore((state) => state.setHasUploadedInterval);
  const setHasCallInterval = useDynamicZoomStore((state) => state.setHasCallInterval);
  const getInProgress = useDynamicZoomStore((state) => state.setInProgressStatusFile);
  const setUploadStep = useDynamicZoomStore((state) => state.setUploadStep);
  const uploadStep = useDynamicZoomStore((state) => state.uploadStep);
  const setFileUploaded = useDynamicZoomStore((state) => state.setFileUploaded);
  const setLocalVideoUrl = useDynamicZoomStore((state) => state.setLocalVideoUrl);
  const setVideoTrimming = useDynamicZoomStore((state) => state.setVideoTrimming);
  const setVideoTrimmingDuration = useDynamicZoomStore((state) => state.setVideoTrimmingDuration);
  const setIsInProgressTrimmed = useDynamicZoomStore((state) => state.setIsInProgressTrimmed);
  const isInProgressTrimmed = useDynamicZoomStore((state) => state.isInProgressTrimmed);

  const _handleUploadSubmit = () => {
    //Mixpanel Event
    clickUploadAnother(user.id);

    setHasUploadedInterval(true);
    setHasCallInterval(true);
    setUploadStep(0);
    setVideoTrimming([]);
    setVideoTrimmingDuration(0);
    setHasVideo();
    setUploadDone(false);
    setIsInProgressTrimmed(false);
    getInProgress();
    setYoutubeUrl('');
    setLocalVideoUrl('');
    setYoutubeUrlData({});
    setFileUploaded({});
    canBeUploaded && removeFile && removeFile();
  };

  return (
    <>
      <section
        className={`relative w-full h-18 bg-blue-to-gradient px-2 py-3 md:p-4 flex justify-between mt-4 items-center ${
          (hasError || hasConnectionError) && 'grayscale opacity-70 bg-slate-200'
        } gap-2 md:gap-3 overflow-hidden`}
      >
        {/* <div className='absolute 
							bg-gradient-to-r from-transparent via-shimmer-color to-transparent 
							top-0 w-full rounded-sm h-full delay-0 animate-[shimmer_3s_infinite] isolate'></div> */}

        <article className="z-50 flex items-center">
          {Thumbnail}
          <div className={`ml-2 ${isSuccess ? 'w-full' : 'w-28 md:w-32'}`}>
            <div className={`${isSuccess ? 'flex w-full items-center gap-2' : 'w-28 md:w-32'}`}>
              <p
                className={`${
                  isSuccess ? 'text-xs md:text-sm font-medium text-gray-800' : 'text-xs md:text-bg-sm font-medium'
                }`}
              >
                {truncateVideoNames(fileName || '')}
              </p>
              <p className={`${isSuccess ? 'text-xs font-medium text-gray-800' : 'text-bg-xs text-gray-700'}`}>
                {videoDuration}
              </p>
            </div>
          </div>
        </article>

        {canBeUploaded && !isSuccess && <ProgressBar progress={progress || 0} />}

        {!isSuccess ? (
          <button onClick={canBeUploaded ? cancelUpload : removeFile} className={`cursor-pointer z-50`}>
            <TfiClose className="w-3" />
          </button>
        ) : (
          <button className={`z-50`}>
            <img src={checkUpdate} alt="check update" />
          </button>
        )}
      </section>

      {(showButton && isSuccess) && (
        <section className={`flex justify-center ${uploadStep !== 0 ? 'px-5 pt-6 pb-4' : 'p-4'} `}>
          {!hasConnectionError ? (
            (uploadStep !== 0 || !videoDuration || isInProgressTrimmed) && (
              <button
                onClick={_handleUploadSubmit}
                disabled={hasError}
                className="w-full py-3 text-sm font-medium text-center text-white bg-blue-submit rounded-xl disabled:bg-gray-300"
              >
                Upload another
              </button>
            )
          ) : (
            <button className="w-full py-3 text-sm font-medium text-center text-white bg-blue-submit rounded-xl disabled:bg-gray-300">
              Retry
            </button>
          )}
        </section>
      )}
    </>
  );
};

export default VideoLoaded;
