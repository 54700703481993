import React from "react";

import completed from "../../../../assets/icon/completed.svg";
import failed from "../../../../assets/icon/failed.svg";
import inProcess from "../../../../assets/icon/inProcess.svg";
import uploadIcon from "../../../../assets/icon/uploadIcon.svg";

// UI Components
import UploadInfoTooltip from "./UploadInfoTooltip";
// Zustand - Global state
import useAppStore from "../../../../store/useApp.store";
import useVerticalizedStore from "../../../../store/useVerticalized.store";
import useUserStore from "../../../../store/useUser.store";

// Mixpanel Events
import { clickUploadVideoEvent } from "../../../../utils/mixpanelEvents";

const FileStatus = () => {
  const user = useUserStore((state) => state.user);
  const setUploadModal = useAppStore((state) => state.setUploadModal);
  const filesInProcess = useVerticalizedStore((state) => state.filesInProgress).length;
  const filesFailed = useVerticalizedStore((state) => state.filesFailed).length;
  const filesSuccess = useVerticalizedStore(
    (state) => state.filesCompletedPagination.total
  );

  const _uploadVideo = () => {
    clickUploadVideoEvent(user.id);
    setUploadModal(true);
  };

  return (
    <section className="flex flex-col items-center justify-between gap-4 md:flex-row">
      <article className="flex justify-start w-full gap-3 text-sm font-medium text-gray-900 md:gap-6 md:w-fit">
        {filesSuccess ? (
          <span className="flex items-center gap-1">
            <img src={completed} className="w-5" alt="" />
            <span>{filesSuccess}</span>
            <span className="hidden md:flex">{`${
              filesSuccess === 1 ? "video" : "videos"
            }`}</span>
            <span className="hidden md:flex">completed</span>
            <span className="md:hidden">done</span>
          </span>
        ) : null}

        {filesFailed ? (
          <span className="flex items-center gap-1">
            <img src={failed} className="w-5" alt="" />
            <span>{filesFailed}</span>
            <span className="hidden md:flex">{`${
              filesFailed === 1 ? "video" : "videos"
            }`}</span>
            <span>failed</span>
          </span>
        ) : null}

        {filesInProcess ? (
          <span className="flex items-center gap-1">
            <img src={inProcess} className="w-5 animate-spin" alt="" />
            <span>{filesInProcess}</span>
            <span className="hidden md:flex">{`${
              filesInProcess === 1 ? "video" : "videos"
            }`}</span>
            <span>in progress</span>
          </span>
        ) : null}
      </article>

      <article className="w-full md:w-56">
        <button
          onClick={_uploadVideo}
          className="flex items-center justify-center w-full gap-3 px-4 py-4 rounded-xl bg-gradient-to-r from-green-to-gradient to-blue-to-gradient hover:from-blue-to-gradient hover:to-green-to-gradient hover:text-gray-700 md:px-12"
        >
          <img src={uploadIcon} alt="" />
          <span className="text-sm font-semibold">Upload video</span>
        </button>
        <div className="flex justify-end h-4 md:justify-center md:items-center">
          {/* Upload tooltip */}
          <UploadInfoTooltip />
        </div>
      </article>
    </section>
  );
};

export default FileStatus;
