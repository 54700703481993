export const specialCharArray = [
  '~',
  '`',
  '!',
  '@',
  '#',
  '$',
  '%',
  '^',
  '&',
  '*',
  '(',
  ')',
  '+',
  '=',
  '{',
  '}',
  '[',
  ']',
  '|',
  '\\',
  '/',
  ':',
  ';',
  '“',
  '’',
  '<',
  '>',
  '?',
];
