import React, { useEffect, useCallback } from 'react';

// Images
import onboardingCover from '../../../../assets/image/onboardingCover.png';
import onboardingCoverMobile from '../../../../assets/image/mobile/onboardingImage-mobile.svg';
import ilustrationMobile from '../../../../assets/image/mobile/IlustrationOnboarding-mobile.svg';

// Icons
import startIcon from '../../../../assets/icon/starIcon.png';
import Features from './Features';

// Zustand - Global state
import useAppStore from '../../../../store/useApp.store';
import useUserStore from '../../../../store/useUser.store';

// Mixpanel Events
import { viewLoggedInOnboardingEvent, clickUploadVideoEvent } from '../../../../utils/mixpanelEvents';

const Onboarding = () => {
  const user = useUserStore((state) => state.user);
  const setUploadModal = useAppStore((state) => state.setUploadModal);

  const viewOnboarding = useCallback(() => {
    viewLoggedInOnboardingEvent(user.id);
  }, [user]);
  useEffect(() => {
    viewOnboarding();
  }, [viewOnboarding]);

  const _uploadVideo = () => {
    clickUploadVideoEvent(user.id);
    setUploadModal(true);
  };

  return (
    <main className="relative h-full bg-gray-50 md:p-8">
      <section className="flex flex-col-reverse items-center justify-center gap-3 m-auto md:flex-row md:max-w-5xl md:mt-12">
        <button
          onClick={() => setUploadModal(true)}
          className="flex items-center justify-center max-w-lg gap-3 py-4 text-gray-900 rounded-2xl bg-gradient-to-r from-green-to-gradient to-blue-to-gradient hover:from-blue-to-gradient hover:to-green-to-gradient hover:text-gray-700 md:hidden w-80"
        >
          <img src={startIcon} alt="" className="w-6" />
          <span className="text-sm font-semibold">Get started</span>
        </button>

        <article className="m-auto md:w-2/4">
          <img className="hidden md:w-90 md:flex" src={onboardingCover} alt="" />
          <img className="md:hidden" src={onboardingCoverMobile} alt="" />
        </article>

        <article className="flex justify-center p-2 md:w-2/5 md:flex-col">
          <h2 className="flex items-center font-semibold text-bg-xl md:text-2xl md:leading-7 md:tracking-wider">
            Convert your landscape videos to vertical ones for social!
            <img src={ilustrationMobile} className="md:hidden w-11" alt="" />
          </h2>

          <button
            onClick={_uploadVideo}
            className="items-center justify-center hidden max-w-md gap-3 py-6 mt-10 text-gray-900 md:flex rounded-2xl bg-gradient-to-r from-green-to-gradient to-blue-to-gradient hover:from-blue-to-gradient hover:to-green-to-gradient hover:text-gray-700"
          >
            <img src={startIcon} alt="" className="w-6" />
            <span className="text-sm font-semibold">Get started</span>
          </button>
        </article>
      </section>

      <section className="max-w-5xl m-auto mt-7 md:mt-14 md:px-9">
        <h2 className="hidden mb-4 text-2xl font-semibold text-gray-800 md:flex">Pro tips before you upload</h2>
        <Features />
      </section>
    </main>
  );
};

export default Onboarding;
