/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useCallback, useMemo, useEffect } from 'react';

// Utils
import { specialCharArray } from '../../../../../utils/specialCharArray';
import { padTo2Digits, bytesToMegabytes } from '../../../utils/math.utils';

// Zustand - Global state
import useVerticalizedStore from '../../../../../store/useVerticalized.store';
import useUserStore from '../../../../../store/useUser.store';

// Mixpanel Events
import { dashboardUploadFailedEvent } from '../../../../../utils/mixpanelEvents';

// File Validations
import {
  minimumResolution,
  videoDurationLimit,
  maximunSizeAllowed,
  durationErrorMsg,
  widthErrorMsg,
  sizeErrorMsg,
  resolutionErrorMsg,
} from '../../../utils/fileValidation';
import useMulticameraStore from '../../../../../store/useMulticamera.store';

export const useMultifile = () => {
  const user = useUserStore((state) => state.user);
  const setFileName = useVerticalizedStore((state) => state.setFileName);
  // const submitData = useVerticalizedStore((state) => state.submitFile);
  const submitResponse = useVerticalizedStore((state) => state.submitResponse);
  const uploadFile = useVerticalizedStore((state) => state.uploadFile);
  const uploadDone = useVerticalizedStore((state) => state.uploadDone);
  const setUploadDone = useVerticalizedStore((state) => state.setUploadDone);
  const setUploadedSuccess = useVerticalizedStore((state) => state.setUploadedSuccess);
  const setUploadStep = useVerticalizedStore((state) => state.setUploadStep);
  const uploadStep = useVerticalizedStore((state) => state.uploadStep);
  const setLocalVideoUrl = useVerticalizedStore((state) => state.setLocalVideoUrl);


  const setFiles = useMulticameraStore((state) => state.setFiles);
  const files = useMulticameraStore((state) => state.files);
  const setAudioFile = useMulticameraStore((state) => state.setAudioFile);
  const audioFile = useMulticameraStore((state) => state.audioFile);

  const setUploadMulticameraStep = useMulticameraStore((state) => state.setMulticameraUploadStep);
  const multicameraUploadStep = useMulticameraStore((state) => state.multicameraUploadStep);
  const videosUploaded = useMulticameraStore((state) => state.videosUploaded);

  const [file, setFile] = useState<File>();
  const [audio, setAudio] = useState<File>();
  const [controller, setController] = useState(new AbortController());
  const [progress, setProgress] = useState(0);
  // const [uploadDone, setUploadDone] = useState(false);

  // TODO: should be applied in local videoLoaded?
  interface UploadDataProgress {
    loaded: number;
    total: number;
  }

  const options = {
    onUploadProgress: (data: UploadDataProgress) => {
      let dataLoaded = Math.round((100 * data.loaded) / data.total);
      //Set the progress value to show the progress bar
      setProgress(dataLoaded);
    },
    signal: controller.signal,
  };

  // file state validators
  const [isWidthInvalid, setIsWidthInvalid] = useState(false);
  const [isResolutionInvalid, setIsResolutionInvalid] = useState(false);
  const [isDurationInvalid, setIsDurationInvalid] = useState(false);
  const [videoDuration, setVideoDuration] = useState<string>();

  const fileName = useMemo(() => {
    let _fileName = file ? file.name : undefined;
    if (_fileName) {
      specialCharArray.forEach((char) => {
        _fileName = _fileName!.replaceAll(char, '_');
      });
    }
    setFileName(_fileName ?? null);
    return _fileName;
  }, [file]);

  const audioName = useMemo(() => {
    let _audioName = file ? file.name : undefined;
    if (_audioName) {
      specialCharArray.forEach((char) => {
        _audioName = _audioName!.replaceAll(char, '_');
      });
    }
    setFileName(_audioName ?? null);
    return _audioName;
  }, [audio]);

  const fileSize = useMemo(() => (file ? bytesToMegabytes(file.size) : null), [file]);
  const isSizeInvalid = useMemo(() => fileSize && fileSize > maximunSizeAllowed, [fileSize, file]);

  const hasError = isWidthInvalid || isResolutionInvalid || isDurationInvalid || isSizeInvalid;

  const canShowButton = useMemo(() => hasError || !file, [hasError, file]);
  const canBeUploaded = useMemo(() => Boolean((!hasError && file) || (!hasError && audio)), [hasError, file, audio]);
  const [isUploadable, setIsUploadable] = useState(canBeUploaded)
  const isAudioUploaded = useMulticameraStore(state => state.isAudioUploaded)
  const currentProject = useMulticameraStore(state => state.currentProject)

  const getVideoDurationFormatted = useCallback((duration) => {
    if (!duration) {
      return 'Not available';
    }
    const durationNum = duration * 1;
    let seconds = Math.floor(durationNum);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);

    seconds = seconds % 60;
    minutes = minutes % 60;
    hours = hours % 24;

    const hasSecondsOrMinutes = minutes ? 'minutes' : 'seconds';
    const durationFormmated = `${padTo2Digits(hours)}:${padTo2Digits(minutes)}:${padTo2Digits(
      seconds
    )} ${hasSecondsOrMinutes}`;
    setVideoDuration(durationFormmated);
    return durationFormmated;
  }, []);

  const handleVideo = (e: any, file: File) => {
    setIsWidthInvalid(false);
    setIsResolutionInvalid(false);
    setIsDurationInvalid(false);

    const { target } = e;
    const durationValidation = target?.duration > videoDurationLimit * 60;
    const widthValidation = target?.videoWidth < target?.videoHeight;
    const resolutionValidation = target?.videoHeight < minimumResolution;

    widthValidation && setIsWidthInvalid(true);
    durationValidation && setIsDurationInvalid(true);
    resolutionValidation && setIsResolutionInvalid(true);

    getVideoDurationFormatted(target.duration);

    file && setFile(file);
    file && setFiles(file)
  };

  const fileValidator = (file: File) => {
    // setFiles(file);
    setFile(undefined);
    const video = document.createElement('video');
    video.src = URL.createObjectURL(file);
    video.preload = 'metadata';
    video.onloadedmetadata = function (e) {
      handleVideo(e, file);
    };
    return null;
  };

  const audioValidator = (file: File) => {
    setAudio(file);
    setAudioFile(file);
    return null;
  };

  const removeFile = () => {
    setFile(undefined);
    setIsWidthInvalid(false);
    setIsDurationInvalid(false);
    setIsResolutionInvalid(false);
  };

  const cancelUpload = () => {
    removeFile();
    setUploadStep(0);
    dashboardUploadFailedEvent(user.id);
    controller.abort();
    setController(new AbortController());
  };

  // Handle connection logic
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const hasConnectionError = useMemo(() => !isOnline, [isOnline]);

  const updateConnectionStatus = useCallback(() => {
    setIsOnline(() => navigator.onLine);
  }, [isOnline]);

  useEffect(() => {
    isOnline && window.addEventListener('offline', updateConnectionStatus);
    !isOnline && window.addEventListener('online', updateConnectionStatus);

    //Posibble retry logic
    // _checkForConnectionAndUpload(uploadedData, isOnline);

    return () => {
      window.removeEventListener('offline', updateConnectionStatus);
      window.removeEventListener('online', updateConnectionStatus);
    };
  }, [isOnline]);

  const handleTabClosing = useCallback(() => {
    if (uploadStep === 3 && uploadDone) {
      // cancelTrimming()
      // setUploadTrimming
    }
  }, [uploadStep, uploadDone]);

  const alertUser = useCallback(
    (ev) => {
      ev.preventDefault();
      if (uploadStep === 3 && uploadDone) {
        // cancelTrimming()
        // setUploadTrimming()
      }
    },
    [uploadStep, uploadDone]
  );

  useEffect(() => {
    window.addEventListener('beforeunload', alertUser);
    setTimeout(() => {
      window.addEventListener('unload', handleTabClosing);
    }, 1000);
    return () => {
      window.removeEventListener('beforeunload', alertUser);
      window.removeEventListener('unload', handleTabClosing);
    };
  }, [uploadStep, uploadDone]);

  const videoFilesInProject = currentProject?.files?.filter((file: any) => file.type === 'video').length;
  const totalVideosAllowed = 4;
  const videoFilesAllowedConst = useMemo(() => totalVideosAllowed <= (videoFilesInProject+files.length), [files]);

  const uploadState = useMemo(() => {
    if (hasConnectionError) {
      return "Oops...Couldn't upload that";
    }
    if (!!audioFile && multicameraUploadStep === 2 && !isAudioUploaded) {
      return 'Uploading...hang tight!';
    }
    if (multicameraUploadStep === 1 && videosUploaded === files.length && !!videosUploaded) {
      return 'Videos upload complete';
    }
    if (audioFile && multicameraUploadStep === 2 && (uploadDone || isAudioUploaded)) {
      return 'Upload complete';
    }
    if ( multicameraUploadStep === 1 && videoFilesAllowedConst ) {
      return `Videos not allowed`;
    }
    if ( multicameraUploadStep === 1 && canBeUploaded) {
      return 'Step 1 of 2';
    }
    return 'Drag & Drop or';
  }, [canBeUploaded, hasConnectionError, uploadDone, multicameraUploadStep, audioFile, isAudioUploaded, videosUploaded, files]);

  const getVideoSrc = (file: File) => {
    const videoUrl = URL.createObjectURL(file);
    setLocalVideoUrl(videoUrl);
    return `${videoUrl}#t=2.8`;
  };

  const Thumbnail = useMemo(() => {
    if (!file) return null;
    return (
      <div className="relative w-12 h-12 overflow-hidden rounded-xl border border-red-900">
        <video src={file ? getVideoSrc(file) : submitResponse?.thumbnail} className="absolute w-24 max-w-none" />
      </div>
    );
  }, [file]);

  const FileErrors = useMemo(
    () => (
      <section className="mt-4 text-xs text-center text-error-message">
        <p>{isWidthInvalid && widthErrorMsg}</p>
        <p>{isDurationInvalid && durationErrorMsg}</p>
        <p>{isResolutionInvalid && resolutionErrorMsg}</p>
        <p>{isSizeInvalid && sizeErrorMsg}</p>
      </section>
    ),
    [hasError]
  );

  const FilesAllowedToUpload = ({videosAllowed}:any) => {
    return (
      <>
        {((!file && !hasConnectionError) || multicameraUploadStep === 0)  && (
          <>
            <p className="mt-6 text-xs text-gray-800">
              You can upload <b>.mp4</b> and <b>.mov</b> files only
            </p>
            <p className="mt-2 text-xs text-gray-800">
              Choose up to <b>{videosAllowed} videos</b> from different <b>camera angles.</b>
            </p>
          </>
        )}
      </>
    );
  };

  const AudioFilesAllowedToUpload = ({videosAllowed}:any) => {
    return (
      <>
        {!audio && !hasConnectionError && (
          <>
            <p className="mt-6 text-xs text-gray-800">
              You can upload <b>mp3, m4a, aif, wav</b>
            </p>
          </>
        )}
      </>
    );
  };

  const ConnectionError = () => {
    return hasConnectionError ? (
      <section className="-mt-1 text-xs font-normal text-center text-error-message mb-7">
        <p>Check your internet connection before</p>
        <p>giving it another go.</p>
      </section>
    ) : null;
  };

  const UploadingSection = () => {
    return canBeUploaded && !hasConnectionError && !uploadDone ? (
      <section className="text-center">
        <p className="-mt-1 text-xs font-light text-gray-700 md:text-sm">
          Don't close the page or you'll lose your upload.
        </p>
        <p onClick={cancelUpload} className="text-xs font-medium text-gray-800 underline cursor-pointer md:text-sm">
          Cancel upload
        </p>
      </section>
    ) : null;
  };

  const UploadingAudioSection = ({cancelAudioUpload}:any) => {
    return audioFile && !hasConnectionError && !isAudioUploaded ? (
      <section className="text-center">
        <p className="-mt-1 text-xs font-light text-gray-700 md:text-sm">
          Don't close the page or you'll lose your upload.
        </p>
        <p onClick={cancelAudioUpload} className="text-xs font-medium text-gray-800 underline cursor-pointer md:text-sm">
          Cancel upload
        </p>
      </section>
    ) : null;
  };

  const UploadedSection = () => {
    return uploadDone && !hasConnectionError ? (
      <section className="text-center">
        <p className="px-10 pb-4 -mt-1 text-xs font-light text-gray-800 md:text-bg-sm">
          Check your dashboard or email - you'll be notified when your video is complete. Edits can take up to a few
          hours.
        </p>
      </section>
    ) : null;
  };
  const UploadedAudioSection = () => {
    return audioFile && isAudioUploaded && !hasConnectionError ? (
      <section className="text-center">
        <p className="px-4 pb-4 -mt-1 text-xs font-light text-gray-800 md:text-bg-sm">
          Start creating the switcher or go to you dashboard.
        </p>
      </section>
    ) : null;
  };

  const setMulticameraFiles = useCallback(() => {
    const currentVideosQuantityInProject = currentProject?.files?.filter((file: any) => file.type === 'video').length

    if(currentVideosQuantityInProject === 0) {
      if (files.length >= 2 && multicameraUploadStep < 1) {
        setUploadMulticameraStep(1);
        return
      }
      return
    }

    if (files.length >= 1 && multicameraUploadStep < 1) {
      setUploadMulticameraStep(1);
      return
    }

  }, [files, currentProject]);

  useEffect(() => {
    setMulticameraFiles();
  }, [files]);

  useEffect(() => {
    // TODO: improve retry logic
    if (isOnline) {
      if (submitResponse && file && fileName) {
        const media = new File([file], fileName);
        uploadFile(
          submitResponse?.presigned_video_url ? submitResponse?.presigned_video_url[0] : null,
          media,
          options,
          setUploadDone
        );
        return;
      }
    } else {
      setProgress(0);
      controller.abort();
      setController(new AbortController());
    }
  }, [submitResponse, isOnline]);

  const submitUploadSuccess = useCallback(() => {
    if (uploadDone && file) {
      setUploadedSuccess(submitResponse.uid);
    }
  }, [uploadDone]);

  useEffect(() => {
    //TODO: fileUploaded and file are just for upload file, we need it for youtube as well
    // if (uploadDone && !!fileUploaded && !!file) {
    if (uploadDone && uploadStep !== 4) {
      setUploadStep(3);
    }
    submitUploadSuccess();
  }, [uploadDone]);

  return {
    // Properties
    file,
    audio,
    fileName,
    audioName,
    videoDuration,
    canShowButton,
    canBeUploaded,
    isUploadable,
    hasError,
    hasConnectionError,
    progress,
    uploadDone,
    uploadState,
    // Methods
    setFile,
    setAudio,
    setIsUploadable,
    removeFile,
    setUploadDone,
    fileValidator,
    audioValidator,
    getVideoDurationFormatted,
    // Components
    Thumbnail,
    FileErrors,
    ConnectionError,
    UploadingSection,
    UploadingAudioSection,
    UploadedSection,
    UploadedAudioSection,
    FilesAllowedToUpload,
    AudioFilesAllowedToUpload,
    cancelUpload,
  };
};
