import React from 'react';

import MulticameraWEBM from '../../../assets/Features/Multicamera/MultiCam.webm';
import MulticameraMP4 from '../../../assets/Features/Multicamera/MultiCam.mp4';
import CameraIcon from '../../../assets/Features/Icons/CameraIcon.svg';

const MulticameraFeature = () => {
  return (
    <section className='flex flex-col justify-center items-center'>
			<article className='flex items-center justify-center gap-4'>
				<img src={CameraIcon} alt="" />
				<p className='text-white font-ptsans text-2xl font-semibold py-10'>Multicamera AI</p>
			</article>
      <video muted loop playsInline className={'rounded-lg w-[82%] md:w-[540px] lg:w-[840px]'} autoPlay>
        <source src={MulticameraWEBM} type="video/webm" />
        <source src={MulticameraMP4} type="video/mp4" />
      </video>
    </section>
  );
};

export default MulticameraFeature;
