import { commonsEmailDomains } from './commonsEmaildomains';
import { emailValidation } from '../schemas/authSchemas';

export const emailCommonsDomainValidation = function (value: any) {
  //@ts-ignore
  if (this.parent.email === '') return;
  const domainsMatched = commonsEmailDomains.filter((item) => value.includes(item.split('.', 1)));
  const emailDomain = value.split('@')[1]?.split('.')[0];
  const emailSubDomain = value.split('@')[1]?.split('.')[1];
  const emailCountry = value.split('@')[1]?.split('.')[2];
  const domains = domainsMatched.filter((item) => emailDomain === item.split('.', 1)[0]);

  let domainToString;
  if (emailSubDomain) {
    domainToString = `${emailDomain}.${emailSubDomain}`;
  }
  if (emailCountry) {
    domainToString = `${emailDomain}.${emailSubDomain}.${emailCountry}`;
  } else {
    domainToString = domains.length && domains[0];
  }
  //@ts-ignore

  if (emailValidation.test(this.parent.email)) {
    if (domains.length === 0) {
      return emailValidation.test(value);
    } else {
      return value.includes(domainToString);
    }
  }
};
