// Icons
import camera from '../../../assets/icon/onboarding/camera.png';
import text from '../../../assets/icon/onboarding/text.png';
import gallery from '../../../assets/icon/onboarding/gallery.png';
import multiple from '../../../assets/icon/onboarding/multiple.png';
import micro from '../../../assets/icon/onboarding/micro.png';

export interface Feature {
  title: string;
  icon: string;
  subtitle: string;
}

// Features list
export const featureList: Feature[] = [
  {
    title: 'Conversational Content',
    icon: micro,
    subtitle: 'Interviews, chatcasts, presentations, and more',
  },
  {
    title: 'Raw or Edited Videos',
    icon: camera,
    subtitle: 'Single take or edited multi-camera videos both work',
  },
  {
    title: 'Multiple Hosts',
    icon: multiple,
    subtitle: 'And if you’re solo, that’s cool',
  },
];

export const futureFeaturesList: Feature[] = [
  // { title: 'Non-Conversational Content', icon: media, subtitle: 'Sports games, movie trailers, landscapes, etc' },
  {
    title: 'Graphic Overlays',
    icon: text,
    subtitle: 'Avoid recordings that contain lower thirds or other graphics',
  },
  {
    title: 'Gallery Style Virtual Meetings',
    icon: gallery,
    subtitle: 'Tiled virtual meeting recordings like Zoom and Google Meet',
  },
];
