import React, { useEffect, useState } from 'react';

// Zustand - Global state
import useAppStore from '../../../../../store/useApp.store';

// Components
import Modal from '../Modal';
import OutsideClickDetector from '../../../../Shared/outsideClickDetector/OutsideClickDetector';

// Icons
import { Transition } from '@headlessui/react';
import SurveyPopUp from './SurveyPopup';
import SurveyConfirmation from './SurveyConfirmation';
import SurveyClosingPopup from './SurveyAffirmation';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import useVerticalizedStore from '../../../../../store/useVerticalized.store';
import useUserStore from '../../../../../store/useUser.store';

const schema = yup.object().shape({
  rating: yup.number().required('Required'),
  using_bigroom_for: yup.array().min(1).required('Required'),
  social_posting: yup.boolean().required('Required'),
  bigroom_frequency: yup.string().required('Required'),
  video_frequency: yup.string().required('Required'),
  video_bigroom_ratio: yup.string().required('Required'),
  willing_to_pay: yup.string().required('Required'),
  willing_to_pay_other: yup.string().when('willing_to_pay', {
    is: 'Other',
    then: yup.string().required('Required'),
  }),
  expirienced_issues: yup.array().min(1).required('Required'),
  social_handle: yup.string(),
  rason_for_rating: yup.string(),
});

const RemoveWatermarkModal = () => {
  const [surveyPopupOpen, setSurveyPopupOpen] = useState(false);
  const [confirmationPopupOpen, setConfirmationPopupOpen] = useState(false);
  const [closePopup, setClosePopup] = useState(false);

  const user = useUserStore((state) => state.user);

  const removeWatermark = useVerticalizedStore((state) => state.removeWatermark);

  const removeWatermarkJobId = useAppStore((state) => state.removeWatermarkJobId);
  const showRemoveWatermarkModal = useAppStore((state) => state.showRemoveWatermarkModal);
  const setShowRemoveWatermarkModal = useAppStore((state) => state.setShowRemoveWatermarkModal);

  useEffect(() => {
    if (!showRemoveWatermarkModal || !user) {
      return;
    }
    if (!user.watermark) {
      removeWatermark(removeWatermarkJobId);
      setSurveyPopupOpen(false);
      setConfirmationPopupOpen(true);
      return;
    }

    setSurveyPopupOpen(true);
    setConfirmationPopupOpen(false);
  }, [showRemoveWatermarkModal]);

  const _cancel = () => {
    setShowRemoveWatermarkModal(false);
    setSurveyPopupOpen(false);
    setConfirmationPopupOpen(false);
    setClosePopup(false);
  };

  const _handlePermissionSubmit = () => {
    setClosePopup(false);
    setSurveyPopupOpen(true);
  };

  const _handleConfirmationSubmit = () => {
    setConfirmationPopupOpen(false);
    setShowRemoveWatermarkModal(false);
  };

  const _handleSurveySubmit = (data) => {
    setSurveyPopupOpen(false);
    setConfirmationPopupOpen(true);

    data.using_bigroom_for = data.using_bigroom_for.toString();
    data.expirienced_issues = data.expirienced_issues.toString();
    if (data.willing_to_pay === 'Other') {
      data.willing_to_pay = data.willing_to_pay_other;
    }

    removeWatermark(removeWatermarkJobId, data);
  };

  const handleCloseSurvey = () => {
    setSurveyPopupOpen(false);
    setClosePopup(true);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <Modal show={showRemoveWatermarkModal}>
      <OutsideClickDetector callback={_cancel}>
        <Transition
          appear={true}
          show={surveyPopupOpen}
          className="fixed left-0 transition-all duration-200 translate-x-[calc(50vw-50%)]"
          enterFrom="-translate-y-full"
          enterTo="translate-y-[calc(50vh-50%)]"
          leaveFrom="translate-y-[calc(50vh-50%)]"
          leaveTo="translate-y-[calc(100vh)]"
        >
          <SurveyPopUp
            close={handleCloseSurvey}
            nextStep={handleSubmit(_handleSurveySubmit)}
            formRegister={register}
            errors={errors}
            willingOther={watch('willing_to_pay') == 'Other'}
          />
        </Transition>
        <Transition
          appear={true}
          show={closePopup}
          className="fixed left-0 transition-all duration-200 translate-x-[calc(50vw-50%)]"
          enterFrom="-translate-y-full"
          enterTo="translate-y-[calc(50vh-50%)]"
          leaveFrom="translate-y-[calc(50vh-50%)]"
          leaveTo="translate-y-[calc(100vh)]"
        >
          <SurveyClosingPopup close={_cancel} nextStep={_handlePermissionSubmit} />
        </Transition>
        <Transition
          appear={true}
          show={confirmationPopupOpen}
          className="fixed left-0 transition-all duration-200 translate-x-[calc(50vw-50%)]"
          enterFrom="-translate-y-full"
          enterTo="translate-y-[calc(50vh-50%)]"
          leaveFrom="translate-y-[calc(50vh-50%)]"
          leaveTo="translate-y-[calc(100vh)]"
        >
          <SurveyConfirmation close={_cancel} nextStep={_handleConfirmationSubmit} />
        </Transition>
      </OutsideClickDetector>
    </Modal>
  );
};

export default RemoveWatermarkModal;
