import React from 'react';
// Libraries
import { useDropzone } from 'react-dropzone';
import { useMultifile } from '../hooks/useMultifile';
import useMulticameraStore from '../../../../../store/useMulticamera.store';
import uploadFileIcon from '../../../../../assets/icon/uploadFileIcon.svg';
import disclaimerIcon from '../../../../../assets/icon/disclaimerIcon.svg';

import MulticameraFile from '../shared/MulticameraFile';
const AudioDropzone = ({signal, cancelUpload}:any) => {
  const {
    // Properties
    hasError,
    canShowButton,
    canBeUploaded,
    hasConnectionError,
    uploadState,
    uploadDone,
    // Methods
    // removeFile,
    audioValidator,
    // Components
    FileErrors,
    // ConnectionError,
    // UploadingSection,
    // UploadedSection,
    AudioFilesAllowedToUpload,
    // cancelUpload,
  } = useMultifile();

  const audioFile = useMulticameraStore((state) => state.audioFile);
  // const currentProject = useMulticameraStore(state => state.currentProject)

  const { getRootProps, getInputProps, isDragAccept } = useDropzone({
    maxFiles: 1,
    accept: 'audio/mp3,audio/m4a,audio/aif,audio/wav',
    validator: audioValidator,
  });

  return (
    <>
      {!audioFile && (
        <>
        <div className='px-4'>
        <div className='flex gap-1 justify-center items-start p-2 mt-8 rounded-lg bg-green-200/70'>
          <img src={disclaimerIcon} className='pt-0.5' alt="" />
          <p className='text-xs tracking-wide'>
          Make sure the file sound has good quality and that includes all the cameras audio of the speaking participants.
          </p>
        </div>
  
        </div>
          <p className="font-medium text-sm px-5 pt-2">Or upload new file</p>
          <section
            {...getRootProps()}
            className={`mt-1 flex flex-col items-center pb-4 border border-gray-400 border-dashed rounded-xl p-5 mx-5 my-2
                ${(uploadDone || canBeUploaded) && 'pb-4 border-none rounded-xl p-0 mx-0 my-0'}
								${isDragAccept && 'mt-0 opacity-40 border border-gray-400 border-dashed rounded-xl p-3'}
              `}
          >
            <input type="file" name="file" {...getInputProps()} disabled={canBeUploaded} />

            <img src={uploadFileIcon} className="w-20 md:w-28" alt="upload icon" />

            <p className="mt-4 text-sm font-semibold text-gray-800 md:text-lg">{uploadState}</p>

            {canShowButton && !hasConnectionError && !uploadDone && (
              <button className="flex items-center justify-center py-3 mt-3 text-xs font-semibold text-gray-800 rounded-xl md:rounded-2xl bg-blue-to-gradient w-72 md:w-40 md:text-sm hover:text-gray-700">
                Choose file
              </button>
            )}

            {hasError && FileErrors}
            <AudioFilesAllowedToUpload />
          </section>
        </>
      )}
      {audioFile && (
        <>
          <MulticameraFile file={audioFile} hasConnectionError isAudio fileName={audioFile.name} signal={signal} cancelUpload={cancelUpload} />
        </>
      )}
    </>
  );
};

export default AudioDropzone;
