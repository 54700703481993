import axios from "./base.service";
import instance from 'axios'
import moment from 'moment'
const resource = 'offline-jobs'

export const getDashboardData = async () => {
  try {
    const { data, status } = await axios.get(`${resource}`);

    if (status === 200) {
      return data;
    }
  } catch (error) {
    console.info(error);
  }
};

export const getFailedStatus = async () => {
  const params = {
    'filters[is_error][$eq]': true,
    'filters[started][$eq]': true,
    sort: 'updatedAt:desc',
  };

  try {
    const { data, status } = await axios.get(`${resource}`, { params });

    if (status === 200) {
      return data;
    }
  } catch (error) {
    console.info(error);
  }
};

export const getInProgressStatus = async () => {
  const params = {
    // 'filters[progress][$lt]': 100,
    'filters[upload_done][$eq]': true,
    'filters[is_error][$eq]': false,
    'filters[bigroom_fcp][$null]': true,
    'filters[bigroom_fcp_portrait][$null]': true,
    // 'filters[complete][$eq]': false,
    sort: 'updatedAt:desc',
  };

  try {
    const { data, status } = await axios.get(resource, { params });
    if (status === 200) {
      return data;
    }
  } catch (error) {
    console.info(error);
  }
};

export const getCompletedStatus = async (page = 1) => {
  const params = {
    'filters[complete][$eq]': true,
    'filters[started][$eq]': true,
    'filters[viewed][$eq]': false,
    'filters[bigroom_fcp][$notNull]': true,
    'filters[bigroom_fcp_portrait][$notNull]': true,
    'filters[is_error][$eq]': false,
    'filters[job_end_time][$gte]': moment().subtract(1, 'days').toISOString(),
    'pagination[page]': page,
    'pagination[pageSize]': 25,
    sort: 'job_end_time:desc',
  };

  try {
    const { data, status } = await axios.get(resource, { params });
    if (status === 200) {
      return data;
    }
  } catch (error) {
    console.info(error);
  }
};

export const getMyVideos = async (page = 1, pageSize = 12) => {
  const params = {
    'filters[complete][$eq]': true,
    'filters[started][$eq]': true,
    'filters[job_end_time][$notNull]': true,
    'filters[bigroom_fcp][$notNull]': true,
    'filters[bigroom_fcp_portrait][$notNull]': true,
    'filters[is_error][$eq]': false,
    'pagination[page]': page,
    'pagination[pageSize]': pageSize,
    sort: 'job_end_time:desc',
  };

  try {
    const { data, status } = await axios.get(resource, { params });

    if (status === 200) {
      return data;
    }
  } catch (error) {
    console.info(error);
  }
};

export const setVideoViewed = async (uid: string) => {
  const request = {
    data: {
      viewed: true,
    },
  };

  try {
    const { data, status } = await axios.put(`${resource}/${uid}`, request);

    if (status === 200) {
      return data;
    }
  } catch (error) {
    console.info(error);
  }
};

export const rateVideo = async (uid: string, rate: number) => {
  const request = {
    data: {
      rating: rate,
    },
  };

  try {
    const { data, status } = await axios.put(`${resource}/${uid}`, request);
    const response = data.data.attributes;

    if (status === 200) {
      return response;
    }
  } catch (error) {
    console.info(error);
  }
};

export const deleteVideo = async (uid: string) => {
  const request = {
    data: {
      delete: true,
    },
  };

  try {
    const { data, status } = await axios.put(`${resource}/${uid}`, request);

    if (status === 200) {
      return data;
    }
  } catch (error) {
    console.info(error);
  }
};

export const getSubtitles = async (url:string) => {

  try {
    const { data, status } = await instance.get(url)
    console.info(data)

    if (status === 200) {
      return data;
    }

  } catch (error) {
    console.info(error)
  }
};

export const setSubtitles = async (uid:string, subtitles:unknown[]) => {
  const request = {
    data: {
      edited_subtitles: subtitles,
    },
  };

  try {
    const { data, status } = await axios.put(`${resource}/${uid}`, request);
    if (status === 200) {
      return data;
    }
  } catch (error) {
    console.info(error);
  }
};

export const removeWatermarkWithSurvey = async (uid:string, data:any) => {
  const body = {
    data: { ...data, job_id: uid },
  };

  try {
    const { data, status } = await axios.post(`watermark-surveys`, body);

    if (status === 200) {
      return data;
    }
  } catch (error) {
    console.info(error);
  }
};

export const removeWatermark = async (uid:string) => {
  const body = {
    data: {
      watermark: false,
      started: false,
      bigroom_fcp_portrait: null,
      bigroom_fcp: null,
      complete: false,
      progress: 0,
    },
  };

  try {
    const { data, status } = await axios.put(`offline-jobs/${uid}`, body);

    if (status === 200) {
      return data;
    }
  } catch (error) {
    console.info(error);
  }
};