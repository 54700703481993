import { useEffect, useCallback } from 'react';

// Libraries
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// Zustand Global State
import useUserStore from '../../../store/useUser.store';
import useAppStore from '../../../store/useApp.store';

// Form Schema
import { forgetPasswordSchema } from '../schemas/authSchemas';

// Mixpanel Event
import { viewForgetPasswordEvent } from '../../../utils/mixpanelEvents';

// UI components
import FormHeader from './FormHeader';
import Input from './Input';
import Button from './Button';

const ForgetPassword = () => {
  const doForgetPassword = useUserStore((state) => state.forgetPassword);
  const isLoading = useAppStore((state) => state.isForgotPasswordLoading);
  const forgotPasswordError = useUserStore((state) => state.forgotPasswordError);

  const viewForgetPassword = useCallback(() => {
    viewForgetPasswordEvent();
  }, []);
  useEffect(() => {
    viewForgetPassword();
  }, [viewForgetPassword]);

  const {
    register,
    formState: { errors, isDirty, isValid },
    handleSubmit,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(forgetPasswordSchema),
  });

  const onSubmit = async (formData: any) => {
    await doForgetPassword(formData);
  };

  return (
    <div className="flex flex-col h-screen px-4 mx-auto align-middle sm:px-0 md:w-2/5">
      <FormHeader
        title="Reset your password"
        subtitle="Enter the email address you used when you joined and we’ll send you instructions to reset your password."
        showGoogle={false}
      />

      <form onSubmit={handleSubmit(onSubmit)} className="grid grid-cols-6 mt-2 gap-y-1">
        <div className="col-span-6">
          <Input label="Email address" type="email" inputName="email" errors={errors} validation={register('email')} />
        </div>

        {forgotPasswordError && <p className="col-span-6 mb-4 text-xs text-red-400">{forgotPasswordError}</p>}

        <div className="col-span-6 -mt-3 sm:flex sm:items-center sm:gap-2">
          <Button disabled={!isDirty || !isValid || isLoading} type="submit" isLoading={isLoading} text="Send" />
        </div>
      </form>
    </div>
  );
};

export default ForgetPassword;
