// Router
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

//Routes
//Auth - Routes
import LogIn from './views/Auth/LogIn';
import SignUp from './views/Auth/SignUp';
import ResetPassword from './views/Auth/ResetPassword';
import ForgetPassword from './views/Auth/ForgetPassword';
import EmailVerification from './views/Auth/EmailVerification';
import GoogleAuthentication from './views/Auth/components/GoogleAuth';

// 404
import NotFound from './views/404/404';

// DashBoard Experience
import { default as Landing } from './views/Landing/Landing';
import Dashboard from './views/Dashboard';
import Caption from './views/Caption';
import Project from './views/Dashboard/components/Multicamera/views/Project';

// UI Components
import ScrollToTop from './components/scrollToTop/ScrollToTop';
import MainLayout from './components/Layout/MainLayout';

//Private HOC
import ProtectedRoute from './components/protectedRoute';

// External Libraries
import { createTheme, ThemeProvider } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';
import Maintenance from './views/Landing/Maintenance';

const theme = createTheme({
  typography: {
    fontFamily: ['"Poppins"', 'sans-serif'].join(','),
  },
  breakpoints: {
    values: {
      //@ts-ignore
      mobile: 0,
      desktop: 768,
    },
  },
});

function App() {
  //get queryParams
  const queryParams = new URLSearchParams(window.location.search);
  const debugging = queryParams.get('debugging');
  const inMaintenance = process.env.REACT_APP_MAINTENANCE_MODE === 'true';

  return (
    <ThemeProvider theme={theme}>
      <>
        <ToastContainer
          position="top-center"
          hideProgressBar={true}
          autoClose={1000}
          closeOnClick={true}
          className="toast-message"
          newestOnTop={true}
          closeButton={false}
        />
        <Router>
          <ScrollToTop>
            {/* DASHBOARD EXPERIENCE FLOW */}
            <Routes>
              {/* Public Routes */}
              {!inMaintenance || debugging ? (
                <>
                  <Route
                    path="/"
                    element={
                      <MainLayout>
                        <Landing />
                      </MainLayout>
                    }
                  />

                  {/* Auth routes */}
                  <Route path="/login" element={<LogIn />} />
                  <Route path="/signup" element={<SignUp />} />
                  <Route path="/forget-password" element={<ForgetPassword />} />
                  <Route path="/reset_password" element={<ResetPassword />} />
                  <Route path="/email-confirmation" element={<EmailVerification />} />
                  <Route path="/google-auth" element={<GoogleAuthentication />} />

                  {/* Dashboard experience */}
                  <Route
                    path="/dashboard"
                    element={
                      <ProtectedRoute>
                        <MainLayout>
                          <Dashboard />
                        </MainLayout>
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="/caption-editor"
                    element={
                      <ProtectedRoute>
                        <MainLayout>
                          <Caption />
                        </MainLayout>
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/project/:projectUid"
                    element={
                      <ProtectedRoute>
                        <MainLayout>
                          <Project />
                        </MainLayout>
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/caption-editor"
                    element={
                      <ProtectedRoute>
                        <MainLayout>
                          <Caption />
                        </MainLayout>
                      </ProtectedRoute>
                    }
                  />

                  {/* 404 page */}
                  <Route path="*" element={<NotFound />} />
                </>
              ) : (
                <Route path="/*" element={<Maintenance />} />
              )}
            </Routes>
          </ScrollToTop>
        </Router>
      </>
    </ThemeProvider>
  );
}

export default App;
