import React, { useEffect } from 'react';

// UI Components
import Header from '../../views/Dashboard/components/Verticalized/Header';
import Footer from '../../views/Landing/components/Footer';
import UploadModal from '../../views/Dashboard/components/Verticalized/Upload';
import Download from '../../views/Dashboard/components/Verticalized/Download';
import ConfirmDeleteModal from '../../views/Dashboard/components/shared/ConfirmDelete';
import GeneratingCaptionModal from '../../views/Dashboard/components/shared/GeneratingCaptions';
import ConfirmCancelCaptionModal from '../../views/Dashboard/components/shared/ConfirmCancelCaption';
import FeaturesMobile from '../../views/Dashboard/components/shared/FeaturesMobile';
import RemoveWatermarkModal from '../../views/Dashboard/components/shared/RemoveWatermark/RemoveWatermark';
import SideMenu from '../SideMenu';
import DashboardNav from '../../views/Dashboard/components/Navigation/Nav';

import useUserStore from '../../store/useUser.store';
import CreateProjectModal from '../../views/Dashboard/components/Multicamera/shared/CreateProjectModal';
import UploadMulticameraModal from '../../views/Dashboard/components/Multicamera/shared/UploadMulticameraModal';
import ConfirmGenerateCaptionModal from '../../views/Caption/components/ConfirmGenerateCaptions';
import { SS_GetProductPaymentDetails } from '../../services/stripe.service';
import PaymentReceivedModal from '../../views/Dashboard/components/shared/PaymentReceivedModal';
import UploadDynamicZoomModal from '../../views/Dashboard/components/DynamicZoom/Upload';
import DownloadDynamic from '../../views/Dashboard/components/Verticalized/DownloadDynamic';
import ChooseAnotherMasterAudioModal from '../../views/Dashboard/components/Multicamera/shared/ChooseAnotherMasterAudioModal'
import ConfirmMixVideoModal from '../../views/Dashboard/components/Multicamera/shared/ConfirmMixVideoModal';
import UploadAudioModal from '../../views/Dashboard/components/Multicamera/shared/UploadAudioModal';

const MainLayout = ({ children }: { children: React.ReactNode }) => {
  const userFromStore = useUserStore((state) => state.user);
  const stripePlanId = useUserStore((state) => state.stripePlanId);

  const user = JSON.parse(localStorage.getItem('user') as any) ?? userFromStore;

  const queryParams = new URLSearchParams(window.location.search);
  const sessionId = queryParams.get('sessionId');

  const setManualPaid = useUserStore((state) => state.setManualPaid);

  const getAndSetUserSubscription = useUserStore((state) => state.getAndSetUserSubscription);

  useEffect(() => {
    if (user.id && sessionId) {
      SS_GetProductPaymentDetails(sessionId,stripePlanId).then((res) => {
        setManualPaid(true);
      });
    } else {
      getAndSetUserSubscription();
    }
  }, [user.id, sessionId]);

  return (
    <div id="tailwind-base">
      {user.id && (
        <>
          {/* Mobile */}
          <FeaturesMobile />
          <SideMenu />

          <Download />
          <DownloadDynamic />
          <ConfirmDeleteModal />
          <PaymentReceivedModal />
          <UploadModal />
          <UploadDynamicZoomModal />
          <RemoveWatermarkModal />
          <ConfirmCancelCaptionModal />
          <GeneratingCaptionModal />
          <CreateProjectModal />
          <UploadMulticameraModal />
          <ConfirmGenerateCaptionModal />
          <ChooseAnotherMasterAudioModal />
          <UploadAudioModal />
          <ConfirmMixVideoModal />
          <Header />
          <DashboardNav />
        </>
      )}
      <section className="relative">
        <section>{children}</section>
        <Footer />
      </section>
    </div>
  );
};

export default MainLayout;
