import React, { useEffect } from 'react';

import useMulticameraStore from '../../../../store/useMulticamera.store';
import InitProyects from './components/InitProjects';
import Projects from './components/Projects';

const Multicamera = () => {
  const hasProjects = useMulticameraStore((state) => state.hasProjects);
  const getProjects = useMulticameraStore((state) => state.getProjects);
  const hasProjectInProgress = useMulticameraStore((state) => state.hasProjectInProgress);

  useEffect(() => {
    getProjects();
  }, [getProjects]);

  useEffect(() =>{
    if(hasProjectInProgress) {
      const interval = setInterval(() => {
        getProjects();
      }, 45000);
      return () => clearInterval(interval);
    }
  },[getProjects, hasProjectInProgress])


  return (
    <main className="h-[calc(100vh-10%)]">
      <section className="text-center font-semibold text-lg">
				{hasProjects ? <Projects /> : <InitProyects />}
			</section>
    </main>
  );
};

export default Multicamera;
