import React from 'react';

// Zustand - Global state
import useAppStore from '../../../../store/useApp.store';
import useUserStore from '../../../../store/useUser.store';

// Mixpanel Events
import { clickDeleteCompletedVideoEvent } from '../../../../utils/mixpanelEvents';

// Components
import Modal from './Modal';
import OutsideClickDetector from '../../../Shared/outsideClickDetector/OutsideClickDetector';

// Icons
import { TfiClose } from 'react-icons/tfi';
import { Transition } from '@headlessui/react';

const ConfirmDeleteModal = () => {
  const currentFunctionality:any = useAppStore((state) => state.currentFunctionality);

  const user = useUserStore((state) => state.user);
  const setDeleteModal = useAppStore((state) => state.setDeleteModal);
  const setDownloadModal = useAppStore((state) => state.setDownloadModal);
  const setDownloadDynamicModal = useAppStore((state) => state.setDownloadDynamicModal);
  const setFileDeleted = currentFunctionality((state:any) => state.setFileDeleted);
  const isDeleteModalActive = useAppStore((state) => state.deleteModal);
  const currentFile = currentFunctionality((state:any) => state.currentFile);
  const setCancelTrimming = currentFunctionality((state:any) => state.setCancelTrimming);

  const isDelete = !!currentFile?.bigroom_fcp;

  const _handleDeleteFile = () => {
    if (isDelete) {
      clickDeleteCompletedVideoEvent(user.id);
      setFileDeleted(currentFile.uid);
    } else {
      setCancelTrimming();
    }
    setDeleteModal(false);
    setDownloadModal(false);
    setDownloadDynamicModal(false);
  };

  const _handleDeleteModal = () => {
    setDeleteModal(false);
  };

  const CancelCopy = () => (
    <h3 className="mb-2">Are you sure you want to delete this video? It won't get converted to a vertical video.</h3>
  );
  const DeleteCopy = () => <h3 className="mb-2">Are you sure you want to remove the video</h3>;

  return (
    <Modal show={isDeleteModalActive}>
      <Transition
        appear={true}
        show={isDeleteModalActive}
        className="fixed left-0 transition-all duration-200 translate-x-[calc(50vw-50%)]"
        enterFrom="translate-y-[100vh]"
        enterTo="translate-y-[calc(50vh-50%)]"
        leaveFrom="translate-y-[calc(50vh-50%)]"
        leaveTo="translate-y-[100vh]"
      >
        <OutsideClickDetector callback={() => _handleDeleteModal()}>
          <main className={`relative max-w-96 w-80 ${isDelete ? 'md:w-[520px]' : 'md:w-[440px]'}`}>
            <div className="p-6 overflow-hidden bg-gray-100 border shadow-lg rounded-xl md:p-10 border-gray-50">
              <button onClick={_handleDeleteModal} className={`absolute top-2 right-2 p-1 cursor-pointer`}>
                <TfiClose className="w-6" />
              </button>
              <section className="pt-2 text-center">
                {isDelete ? <DeleteCopy /> : <CancelCopy />}
                <p className="font-semibold text-bg-sm">{currentFile?.name}?</p>
                <div className="flex items-center justify-between gap-6 pt-6">
                  <button
                    className={`${
                      isDelete ? 'bg-blue-submit' : 'bg-black'
                    } px-6 py-3 text-sm font-semibold text-white rounded-xl w-44`}
                    onClick={_handleDeleteModal}
                  >
                    {isDelete ? 'Cancel' : 'Nevermind'}
                  </button>
                  <button
                    className={`${
                      isDelete ? 'border-blue-submit text-blue-submit' : 'border-black text-black'
                    } px-6 py-3 text-sm font-semibold border rounded-xl w-44`}
                    onClick={_handleDeleteFile}
                  >
                    Yes, delete
                  </button>
                </div>
              </section>
            </div>
          </main>
        </OutsideClickDetector>
      </Transition>
    </Modal>
  );
};

export default ConfirmDeleteModal;
