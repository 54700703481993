import React,{useMemo, useState} from 'react';

import useAppStore from '../../../../../store/useApp.store';
import useMulticameraStore from '../../../../../store/useMulticamera.store';

import Modal from '../../shared/Modal';
import { TfiClose } from 'react-icons/tfi';
import { CheckIcon } from '@heroicons/react/20/solid'
import audioIcon from '../../../../../assets/icon/audioIcon.svg';
import OutsideClickDetector from '../../../../Shared/outsideClickDetector/OutsideClickDetector';
import { Transition } from '@headlessui/react';

const VideoOptions = ({ file, setSelected }: any) => {
	const ThumbnailMulticamera = useMemo(() => {
    if (!file) return null;
    return (
      <div className='relative w-12 h-12 overflow-hidden rounded-xl flex items-center'>
        {file?.thumbnail_url 
				? <img src={file?.thumbnail_url} alt='thumbnail' className='absolute w-24 max-w-none' /> 
				: <img src={audioIcon} alt='thumbnail' className='w-24 max-w-none' />}
      </div>
    );
  },[file])
	
	return(
  <span
	onClick={() => setSelected(file)}
	className={`truncate font-normal flex items-center gap-1 cursor-pointer`}>
    <div className="relative w-14 h-14 overflow-hidden rounded-xl flex items-center m-auto">
      {ThumbnailMulticamera}
    </div>
		<span className='truncate text-sm w-80 font-medium'>
    {file.name}
		</span>
  </span>
)};

const ChooseAnotherMasterAudioModal = () => {
  const setChooseAnotherMasterAudioModal = useAppStore((state) => state.setChooseAnotherMasterAudioModal);
  const chooseAnotherMasterAudioModal = useAppStore((state) => state.chooseAnotherMasterAudioModal);
  const currentProject = useMulticameraStore((state) => state.currentProject);
  const setMasterAudioFile = useMulticameraStore((state) => state.setMasterAudioFile);
  const setMulticameraUploadStep = useMulticameraStore((state) => state.setMulticameraUploadStep);
  const setUploadMulticameraModal = useAppStore((state) => state.setUploadMulticameraModal);

	const [selected, setSelected] = useState<any>()

  const _handleChooseAnotherMasterAudioModal = () => {
    setChooseAnotherMasterAudioModal(false);
  };

  return (
    <Modal show={chooseAnotherMasterAudioModal}>
      <Transition
        appear={true}
        show={chooseAnotherMasterAudioModal}
        className="fixed left-0 transition-all duration-200 translate-x-[calc(50vw-50%)] translate-y-[calc(50vh-50%)]"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <OutsideClickDetector callback={() => _handleChooseAnotherMasterAudioModal()}>
          <main className="relative max-w-96 w-80 md:w-[420px]">
            <div className="p-6 overflow-hidden bg-gray-100 border shadow-lg rounded-xl md:p-3 border-gray-50">
              <button onClick={_handleChooseAnotherMasterAudioModal} className={`absolute top-2 right-2 p-1 cursor-pointer`}>
                <TfiClose className="text-gray-700" size={20} />
              </button>
              <section className="pt-6">
                {
									currentProject?.files?.map((file:any, fileIdx:number) => (
										<div
												key={fileIdx}
												className={`${selected?.id === file.id && 'bg-blue-to-gradient'} relative cursor-default select-none py-1 pl-4 pr-4 hover:bg-blue-to-gradient/50 rounded-xl text-gray-900 flex items-center justify-between`} >
													<VideoOptions file={file} setSelected={setSelected}  />

													{selected?.id === file.id  ? (
                        <span className="absolute right-3 flex items-center pl-3 text-green-800">
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
											</div>
									))								
								}

                <div className="flex flex-col gap-3 items-center pt-3">
								<button
                    className="px-6 py-2 text-bg-sm font-medium bg-gray-50 text-black border border-black rounded-lg w-full disabled:text-gray-400 disabled:border-gray-500"
                    disabled={false}
                    onClick={() => { 
											setUploadMulticameraModal(true)
											setMulticameraUploadStep(2)
											setChooseAnotherMasterAudioModal(false)
										}}
                  >
                    Update new audio
                  </button>
                  <button
                    className="px-6 py-2 text-bg-sm font-medium bg-black text-white rounded-lg w-full disabled:bg-gray-600"
                    disabled={false}
                    onClick={() => {
											setMasterAudioFile(currentProject.uid, selected.uid)
											setChooseAnotherMasterAudioModal(false)
										}}
                  >
                    Update master audio
                  </button>
                </div>
              </section>
            </div>
          </main>
        </OutsideClickDetector>
      </Transition>
    </Modal>
  );
};

export default ChooseAnotherMasterAudioModal;
