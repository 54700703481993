import { setToken } from '../utils/setToken';

// Mixpanel Events
import {
  signupErrorEvent,
  loginErrorEvent,
  loginSuccessEvent,
  resetPasswordSuccessEvent,
  signupInitiatedEvent,
} from '../utils/mixpanelEvents';

import { ErrorValidationMessages } from '../views/Auth/utils/ErrorValidationMessages';

import useUserStore from '../store/useUser.store';
import instance from 'axios';
const baseURL = process.env.REACT_APP_API;

const resource = 'auth/local';

export const logIn = async (logInData: any) => {
  try {
    const { data, status } = await instance.post(`${baseURL}${resource}`, logInData);
    if (status === 200) {
      setToken(data.jwt);
      loginSuccessEvent(data.user.id);
      return { user: data.user, token: data.jwt };
    }
  } catch ({ response }) {
    loginErrorEvent();
    //@ts-ignore
    const { data, status } = response;
    if (status === 500) {
      if (status === 400) {
        const errorMessage = data.error.message;
        useUserStore.setState({ logInError: ErrorValidationMessages(errorMessage) });
      }
    }
  }
  return {
    user: null,
    token: null,
  };
};

export const signUp = async (signupData: any) => {
  try {
    const { data, status } = await instance.post(`${baseURL}${resource}/register`, signupData);
    if (status === 200) {
      signupInitiatedEvent(useUserStore.getState().trackingId);
      return data.user;
    }
  } catch ({ response }) {
    signupErrorEvent();
    //@ts-ignore
    const { data, status } = response;
    const errorMessage = data.error.message;
    if (status === 500) {
      useUserStore.setState({ signUpError: ErrorValidationMessages(errorMessage, 'signup') });
    }
    if (status === 400) {
      useUserStore.setState({ signUpError: ErrorValidationMessages(errorMessage, 'signup') });
    }
  }
};

const auth = 'auth';

export const forgotPassword = async (userEmail: string) => {
  try {
    const { data, status } = await instance.post(`${baseURL}${auth}/forgot-password`, userEmail);
    if (status === 200) {
      return data;
    }
  } catch ({ response }) {
    //@ts-ignore
    const { data, status } = response;
    const errorMessage = data?.error?.message;
    if (status === 400) {
      useUserStore.setState({ forgotPasswordError: ErrorValidationMessages(errorMessage) });
    }
  }
};

export const resetPassword = async (resetData: any) => {
  try {
    const { data, status } = await instance.post(`${baseURL}${auth}/reset-password`, resetData);
    if (status === 200) {
      resetPasswordSuccessEvent();
      return data;
    }
  } catch ({ response }) {
    //@ts-ignore
    const { data, status } = response;
    const errorMessage = data?.error?.message;

    if (status === 400) {
      useUserStore.setState({ resetPasswordError: ErrorValidationMessages(errorMessage) });
    }
  }
};

export const emailVerification = async (code: string) => {
  const params = {
    confirmation: code,
  };

  try {
    const { data, status } = await instance.get(`${baseURL}${auth}/email-confirmation`, { params });
    if (status === 200) {
      return data;
    }
  } catch ({ response }) {
    //@ts-ignore
    const { data, status } = response;
    const errorMessage = data?.error?.message;
    if (status === 400) {
      useUserStore.setState({ forgotPasswordError: ErrorValidationMessages(errorMessage) });
    }
  }
};

const me = 'users/me';

export const getUser = async (token: string) => {
  setToken(token);
  const config = {
    headers: {
      Authorization: `Beaerer ${token}`,
    },
  };
  try {
    const { data, status } = await instance.get(`${baseURL}${me}`, config);
    console.info(data);
    if (status === 200) {
      return data;
    }
  } catch ({ response }) {
    //@ts-ignore
    const { data, status } = response;
    const errorMessage = data?.error?.message;
    if (status === 400) {
      useUserStore.setState({ forgotPasswordError: ErrorValidationMessages(errorMessage) });
    }
  }
};

const authCallback = 'auth/google/callback';

export const getUserByGoogleAuth = async (authParams: string) => {
  try {
    const { data, status } = await instance.get(`${baseURL}${authCallback}${authParams}`);
    if (status === 200) {
      setToken(data.jwt);
      return data;
    }
  } catch ({ response }) {
    //@ts-ignore
    const { data, status } = response;
    const errorMessage = data?.error?.message;
    if (status === 400) {
      useUserStore.setState({ forgotPasswordError: ErrorValidationMessages(errorMessage) });
    }
  }
};

export const getUserSubscription = async (token: string) => {
  return instance
    .get(`${baseURL}subscription`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data);
};
