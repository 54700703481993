import React, { useEffect, useRef, useState } from 'react';

import 'swiper/scss';
import 'swiper/scss/mousewheel';
import 'swiper/scss/effect-fade';
import './Carrousel.scss';

import PropTypes from 'prop-types';

//import IntelligentDetectionVideo from '../../../assets/Features/IntelligentDetection.mp4';
//import IntelligentDetectionVideoWebm from '../../../assets/Features/IntelligentDetection.webm';

import AutoTrackingVideo from '../../../assets/Features/Autotracking.mp4';
import AutoTrackingVideoWebm from '../../../assets/Features/Autotracking.webm';

import SmartCutsVideo from '../../../assets/Features/SmartCuts.mp4';
import SmartCutsVideoWebm from '../../../assets/Features/SmartCuts.webm';

import VideoCustomizationVideo from '../../../assets/Features/ThirdParty.mp4';
import VideoCustomizationVideoWebm from '../../../assets/Features/ThirdParty.webm';

const Carousel = ({ children }) => {
  const texts = [
    // {
    //   title: 'Intelligent Subject Detection',
    //   paragraph:
    //     'By analyzing a complex array of visual and audio signals, our proprietary AI identifies the most significant person or object in every frame of video',
    //   video: IntelligentDetectionVideo,
    //   videoWebm: IntelligentDetectionVideoWebm,
    //   textRef: useRef(null),
    //   videoRef: useRef(null),
    // },
    {
      title: 'Smart Cuts and Auto-Editing',
      paragraph:
        'Our in-house AI can detect the most important people or objects in every frame of video and cut to them, so the vertical video we auto-edit for you will look like a camera crew was by your side the entire time.',
      video: SmartCutsVideo,
      videoWebm: SmartCutsVideoWebm,
      textRef: useRef(null),
      videoRef: useRef(null),
    },
    {
      title: 'Tracking and Framing',
      paragraph:
        'We track subjects across frames and automatically reframe your vertical clip so the right thing is always in focus.',
      video: AutoTrackingVideo,
      videoWebm: AutoTrackingVideoWebm,
      textRef: useRef(null),
      videoRef: useRef(null),
    },
    {
      title: 'Export to your Favorite Video Editor',
      paragraph:
        'Sometimes you need to make a final adjustment to your video before posting to social. Download our sequence files for Adobe Premiere Pro, Final Cut Pro, and DaVinci Resolve to put the finishing touches on your clips.',
      video: VideoCustomizationVideo,
      videoWebm: VideoCustomizationVideoWebm,
      textRef: useRef(null),
      videoRef: useRef(null),
    },
  ];

  const [activeVideo, setActiveVideo] = useState(texts[0]);

  useEffect(() => {
    texts[0].videoRef.current.play();
    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = (e) => {
    let newActiveElement = texts[0];
    texts.forEach((element) => {
      if (
        element != activeVideo &&
        e.target.scrollingElement.scrollTop + window.innerHeight / 2 >= element.textRef.current.offsetTop
      ) {
        newActiveElement = element;
      }
    });
    newActiveElement.videoRef.current.play();
    setActiveVideo(newActiveElement);
  };

  return (
    <>
      <div className="flex justify-between w-full md:h-[400vh]">
        <div className="hidden w-5/12 h-full md:block">
          {texts.map((content) => (
            <div
              ref={content.textRef}
              key={content.title}
              className={'h-1/4 md:min-h-[38vh] flex justify-center px-4 md:px-2 w-fit max-w-[75%] flex-col mx-auto'}
            >
              <div className={'flex sm:items-center md:items-stretch mb-2 md:mb-0 items-center'}>
                <p className={'text-black text-3xl lg:text-4xl font-bold text-center md:text-left m-auto md:m-0'}>
                  {content.title}
                </p>
              </div>
              <div className={'flex md:text-left text-center mb-4 md:mb-0'}>
                <p className={'leading-7 mt-4 lg:mt-8'}>{content.paragraph}</p>
              </div>
              <div className="flex-row items-center hidden gap-4 md:flex swiper-pagination md:fixed md:bottom-0 lg:bottom-8 xl:bottom-14"></div>
            </div>
          ))}
        </div>
        <div
          className={
            'h-1/4 md:min-h-[38vh] md:sticky right-0 top-0 transition-colors duration-500 flex flex-col md:block items-center w-full mt-16 md:mt-0 md:w-7/12'
          }
        >
          {texts.map((content) => {
            return (
              <div
                key={content.title}
                className={`md:flex md:w-full md:absolute md:flex-row md:items-center md:h-full transition-opacity duration-500 ease-in-out mb-14 md:mb-0 ${content.title == activeVideo.title ? 'opacity-1' : 'md:opacity-0'
                  }`}
              >
                <div className={'w-full flex md:hidden justify-center px-4 md:px-2 max-w-[75%] flex-col mx-auto'}>
                  <div className={'flex sm:items-center md:items-stretch mb-2 md:mb-0 items-center'}>
                    <p className={'text-black text-3xl lg:text-4xl font-bold text-center md:text-left m-auto md:m-0'}>
                      {content.title}
                    </p>
                  </div>
                  <div className={'flex md:text-left text-center mb-4 md:mb-0'}>
                    <p className={'leading-7 mt-4 lg:mt-8'}>{content.paragraph}</p>
                  </div>
                  <div className="flex-row items-center hidden gap-4 md:flex swiper-pagination md:fixed md:bottom-0 lg:bottom-8 xl:bottom-14"></div>
                </div>
                <video
                  loop
                  muted
                  playsInline
                  autoPlay
                  ref={content.videoRef}
                  alt={'carousel-1'}
                  className={`rounded-none mb-4 md:mb-0 md:rounded-l-2xl aspect-video object-cover w-full max-h-[100%]`}
                >
                  <source src={content.videoWebm} type="video/webm" />
                  <source src={content.video} type="video/mp4" />
                </video>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

Carousel.propTypes = {
  children: PropTypes.node,
};

export default Carousel;
