import React from 'react';
import Marquee from 'react-fast-marquee';
import PropTypes from 'prop-types';
import './banner.scss';
const Banner = ({ children }: { children: React.ReactNode }) => {
  return (
    <div
      className={
        'bg-gradient-to-r from-green-to-gradient to-blue-to-gradient hover:from-blue-to-gradient hover:to-green-to-gradient'
      }
    >
      <Marquee gradient={false}>
        <div className="flex items-center p-[10px] h-28 w-full">{children}</div>
      </Marquee>
    </div>
  );
};

Banner.propTypes = {
  children: PropTypes.node.isRequired,
};
export default Banner;
