import React, { useState, useMemo, useEffect, useCallback } from 'react';

import { toTimeString } from '../../utils/toTimeString';
import useVerticalizedStore from '../../../../store/useVerticalized.store';

// import Thumbnail from "./Thumbnail";
// import TooltipSlider from "./TooltipSlider";

import InputMask from 'react-input-mask';

import Slider from 'rc-slider';

import 'rc-slider/assets/index.css';

// Libraries
// import { Oval } from "react-loader-spinner";
import moment from 'moment/moment';
import useDynamicZoomStore from '../../../../store/useDynamicZoom.store';
import useUserStore from '../../../../store/useUser.store';

const StepTwo = ({
  functionalityStore,
}: {
  functionalityStore: typeof useVerticalizedStore | typeof useDynamicZoomStore;
}) => {
  const trimmingDurationLimit = process.env.REACT_APP_TRIMMING_DURATION_LIMIT
    ? Number(process.env.REACT_APP_TRIMMING_DURATION_LIMIT)
    : 1800; // Eq to 30min of duration
  const secondsBetweenStartEnd = 2; // Eq to 2sec of distance between start and end
  const setVideoTrimming = functionalityStore((state) => state.setVideoTrimming);
  const videoTrimmingDuration = functionalityStore((state) => state.videoTrimmingDuration);
  const submitResponse = functionalityStore((state) => state.submitResponse);
  const fileNames = functionalityStore((state) => state.fileNames);
  const setVideoTrimmingDurationInvalid = functionalityStore((state) => state.setVideoTrimmingDurationInvalid);
  const [duration, setDuration] = useState<number[]>([0,0]);
  const [isDurationInvalid, setIsDurationInvalid] = useState(false);
  const [invalidMessage, setInvalidMessage] = useState("");

  const [lowerDuration, setLowerDuration] = useState<string>(toTimeString(0, true, false));
  const [upperDuration, setUpperDuration] = useState<string>(toTimeString(videoTrimmingDuration, true, false));
  const maxAllowedMinutes = useUserStore((state) => state.maxAllowedMinutes);
  const numberOfMinutesLeft = useUserStore((state) => state.numberOfMinutesLeft);
  const paid = useUserStore((state) => state.paid);

  const validateTrim = () => {
    console.log('validateTrim')
    console.log('validateTrim numberOfMinutesLeft',numberOfMinutesLeft)
    console.log('validateTrim paid',paid)
    console.log('validateTrim duration',duration)
    console.log('validateTrim videoTrimmingDuration',videoTrimmingDuration)
    console.log('validateTrim trimmingDurationLimit',trimmingDurationLimit)
    
    setVideoTrimmingDurationInvalid(false);
    setIsDurationInvalid(false);
    setLowerDuration(toTimeString(duration[0], false, true));
    setUpperDuration(toTimeString(duration[1], false, true));
    setVideoTrimming([duration[0], duration[1]]);
    console.log('validateTrim lowerDuration',lowerDuration)
    console.log('validateTrim upperDuration',upperDuration)

    if(!paid) {
      let not_ok_to_trim = ((duration[1] - duration[0]) > (numberOfMinutesLeft*60))
      if(not_ok_to_trim) {
        setIsDurationInvalid(true);
        setVideoTrimmingDurationInvalid(true);
        setInvalidMessage('This trim is over your monthly limit.')
        return
      }
      else if (duration[1] < duration[0]) {
        setInvalidMessage('Duration not valid. (103)')
        setIsDurationInvalid(true);
        setVideoTrimmingDurationInvalid(true);
        return
      }

    } else {

      if (duration[1] - duration[0] > (numberOfMinutesLeft*60)) {
        setInvalidMessage('This trim is over your monthly limit.')
        setIsDurationInvalid(true);
        setVideoTrimmingDurationInvalid(true);
      }
      else if (duration[1] - duration[0] > trimmingDurationLimit + 1) {
        setInvalidMessage('Trim length must be ' + trimmingDurationLimit + ' minutes or less.')
        setIsDurationInvalid(true);
        setVideoTrimmingDurationInvalid(true);
      }

      else if (duration[1] < duration[0]) {
        setInvalidMessage('Duration not valid. (101)')
        setIsDurationInvalid(true);
        setVideoTrimmingDurationInvalid(true);
      }
      else if (
        duration[0] > videoTrimmingDuration ||
        duration[0] > duration[1] ||
        duration[0] > duration[1]
      ) {
        setInvalidMessage('Duration not valid. (102)')
        setIsDurationInvalid(true);
        setVideoTrimmingDurationInvalid(true);
      }
      //setLowerDuration(toTimeString(duration[0], false, true));
      //setUpperDuration(toTimeString(duration[1], false, true));
      //setDuration(duration);
      //setVideoTrimming(duration);
    }



  }
  const onSliderChange = (duration: number[]) => {
    console.log('onSliderChange',paid,maxAllowedMinutes)
    console.log('onSliderChange duration',duration)
    setDuration(duration);
    validateTrim()
  };

  const setTotalDuration = useCallback(
    () => {
      const durationLimit =
        videoTrimmingDuration > trimmingDurationLimit ? trimmingDurationLimit : videoTrimmingDuration;
        console.log('setTotalDuration duration',duration)
        console.log('setTotalDuration videoTrimmingDuration',videoTrimmingDuration)
        console.log('setTotalDuration trimmingDurationLimit',trimmingDurationLimit)

        duration[0] = 0
        duration[1] = videoTrimmingDuration
        setDuration(duration)
        validateTrim()
      
      
      //  setIsDurationInvalid(false);
      //setVideoTrimmingDurationInvalid(false);
      
      //setVideoTrimming([0, durationLimit]);
      //setDuration([0, durationLimit]);
      //setUpperDuration(toTimeString(durationLimit, false, true));
      //if (durationLimit < secondsBetweenStartEnd) {
      //  setIsDurationInvalid(true);
      //  setVideoTrimmingDurationInvalid(true);
     // }
      
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setVideoTrimming, videoTrimmingDuration]
  );
  useEffect(() => {
    setTotalDuration();
  }, [setTotalDuration]);

  const remainingTime = useMemo(() => toTimeString(duration[1] - duration[0], false, true), [duration]);

  return (
    <section className="h-full p-3 mt-6 md:py-6 md:px-6">
      <div className="flex flex-col justify-between md:flex-row">
        <p className="text-lg font-medium">2. Trim your video</p>
        <p className="mt-1 mb-4 font-medium text-bg-sm md:hidden md:mt-2 w-80">
          <span className="truncate">
            {fileNames[0] ? fileNames[0] : submitResponse?.youtube_info?.name ?? submitResponse?.name}
          </span>
        </p>

        <div className="flex flex-col items-end justify-end gap-1 md:items-center">
          <div className="flex items-center gap-3">
            <InputMask
              type="text"
              maskChar="0"
              alwaysShowMask={true}
              mask="99:99:99"
              value={lowerDuration}
              //placeholder="00:00:00"
              onChange={({ target: { value } }) => {
                console.log('InputMask onChange',value)
                const lowerDuration = moment.duration(`${value}`).asSeconds();
                //setLowerDuration(value);
                //setIsDurationInvalid(false);
                //setVideoTrimmingDurationInvalid(false);
                //setVideoTrimming([lowerDuration, duration[1]]);
                duration[0] = lowerDuration
                //setDuration([lowerDuration, duration[1]]);
                validateTrim()
                /*  
                if (duration[1] - lowerDuration > trimmingDurationLimit + 1) {
                  setIsDurationInvalid(true);
                  setVideoTrimmingDurationInvalid(true);
                  setVideoTrimming([lowerDuration, duration[1]]);
                  setDuration([lowerDuration, duration[1]]);
                  return;
                }
                if (
                  lowerDuration > videoTrimmingDuration ||
                  lowerDuration > duration[1] ||
                  lowerDuration > duration[1] - secondsBetweenStartEnd ||
                  value.includes('_')
                ) {
                  setIsDurationInvalid(true);
                  setVideoTrimmingDurationInvalid(true);
                  return;
                }
                */
              }}
              className="text-center border-gray-800 rounded-lg h-9 border-1 w-28 focus:ring-0"
              id=""
            />
            <p>to</p>
            <InputMask
              type="text"
              mask="99:99:99"
              maskChar="0"
              alwaysShowMask={true}
              placeholder="00:00:00"
              onChange={({ target: { value } }) => {
                //setIsDurationInvalid(false);
                //setUpperDuration(value);
                //setVideoTrimmingDurationInvalid(false);
                const upperDuration = moment.duration(`${value}`).asSeconds();
                //setDuration([duration[0], upperDuration]);
                duration[1] = upperDuration
                validateTrim()
                //console.log('paid',paid)
                /*
                if (paid) {
                  if (upperDuration - duration[0] > trimmingDurationLimit + 1) {
                    setInvalidMessage('Trim length must be 30 minutes or less.')
                    setIsDurationInvalid(true);
                    setVideoTrimmingDurationInvalid(true);
                    setVideoTrimming([duration[0], upperDuration]);
                    setDuration([duration[0], upperDuration]);
                    return;
                  }
                  if (
                    upperDuration > videoTrimmingDuration ||
                    upperDuration < duration[0] + secondsBetweenStartEnd ||
                    value.includes('_')
                  ) {
                    setIsDurationInvalid(true);
                    setVideoTrimmingDurationInvalid(true);
                    setInvalidMessage('Duration not valid.')
                    return;
                  }
                  setVideoTrimming([duration[0], upperDuration]);
                  setDuration([duration[0], upperDuration]);
                } else {
                  let not_ok_to_trim = ((upperDuration - duration[0]) > numberOfMinutesLeft)
                  console.log('not_ok_to_trim',not_ok_to_trim)
                  console.log('numberOfMinutesLeft',numberOfMinutesLeft)
                  console.log('upperDuration',upperDuration)
                  console.log('duration[0]',duration[0])
                  if(not_ok_to_trim) {
                    setIsDurationInvalid(true);
                    setVideoTrimmingDurationInvalid(true);
                    setInvalidMessage('This trim is over your monthly limit.')
                    return
                  }
                }
                */
              }
              }
              value={upperDuration}
              className="text-center border-gray-800 rounded-lg h-9 border-1 w-28 focus:ring-0"
              id=""
            />
          </div>
          {isDurationInvalid && (
            <p className="w-full text-xs font-medium text-right text-red-to-gradient">
              {invalidMessage}
            </p>
          )}
         
        </div>
      </div>
      <>
        {/* <article className='flex justify-center mt-4'>
					<video
						poster={videoUploaded?.thumbnail_url}
						// src={`${localVideoUrl}#t=${value[0]},${value[1]}`}
						src={`${videoUploaded?.final_video_url}#t=${value[0]},${value[1]}`}
						controls
						preload='metadata'
						className='w-full h-full rounded-2xl'>
					</video>
				</article> */}

        <p className="hidden mt-1 font-medium text-bg-sm md:flex md:mt-2">
          <span className="truncate">
            {fileNames[0] ? fileNames[0] : submitResponse?.youtube_info?.name ?? submitResponse?.name}
          </span>
        </p>

        <article className="relative object-fill mt-4 rounded-lg">
          {/* <div className='z-10 flex justify-between object-fill h-16 gap-2 p-1 overflow-hidden bg-gray-900 rounded-xl'>
						<img src={localVideoUrl} alt="thumbnail" className='w-22' />
						{timeLineThumbnails.map((url) =>
							<Thumbnail src={url} alt="thumbnail" />
						)}
					</div> */}

          {/* <TooltipSlider
						className='absolute top-0 z-40'
						range
						allowCross={false}
						max={videoTrimmingDuration}
						step={0.1}
						trackStyle={{
							height: '-webkit-fill-available'
						}}
						value={[...value]}
						onChange={onSliderChange}
					/> */}

          <div className="z-40 p-4 md:p-2">
            <Slider
              range
              allowCross={false}
              className="z-40"
              max={videoTrimmingDuration + 1}
              step={1}
              value={[...duration]}
              trackStyle={{ backgroundColor: 'black', height: 7, marginTop: 2 }}
              handleStyle={{
                backgroundColor: 'black',
                opacity: 1,
                width: 22,
                height: 22,
              }}
              railStyle={{
                backgroundColor: 'gray',
                height: 7,
                opacity: 0.8,
                marginTop: 2,
              }}
              onChange={onSliderChange as any}
            />
          </div>
        </article>

        <article className="flex justify-end mt-4 text-xs font-medium">
          <p>Total Minutes: {!isDurationInvalid ? `${remainingTime.slice(0, 8)}` : 'Duration Invalid'}</p>
        </article>
      </>
      {/* )} */}
    </section>
  );
};
export default StepTwo;
