import { BiDotsVerticalRounded } from 'react-icons/bi';

import useAppStore from '../../../../store/useApp.store';
import useDynamicZoomStore from '../../../../store/useDynamicZoom.store';
import useVerticalizedStore from '../../../../store/useVerticalized.store';
import { useNavigate } from 'react-router-dom';

import Badge from '../Multicamera/shared/Badge';

const tabsOptions = ['Landscape to Vertical', 'Multicamera AI', 'Dynamic Zoom AI'];

const DashboardNav = () => {
  const setSideMenu = useAppStore((state) => state.setSideMenu);
  const currentTab = useAppStore((state) => state.currentTab);
  const navigate = useNavigate();

  const flags: any = useAppStore((state) => state.flags);
  const setCurrentTab = useAppStore((state) => state.setCurrentTab);
  const setCurrentFunctionality = useAppStore((state) => state.setCurrentFunctionality);

  const _openSideMenu = () => {
    setSideMenu(true);
  };

  const _navToTab = (idx: number) => {
    navigate('/dashboard');
    setCurrentTab(idx);
    if (idx === 2) {
      setCurrentFunctionality(useDynamicZoomStore);
    } else {
      setCurrentFunctionality(useVerticalizedStore);
    }
  };

  return (
    <>
      <section
        className={`hidden md:flex flex-row justify-between w-screen h-10 items-center text-sm focus:ring-transparent ring-transparent z-10`}
      >
        <div
          onClick={() => _navToTab(0)}
          className={`
          flex w-1/3 cursor-pointer focus:ring-transparent hover:bg-black hover:text-white h-full text-center  justify-center items-center
          ${currentTab === 0 ? 'bg-teal-green  font-semibold' : 'bg-gray-400'}
          `}
        >
          Landscape to Vertical
        </div>
        <div
          onClick={() => (flags.Multicamera ? _navToTab(1) : null)}
          className={`
          flex w-1/3 cursor-pointer focus:ring-transparent flex-row items-center justify-center gap-4 h-full text-center group hover:bg-black hover:text-white
          ${currentTab === 1 ? 'bg-teal-green  font-semibold' : 'bg-gray-300'}`}
        >
          Multicamera AI
          {!flags.Multicamera ? <Badge /> : <Badge message='Preview' />}
        </div>
        <div
          onClick={() => (flags.DynamicZoom ? _navToTab(2) : null)}
          className={`
          flex w-1/3 cursor-pointer focus:ring-transparent flex-row items-center justify-center gap-4 h-full text-center group hover:bg-black hover:text-white
          ${currentTab === 2 ? 'bg-teal-green  font-semibold' : 'bg-gray-200'}`}
        >
          Dynamic Zoom AI
          {!flags.DynamicZoom ? <Badge /> : <Badge message='Preview' />}
        </div>
      </section>

      <div
        className="flex items-center justify-center w-full h-12 cursor-pointer md:hidden bg-teal-green"
        onClick={_openSideMenu}
      >
        <div className="flex justify-center gap-4 font-semibold text-center">{tabsOptions[currentTab]}</div>
        <BiDotsVerticalRounded className="w-6" />
      </div>
    </>
  );
};

export default DashboardNav;
