import React from "react";

const Modal = ({ children, show }) => (
  <section
    className={`fixed transition-opacity duration-300 top-0 left-0 right-0 z-50 w-full h-full overflow-x-hidden overflow-y-auto md:h-modal md:inset-0 bg-black/50 ${show ? "opacity-100" : "opacity-0 pointer-events-none"
      }`}
  >
    {children}
  </section>
);

export default Modal;
