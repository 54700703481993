import React from 'react';
import ProjectList from './ProjectList';

import { HiPlus } from 'react-icons/hi';
import useAppStore from '../../../../../store/useApp.store';

const Projects = () => {
  const setCreateMulticameraProjectModal = useAppStore((state) => state.setCreateMulticameraProjectModal);
  const setCurrentTab = useAppStore((state) => state.setCurrentTab);

  setCurrentTab(1)

  return (
    <main className="h-[calc(100vh-10%)] min-h-[600px]">
      <section className='flex justify-between mb-10 items'>
        <article className='md:flex items-center hidden'>
					<p className='text-2xl font-normal' >Projects</p>
				</article>
        <article className='w-full md:w-auto'>
          <button
            onClick={() => setCreateMulticameraProjectModal(true)}
            className="flex items-center justify-center gap-3 px-4 py-4 rounded-xl bg-gradient-to-r from-green-to-gradient to-blue-to-gradient hover:from-blue-to-gradient hover:to-green-to-gradient hover:text-gray-700 md:px-8 w-full md:w-auto"
          >
            <HiPlus />
            <span className="text-sm font-semibold">Create New Project</span>
          </button>
        </article>
      </section>

      <section>
        <ProjectList />
      </section>
    </main>
  );
};

export default Projects;
