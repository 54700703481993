import React from "react";

// Zustand - Global state
import useAppStore from "../../../../../store/useApp.store";

// Components
import Modal from "../../shared/Modal";
import OutsideClickDetector from "../../../../Shared/outsideClickDetector/OutsideClickDetector";
import generatingCaptionIllustration from "../../../../../assets/image/generatingCaptionIllustration.svg"

// Icons
import { TfiClose } from "react-icons/tfi";
import { Transition } from "@headlessui/react";
import useMulticameraStore from "../../../../../store/useMulticamera.store";


const ConfirmMixVideoModal = () => {
	const setConfirmMixModal = useAppStore((state) => state.setConfirmMixModal);
	const confirmMixModal = useAppStore((state) => state.confirmMixModal);
	const currentProject = useMulticameraStore(state => state.currentProject)
	const startJob = useMulticameraStore(state => state.startJob)

	const _handleCloseConfirmMixModal = () => {
		setConfirmMixModal(false);
	};

	const _handleDoneCaptionsEdition = () => {
		startJob(currentProject?.uid)
		setConfirmMixModal(false);
	}

	return (
		<Modal show={confirmMixModal}>
			<Transition
				appear={true}
				show={confirmMixModal}
				className="fixed left-0 transition-all duration-200 translate-x-[calc(50vw-50%)]"
				enterFrom="translate-y-[100vh]"
				enterTo="translate-y-[calc(50vh-50%)]"
				leaveFrom="translate-y-[calc(50vh-50%)]"
				leaveTo="translate-y-[100vh]"
			>
				<OutsideClickDetector callback={() => _handleCloseConfirmMixModal()}>
					<main className={`relative w-96`}>
						<div className="p-6 overflow-hidden bg-gray-100 border shadow-lg rounded-xl md:p-4 border-gray-50">
							<button
								onClick={_handleCloseConfirmMixModal}
								className={`absolute top-2 right-2 p-1 cursor-pointer`}
							>
								<TfiClose className="w-6" />
							</button>
							<section className="pt-2 text-center">
							<img src={generatingCaptionIllustration} alt="" className="w-28 m-auto mb-4" />
								<p className="font-medium text-sm">
								Before you start mixing the video, please make sure all the necessary assets are there.
								</p>
								<div className="flex items-center justify-between gap-3 md:gap-6 pt-6 flex-col md:flex-row">
									<button
										className={`border border-black px-6 py-3 text-sm font-semibold rounded-xl w-full md:w-44 order-1 md:order-0`}
										onClick={_handleCloseConfirmMixModal}
									>
										Cancel
									</button>
									<button
										className={`text-white bg-black px-6 py-3 text-sm font-semibold border rounded-xl w-full md:w-44 order-0 md:order-1`}
										onClick={_handleDoneCaptionsEdition}
									>
										Yes, I'm done
									</button>
								</div>
							</section>
						</div>
					</main>
				</OutsideClickDetector>
			</Transition>
		</Modal>
	);
};

export default ConfirmMixVideoModal;
