import React, {useMemo, useState} from 'react'

import { HiOutlineDotsVertical } from 'react-icons/hi'
import moment from 'moment'
import placeHolderImage from '../../../../../assets/image/multicameraTopRightIllustration.svg'
import { useNavigate } from 'react-router-dom'

import useMulticameraStore from '../../../../../store/useMulticamera.store'
import useAppStore from '../../../../../store/useApp.store'
// Icons
import missingAudioIcon from '../../../../../assets/icon/missingAudioIcon.svg'
import notStartedIcon from '../../../../../assets/icon/notStartedIcon.svg'
import checked from '../../../../../assets/icon/checked.svg'
import error from '../../../../../assets/icon/error.svg'
import inProgress from '../../../../../assets/icon/inProgress.svg'
import uploadIcon from '../../../../../assets/icon/uploadIcon.svg'
import {HiOutlineVideoCameraSlash} from 'react-icons/hi2'
import OutsideClickDetector from '../../../../Shared/outsideClickDetector/OutsideClickDetector';

const ProjectStatus = ({project}:any) => {
	const setCurrentProject = useMulticameraStore(state => state.setCurrentProject)
	const setMulticameraUploadStep = useMulticameraStore(state => state.setMulticameraUploadStep)
	const setUploadMulticameraModal = useAppStore(state => state.setUploadMulticameraModal)
	const _handleUploadProject = (project:any) => {

		setCurrentProject(project)

		if(!!project.files.length) {
			setMulticameraUploadStep(2)
		}
		setTimeout(() =>{
			setUploadMulticameraModal(true)
		},200)
	}



	const status = useMemo(() => {
		if(!project?.files?.filter((file: any) => file.type === 'video').length) {
			return {message:'Missing video', icon: null, component: <HiOutlineVideoCameraSlash size={18} />, hasToUpload:true}
		}
		if(!project.master_audio_file) {
			return {message:'Missing audio', icon: missingAudioIcon, hasToUpload:true}
		}
		if(project.is_error) {
			return {message:'Failed', icon: error}
		}
		if(project.complete) {
			return {message:'Done', icon: checked}
		}
		if(project.started && !project.complete) {
			return {message:'Processing', icon: inProgress}
		}
		return {message:'Not started', icon: notStartedIcon}

	}, [project])

	return (
		<span className='flex gap-1 px-1 items-center'>
			{status.icon ? <img src={status!.icon} className='w-4 h-4' alt="" /> : (status.component)}
			{status!.message}

			{status.hasToUpload && <img src={uploadIcon} className='w-4 ml-auto cursor-pointer' alt="" onClick={() => _handleUploadProject(project)} /> }
		</span>
	)
}

const ProjectDropdownMenu = ({ project }: any) => {
  const removeProject = useMulticameraStore(state => state.removeProject)
  const [showMenu, setShowMenu] = useState<boolean>(false);
  return (
		<>
      <HiOutlineDotsVertical
        onClick={() => setShowMenu(!showMenu)}
        className="absolute top-2 w-6 h-5 right-0 cursor-pointer"
      />
      {showMenu ? (
        <OutsideClickDetector callback={() => setShowMenu(!showMenu)}>
          <div className="absolute z-20 flex flex-col items-center w-[140px] gap-2 p-2 font-medium transition-all bg-gray-50 border border-gray-300 rounded-xl fadeIn right-1 top-8 text-bg-sm">
            <div
              onClick={() => removeProject(project?.uid)}
              className="flex items-center w-full rounded-lg hover:bg-gray-100 p-2 cursor-pointer"
            >
              Delete project
            </div>
          </div>
        </OutsideClickDetector>
      ) : null}
    </>
  );
};


const ProjectCard = ({ project }: any) => {
	const navigate = useNavigate()

	const _handleCurrentProject = (projectUid:string) => {
		navigate(`/project/${projectUid}`)
	}

	const videoFilesInProject = project?.files?.filter((file: any) => file.type === 'video')

	return (
		<section
			className='border rounded-xl p-3 pt-1 max-w-[300px] mx-auto md:m-auto'>
			<div className='flex justify-between items-center py-2 cursor-pointer relative'>
				<p onClick={() => _handleCurrentProject(project?.uid)} className='text-sm pt-0'>{project.name}</p>
				{/* <HiOutlineDotsVertical  /> */}
				<ProjectDropdownMenu project={project}  />
			</div>

			<div>
				<img
					src={project.thumbnail_url ?? videoFilesInProject[0]?.thumbnail_url ?? placeHolderImage}
					alt=""
					onClick={() => _handleCurrentProject(project?.uid)}
					className='rounded-2xl w-full h-44 border object-cover aspect-video cursor-pointer' />
			</div>

			<div className='text-xs'>
				<p className='text-left pt-2 pb-1'>
					<ProjectStatus project={project} />
				</p>
				<p className='font-light p-0.5 text-gray-400 pt-1'>Updated {moment(project?.updatedAt).calendar()}</p>
			</div>
		</section>
	)
}

export default ProjectCard