import React, { useState, useMemo, useCallback, useEffect } from 'react';

// Icons
import backArrow from '../../assets/icon/backArrow.svg';
import playIcon from '../../assets/icon/play.svg';
import downloadIcon from '../../assets/icon/downloadIcon.svg';
import verticalAlign from '../../assets/icon/verticalAlign.svg';
import captionsIcon from '../../assets/icon/captionsIcon.svg';
import exportVideoIcon from '../../assets/icon/exportVideoIcon.svg';
import exportSrt from '../../assets/icon/exportSrtIcon.svg';
import { FiChevronDown } from 'react-icons/fi';

// Zustand - Global state
import useAppStore from '../../store/useApp.store';
import useVerticalizedStore from '../../store/useVerticalized.store';

// External libraries
import srtParser2 from 'srt-parser-2';
import { useNavigate } from 'react-router-dom';

import ConfirmGenerateCaptionModal from './components/ConfirmGenerateCaptions';

import { getPreferences, setPreferences } from '../../utils/setUserCaptionPreferences';

const captionSize = [
  { name: 'Small', value: 'text-[14px]' },
  { name: 'Medium', value: 'text-[17px]' },
  { name: 'Big', value: 'text-[26px]' },
];

const captionPosition = [
  { name: 'Bottom', value: 'bottom-28' },
  { name: 'Middle', value: 'bottom-38' },
  { name: 'Top', value: 'top-14' },
];

const Caption = () => {
  const userPreferences = getPreferences();
  const navigate = useNavigate();
  const [showPlayIcon, setShowPlayIcon] = useState(true);
  const [timeStamp, setTimeStamp] = useState(0);
  const [enabled, setEnabled] = useState(userPreferences?.font ?? false);
  const [size, setSize] = useState(captionSize[userPreferences?.size ?? 1]);
  const [position, setPosition] = useState(captionPosition[userPreferences?.position ?? 0]);
  const [subtitleType, setSubtitleType] = useState(userPreferences?.font_style ?? 'clear');

  const currentFile = useVerticalizedStore((state) => state.currentFile);
  const getSubtitles = useVerticalizedStore((state) => state.getSubtitles);
  const setSubtitles = useVerticalizedStore((state) => state.setSubtitles);
  const subtitles = useVerticalizedStore((state) => state.subtitles);
  const editSubtitles = useVerticalizedStore((state) => state.editSubtitles);

  const setCancelCaptionModal = useAppStore((state) => state.setCancelCaptionModal);

  const setConfirmGenerateCaptionsModal = useAppStore((state) => state.setConfirmGenerateCaptionsModal);

  const _getSubtitle = useCallback(() => getSubtitles(currentFile.subtitles), [currentFile.subtitles, getSubtitles]);

  useEffect(() => {
    _getSubtitle();
  }, [_getSubtitle]);

  const _editSubtitles = (event, subtitleId) => {
    const subId = subtitleId ?? _getCurrentSubtitle().id;
    const sub = subtitles.filter((sub) => sub.id === subId);
    sub[0].text = event.currentTarget.textContent;
    subtitles.splice(subId - 1, 1, sub[0]);

    editSubtitles(subtitles);
  };

  const fontType = useMemo(() => (enabled ? 'font-typewriter' : 'font-montserrat'), [enabled]);

  const captionOptions = {
    clear: `${fontType} text-white`,
    box: `${fontType} bg-black text-white rounded`,
    box_inverted: `${fontType} bg-white text-black rounded`,
    outline: `${fontType} drop-shadow-black-border text-white `,
    outline_inverted: `${fontType} drop-shadow-white-border text-black`,
  };

  const _playVideo = () => {
    const video = document.getElementById('br-video-caption');
    video.play();
  };

  const _scrollTo = (id) => {
    if (id) {
      const element = document?.getElementById(id);
      element?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  };

  const _getCurrentSubtitle = useCallback(
    (id) => {
      const currentSubtitle = subtitles.find((sub) => sub.start > timeStamp || timeStamp < sub.end);

      if (currentSubtitle?.id === id) {
        _scrollTo(id);
      }

      if (currentSubtitle) {
        return {
          id: currentSubtitle?.id,
          isCurrent: currentSubtitle?.id === id,
          text: `${currentSubtitle?.text}`,
        };
      }
    },
    [subtitles, timeStamp]
  );

  const _handlePlay = () => {
    setShowPlayIcon(!showPlayIcon);
    _playVideo();
  };

  const _generateSubtitles = useCallback(() => {
    const textOption = {
      size: size.name.toLowerCase(),
      position: position.name.toLowerCase(),
      font: !enabled ? 'default' : 'typewriter',
      font_style: subtitleType,
    };

    setPreferences({
      size: captionSize.map((e) => e.name).indexOf(size.name),
      position: captionPosition.map((e) => e.name).indexOf(position.name),
      font: enabled,
      font_style: subtitleType,
    });

    const captionFormatted = subtitles.map((subtitle) => ({ ...subtitle, ...textOption }));
    editSubtitles(captionFormatted);
    setSubtitles();

    setTimeout(() => {
      navigate('/dashboard');
    }, 2000);
  }, [editSubtitles, enabled, navigate, position?.name, setSubtitles, size?.name, subtitleType, subtitles]);

  const [mobileCaptionOptionsIndex, setMobileCaptionOptionIndex] = useState(1);
  const options = Object.keys(captionOptions);

  const _setCaptionOptionMobile = () => {
    mobileCaptionOptionsIndex + 1 >= options.length
      ? setMobileCaptionOptionIndex(0)
      : setMobileCaptionOptionIndex(() => mobileCaptionOptionsIndex + 1);

    setSubtitleType(options[mobileCaptionOptionsIndex]);
  };

  const [exportDropdownIsOpen, setExportDropdownIsOpen] = useState(false);

  const _exportSrt = () => {
    const parser = new srtParser2();

    const srt_array = subtitles.map((subtitle) => ({
      ...subtitle,
      id: subtitle.id,
      text: subtitle.text,
      startTime: subtitle.start_ts,
      endTime: subtitle.end_ts,
      endSeconds: subtitle.end,
      startSeconds: subtitle.start,
    }));

    // turn array back to SRT string.
    const srt_string = parser.toSrt(srt_array);
    const element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(srt_string));
    element.setAttribute('download', 'subtitles.srt');

    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  return (
    <>
      <ConfirmGenerateCaptionModal generateCaptions={_generateSubtitles} />

      <main className="py-4 lg:px-20 md:px-16 sm:px-12 md:pb-16 bg-gray-50">
        {/* back arrow */}
        <div className="items-center hidden mt-2 mb-2 md:flex h-9">
          <img
            src={backArrow}
            alt=""
            className="hidden cursor-pointer md:block"
            onClick={() => setCancelCaptionModal(true)}
          />
        </div>

        <section className="flex flex-col h-full gap-2 mb-3 md:flex-row md:justify-between md:mb-6">
          {/* Caption editor section md - lg */}
          <section className="hidden md:block md:min-w-[380px] lg:w-4/6 pt-4 order-2 md:order-1 lg:min-w-[700px]">
            <section className="items-center justify-between hidden lg:gap-2 lg:justify-between sm:flex lg:flex-row">
              <div className="flex flex-col items-start justify-between gap-6 lg:flex-row lg:items-center">
                {/* Captions type */}
                <section className="flex flex-col gap-2">
                  <p className="text-xs font-semibold">Font family</p>
                  <div className="flex items-center gap-2">
                    <article
                      onClick={() => setEnabled(false)}
                      className={`${
                        !enabled ? 'border-selected-caption border-4' : 'border border-gray-400'
                      } text-sm font-semibold h-9 cursor-pointer rounded-xl bg-white flex flex-col justify-center items-center gap-1 px-2 py-1`}
                    >
                      <span className={`px-1.5 rounded-sm font-ptsans`}>Default</span>
                    </article>
                    <article
                      onClick={() => setEnabled(true)}
                      className={`${
                        enabled ? 'border-selected-caption border-4' : 'border border-gray-400'
                      } text-sm font-semibold h-9 cursor-pointer rounded-xl bg-white flex flex-col justify-center items-center gap-1 px-2 py-1`}
                    >
                      <span className={`px-1.5 rounded-sm font-typewriter`}>Typewriter</span>
                    </article>
                  </div>
                </section>

                {/* Captions options */}
                <section className={`flex flex-col gap-2 font-ptsans min-w-[200px] mr-3`}>
                  <p className="text-xs font-semibold">Font style</p>
                  <div className={`grid grid-cols-5 gap-2 justify-center items-center font-semibold font-ptsans`}>
                    <article
                      onClick={() => setSubtitleType('clear')}
                      className={`${
                        subtitleType === 'clear' ? 'border-selected-caption border-4' : 'border border-gray-400'
                      } h-9 w-9 cursor-pointer rounded-xl bg-white flex flex-col justify-center items-center gap-1 px-0.5 py-1`}
                    >
                      <span className={`px-1.5 rounded-sm`}>Aa</span>
                    </article>
                    <article
                      onClick={() => setSubtitleType('box')}
                      className={`${
                        subtitleType === 'box' ? 'border-selected-caption border-4' : 'border border-gray-400'
                      } h-9 w-9 cursor-pointer bg-white rounded-xl flex flex-col justify-center items-center gap-1 px-0.5 py-1`}
                    >
                      <span className={`bg-black text-white px-1 rounded font-semibold`}>Aa</span>
                    </article>
                    <article
                      onClick={() => setSubtitleType('box_inverted')}
                      className={`${
                        subtitleType === 'box_inverted' ? 'border-selected-caption border-4' : 'border border-gray-400'
                      } h-9 w-9 cursor-pointer bg-black text-white rounded-xl flex flex-col justify-center items-center gap-1 px-0.5 py-1`}
                    >
                      <span className={`bg-white text-black px-1 rounded font-semibold`}>Aa</span>
                    </article>
                    <article
                      onClick={() => setSubtitleType('outline')}
                      className={`${
                        subtitleType === 'outline' ? 'border-selected-caption border-4' : 'border border-gray-400'
                      } h-9 w-9 cursor-pointer text-white bg-white rounded-xl flex flex-col justify-center items-center gap-1 px-0.5 py-1`}
                    >
                      <span className={`drop-shadow-black-border px-1.5 rounded-sm`}>Aa</span>
                    </article>
                    <article
                      onClick={() => setSubtitleType('outline_inverted')}
                      className={`${
                        subtitleType === 'outline_inverted'
                          ? 'border-selected-caption border-4'
                          : 'border border-gray-400'
                      } h-9 w-9 cursor-pointer bg-black text-s rounded-xl flex flex-col justify-center items-center gap-1 px-0.5 py-1`}
                    >
                      <span className={`drop-shadow-white-border px-1.5 rounded-sm`}>Aa</span>
                    </article>
                  </div>
                </section>
              </div>

              <div className="flex flex-col items-start justify-between gap-4 lg:flex-row lg:items-center">
                {/* Captions size */}
                <section className={`flex flex-col gap-2 min-w-[120px]`}>
                  <p className="text-xs font-semibold">Font size</p>
                  <div className="grid justify-center grid-cols-3 gap-2 font-semibold font-ptsans">
                    <article
                      onClick={() => setSize(captionSize[0])}
                      className={`${
                        size === captionSize[0] ? 'border-selected-caption border-4' : 'border border-gray-400'
                      } text-sm h-9 w-9 cursor-pointer rounded-xl bg-white  flex flex-col justify-center items-center gap-1 px-0.5 py-1.5`}
                    >
                      <span className={`px-1.5 rounded-sm`}>Aa</span>
                    </article>
                    <article
                      onClick={() => setSize(captionSize[1])}
                      className={`${
                        size === captionSize[1] ? 'border-selected-caption border-4' : 'border border-gray-400'
                      } text-normal h-9 w-9 cursor-pointer bg-white rounded-xl flex flex-col justify-center items-center gap-1 px-0.5 py-1.5`}
                    >
                      <span className={`px-1.5 rounded-sm`}>Aa</span>
                    </article>
                    <article
                      onClick={() => setSize(captionSize[2])}
                      className={`${
                        size === captionSize[2] ? 'border-selected-caption border-4' : 'border border-gray-400'
                      } text-lg h-9 w-9 cursor-pointer rounded-xl flex flex-col justify-center items-center gap-1 px-0.5 py-1.5`}
                    >
                      <span className={`bg-white text-black px-0.5 rounded-full`}>Aa</span>
                    </article>
                  </div>
                </section>

                {/* Captions position */}
                <section className={`flex flex-col gap-2 min-w-[120px]`}>
                  <p className="text-xs font-semibold">Captions position</p>
                  <div className="grid justify-center grid-cols-3 gap-2 font-semibold font-ptsans">
                    <article
                      onClick={() => setPosition(captionPosition[0])}
                      className={`${
                        position === captionPosition[0] ? 'border-selected-caption border-4' : 'border border-gray-400'
                      } text-sm h-9 bg-white cursor-pointer rounded-xl flex justify-center w-9 items-end gap-1 px-0.5 py-1.5`}
                    >
                      <img src={verticalAlign} className="w-5" alt="" />
                    </article>
                    <article
                      onClick={() => setPosition(captionPosition[1])}
                      className={`${
                        position === captionPosition[1] ? 'border-selected-caption border-4' : 'border border-gray-400'
                      } text-normal h-9 bg-white cursor-pointer rounded-xl flex justify-center w-9 items-center gap-1 px-0.5 py-1.5`}
                    >
                      <img src={verticalAlign} className="w-5" alt="" />
                    </article>
                    <article
                      onClick={() => setPosition(captionPosition[2])}
                      className={`${
                        position === captionPosition[2] ? 'border-selected-caption border-4' : 'border border-gray-400'
                      } text-lg h-9 bg-white cursor-pointer rounded-xl flex justify-center w-9 items-start gap-1 px-0.5 py-1.5`}
                    >
                      <img src={verticalAlign} className="w-5" alt="" />
                    </article>
                  </div>
                </section>
              </div>
            </section>

            <div className="w-full mt-4 border-t-2 border-gray-300 rounded-full"></div>

            <section className="flex items-center justify-between mt-4">
              <article className="flex items-center justify-center gap-1">
                <img src={captionsIcon} alt="" />
                <p className="text-lg font-medium">Captions</p>
              </article>
              <article>
                <p onClick={_exportSrt} className="text-sm font-medium cursor-pointer hover:underline">
                  Export .SRT
                </p>
              </article>
            </section>

            {/* Captions */}
            <section className={`hidden md:block w-full overflow-y-scroll caption-scroll h-96 mt-4 mb-8`}>
              {!subtitles?.length ? (
                <p>Loading subtitle...</p>
              ) : (
                subtitles?.map(
                  (subtitle) =>
                    subtitle?.text && (
                      <div
                        id={subtitle.id}
                        key={subtitle.id}
                        contentEditable
                        suppressContentEditableWarning={true}
                        onInput={(e) => _editSubtitles(e, subtitle.id)}
                        className={`
											${
                        _getCurrentSubtitle(subtitle.id)?.isCurrent
                          ? 'py-6 text-sm mr-4 text-black bg-white font-semibold border border-gray-300 rounded-tl-sm rounded-bl-sm rounded-lg'
                          : 'py-4 text-sm'
                      } px-2 text-gray-800`}
                      >
                        <span
                          className={`${
                            _getCurrentSubtitle(subtitle.id)?.isCurrent && 'border-l-2 border-gray-700 pl-1.5'
                          }`}
                        >
                          {/* {_getCurrentSubtitle().text} */}
                          {/* {subtitle.text} */}
                          {_getCurrentSubtitle()?.isCurrent && '| '}
                          {subtitle.text}
                        </span>
                        {/* {_getCurrentSubtitle(subtitle.id).isCurrent && '| '}{subtitle.text} */}
                      </div>
                    )
                )
              )}
            </section>
          </section>

          {/* Caption editor section sm */}
          <section className="w-full md:hidden pt-2 order-2 md:order-1 p-2 mt-2 min-h-[180px]">
            <section className="flex flex-col items-center justify-between">
              <div className="flex items-center justify-between gap-5">
                {/* Captions options */}
                <section className={`grid grid-cols-5 justify-bet items-center font-semibold font-ptsans w-16`}>
                  <article
                    onClick={_setCaptionOptionMobile}
                    className={`${
                      subtitleType === 'clear' ? 'border-selected-caption border-4' : 'border border-gray-400 hidden'
                    } h-12 w-12 cursor-pointer rounded-xl bg-white flex flex-col justify-center items-center p-2`}
                  >
                    <span className={`px-1.5 rounded-sm`}>Aa</span>
                  </article>

                  <article
                    onClick={_setCaptionOptionMobile}
                    className={`${
                      subtitleType === 'box' ? 'border-selected-caption border-4' : 'border border-gray-400 hidden'
                    } h-12 w-12 cursor-pointer bg-white rounded-xl flex flex-col justify-center items-center p-2`}
                  >
                    <span className={`bg-black text-white px-1 rounded font-semibold`}>Aa</span>
                  </article>
                  <article
                    onClick={_setCaptionOptionMobile}
                    className={`${
                      subtitleType === 'box_inverted'
                        ? 'border-selected-caption border-4'
                        : 'border border-gray-400 hidden'
                    } h-12 w-12 cursor-pointer bg-black text-white rounded-xl flex flex-col justify-center items-center p-2`}
                  >
                    <span className={`bg-white text-black px-1 rounded font-semibold`}>Aa</span>
                  </article>
                  <article
                    onClick={_setCaptionOptionMobile}
                    className={`${
                      subtitleType === 'outline' ? 'border-selected-caption border-4' : 'border border-gray-400 hidden'
                    } h-12 w-12 cursor-pointer text-white bg-white rounded-xl flex flex-col justify-center items-center p-2`}
                  >
                    <span className={`drop-shadow-black-border px-1.5 rounded-sm`}>Aa</span>
                  </article>
                  <article
                    onClick={_setCaptionOptionMobile}
                    className={`${
                      subtitleType === 'outline_inverted'
                        ? 'border-selected-caption border-4'
                        : 'border border-gray-400 hidden'
                    } h-12 w-12 cursor-pointer bg-black text-s rounded-xl flex flex-col justify-center items-center p-2`}
                  >
                    <span className={`drop-shadow-white-border px-1.5 rounded-sm`}>Aa</span>
                  </article>
                </section>

                <div className="h-10 m-auto border border-r-0 border-gray-400"></div>

                {/* Captions type */}
                <div className="flex w-full gap-3">
                  <article
                    onClick={() => setEnabled(false)}
                    className={`${
                      !enabled ? 'border-selected-caption border-2' : 'border border-gray-400'
                    } text-lg font-semibold h-12 cursor-pointer rounded-xl bg-white flex flex-col justify-center items-center px-5 py-4 w-full`}
                  >
                    <span className={`px-1.5 rounded-sm font-ptsans`}>Default</span>
                  </article>
                  <article
                    onClick={() => setEnabled(true)}
                    className={`${
                      enabled ? 'border-selected-caption border-2' : 'border border-gray-400'
                    } text-lg font-semibold h-12 cursor-pointer rounded-xl bg-white flex flex-col justify-center items-center px-5 py-4 w-full`}
                  >
                    <span className={`px-1.5 rounded-sm font-typewriter`}>Typewriter</span>
                  </article>
                </div>
              </div>

              <div className="flex items-center justify-between gap-4 mt-4">
                {/* Captions position */}
                <section className={`grid grid-cols-3 gap-4 justify-center font-semibold font-ptsans`}>
                  <article
                    onClick={() => setPosition(captionPosition[0])}
                    className={`${
                      position === captionPosition[0] ? 'border-selected-caption border-4' : 'border border-gray-400'
                    } text-sm h-12 bg-white cursor-pointer rounded-xl flex justify-center w-12 items-end p-2`}
                  >
                    <img src={verticalAlign} className="w-5" alt="" />
                  </article>
                  <article
                    onClick={() => setPosition(captionPosition[1])}
                    className={`${
                      position === captionPosition[1] ? 'border-selected-caption border-4' : 'border border-gray-400'
                    } text-normal h-12 bg-white cursor-pointer rounded-xl flex justify-center w-12 items-center p-2`}
                  >
                    <img src={verticalAlign} className="w-5" alt="" />
                  </article>
                  <article
                    onClick={() => setPosition(captionPosition[2])}
                    className={`${
                      position === captionPosition[2] ? 'border-selected-caption border-4' : 'border border-gray-400'
                    } text-lg h-12 bg-white cursor-pointer rounded-xl flex justify-center w-12 items-start p-2`}
                  >
                    <img src={verticalAlign} className="w-5" alt="" />
                  </article>
                </section>

                <div className="h-10 border border-r-0 border-gray-400"></div>
                {/* Captions size */}
                <section className={`grid grid-cols-3 gap-4 justify-center font-semibold font-ptsans`}>
                  <article
                    onClick={() => setSize(captionSize[0])}
                    className={`${
                      size === captionSize[0] ? 'border-selected-caption border-2' : 'border border-gray-400'
                    } text-sm h-12 w-12 cursor-pointer rounded-xl bg-white  flex flex-col justify-center items-center p-2`}
                  >
                    <span className={`px-1.5 rounded-sm`}>Aa</span>
                  </article>
                  <article
                    onClick={() => setSize(captionSize[1])}
                    className={`${
                      size === captionSize[1] ? 'border-selected-caption border-2' : 'border border-gray-400'
                    } text-lg h-12 w-12 cursor-pointer bg-white rounded-xl flex flex-col justify-center items-center p-2`}
                  >
                    <span className={`px-1.5 rounded-sm`}>Aa</span>
                  </article>
                  <article
                    onClick={() => setSize(captionSize[2])}
                    className={`${
                      size === captionSize[2] ? 'border-selected-caption border-2' : 'border border-gray-400'
                    } text-2xl h-12 w-12 cursor-pointer rounded-xl flex flex-col justify-center items-center p-2`}
                  >
                    <span className={`bg-white text-black px-0.5 rounded-sm`}>Aa</span>
                  </article>
                </section>
              </div>
            </section>
          </section>

          <section className="flex items-center justify-between px-6 md:hidden">
            <div onClick={() => setCancelCaptionModal(true)} className="text-sm font-semibold cursor-pointer">
              Cancel
            </div>

            <div
              onClick={() => setExportDropdownIsOpen(!exportDropdownIsOpen)}
              className="relative flex items-center justify-center gap-1 px-10 py-2 text-sm font-semibold transition-all rounded-lg cursor-pointer md:hidden bg-blue-to-gradient hover:font-semibold hover:bg-blue-to-gradient/80"
            >
              Generate video
              <FiChevronDown size={16} />
              {exportDropdownIsOpen ? (
                <div className="absolute z-20 flex flex-col items-center w-full gap-3 p-3 font-medium transition-all bg-white border rounded-lg fadeIn top-10 text-bg-sm">
                  <div onClick={_exportSrt} className="flex items-center w-full gap-2 rounded hover:bg-gray-100">
                    <img src={exportSrt} alt="" />
                    Export srt
                  </div>
                  <div className="w-20 border border-b-2 border-gray-100 rounded-full"></div>
                  <div
                    onClick={() => setConfirmGenerateCaptionsModal(true)}
                    className="flex items-center w-full gap-2 rounded hover:bg-gray-100"
                  >
                    <img src={exportVideoIcon} alt="" />
                    Export video
                  </div>
                </div>
              ) : null}
            </div>
          </section>

          {/* Video section */}
          <section className="order-1 w-full pt-6 lg:w-2/6 md:3/6">
            <section className="relative flex justify-center w-full rounded-2xl">
              <div className={`w-full relative px-2 flex justify-center items-center`}>
                {showPlayIcon ? (
                  <article className={`absolute flex justify-center items-center cursor-pointer w-full z-20`}>
                    <img src={playIcon} alt={currentFile?.name} onClick={_handlePlay} className="w-28" />
                  </article>
                ) : null}

                <video
                  poster={currentFile?.thumbnail_portrait}
                  id="br-video-caption"
                  className={`rounded-2xl h-full z-10 m-auto w-80 sm:w-72`}
                  src={currentFile?.produced_video_portrait_url}
                  controls={!showPlayIcon}
                  playsInline
                  onTimeUpdate={({ target }) => {
                    setTimeStamp(target.currentTime);
                  }}
                />
                {/* Subtitle area */}
                {!showPlayIcon && (
                  <div
                    className={`
							flex justify-center object-fill z-30 m-auto absolute 
							${position.value}`}
                  >
                    <div
                      contentEditable
                      suppressContentEditableWarning={true}
                      onInput={(e) => _editSubtitles(e)}
                      className={`z-20 bg-transparent w-64 text-center font-semibold h-fill p-2 px-3`}
                    >
                      <span
                        className={`
                          ${captionOptions[subtitleType]} ${size.value}
                          ${size.name === 'Big' && 'leading-9'}
                          ${size.name === 'Medium' && 'leading-6'}
                          ${size.name === 'Small' && 'leading-5'}
                          box-decoration-clone p-1`}
                      >
                        {_getCurrentSubtitle()?.text ? _getCurrentSubtitle()?.text : null}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </section>
            <section className="flex justify-center">
              <div
                onClick={() => setConfirmGenerateCaptionsModal(true)}
                className="items-center justify-center hidden gap-1 p-3 mt-8 text-sm font-medium text-gray-800 cursor-pointer md:flex rounded-xl bg-blue-to-gradient w-72 hover:font-semibold hover:bg-blue-to-gradient/80"
              >
                <img src={downloadIcon} alt="" className="w-4" />
                Generate video
              </div>
            </section>
          </section>
        </section>
      </main>
    </>
  );
};

export default Caption;
