import { Navigate } from 'react-router-dom';
import React from 'react';

//Zustand Global State
import useUserStore from '../store/useUser.store';

const ProtectedRoute = ({ children }: { children: JSX.Element }): JSX.Element => {
  const user = useUserStore((state) => state.user);
  const lsUser = JSON.parse(localStorage.getItem('user') || '{}');

  if (lsUser?.confirmed) {
    return children;
  } else {
    return <Navigate to="/login" />;
  }
};

export default ProtectedRoute;
