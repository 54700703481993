import axios from './base.service';
import instance from 'axios';
import moment from 'moment';
import useUserStore from '../store/useUser.store';
import { youtubeResponseValidations } from '../views/Dashboard/utils/youtubeValidations';
import { dashboardUploadErrorEvent } from '../utils/mixpanelEvents';
import { setToken } from '../utils/setToken';

const resource = 'dynamic-zoom-jobs';

export const getDashboardData = async () => {
  try {
    const { data, status } = await axios.get(`${resource}`);

    if (status === 200) {
      return data;
    }
  } catch (error) {
    console.info(error);
  }
};

export const getFailedStatus = async () => {
  const params = {
    'filters[is_error][$eq]': true,
    'filters[started][$eq]': true,
    sort: 'updatedAt:desc',
  };

  try {
    const { data, status } = await axios.get(`${resource}`, { params });

    if (status === 200) {
      return data;
    }
  } catch (error) {
    console.info(error);
  }
};

export const getInProgressStatus = async () => {
  const params = {
    'filters[progress][$lt]': 100,
    'filters[upload_done][$eq]': true,
    'filters[is_error][$eq]': false,
    'filters[fcp_url][$null]': true,
    sort: 'updatedAt:desc',
  };

  try {
    const { data, status } = await axios.get(resource, { params });
    if (status === 200) {
      return data;
    }
  } catch (error) {
    console.info(error);
  }
};

export const getCompletedStatus = async (page = 1) => {
  const params = {
    'filters[complete][$eq]': true,
    'filters[started][$eq]': true,
    'filters[viewed][$eq]': false,
    'filters[is_error][$eq]': false,
    'filters[fcp_url][$notNull]': true,
    'filters[job_end_time][$gte]': moment().subtract(1, 'days').toISOString(),
    'pagination[page]': page,
    'pagination[pageSize]': 25,
    sort: 'job_end_time:desc',
  };

  try {
    const { data, status } = await axios.get(resource, { params });
    if (status === 200) {
      return data;
    }
  } catch (error) {
    console.info(error);
  }
};

export const getMyVideos = async (page = 1, pageSize = 12) => {
  const params = {
    'filters[complete][$eq]': true,
    'filters[started][$eq]': true,
    'filters[job_end_time][$notNull]': true,
    'filters[is_error][$eq]': false,
    'filters[fcp_url][$notNull]': true,
    'pagination[page]': page,
    'pagination[pageSize]': pageSize,
    sort: 'job_end_time:desc',
  };

  try {
    const { data, status } = await axios.get(resource, { params });

    if (status === 200) {
      return data;
    }
  } catch (error) {
    console.info(error);
  }
};

export const setVideoViewed = async (uid: string) => {
  const request = {
    data: {
      viewed: true,
    },
  };

  try {
    const { data, status } = await axios.put(`${resource}/${uid}`, request);

    if (status === 200) {
      return data;
    }
  } catch (error) {
    console.info(error);
  }
};

export const rateVideo = async (uid: string, rate: number) => {
  const request = {
    data: {
      rating: rate,
    },
  };

  try {
    const { data, status } = await axios.put(`${resource}/${uid}`, request);
    const response = data.data.attributes;

    if (status === 200) {
      return response;
    }
  } catch (error) {
    console.info(error);
  }
};

export const deleteVideo = async (uid: string) => {
  const request = {
    data: {
      delete: true,
    },
  };

  try {
    const { data, status } = await axios.put(`${resource}/${uid}`, request);

    if (status === 200) {
      return data;
    }
  } catch (error) {
    console.info(error);
  }
};

export const getSubtitles = async (url: string) => {
  try {
    const { data, status } = await instance.get(url);
    console.info(data);

    if (status === 200) {
      return data;
    }
  } catch (error) {
    console.info(error);
  }
};

export const setSubtitles = async (uid: string, subtitles: unknown[]) => {
  const request = {
    data: {
      edited_subtitles: subtitles,
    },
  };

  try {
    const { data, status } = await axios.put(`${resource}/${uid}`, request);
    if (status === 200) {
      return data;
    }
  } catch (error) {
    console.info(error);
  }
};

export const removeWatermarkWithSurvey = async (uid: string, data: any) => {
  const body = {
    data: { ...data, job_id: uid },
  };

  try {
    const { data, status } = await axios.post(`watermark-surveys`, body);

    if (status === 200) {
      return data;
    }
  } catch (error) {
    console.info(error);
  }
};

export const removeWatermark = async (uid: string) => {
  const body = {
    data: {
      watermark: false,
      started: false,
      bigroom_fcp_portrait: null,
      bigroom_fcp: null,
      complete: false,
      progress: 0,
    },
  };

  try {
    const { data, status } = await axios.put(`offline-jobs/${uid}`, body);

    if (status === 200) {
      return data;
    }
  } catch (error) {
    console.info(error);
  }
};

export const submitData = async (uploadData: any, options: any) => {
  const config = {
    ...options,
  };
  try {
    const { data, status } = await axios.post(resource, uploadData, config);
    const response = data.data.attributes;
    if (status === 200) {
      if (uploadData.data.youtube_url) {
        return youtubeResponseValidations(response);
      }
      return response;
    }
  } catch (error) {
    console.info(error);
  }
};

export const uploadVideo = async (
  presignedUrl: string,
  video: any,
  options: any,
  jobId: string,
  setSuccess: any,
  retryAttempt: number,
  setErrorPopup: any
) => {
  const config = {
    headers: {
      'Content-Type': video.type,
    },
    ...options,
  };

  try {
    const { status } = await axios.put(presignedUrl, video, config);
    if (status === 200) {
      setSuccess(true);
      // await axios.put(`${resource}/${jobId}`, { upload_done: true });
    }
  } catch (error: any) {
    if (error.message !== 'canceled') {
      const userId = useUserStore.getState()?.user.id;
      dashboardUploadErrorEvent(userId);
    }

    if (error.message !== 'canceled' && retryAttempt < 3) {
      uploadVideo(presignedUrl, video, options, jobId, setSuccess, retryAttempt + 1, setErrorPopup);
    } else if (retryAttempt >= 3) {
      setErrorPopup(true);
    }
  }
};

export const uploadMedia = async (presignedUrl: string, media: any, options: any, setSuccess: any) => {
  const config = {
    headers: {
      'Content-Type': media.type,
    },
    ...options,
  };

  try {
    const { status } = await axios.put(presignedUrl, media, config);
    if (status === 200) {
      setSuccess(true);
    }
  } catch (error: any) {
    if (error.message !== 'canceled') {
      const userId = useUserStore.getState()?.user.id;
      dashboardUploadErrorEvent(userId);
    }
    console.info(error);
  }

  setToken();
};

export const uploadedSuccess = async (jobId: string, hasError = false, errorMessage: string = '') => {
  const uploadDone = { upload_done: true, start_job: true };
  const uploadError = { is_error: true, error_message: errorMessage };

  const request = {
    data: hasError ? uploadError : uploadDone,
  };
  try {
    const { data, status } = await axios.put(`${resource}/${jobId}`, request);
    const response = data.data.attributes;
    if (status === 200) {
      return response;
    }
  } catch (error) {
    console.info(error);
  }
};

export const uploadTrimming = async (jobId: string, trimmingData: any) => {
  const request = {
    data: trimmingData,
  };
  try {
    const { data, status } = await axios.put(`${resource}/${jobId}`, request);
    const response = data.data.attributes;
    if (status === 200) {
      return response;
    }
  } catch (error) {
    console.info(error);
  }
};

export const uploadAudioUrl = async (jobId: string, url: any, startJob?: boolean) => {
  const request = {
    data: {
      upload_audio_done: true,
      source_audio_url: url,
      start_job: startJob,
      trim_done: true,
    },
  };
  try {
    const { data, status } = await axios.put(`${resource}/${jobId}`, request);
    const response = data.data.attributes;
    if (status === 200) {
      return response;
    }
  } catch (error) {
    console.info(error);
  }
};

export const submitWithoutAudio = async (jobId: string, startJob?: boolean) => {
  const request = {
    data: {
      start_job: startJob,
      trim_done: true,
    },
  };

  try {
    const { data, status } = await axios.put(`${resource}/${jobId}`, request);
    const response = data.data.attributes;
    if (status === 200) {
      return response;
    }
  } catch (error) {
    console.info(error);
  }
};

export const uploadAudio = async (presignedUrl: string, audio: any, options: any) => {
  const config = {
    ...options,
  };

  try {
    const { status } = await instance.put(presignedUrl, audio, config);
    if (status === 200) {
      return true;
    }
  } catch (error) {
    console.info(error);
    return false;
  }
};

export const submitAudioData = async (jobId: number, startJob?: boolean) => {
  const request = {
    data: {
      upload_audio_done: true,
      start_job: startJob,
      trim_done: true,
    },
  };
  try {
    const { data, status } = await axios.put(`${resource}/${jobId}`, request);
    const response = data.data.attributes;
    if (status === 200) {
      return response;
    }
  } catch (error) {
    console.info(error);
  }
};

export const cancelTrimming = async (jobId: string) => {
  const request = {
    data: {
      delete: true,
      error_message: 'user cancel',
    },
  };
  try {
    const { data, status } = await axios.put(`${resource}/${jobId}`, request);
    const response = data.data.attributes;
    if (status === 200) {
      return response;
    }
  } catch (error) {
    console.info(error);
  }
};

export const uploadMediaWithoutToken = async (presignedUrl: string, media: any, options: any, setSuccess?: any) => {
  const config = {
    headers: {
      'Content-Type': media.type,
    },
    ...options,
  };

  try {
    const { status } = await instance.put(presignedUrl, media, config);
    if (status === 200) {
      setSuccess(true);
      return true;
    }
  } catch (error: any) {
    if (error.message !== 'canceled') {
      const userId = useUserStore.getState()?.user.id;
      dashboardUploadErrorEvent(userId);
    }
    console.info(error);
  }
};
