import React from 'react';
import logoWhite from '../../../assets/logo/logo-white.svg';
import { useNavigate } from 'react-router-dom';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const navigate = useNavigate();

  const _navigateTo = (path: string) => {
    const getUserLS = JSON.parse(localStorage.getItem('user') || '{}');
    if (getUserLS?.confirmed) {
      navigate('/dashboard');
      return;
    }
    if (!getUserLS?.confirmed) {
      navigate('/login');
      return;
    }
    navigate(path);
  };

  return (
    <nav className="pt-[20px] flex sm:bg-transparent items-center sm:items-start md:bg-no-repeat justify-between w-full z-40">
      <section className="flex items-center">
        <img src={logoWhite} alt="BigRoom logo" className="w-24 cursor-pointer" onClick={() => navigate('/')} />
        <p className="w-3 font-semibold text-[10px] pt-3 text-white ml-1">EARLY ACCESS</p>
      </section>
      <div className={'flex justify-end'}>
        <button
          onClick={() => _navigateTo(`/login`)}
          className={
            'float-right bg-transparent text-white border border-white rounded-xl hover:bg-white hover:text-black transition-all duration-300 min-w-[108.5px] min-h-[2.75rem]'
          }
          type="button"
        >
          Log in
        </button>
      </div>
    </nav>
  );
};
