import { Fragment, useState } from 'react';
import { Tab } from '@headlessui/react';

import DrewWEBM from '../../../assets/Features/VerticalAI/Drew.webm';
import DrewMP4 from '../../../assets/Features/VerticalAI/Drew.mp4';
import MigosWEBM from '../../../assets/Features/VerticalAI/Migos.webm';
import MigosMP4 from '../../../assets/Features/VerticalAI/Migos.mp4';
import SethWEBM from '../../../assets/Features/VerticalAI/Seth.webm';
import SethMP4 from '../../../assets/Features/VerticalAI/Seth.mp4';
import TAstyWEBM from '../../../assets/Features/VerticalAI/TAsty.webm';
import TAstyMP4 from '../../../assets/Features/VerticalAI/TAsty.mp4';
import SouljaWEBM from '../../../assets/Features/VerticalAI/Soulja.webm';
import SouljaMP4 from '../../../assets/Features/VerticalAI/Soulja.mp4';

import VerticalIcon from '../../../assets/Features/Icons/verticalIcon.svg';

const VerticalizationFeature = () => {
  const [currentFeature, setCurrentFeature] = useState<number>(0);

  // const _isCurrentFeatureTab = useCallback((tab) => currentFeature === tab, [currentFeature]);

  return (
    <section className="flex flex-col justify-center items-center relative lg:min-h-[860px] pt-24">
      <article className="flex items-center justify-center gap-3">
        <img src={VerticalIcon} alt="" />
        <p className="text-white font-ptsans text-3xl font-semibold py-2">Landscape to vertical AI</p>
      </article>
      <article className="md:w-[760px] pt-6">
        <p className="text-white flex text-center text-lg">
          How does it work? Just upload your video and get your vertical clip back without lifting a finger. You also
          have the possibility to add captions automatically.
        </p>
      </article>
      <Tab.Group>
        <Tab.Panels className={`text-white lg:min-h-[640px]`}>
          <article className="flex justify-between items-center w-96 md:w-[660px] lg:w-[1000px]">
            <p className="text-white text-sm md:text-xl font-semibold lg:pl-8">Original</p>
            <p className="text-white text-sm md:text-xl font-semibold pr-8 lg:pr-0">Big Room</p>
          </article>
          <Tab.Panel className={`transition-all animate-fadeIn`}>
            <video muted loop playsInline className={'rounded-lg w-96 md:w-[720px] lg:w-[1200px]'} autoPlay>
              <source src={DrewWEBM} type="video/webm" />
              <source src={DrewMP4} type="video/mp4" />
            </video>
          </Tab.Panel>

          <Tab.Panel className={`transition-all animate-fadeIn`}>
            <video muted loop playsInline className={'rounded-lg w-96 md:w-[720px] lg:w-[1200px]'} autoPlay>
              <source src={MigosWEBM} type="video/webm" />
              <source src={MigosMP4} type="video/mp4" />
            </video>
          </Tab.Panel>

          <Tab.Panel className={`transition-all animate-fadeIn`}>
            <video muted loop playsInline className={'rounded-lg w-96 md:w-[720px] lg:w-[1200px]'} autoPlay>
              <source src={SethWEBM} type="video/webm" />
              <source src={SethMP4} type="video/mp4" />
            </video>
          </Tab.Panel>

          <Tab.Panel className={`transition-all animate-fadeIn`}>
            <video muted loop playsInline className={'rounded-lg w-96 md:w-[720px] lg:w-[1200px]'} autoPlay>
              <source src={SouljaWEBM} type="video/webm" />
              <source src={SouljaMP4} type="video/mp4" />
            </video>
          </Tab.Panel>

          <Tab.Panel className={`transition-all animate-fadeIn`}>
            <video muted loop playsInline className={'rounded-lg w-96 md:w-[720px] lg:w-[1240px]'} autoPlay>
              <source src={TAstyWEBM} type="video/webm" />
              <source src={TAstyMP4} type="video/mp4" />
            </video>
          </Tab.Panel>
        </Tab.Panels>

        <Tab.List className={`flex overflow-auto w-96 md:w-[720px] justify-between items-center`}>
          <Tab as={Fragment}>
            {({ selected }) => (
              /* Use the `selected` state to conditionally style the selected tab. */
              <button
                onClick={() => setCurrentFeature(0)}
                className={`${
                  selected ? 'text-white font-semibold' : 'font-light'
                } text-white md:text-xl focus:ring-0 ring-transparent focus:outline-none outline-none text-sm`}
              >
                Podcast
                {selected && (
                  <div className="border border-blue-to-gradient h-1 w-16 mx-auto mt-1 rounded bg-gradient-to-r from-green-to-gradient to-blue-to-gradient hover:from-blue-to-gradient hover:to-green-to-gradient"></div>
                )}
              </button>
            )}
          </Tab>
          <Tab as={Fragment}>
            {({ selected }) => (
              /* Use the `selected` state to conditionally style the selected tab. */
              <button
                onClick={() => setCurrentFeature(1)}
                className={`${
                  selected ? 'text-white font-semibold' : 'font-light'
                } text-white md:text-xl focus:ring-0 ring-transparent focus:outline-none outline-none text-sm`}
              >
                Talk Shows
                {selected && (
                  <div className="border border-blue-to-gradient h-1 w-16 mx-auto mt-1 rounded bg-gradient-to-r from-green-to-gradient to-blue-to-gradient hover:from-blue-to-gradient hover:to-green-to-gradient"></div>
                )}
              </button>
            )}
          </Tab>
          <Tab as={Fragment}>
            {({ selected }) => (
              /* Use the `selected` state to conditionally style the selected tab. */
              <button
                onClick={() => setCurrentFeature(2)}
                className={`${
                  selected ? 'text-white font-semibold' : 'font-light'
                } text-white md:text-xl focus:ring-0 ring-transparent focus:outline-none outline-none text-sm`}
              >
                Stand Up
                {selected && (
                  <div className="border border-blue-to-gradient h-1 w-16 mx-auto mt-1 rounded bg-gradient-to-r from-green-to-gradient to-blue-to-gradient hover:from-blue-to-gradient hover:to-green-to-gradient"></div>
                )}
              </button>
            )}
          </Tab>
          <Tab as={Fragment}>
            {({ selected }) => (
              /* Use the `selected` state to conditionally style the selected tab. */
              <button
                onClick={() => setCurrentFeature(3)}
                className={`${
                  selected ? 'text-white font-semibold' : 'font-light'
                } text-white md:text-xl focus:ring-0 ring-transparent focus:outline-none outline-none text-sm`}
              >
                Skits
                {selected && (
                  <div className="border border-blue-to-gradient h-1 w-16 mx-auto mt-1 rounded bg-gradient-to-r from-green-to-gradient to-blue-to-gradient hover:from-blue-to-gradient hover:to-green-to-gradient"></div>
                )}
              </button>
            )}
          </Tab>
          <Tab as={Fragment}>
            {({ selected }) => (
              /* Use the `selected` state to conditionally style the selected tab. */
              <button
                onClick={() => setCurrentFeature(4)}
                className={`${
                  selected ? 'text-white font-semibold' : 'font-light'
                } text-white md:text-xl focus:ring-0 ring-transparent focus:outline-none outline-none text-sm`}
              >
                Cooking
                {selected && (
                  <div className="border border-blue-to-gradient h-1 w-16 mx-auto mt-1 rounded bg-gradient-to-r from-green-to-gradient to-blue-to-gradient hover:from-blue-to-gradient hover:to-green-to-gradient"></div>
                )}
              </button>
            )}
          </Tab>
          {/* ...  */}
        </Tab.List>
      </Tab.Group>
    </section>
  );
};

export default VerticalizationFeature;
