import React from "react";

// Zustand - Global state
import useAppStore from "../../../../store/useApp.store";

// Components
import Modal from "./Modal";
import OutsideClickDetector from "../../../Shared/outsideClickDetector/OutsideClickDetector";

// Icons
import { TfiClose } from "react-icons/tfi";
import { Transition } from "@headlessui/react";

import { useNavigate } from 'react-router-dom';


const ConfirmDeleteModal = () => {
	const navigate = useNavigate()
	const setCancelCaptionModal = useAppStore((state) => state.setCancelCaptionModal);
	const isCancelCaptionModalActive = useAppStore((state) => state.cancelCaptionModal);

	const _handleCloseCaptionModal = () => {
		setCancelCaptionModal(false);
	};

	const _handleDoneCaptionsEdition = () => {
		setCancelCaptionModal(false);
		navigate('/')
	}

	return (
		<Modal show={isCancelCaptionModalActive}>
			<Transition
				appear={true}
				show={isCancelCaptionModalActive}
				className="fixed left-0 transition-all duration-200 translate-x-[calc(50vw-50%)]"
				enterFrom="translate-y-[100vh]"
				enterTo="translate-y-[calc(50vh-50%)]"
				leaveFrom="translate-y-[calc(50vh-50%)]"
				leaveTo="translate-y-[100vh]"
			>
				<OutsideClickDetector callback={() => _handleCloseCaptionModal()}>
					<main className={`relative w-96`}>
						<div className="p-6 overflow-hidden bg-gray-100 border shadow-lg rounded-xl md:p-10 border-gray-50">
							<button
								onClick={_handleCloseCaptionModal}
								className={`absolute top-2 right-2 p-1 cursor-pointer`}
							>
								<TfiClose className="w-6" />
							</button>
							<section className="pt-2 text-center">
								<p className="text-sm font-medium">
									Are you sure you're done? Unless you export your video, your edits will be discarded.
								</p>
								<div className="flex items-center justify-between gap-3 md:gap-6 pt-6 flex-col md:flex-row">
									<button
										className={`border border-black px-6 py-3 text-sm font-semibold rounded-xl w-full md:w-44 order-1 md:order-0`}
										onClick={_handleCloseCaptionModal}
									>
										Cancel
									</button>
									<button
										className={`text-white bg-black px-6 py-3 text-sm font-semibold border rounded-xl w-full md:w-44 order-0 md:order-1`}
										onClick={_handleDoneCaptionsEdition}
									>
										Yes, I'm done
									</button>
								</div>
							</section>
						</div>
					</main>
				</OutsideClickDetector>
			</Transition>
		</Modal>
	);
};

export default ConfirmDeleteModal;
