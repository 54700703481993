import { Fragment, useMemo, useState, useEffect } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import useMulticameraStore from '../../../../../store/useMulticamera.store';

const VideoOptions = ({ file, selected }: any) => {
	const ThumbnailMulticamera = useMemo(() => {
    if (!file) return null;
    return (
      <div className='relative w-10 h-10 overflow-hidden rounded-xl flex items-center'>
        <video src={file && file?.url} className='absolute w-24 max-w-none' />
      </div>
    );
  },[file])
	
	return(
  <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'} flex items-center cursor-pointer`}>
    <div className="relative w-12 h-12 overflow-hidden rounded-xl flex items-center">
      {ThumbnailMulticamera}
    </div>
		<span className='font-medium truncate w-80'>
    {file.name}
		</span>
  </span>
)};


const SelectAudioFromFile = () => {
  const currentProject = useMulticameraStore((state) => state.currentProject)
  const setMasterAudioFile = useMulticameraStore((state) => state.setMasterAudioFile);
  const [selected, setSelected] = useState<any>();

  useEffect(() => {
    if(selected){
      setMasterAudioFile(currentProject.uid, selected.uid)
      return
    }
  },[currentProject.uid, selected, setMasterAudioFile])

  return (
    <div className={`${selected ? 'mt-8' : 'mt-4'} px-5`}>
      <Listbox value={selected} onChange={setSelected}>
        <div className="relative mt-1 border border-black rounded-xl">
          <Listbox.Button className="relative w-full cursor-default rounded-xl h-10 bg-white py-2 pl-3 pr-10 text-left sm:text-sm">
            <span className="block truncate">
              {selected?.name ? selected?.name : 'Select master audio from an uploaded video'}
            </span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronDownIcon className="h-6 w-6 text-gray-900" aria-hidden="true" />
            </span>
          </Listbox.Button>
          <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Listbox.Options className="absolute bottom-14 mt-3 max-h-72 w-full overflow-auto rounded-xl bg-white border border-gray-400 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {currentProject?.files.map((file:any, fileIdx:number) => (
                <Listbox.Option
                  key={fileIdx}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-4 pr-4 ${
                      active ? 'bg-blue-to-gradient/50 rounded-xl' : 'text-gray-900'
                    }`
                  }
                  value={file}
                >
                  {({ selected }) => (
                    <>
										<VideoOptions selected={selected} file={file} />
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
};

export default SelectAudioFromFile;
