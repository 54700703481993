import { useEffect, useMemo } from 'react';

// Libraries
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// Zustand Global State
import useUserStore from '../../../store/useUser.store';

// Form Schema
import { resetPasswordSchema } from '../schemas/authSchemas';
import useAppStore from '../../../store/useApp.store';

// UI components
import FormHeader from './FormHeader';
import Input from './Input';
import Button from './Button';

const ResetPassword = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('code');
  const email = searchParams.get('email');
  const navigate = useNavigate();
  const doReset = useUserStore((state) => state.resetPassword);

  const isLoading = useAppStore((state) => state.isResetPasswordLoading);
  const resetPasswordError = useUserStore((state) => state.resetPasswordError);

  const {
    register,
    formState: { errors, isDirty, isValid },
    handleSubmit,
    watch,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(resetPasswordSchema),
  });

  useEffect(() => {
    if (!token) {
      navigate('/');
    }
  }, [navigate, token]);

  const onSubmit = async (resetData: any) => {
    await doReset({ ...resetData, code: token });
  };

  const pass = watch('password');
  const confirm = watch('passwordConfirmation');
  const validateMatchPassword = useMemo(() => (confirm?.length ? pass === confirm : true), [confirm, pass]);

  return (
    <div className="flex flex-col h-screen px-4 mx-auto align-middle sm:px-0 md:w-2/5">
      <FormHeader title="Reset your password" subtitle={`Create a new password`} showGoogle={false} />

      <form onSubmit={handleSubmit(onSubmit)} className="grid grid-cols-6 mt-2 gap-y-1">
        <div className="col-span-6 ">
          <Input
            label="New password"
            type="password"
            inputName="password"
            errors={errors}
            validation={register('password')}
          />
        </div>

        <div className="col-span-6">
          <Input
            label="Re-enter new password"
            type="password"
            inputName="passwordConfirmation"
            errors={errors ?? resetPasswordError}
            validation={register('passwordConfirmation')}
            arePasswordMatch={validateMatchPassword}
          />
        </div>

        {resetPasswordError && <p className="col-span-6 mb-4 text-xs text-red-400">{resetPasswordError}</p>}

        <div className="col-span-6 mt-0 sm:-mt-3 sm:flex sm:items-center sm:gap-2">
          <Button
            disabled={!isDirty || !isValid || isLoading}
            type="submit"
            isLoading={isLoading}
            text="Save new password"
          />
        </div>
      </form>
    </div>
  );
};

export default ResetPassword;
