/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from 'react';

// Icons
import { TfiClose } from 'react-icons/tfi';
import checkUpdate from '../../../../../assets/icon/checkUpdate.svg';
import greenAudioIcon from '../../../../../assets/icon/greenAudioIcon.svg';

// Components
import ProgressBar from '../../shared/ProgressBar';

// Hooks
import { useMultifile } from '../hooks/useMultifile';

// Zustand - Global state
import useMulticameraStore from '../../../../../store/useMulticamera.store';

import {
  minimumResolution,
  videoDurationLimit,
  maximunSizeAllowed,
  durationErrorMsg,
  widthErrorMsg,
  sizeErrorMsg,
  resolutionErrorMsg,
} from '../../../utils/fileValidation';

// Utils
import { truncateVideoNames } from '../../../../../utils/truncateLargeName';

import { submitMedia, uploadMediaWithoutToken } from '../../../../../services/multicamera.service';
import { bytesToMegabytes } from '../../../utils/math.utils';

const MulticameraFile = ({
  fileName,
  hasConnectionError,
  // removeFile,
  file,
  cancelUpload,
  isAudio,
  signal,
}: {
  fileName?: string;
  hasConnectionError: boolean;
  cancelUpload?: () => void;
  file: File;
  isAudio: boolean;
  signal: any;
}) => {
  // const user = useUserStore((state) => state.user);
  // const setHasVideo = useUserStore((state) => state.setHasVideo);
  const currentProject = useMulticameraStore((state) => state.currentProject);
  const [submitResponse, setSubmitResponse] = useState<any>(null);

  // const [controller, setController] = useState(new AbortController());
  const [progress, setProgress] = useState(0);
  const [isSuccess, setUploadDone] = useState(false);
  const setMasterAudioFile = useMulticameraStore((state) => state.setMasterAudioFile);
  const setIsAudioUploaded = useMulticameraStore((state) => state.setIsAudioUploaded);
  const getProject = useMulticameraStore((state) => state.getProject);
  const getProjects = useMulticameraStore((state) => state.getProjects);
  const videosUploaded = useMulticameraStore((state) => state.videosUploaded);
  const setVideosUploaded = useMulticameraStore((state) => state.setVideosUploaded);
  const files = useMulticameraStore((state) => state.files);
  const removeFile = useMulticameraStore((state) => state.removeFile);
  const [noErrorFile, setNotErrorFile] = useState<any>();
  const setIsJustUploadAudio = useMulticameraStore((state) => state.setIsJustUploadAudio);

  // file state validators
  const [isWidthInvalid, setIsWidthInvalid] = useState(false);
  const [isResolutionInvalid, setIsResolutionInvalid] = useState(false);
  const [isDurationInvalid, setIsDurationInvalid] = useState(false);
  // const [videoDuration, setVideoDuration] = useState<string>();

  const fileSize = useMemo(() => (file ? bytesToMegabytes(file.size) : null), [file]);
  const isSizeInvalid = useMemo(() => fileSize && fileSize > maximunSizeAllowed, [fileSize, file]);
  const hasError = isWidthInvalid || isResolutionInvalid || isDurationInvalid || isSizeInvalid;

  const fileValidator = (file: File) => {
    setFile(undefined);
    const video = document.createElement('video');
    video.src = URL.createObjectURL(file);
    video.preload = 'metadata';
    video.onloadedmetadata = function (e) {
      handleVideo(e, file);
    };
    return null;
  };

  const handleVideo = (e: any, file: File) => {
    setIsWidthInvalid(false);
    setIsResolutionInvalid(false);
    setIsDurationInvalid(false);

    const { target } = e;
    const durationValidation = target?.duration > videoDurationLimit * 60;
    const widthValidation = target?.videoWidth < target?.videoHeight;
    const resolutionValidation = target?.videoHeight < minimumResolution;

    widthValidation && setIsWidthInvalid(true);
    durationValidation && setIsDurationInvalid(true);
    resolutionValidation && setIsResolutionInvalid(true);
    // getVideoDurationFormatted(target.duration);

    if (!widthValidation && !durationValidation && !resolutionValidation) {
      setNotErrorFile(() => file);
    }
    setFile(file);
  };

  const FileErrors = useMemo(
    () => (
      <section className="text-xs text-error-message">
        <p>{isWidthInvalid && widthErrorMsg}</p>
        <p>{isDurationInvalid && durationErrorMsg}</p>
        <p>{isResolutionInvalid && resolutionErrorMsg}</p>
        <p>{isSizeInvalid && sizeErrorMsg}</p>
      </section>
    ),
    [hasError]
  );

  // TODO: should be applied in local videoLoaded?
  interface UploadDataProgress {
    loaded: number;
    total: number;
  }

  const options = {
    onUploadProgress: (data: UploadDataProgress) => {
      let dataLoaded = Math.round((100 * data.loaded) / data.total);
      //Set the progress value to show the progress bar
      setProgress(dataLoaded);
    },
    signal: signal.signal,
  };

  const {
    // Properties
    canBeUploaded,
    videoDuration,
    // Methods
    setFile,
    setAudio,
  } = useMultifile();

  useEffect(() => {
    if(isAudio) {
      setAudio(file)
      return
    }
    fileValidator(file);
  }, [file, setFile, setAudio]);

  const submitFile = async () => {
      const response = await submitMedia({
        job_uid: currentProject.uid,
        name: isAudio ? file.name : noErrorFile.name,
        type: isAudio ? 'audio' : 'video',
      });
      if (response?.uid) {
        setSubmitResponse(response);
        return
      }
  };


  useEffect(() => {
    if(isAudio && !submitResponse?.presigned_url) {
      submitFile();
      return;
    }
    if ((!submitResponse?.presigned_url && noErrorFile)) {
      submitFile();
      return;
    }
    if (submitResponse?.presigned_url ) {
      uploadMediaWithS3();
    }
  }, [submitResponse?.presigned_url, noErrorFile, isAudio]);

  const uploadMediaWithS3 = useCallback(() => {
    if (submitResponse?.presigned_url) {
      uploadMediaWithoutToken(submitResponse?.presigned_url, file, options, setUploadDone, submitResponse?.uid);
    }
  }, [submitResponse?.presigned_url]);

  useEffect(() => {
    if (isSuccess) {
      setIsJustUploadAudio(false)
      setVideosUploaded(videosUploaded + 1);
      getProject(currentProject.uid);
      getProjects()
    }
    if (isAudio && isSuccess) {
      setMasterAudioFile(currentProject.uid, submitResponse.uid);
      setIsAudioUploaded(isSuccess);
      return;
    }
  }, [isSuccess]);

  const getVideoSrc = (file: File) => {
    const videoUrl = URL.createObjectURL(file);
    return `${videoUrl}#t=2.8`;
  };

  const ThumbnailMulticamera = useMemo(() => {
    if (!file) return null;
    return (
      <div className="relative w-12 h-12 overflow-hidden rounded-xl">
        <video src={file && getVideoSrc(file)} className="absolute w-24 max-w-none" />
      </div>
    );
  }, [file]);

  const _removeFile = () => {
    removeFile(file);
    if(files.length === 1) {
      cancelUpload && cancelUpload() 
    }
  };

  return (
    <>
      <section
        className={`${
          isAudio ? 'bg-blue-to-gradient h-14 px-4 py-6 md:py-6' : 'h-12 py-6 md:py-6'
        } relative w-full flex justify-between mt-4 items-center ${
          (!isAudio ? hasError || hasConnectionError : null) && 'w-full h-16 opacity-90 bg-slate-200'
        } gap-2 md:gap-3 overflow-hidden px-3`}
      >
        <article className={`z-50 flex items-center ${hasError && 'grayscale'}`}>
          {isAudio ? <img src={greenAudioIcon} className="w-8 text-black" alt="" /> : ThumbnailMulticamera}
          <div className={`ml-2 ${isSuccess ? 'object-fill' : 'w-28 md:w-32'}`}>
            <div className={`${isSuccess ? 'flex w-full items-center gap-2' : 'w-28 md:w-32'}`}>
              <p
                className={`${
                  isSuccess ? 'text-xs md:text-sm font-medium text-gray-800' : 'text-xs md:text-bg-sm font-medium'
                }`}
              >
                {truncateVideoNames(fileName || '')}
              </p>
              <p className={`${isSuccess ? 'text-xs font-medium text-gray-800' : 'text-bg-xs text-gray-700'}`}>
                {!isAudio && videoDuration}
              </p>
            </div>
          </div>
        </article>

        {canBeUploaded && !isSuccess && !hasError ? (
          <div className="w-60">
            <ProgressBar progress={progress || 0} />
          </div>
        ) : null}
        {hasError && FileErrors}

        {!isSuccess ? (
          <button onClick={!hasError ? cancelUpload : _removeFile} className={`cursor-pointer z-50`}>
            <TfiClose className="w-4" />
          </button>
        ) : (
          <button className={`z-50`}>
            <img src={checkUpdate} className="w-4" alt="check update" />
          </button>
        )}
      </section>
    </>
  );
};

export default MulticameraFile;
