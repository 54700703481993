import {
  Disclosure as HeadlessDisclosure,
  Transition,
} from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/20/solid";

export default function Disclosure({ title, children, className, error }) {
  return (
    <div className={"border rounded-lg border-gray-500 " + className}>
      <HeadlessDisclosure>
        {({ open }) => (
          <>
            <HeadlessDisclosure.Button className='flex items-center justify-between w-full h-12 px-4'>
              <span
                className={`${error ? "text-red-500" : ""} ${
                  open ? "font-semibold" : ""
                }`}
              >
                {title}
              </span>
              <ChevronUpIcon
                className={`transition-all duration-500 ${
                  open ? "rotate-180  transform" : ""
                } h-5 w-5 text-gray-500`}
              />
            </HeadlessDisclosure.Button>
            <Transition
              show={open}
              className='transition-all duration-500 ease-in-out'
              enterFrom='max-h-0 opacity-0 overflow-hidden'
              enterTo='max-h-96 opacity-100 overflow-hidden'
              leaveFrom='max-h-96 opacity-100 overflow-hidden'
              leaveTo='max-h-0 opacity-0 overflow-hidden'
            >
              <HeadlessDisclosure.Panel className='px-4 pb-4 text-sm'>
                {children}
              </HeadlessDisclosure.Panel>
            </Transition>
          </>
        )}
      </HeadlessDisclosure>
    </div>
  );
}
