// Libraries
import { Oval } from 'react-loader-spinner';

const Button = ({
  disabled,
  type,
  text,
  isLoading,
}: {
  disabled: boolean;
  type: 'button' | 'submit' | 'reset' | undefined;
  text: string;
  isLoading: boolean;
}) => {
  return (
    <button
      disabled={disabled}
      type={type}
      className="flex items-center justify-center w-full px-12 py-3 mt-4 text-sm font-medium text-white transition bg-gray-800 cursor-pointer shrink-0 rounded-xl disabled:bg-gray-400 disabled:border-none disabled:hover:text-white hover:bg-gray-700 focus:outline-none focus:ring active:text-blue-500 disabled:cursor-default"
    >
      {isLoading ? (
        <Oval
          height={20}
          width={20}
          color="white"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
          ariaLabel="oval-loading"
          secondaryColor="white"
          strokeWidth={8}
          strokeWidthSecondary={8}
        />
      ) : (
        text
      )}
    </button>
  );
};

export default Button;
