import React from "react";

// Zustand - Global state
import useAppStore from "../../../../store/useApp.store";
// Components
import Modal from "./Modal";
import OutsideClickDetector from "../../../Shared/outsideClickDetector/OutsideClickDetector";

//Illustrations
import generatingCaptionIllustration from "../../../../assets/image/generatingCaptionIllustration.svg"

// Icons
import { TfiClose } from "react-icons/tfi";
import { Transition } from "@headlessui/react";

import { useNavigate } from 'react-router-dom';

import { Comment } from "react-loader-spinner";


const ConfirmDeleteModal = () => {
	const navigate = useNavigate()

	const setGeneratingCaptionModal = useAppStore((state) => state.setGeneratingCaptionModal);
	const isGeneratingCaptionModalActive = useAppStore((state) => state.generatingCaptionModal);


	return (
		<Modal show={isGeneratingCaptionModalActive}>
			<Transition
				appear={true}
				show={isGeneratingCaptionModalActive}
				className="fixed left-0 transition-all duration-200 translate-x-[calc(50vw-50%)]"
				enterFrom="translate-y-[100vh]"
				enterTo="translate-y-[calc(50vh-50%)]"
				leaveFrom="translate-y-[calc(50vh-50%)]"
				leaveTo="translate-y-[100vh]"
			>
				<OutsideClickDetector callback={() => setGeneratingCaptionModal(false)}>
					<main className={`relative w-96 md:w-96 md:min-w-[600px]`}>
						<div className="p-6 overflow-hidden bg-gray-100 border shadow-lg rounded-xl md:p-10 border-gray-50 flex flex-col items-center justify-center">

							<section className="h-20 mt-4">
								<img src={generatingCaptionIllustration} alt="" />
							</section>
							<section className="pt-4 text-center mb-4">
								<p className="text-lg font-bold flex">
									<span>
										Generating captions
									</span>
									<Comment
										visible={true}
										height="30"
										width="30"
										ariaLabel="comment-loading"
										wrapperStyle={{ padding: 0, margin: '4px 0 0 -4px' }}
										wrapperClass="comment-wrapper"
										color="black"
										backgroundColor="transparent"
									/>
								</p>

							</section>
						</div>
					</main>
				</OutsideClickDetector>
			</Transition>
		</Modal>
	);
};

export default ConfirmDeleteModal;
