import React, { useMemo, useState } from 'react';
import placeHolderImage from '../../../../../assets/image/multicameraTopRightIllustration.svg';
import useMulticameraStore from '../../../../../store/useMulticamera.store';

import OutsideClickDetector from '../../../../Shared/outsideClickDetector/OutsideClickDetector';

// Icons
import { HiOutlineDotsVertical } from 'react-icons/hi';

const VideoCard = ({ file }: any) => {
  const removeMedia = useMulticameraStore((state) => state.removeMedia);
  const setMasterAudioFile = useMulticameraStore((state) => state.setMasterAudioFile);
  const currentProject = useMulticameraStore((state) => state.currentProject);
  const isMasterAudioFile = currentProject?.master_audio_file === file.uid;
  const [showMenu, setShowMenu] = useState<boolean>(false);

  const getVideoSrc = useMemo(() => {
    return `${file?.url}#t=1.5`;
  }, [file]);

  return (
    <section className="rounded-xl max-w-[300px] mx-auto">
      <div className="relative">
        {file?.thumbnail_url ? (
          <img
            src={file?.thumbnail_url ?? placeHolderImage}
            alt=""
            className="rounded-2xl min-w-[200px] w-full h-44 border object-fill aspect-video"
          />
        ) : (
          <video
            src={getVideoSrc}
            className="rounded-2xl min-w-[200px] w-full h-44 border object-fill aspect-video"
          ></video>
        )}
        {!currentProject.started && (
          <>
            <HiOutlineDotsVertical
              onClick={() => setShowMenu(!showMenu)}
              className="absolute top-4 w-6 h-5 right-1 cursor-pointer"
            />
            {showMenu ? (
              <OutsideClickDetector callback={() => setShowMenu(!showMenu)}>
                <div className="absolute z-20 flex flex-col items-center w-[220px] gap-2 p-2 font-medium transition-all bg-gray-50 border border-gray-300 rounded-xl fadeIn left-40 top-10 text-bg-sm">
                  <div
                    onClick={() => {
                      setMasterAudioFile(currentProject.uid, file.uid);
                      setTimeout(() => setShowMenu(false), 200);
                    }}
                    className="flex items-center w-full p-2 rounded-lg hover:bg-gray-100 cursor-pointer"
                  >
                    Select as master audio
                  </div>
                  {!currentProject?.started && (
                    <div
                      onClick={() => removeMedia(file?.uid)}
                      className="flex items-center w-full rounded-lg hover:bg-gray-100 p-2 cursor-pointer"
                    >
                      Delete video
                    </div>
                  )}
                </div>
              </OutsideClickDetector>
            ) : null}
          </>
        )}

        {isMasterAudioFile && (
          <span className="absolute top-3 left-3 text-gray-900 bg-blue-to-gradient font-medium cursor-pointer rounded-full py-1 px-2 text-xs">
            Master audio
          </span>
        )}
      </div>

      <div className="text-xs truncate w-48">
        <p className="text-sm pt-0 font-semibold truncate">{file.name}</p>
      </div>
    </section>
  );
};

export default VideoCard;
