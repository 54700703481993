import React from 'react';

import { Disclosure, Transition } from '@headlessui/react';
import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai';

const FAQContent = [
  {
    question: `How much does this cost?`,
    answer: `While we are growing, Big Room is free to use. We'll be rolling out paid tiers with new features and tools soon, but we aim to always have a free version of our editor available to all creators!`,
  },
  {
    question: `Does Big Room only create 9:16 video edits?`,
    answer: `Yes, Big Room currently uses AI to create cinematic 9:16 format (vertical) edits from 16:9 format (horizontal) video. `,
  },
  {
    question: `Will I still be able to retain full rights to my content when I upload?`,
    answer: `Big Room is a creator-driven company - we care deeply about the privacy and security of your content!
Creators retain the rights to their original videos when they are uploaded to Big Room's AI editor as well as the version edited by Big Room's AI. If Big Room is interested in using your edited video for marketing purposes, we'll reach out!
Full terms are laid out in Big Room's EULA.`,
  },
  {
    question: `Is there a size limit for uploading videos?`,
    answer: `Yes, currently we limit videos to 30 minutes long (after being trimmed down) and 3.2 GB max when uploaded locally, but we are working to support larger files very soon.`,
  },
  {
    question: `Do you have a mobile app?`,
    answer: `Not yet! Big Room is a web-based AI editor. No need to download an app, just open a browser on your computer, phone or tablet to log in and access our editing tools!`,
  },
  {
    question: `What is Big Room’s secret sauce?`,
    answer: `Obviously, we can't give away Big Room's 'special sauce' in the FAQ's section of our website, but we can say that unlike other AI editors on the scene, Big Room's AI gives you a cinematic edit using intelligent tracking, framing and subject detection. 
If you have a specific question about the edit output on one of your videos, reach out to our team at support@bigroom.tv!`,
  },
];

const FAQ = () => {
  return (
    <section className="bg-black flex flex-col justify-center items-center w-full p-10 pb-20 h-full">
      <article>
        <h3 className="text-white text-2xl font-medium mt-12">FAQ</h3>
      </article>

      <div className="w-full px-4 pt-2">
        <div className="mx-auto w-full max-w-2xl rounded-2xl p-2">
					{FAQContent.map((faq) => (
          <Disclosure key={faq.question}>
            {({ open }) => (
              <div className="border-2 border-green-300 bg-green-to-gradient/10 mb-4 rounded-xl">
                <Disclosure.Button className="flex w-full justify-between rounded-xl px-4 py-2 text-left text-sm font-semibold text-white hover:bg-green-to-gradient/10">
                  <span>{faq.question}</span>
                  {open ? (
                    <AiOutlineMinus className="h-5 w-5 text-gray-200" />
                  ) : (
                    <AiOutlinePlus className="h-5 w-5 text-gray-200" />
                  )}
                </Disclosure.Button>

                <Transition
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                >
                  <Disclosure.Panel className="px-4 pt-1 text-sm text-white pb-4">
                    {faq.answer}
                  </Disclosure.Panel>
                </Transition>
              </div>
            )}
          </Disclosure>
					))}
        </div>
      </div>
    </section>
  );
};

export default FAQ;
