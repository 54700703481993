import React from 'react';

import { TfiClose } from 'react-icons/tfi';
import check from '../../../../assets/icon/onboarding/check.png';
import errorIcon from '../../../../assets/icon/onboarding/error.png';
import { featureList, futureFeaturesList } from '../../utils/features';
import { FeatureListIcon, FeaturesList } from './FeatureList';
import Modal from './Modal';

// Zustand - global state
import useAppStore from '../../../../store/useApp.store';

const FeaturesMobile = () => {
  const featuresModal = useAppStore((state) => state.featuresModal);
  const setFeaturesModal = useAppStore((state) => state.setFeaturesModal);

  return (
    <Modal show={featuresModal}>
      <main className="relative px-3 m-auto w-96">
        <div className="rounded-xl border p-2.5 px-3 overflow-hidden border-gray-50 shadow-lg bg-gray-100 h-download-modal-md mt-16">
          <button onClick={() => setFeaturesModal(false)} className={`absolute top-2 right-4 p-1 cursor-pointer z-50`}>
            <TfiClose className="w-6" />
          </button>

          <section className="">
            <div className="relative flex p-4 border-b-2 border-gray-300">
              <article className="">
                <p className="flex items-center gap-2 text-sm font-semibold">
                  <FeatureListIcon icon={check} />
                  What works best
                </p>
                <FeaturesList list={featureList} />
              </article>
            </div>

            <div className="flex p-4">
              <article className="">
                <p className="flex items-center gap-2 text-sm font-semibold">
                  <FeatureListIcon icon={errorIcon} />
                  What Doesn’t (…Yet!)
                </p>
                <FeaturesList list={futureFeaturesList} />
              </article>
            </div>
          </section>
        </div>
      </main>
    </Modal>
  );
};

export default FeaturesMobile;
