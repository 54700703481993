import { getToken } from '../utils/setToken';

export async function SS_GetProductPaymentDetails(checkoutSessionId: string,stripePlanId:string) {
  const baseUrl = localStorage.getItem('strapiStripeUrl');
  const retrieveCheckoutSessionUrl = baseUrl + '/strapi-stripe/retrieveCheckoutSession/' + checkoutSessionId;


  return fetch(retrieveCheckoutSessionUrl, {
    method: 'get',
    mode: 'cors',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
  })
    .then((response) => response.json())
    .then((response) => {
      console.log('SS_GetProductPaymentDetails response',response)
      if (response.payment_status === 'paid') {
        const stripePaymentUrl = baseUrl + '/api/subscription';
        response.stripePlanId = stripePlanId
        
        //let body = JSON.stringify(response)
        let body = JSON.stringify({'sessionId':checkoutSessionId})
        console.log('SS_GetProductPaymentDetails body',body)
        let token = getToken()
        console.log('SS_GetProductPaymentDetails token',token)

        fetch(stripePaymentUrl, {
          method: 'post',
          body: body,
          mode: 'cors',
          headers: new Headers({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${ token ?? ''}`
          }),
        });
      
        /*
        if (
          window.performance
            .getEntriesByType('navigation')
            .map((nav: any) => nav.type)
            .includes('reload')
        ) {
          console.info('website reloded');
        } else {
          // store payment in strapi
          const stripePaymentUrl = baseUrl + '/subscription';
          fetch(stripePaymentUrl, {
            method: 'post',
            body: JSON.stringify(response),
            mode: 'cors',
            headers: new Headers({
              'Content-Type': 'application/json',
            }),
          });
          //const stripePaymentUrl = baseUrl + '/strapi-stripe/stripePayment';
          /*
          fetch(stripePaymentUrl, {
            method: 'post',
            body: JSON.stringify({
              txnDate: new Date(),
              transactionId: response.id,
              isTxnSuccessful: true,
              txnMessage: response,
              txnAmount: response.amount_total / 100,
              customerName: response.customer_details.name,
              customerEmail: response.customer_details.email,
              stripeProduct: response.metadata.productId,
            }),
            mode: 'cors',
            headers: new Headers({
              'Content-Type': 'application/json',
            }),
          });
          */
      }
    });
}

export function SS_ProductCheckout(stripePlanId: string, baseUrl: string, userEmail: string) {
  localStorage.setItem('strapiStripeUrl', baseUrl);
  const getProductApi = baseUrl + '/strapi-stripe/getProduct/' + stripePlanId;
  const checkoutSessionUrl = baseUrl + '/strapi-stripe/createCheckoutSession/';

  console.log('SS_ProductCheckout stripePlanId',stripePlanId)
  fetch(checkoutSessionUrl, {
    method: 'post',
    body: JSON.stringify({
      stripePriceId: '',
      stripePlanId: stripePlanId, //response.stripePlanId,
      isSubscription: true,
      //productId: response.id,
      //productName: response.title,
      userEmail,
    }),
    mode: 'cors',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
  })
    .then((response) => response.json())
    .then((response) => {
      console.log('response from post',response)
      if (response.id) {
        window.location.replace(response.url);
      }
    });

    /*
  fetch(getProductApi, {
    method: 'get',
    mode: 'cors',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
  })
    .then((response) => response.json())
    .then((response) => {
      console.log('SS_ProductCheckout response',response)
      fetch(checkoutSessionUrl, {
        method: 'post',
        body: JSON.stringify({
          stripePriceId: response.stripePriceId,
          stripePlanId: 'plan_NmLreXP2bVIbt2', //response.stripePlanId,
          isSubscription: response.isSubscription,
          productId: response.id,
          productName: response.title,
          userEmail,
        }),
        mode: 'cors',
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          console.log('response from post',response)
          if (response.id) {
            window.location.replace(response.url);
          }
        });
    });
    */
}
