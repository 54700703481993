import React from 'react';

// Icons
import { TfiClose } from 'react-icons/tfi';
import Disclosure from '../Disclosure';

const feedbackOptions = [
  'Onboarding / understanding how to use Big Room',
  'How the video was edited',
  'Getting the edited video back',
  'Video production speed',
  'Using the logged-in website',
  'Signing up / logging in',
  'Something else',
  'None of the above',
];

const bigroomForOptions = ['Creating clips for social media', "I'm just testing it", 'Something else'];

const bigroomFrequencyOptions = [
  'Multiple times a day',
  'Daily',
  'Weekly',
  'Monthly',
  "I won't be using Big Room again",
];

const videoFrequencyOptions = ['Multiple times a day', 'Daily', 'Weekly', 'Monthly', 'Once in a while', 'Almost never'];

const verticalRatioOptions = ['0%', 'Less than 25%', '25% to 50%', '50% to 75%', '75% to 100%', '100%'];

const payingOptions = [
  'Nothing',
  '$5 per month',
  '$10 per month',
  '$20 per month',
  '$50 per month',
  '$100 per month',
  'Other',
];

const SurveyPopUp = ({ close, nextStep, formRegister, errors }) => {
  const [otherPrice, setOtherPrice] = React.useState(false);

  return (
    <main className={`relative max-w-96 w-80 md:w-[700px] border shadow-lg rounded-xl overflow-hidden border-gray-50`}>
      <div className="p-5 overflow-hidden bg-gray-100">
        <div className="absolute left-0 right-0 flex justify-between w-full px-5 pt-5 top-2">
          <h2 className="text-sm font-bold md:text-lg top-2">Remove Watermarks by filling out this Survey</h2>
          <button onClick={close} className={`cursor-pointer`}>
            <TfiClose className="w-6" />
          </button>
        </div>
        <section
          id="survey"
          className="mt-16 text-xs md:text-sm text-left max-h-[70vh] pr-2 md:pr-4 mr-1 overflow-y-scroll"
        >
          <h3 className={`font-semibold ${errors.rating ? 'text-red-500' : ''}`}>
            How likely are you to recommend Big Room to others?
          </h3>
          <ul className="grid justify-between w-full grid-cols-5 mt-4 md:grid-cols-10 gap-y-4">
            {[...Array(10)].map((_, i) => (
              <li className="flex flex-col items-center font-semibold" key={i + 1}>
                <input
                  type="radio"
                  value={i + 1}
                  {...formRegister('rating', { required: true })}
                  className="w-6 h-6 mb-2 border border-gray-700 cursor-pointer ring-0"
                />
                {i + 1}
              </li>
            ))}
          </ul>
          <Disclosure
            title="If your rating was less than 10, what changes would make it a 10?"
            className={`mt-4`}
            error={errors.rason_for_rating}
          >
            <input
              placeholder="Your suggestions here"
              className={`w-full h-12 p-2 mt-4 border border-gray-400 rounded-lg ${
                errors.rason_for_rating ? 'border border-red-500' : 'border-gray-500'
              }`}
              {...formRegister('rason_for_rating')}
            />
          </Disclosure>
          <Disclosure
            title="What are you using Big Room videos for? (Choose 1 or more)"
            className={`mt-4`}
            error={errors.using_bigroom_for}
          >
            <ul className="mt-4">
              {bigroomForOptions.map((option) => (
                <li className="mt-4 text-xs md:text-sm" key={option}>
                  <input
                    type="checkbox"
                    value={option}
                    {...formRegister('using_bigroom_for', { required: true })}
                    className="w-5 h-5 mr-2 border border-gray-700 rounded cursor-pointer ring-0"
                  />
                  {option}
                </li>
              ))}
            </ul>
          </Disclosure>
          <Disclosure
            title="Are you posting Big Room videos to social and/or elsewhere?"
            className={`mt-4`}
            error={errors.social_posting}
          >
            <ul className="mt-4">
              <li className="mt-4 text-xs md:text-sm">
                <input
                  type="radio"
                  value={true}
                  {...formRegister('social_posting', { required: true })}
                  className="w-5 h-5 mr-2 border border-gray-700 cursor-pointer ring-0"
                />
                Yes
              </li>
              <li className="mt-4 text-xs md:text-sm">
                <input
                  type="radio"
                  value={false}
                  {...formRegister('social_posting', { required: true })}
                  className="w-5 h-5 mr-2 border border-gray-700 cursor-pointer ring-0"
                />
                No
              </li>
            </ul>
          </Disclosure>
          <Disclosure
            title="If you plan to use Big Room again, how often do you think you will use it?"
            className={`mt-4`}
            error={errors.bigroom_frequency}
          >
            <ul className="mt-4">
              {bigroomFrequencyOptions.map((option) => (
                <li className="mt-4 text-xs md:text-sm" key={option}>
                  <input
                    type="radio"
                    value={option}
                    {...formRegister('bigroom_frequency', { required: true })}
                    className="w-5 h-5 mr-2 border border-gray-700 cursor-pointer ring-0"
                  />
                  {option}
                </li>
              ))}
            </ul>
          </Disclosure>
          <Disclosure title="How often do you create new videos?" className={`mt-4`} error={errors.video_frequency}>
            <ul className="mt-4">
              {videoFrequencyOptions.map((option) => (
                <li className="mt-4 text-xs md:text-sm" key={option}>
                  <input
                    type="radio"
                    value={option}
                    {...formRegister('video_frequency', { required: true })}
                    className="w-5 h-5 mr-2 border border-gray-700 cursor-pointer ring-0"
                  />
                  {option}
                </li>
              ))}
            </ul>
          </Disclosure>
          <Disclosure
            title="What percentage of videos that you create need to be converted to vertical?"
            className={`mt-4`}
            error={errors.video_bigroom_ratio}
          >
            <ul className="mt-4">
              {verticalRatioOptions.map((option) => (
                <li className="mt-4 text-xs md:text-sm" key={option}>
                  <input
                    type="radio"
                    value={option}
                    {...formRegister('video_bigroom_ratio', { required: true })}
                    className="w-5 h-5 mr-2 border border-gray-700 cursor-pointer ring-0"
                  />
                  {option}
                </li>
              ))}
            </ul>
          </Disclosure>
          <Disclosure
            title="What would you be willing to pay to use Big Room?"
            className={`mt-4`}
            error={errors.willing_to_pay || (errors.willing_to_pay_other && otherPrice)}
          >
            <>
              <ul className="mt-4">
                {payingOptions.map((option) => (
                  <li className="mt-4 text-xs md:text-sm" key={option}>
                    <input
                      type="radio"
                      value={option}
                      {...formRegister('willing_to_pay', { required: true })}
                      onChange={(e) => {
                        formRegister('willing_to_pay').onChange(e);
                        if (e.target.value === 'Other') {
                          setOtherPrice(true);
                        } else {
                          setOtherPrice(false);
                        }
                      }}
                      className="w-5 h-5 mr-2 border border-gray-700 cursor-pointer ring-0"
                    />
                    {option}
                  </li>
                ))}
              </ul>
              <input
                type="text"
                placeholder="Other"
                {...formRegister('willing_to_pay_other')}
                disabled={!otherPrice}
                className={`w-32 p-2 mt-4 border border-gray-400 rounded-lg ${otherPrice ? '' : 'bg-gray-200'} ${
                  errors.willing_to_pay_other && otherPrice ? 'border-red-500' : ''
                }`}
              />
            </>
          </Disclosure>
          <Disclosure
            title="Did you experience issues with any of the following while using Big Room?"
            className={`mt-4`}
            error={errors.expirienced_issues}
          >
            <ul className="mt-4">
              {feedbackOptions.map((option) => (
                <li className="mt-4 text-xs md:text-sm" key={option}>
                  <input
                    type="checkbox"
                    value={option}
                    {...formRegister('expirienced_issues', { required: true })}
                    className="w-5 h-5 mr-2 border border-gray-700 rounded cursor-pointer ring-0"
                  />
                  {option}
                </li>
              ))}
            </ul>
          </Disclosure>
          <h3 className={`mt-4`}>Please link the URL of your main social handle</h3>
          <input
            placeholder="www.tiktok.com/@yourhandle"
            className={`w-full h-12 p-2 mt-4 border border-gray-400 rounded-lg ${
              errors.social_handle ? 'border border-red-500' : 'border-gray-500'
            }`}
            {...formRegister('social_handle', { required: true })}
          />
        </section>
        <div className="relative h-16 pt-4">
          <button
            className="absolute right-0 w-full px-6 py-3 text-sm font-semibold text-white bg-black md:w-auto rounded-xl"
            onClick={nextStep}
          >
            Submit & Remove Watermarks
          </button>
        </div>
      </div>
    </main>
  );
};

export default SurveyPopUp;
