import React from 'react';

import useAppStore from '../../../../../store/useApp.store';

import {HiPlus} from 'react-icons/hi'

import multicameraTopRightIllustration from '../../../../../assets/image/multicameraTopRightIllustration.svg'
import multicameraBottomLeftIllustration from '../../../../../assets/image/multicameraBottomLeftIllustration.svg'

const InitProyects = () => {
  const setCreateMulticameraProjectModal = useAppStore((state) => state.setCreateMulticameraProjectModal);
  return (
    <main className="flex flex-col justify-center items-center min-h-[600px] m-auto">
      <img src={multicameraTopRightIllustration} className='absolute hidden lg:flex top-0 right-0' alt="" />
      <img src={multicameraBottomLeftIllustration} className='absolute bottom-0 left-0 w-56 md:w-auto' alt="" />
      <p className='font-medium text-base my-2'>You don’t have any projects yet</p>
      <button
          onClick={() => setCreateMulticameraProjectModal(true)}
          className="flex items-center justify-center gap-3 px-4 py-4 rounded-xl bg-gradient-to-r from-green-to-gradient to-blue-to-gradient hover:from-blue-to-gradient hover:to-green-to-gradient hover:text-gray-700 md:px-8 w-full md:w-fit"
        >
          <HiPlus />
          <span className="text-sm font-semibold">Create New Project</span>
        </button>
    </main>
  );
};

export default InitProyects;
