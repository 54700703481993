import { useLocation } from 'react-router-dom';
// Icons
import { FcGoogle } from 'react-icons/fc';

import { signupInitiatedEvent } from '../../../utils/mixpanelEvents';

import useUserStore from '../../../store/useUser.store';

const baseURL = process.env.REACT_APP_API;
const FormHeader = ({
  title,
  subtitle,
  buttonText,
  onSuccess,
  onFail,
  showGoogle,
}: {
  title: string;
  subtitle: string;
  buttonText?: string;
  onSuccess?: any;
  onFail?: any;
  showGoogle: boolean;
}) => {
  const location = useLocation();
  const trackingID = useUserStore((state) => state.trackingId);

  const _doGoogleAuth = () => {
    if (location.pathname === '/signup') {
      signupInitiatedEvent(trackingID);
    }
    setTimeout(() => {
      window.location.assign(`${baseURL}connect/google`);
    }, 3000);
  };

  return (
    <>
      <h2 className="text-lg font-semibold md:text-bg-lg text-font-black md:tracking-wide">{title}</h2>

      <p
        className={` text-gray-700 font-base mt-1 mb-4 ${
          showGoogle ? 'text-sm' : 'text-bg-sm text-gray-800 font-normal'
        }`}
      >
        {subtitle}
      </p>

      {showGoogle && (
        <>
          <div
            className="flex justify-center px-2 py-2 mb-4 text-sm font-semibold text-center text-gray-700 border-2 border-gray-700 cursor-pointer rounded-xl"
            // href={`${baseURL}connect/google`}
            onClick={_doGoogleAuth}
          >
            <FcGoogle fontSize={20} className="mr-2" />
            {buttonText}
          </div>

          <div className="flex items-center justify-between w-full my-3 text-sm font-medium text-center text-font-black">
            <span className="h-0.5 bg-gray-400 w-52 inline-block mr-2"></span>
            or
            <span className="h-0.5 bg-gray-400 w-52 inline-block ml-2"></span>
          </div>
        </>
      )}
    </>
  );
};

export default FormHeader;
