import React, { useState, useMemo } from 'react';
import { AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai';

const Input = ({
  label,
  type,
  inputName,
  validation,
  errors,
  arePasswordMatch,
}: {
  label: string;
  type: string;
  inputName: string;
  validation: any;
  errors: any;
  arePasswordMatch?: boolean;
}) => {
  const [showPassword, setshowPassword] = useState(false);
  const isPassword = type === 'password';
  const isPasswordConfirmation = inputName === 'passwordConfirmation';
  const switchType = useMemo(() => {
    if (isPassword) {
      return showPassword ? 'text' : 'password';
    }
    return type;
  }, [isPassword, showPassword, type]);

  const hasErrors = (isPasswordConfirmation && !arePasswordMatch) || errors[inputName];

  return (
    <div className="w-full">
      <label htmlFor={inputName} className="block text-xs font-medium text-gray-500">
        {label}
      </label>
      <div
        className={`
					relative
					mt-1
					rounded-xl
					flex
					justify-center
					shadow-inner
					box-border
					focus-within:bg-gradient-to-r
				${hasErrors && 'bg-gradient-to-r from-soft-red-to-gradient to-red-to-gradient shadow-input-error'} 
				${!hasErrors && 'focus-within:from-green-to-gradient focus-within:to-blue-to-gradient focus-within:shadow-input-focus'}
				`}
      >
        <input
          type={switchType}
          id={inputName}
          name={inputName}
          autoComplete="on"
          {...validation}
          className={`
					w-full
					rounded-xl font-semibold 
					bg-white text-sm
					p-3
					m-0.5
					text-gray-700
					border-1 h-10 focus:ring-0 focus:border-transparent
					${hasErrors && 'border-red-400 focus:ring-0 border-0 border-transparent'}`}
        />
        {isPassword &&
          (showPassword ? (
            <AiOutlineEye
              className={`text-3xl absolute top-2 right-4 cursor-pointer ${
                hasErrors ? 'text-red-400' : 'text-gray-500'
              }`}
              onClick={() => setshowPassword(!showPassword)}
            />
          ) : (
            <AiOutlineEyeInvisible
              className={`text-3xl absolute top-2 right-4 cursor-pointer ${
                hasErrors ? 'text-red-400' : 'text-gray-500'
              }`}
              onClick={() => setshowPassword(!showPassword)}
            />
          ))}
      </div>
      {errors && !isPasswordConfirmation && (
        <p className="p-1 mt-1 text-xs text-red-400">{errors[inputName]?.message}</p>
      )}
      {isPasswordConfirmation && !arePasswordMatch && (
        <p className="p-1 mt-1 text-xs text-red-400">The passwords don’t match. Please try again.</p>
      )}
    </div>
  );
};

export default Input;
