import React, { useCallback } from 'react';

// Zustand - Global state
import useAppStore from '../../../../store/useApp.store';
import useVerticalizedStore from '../../../../store/useVerticalized.store';
import useUserStore from '../../../../store/useUser.store';

// Utils
import { padTo2Digits } from '../../utils/math.utils';

// Mixpanel Events
import { clickUploadVideoEvent, clickMyVideoEvent } from '../../../../utils/mixpanelEvents';

// Icons
import dotIcon from '../../../../assets/icon/dot.svg';
import subtitleIcon from '../../../../assets/icon/subtitleIcon.svg';
// import { FiUpload } from "react-icons/fi";
import noVideos from '../../../../assets/icon/noVideos.svg';
import reloadIcon from '../../../../assets/icon/reloadIcon.svg';
import MyVideosPagination from './MyVideosPagination';

// Ilustration
import myVideosIlustration from '../../../../assets/image/myVideosIllustration.svg';

const MyVideos = () => {
  const user = useUserStore((state) => state.user);
  const setUploadModal = useAppStore((state) => state.setUploadModal);
  const setDownloadModal = useAppStore((state) => state.setDownloadModal);
  const setCurrentFile = useVerticalizedStore((state) => state.setCurrentFile);
  const setFileViewed = useVerticalizedStore((state) => state.setFileViewed);
  const filesCompleted = useVerticalizedStore((state) => state.filesCompleted);
  const filesInProgress = useVerticalizedStore((state) => state.filesInProgress);
  const filesFailed = useVerticalizedStore((state) => state.filesFailed);
  const myVideos = useVerticalizedStore((state) => state.myVideos);
  const hasVideos = myVideos.length;
  const hasFilesCompleted = filesCompleted.length > 0;
  const hasFilesInProgress = filesInProgress.length > 0;
  const hasFilesFailed = filesFailed.length > 0;

  const _handleDownloadModal = (file: any) => {
    clickMyVideoEvent(user.id);
    setDownloadModal(true);
    setCurrentFile(file);
    if (!file.viewed) {
      setFileViewed(file.uid);
    }
  };

  const _uploadVideo = () => {
    clickUploadVideoEvent(user.id);
    setUploadModal(true);
  };

  const _getVideoDurationFormatted = useCallback((duration) => {
    if (!duration) {
      return 'Not available';
    }
    const durationNum = duration * 1;
    let seconds = Math.floor(durationNum);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);

    seconds = seconds % 60;
    minutes = minutes % 60;
    hours = hours % 24;

    const hasSecondsOrMinutes = minutes ? 'min' : 'sec';
    const durationFormmated = `${padTo2Digits(minutes)}:${padTo2Digits(seconds)} ${hasSecondsOrMinutes}`;
    return durationFormmated;
  }, []);

  const hasSomeStatusFiles = hasFilesCompleted || hasFilesInProgress || hasFilesFailed;
  const hasAnyStatusFiles = !hasFilesCompleted && !hasFilesInProgress && !hasFilesFailed;

  return (
    <>
      <section
        className={`
			${hasVideos > 2 && hasSomeStatusFiles ? 'h-full mt-6 md:mt-12' : null}
			${hasVideos < 3 && hasSomeStatusFiles ? 'h-screen mt-6 md:mt-12' : null}
			${hasVideos < 3 && hasAnyStatusFiles ? 'h-screen mt-4 md:-mt-14' : null}
			${hasVideos > 2 && hasAnyStatusFiles ? 'h-full mt-6 md:mt-12' : null}
			`}
      >
        <h2 className="mb-4 text-lg font-medium">My videos</h2>
        {!hasVideos ? (
          <section className="flex flex-col items-center justify-center mt-8 text-center">
            <img src={noVideos} alt="" />
            <div className="mt-8">
              <p className="text-gray-700">We haven't edited any videos for you yet!</p>
              <p className="mt-4 font-semibold underline cursor-pointer" onClick={_uploadVideo}>
                Upload a video now
              </p>
            </div>
          </section>
        ) : null}

        {hasVideos ? (
          <section className="relative grid grid-cols-3 gap-1 md:grid-cols-4 md:gap-10">
            {/* TODO: just for regret cases, if in one week doesn't want it back remove from here */}
            {/* <section className='hidden object-fill pb-10 md:pb-8 md:block'>
							<section
								onClick={_uploadVideo}
								className='h-full rounded-2xl bg-gradient-to-r from-green-to-gradient to-blue-to-gradient hover:shadow-input-focus p-0.5 hover:p-1 transition-all ease-linear cursor-pointer'>
								<article className='flex flex-col items-center justify-center w-full h-full bg-white rounded-2xl'>
									<FiUpload className="text-2xl" />
									<p className='mt-2 text-lg font-medium text-center'>Upload new video</p>
								</article>
							</section>
						</section> */}

            {myVideos.map((video) => (
              <section key={video.uid} className="relative z-10 flex flex-col">
                {/* <section className="relative overflow-hidden border border-red-800 rounded-2xl"> */}
                <article
                  className="relative overflow-hidden cursor-pointer md:mb-1 rounded-xl md:rounded-2xl"
                  onClick={() => _handleDownloadModal(video)}
                >
                  {video?.thumbnail_portrait || video?.thumbnail ? (
                    <img
                      src={video?.thumbnail_portrait ?? video?.thumbnail}
                      alt={video?.name}
                      className="object-fill"
                    />
                  ) : (
                    <video
                      crossOrigin="anonymous"
                      className="object-fill"
                      poster={video?.thumbnail_portrait}
                      src={video.subtitles_video ?? video.produced_video_portrait_url + '#t=0.5'}
                      preload="metadata"
                    />
                  )}
                  <div className="absolute top-0 flex items-center justify-end w-full h-12 px-4">
                    {!video.viewed ? <img src={dotIcon} alt="" className="cursor-pointer hover:animate-pulse" /> : null}
                  </div>
                  {video.subtitles_done ? (
                    <div className="absolute left-0 flex items-center justify-between h-10 p-2 bottom-4 bg-gray-800/30 rounded-tr-xl rounded-br-xl">
                      <div className="flex items-center gap-2 font-medium ">
                        <img src={subtitleIcon} alt="" className="w-6" />
                        <p className="text-bg-sm font-semibold text-white rounded-lg">Captions ready</p>
                      </div>
                    </div>
                  ) : video.edited_subtitles ? (
                    <div className="absolute left-0 flex items-center justify-between h-10 p-2 bottom-4 bg-gray-800/30 rounded-tr-xl rounded-br-xl">
                      <div className="flex items-center gap-2 font-medium ">
                        <img src={reloadIcon} alt="" className="w-4" />
                        <p className="text-bg-sm font-semibold text-white rounded-lg">Adding Captions</p>
                      </div>
                    </div>
                  ) : null}
                </article>
                {/* </section> */}
                {/* Video footer */}
                <div className="justify-between hidden mt-2 text-sm md:flex">
                  <p className="w-40 font-medium text-gray-800 truncate">{video.name}</p>
                  <p className="font-medium text-bg-sm">
                    {video?.video_info
                      ? _getVideoDurationFormatted(video?.duration ?? video?.video_info?.duration)
                      : 'Not available'}
                  </p>
                </div>
              </section>
            ))}

            {hasVideos === 1 && (
              <div className="absolute justify-center hidden w-full h-full md:flex">
                <img src={myVideosIlustration} className="ml-10 w-96" alt="" />
              </div>
            )}
          </section>
        ) : null}
      </section>
      <MyVideosPagination />
    </>
  );
};

export default MyVideos;
