/* eslint-disable no-useless-escape */

import * as Yup from 'yup';
import { emailCommonsDomainValidation } from '../utils/emailCommonsDomainValidation';

const passwordValidation = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
export const emailValidation = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

export const logInSchema = Yup.object({
  email: Yup.string()
    .email('This isn’t a valid email address. Please try again.')
    .matches(emailValidation, 'Must be a valid email, please try again.')
    .test('email-validation', 'Must be a valid email, please try again', emailCommonsDomainValidation)
    .required('Email is a required field.'),
  password: Yup.string().required('Password is a required field.'),
}).required();

export const signUpSchema = Yup.object({
  firstName: Yup.string().required('First Name is a required field.'),
  lastName: Yup.string().required('Last Name is a required field.'),
  email: Yup.string()
    .email('Must be a valid email, please try again')
    .matches(emailValidation, 'Must be a valid email, please try again')
    .test('email-validation', 'Must be a valid email, please try again', emailCommonsDomainValidation)
    .required('Email is a required field.'),

  password: Yup.string()
    .required('Password is a required field.')
    .matches(
      passwordValidation,
      'Must contain at least 8 characters, including numbers, uppercase, lowercase, and special characters (e.g., !@#?>)'
    ),

  passwordConfirmation: Yup.string()
    .required('Re-enter password is a required field')
    .oneOf([Yup.ref('password'), null], 'The passwords don’t match. Please try again.'),
}).required();

export const forgetPasswordSchema = Yup.object({
  email: Yup.string()
    .email('Email must be valid')
    .required('Email is a required field.')
    .test('email-validation', 'Must be a valid email, please try again', emailCommonsDomainValidation),
}).required();

export const resetPasswordSchema = Yup.object({
  password: Yup.string()
    .required('Password is a required field.')
    .matches(
      passwordValidation,
      'Must contain at least 8 characters, including numbers, uppercase, lowercase, and special characters (e.g., !@#?>)'
    ),
  passwordConfirmation: Yup.string()
    .required('Re-enter password is a required field')
    .oneOf([Yup.ref('password'), null], 'The passwords don’t match. Please try again.'),
}).required();
