import React, { useCallback, useEffect, useMemo, useState } from 'react';

// Icons
import S3Icon from '../../../../../assets/icon/amazon-s3.svg';
import audioIcon from '../../../../../assets/icon/audioIcon.svg';
import { FaDropbox } from 'react-icons/fa';

// Custom Hooks
import { useFile } from '../../../hooks/useFile';

// UI Components
import VideoLoaded from '../VideoLoaded';

// Utils
// Services
import { uploadAudioUrl } from '../../../../../services/dynamicZoom.service';
// Libraries
import { Oval } from 'react-loader-spinner';
import useVerticalizedStore from '../../../../../store/useVerticalized.store';
import useDynamicZoomStore from '../../../../../store/useDynamicZoom.store';

const UploadDynamicAudioUrl = ({ jobUid, isAudio }: { jobUid: string; isAudio?: boolean }) => {
  const [uploadDone, setUploadDone] = useState<boolean>(false);
  const [submitResponse, setSubmitResponse] = useState<any>({});
  const [hasWrongFormat, setHasWrongFormat] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [isUploadByUrlLoading, setUrlUploadingLoading] = useState<boolean>(false);
  const uploadStep = useDynamicZoomStore((state) => state.uploadStep);

  const [request, setRequest] = useState({});
  const {
    // Properties
    hasConnectionError,
    // Methods
    // Components
    UrlErrors,
  } = useFile({
    functionalityStore: useVerticalizedStore,
  });

  const [url, setUrl] = useState<string | null>(null);

  function getS3InfoFromURL(url: string): { name: string; format: string } | null {
    // Expresión regular para validar la URL de S3
    const regex = /^https?:\/\/([^.]+)\.s3\.[^.]+\.amazonaws\.com\/(.+)\.(.+)$/i;

    // Verificar si la URL coincide con el patrón regex
    const match = url.match(regex);

    if (match) {
      const name = match[2];
      const format = match[3];

      return { name, format };
    }
    setUrl(null);
    return null; // La URL no es válida
  }

  function getDropboxInfoFromURL(url: string): { name: string; format: string } | null {
    // Expresión regular para validar la URL de Dropbox
    const regex = /^https?:\/\/www\.dropbox\.com\/s\/[a-z0-9]+\/(.+)\.(.+)\?dl=1$/i;

    // Verificar si la URL coincide con el patrón regex
    const match = url.match(regex);

    if (match) {
      const name = match[1];
      const format = match[2];
      return { name, format };
    }

    setUrl(null);
    return null; // La URL no es válida
  }

  const _urlUploadFormat = useCallback(
    (url: string, urlFrom: string) => {
      setHasWrongFormat(false);
      setHasError(false);
      if (urlFrom === 'Dropbox') {
        const data = getDropboxInfoFromURL(url);
        const type = data?.format === 'mp4' || data?.format === 'mov' ? 'video' : 'audio';

        const request = {
          source_audio_url: url,
          jobUid,
        };

        if ((isAudio && type === 'video') || (!isAudio && type === 'audio')) {
          setHasWrongFormat(true);
          setHasError(true);
          return;
        }

        setRequest(request);

        return;
      }
      if (urlFrom === 'S3') {
        const data = getS3InfoFromURL(url);

        const type = data?.format === 'mp4' || data?.format === 'mov' ? 'video' : 'audio';

        const request = {
          source_audio_url: url,
          jobUid,
        };

        if ((isAudio && type === 'video') || (!isAudio && type === 'audio')) {
          setHasWrongFormat(true);
          setHasError(true);
          return;
        }
        setRequest(request);
        return;
      }
    },
    [isAudio, jobUid]
  );

  const _urlValidations = useCallback(
    (url: string) => {
      const regexDropbox = /^https?:\/\/www\.dropbox\.com\//i;
      const regexS3 = /^https?:\/\/([^.]+)\.s3\.[^.]+\.amazonaws\.com\//i;

      if (url.match(regexDropbox)) {
        setUrl(url.replace('dl=0', 'dl=1'));
        _urlUploadFormat(url.replace('dl=0', 'dl=1'), 'Dropbox');
        return;
      } else if (url.match(regexS3)) {
        setUrl(url);
        _urlUploadFormat(url, 'S3');
        return;
      } else {
        setUrl(null);
        setHasError(true);
        return;
      }
    },
    [_urlUploadFormat]
  );

  const _submitFile = async () => {
    try {
      const response = await uploadAudioUrl(jobUid, url!, true);
      if (response?.uid) {
        setUrlUploadingLoading(false);
        setSubmitResponse(response);
        setUploadDone(true);
        setUrl(null);
        useDynamicZoomStore.setState({ uploadStep: uploadStep + 1 });
        return;
      }
    } catch (error) {
      console.info(error);
      setUrlUploadingLoading(false);
    }
  };

  useEffect(() => {
    if (isAudio) {
      setSubmitResponse({});
      setUploadDone(false);
    }
  }, [isAudio]);

  const _handleClick = () => {
    _submitFile();
    setUrlUploadingLoading(true);
  };
  const ThumbnailUrl = useMemo(() => {
    return (
      <div className="relative w-16 h-12 overflow-hidden rounded-xl">
        <img
          src={submitResponse?.thumbnail_url ?? audioIcon}
          className={`${submitResponse?.thumbnail_url && 'absolute w-20 max-w-none'}`}
          alt="youtube thumbail"
        />
      </div>
    );
  }, [submitResponse]);

  return (
    <>
      {uploadDone && submitResponse?.name ? (
        <VideoLoaded
          fileName={submitResponse?.name}
          hasError={hasError}
          isSuccess={uploadDone}
          Thumbnail={ThumbnailUrl}
          hasConnectionError={hasConnectionError}
          showButton
          // isAudio={isAudio}
        />
      ) : (
        <section className="px-4 pt-1 pb-3">
          <p className="flex items-center justify-start gap-1 px-1 text-sm font-semibold text-gray-800">
            Or Upload via URL <img src={S3Icon} className="w-8 grayscale opacity-80" alt="" />
            <FaDropbox className="w-6 grayscale" size={24} color="gray" />
          </p>
          <div className="flex flex-col items-center justify-between gap-2 md:flex-row">
            <div
              className={`
                relative
                mt-1
                rounded-xl
                flex
                md:w-72
                w-full
                justify-center
                shadow-inner
                box-border
                focus-within:bg-gradient-to-r
                ${hasError && 'bg-gradient-to-r from-soft-red-to-gradient to-red-to-gradient shadow-input-error'} 
                ${
                  !hasError &&
                  'focus-within:from-green-to-gradient focus-within:to-blue-to-gradient focus-within:shadow-input-focus'
                }
                `}
            >
              <input
                type="url"
                required
                name="url"
                disabled={isUploadByUrlLoading}
                onChange={async (e) => {
                  _urlValidations(e.target.value);
                }}
                placeholder="Dropbox or S3 URLs"
                className={`
                  md:w-72
                  rounded-xl 
                  bg-white text-bg-sm
                  p-2
                  w-full
                  disabled:bg-gray-400/50
                  m-0.5
                  text-gray-700
                  border-1 h-10 focus:ring-0 focus:border-transparent
                  ${hasError && 'border-red-400 focus:ring-0 border-0 border-transparent'}`}
              />
            </div>

            <button
              className="px-7 py-2 mt-0.5 text-sm font-medium rounded-xl bg-gray-900 text-white disabled:bg-gray-400 w-full flex justify-center"
              onClick={_handleClick}
              disabled={hasError || !url || isUploadByUrlLoading || hasWrongFormat}
            >
              {isUploadByUrlLoading ? (
                <Oval
                  height={12}
                  width={30}
                  color="white"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="white"
                  strokeWidth={8}
                  strokeWidthSecondary={8}
                />
              ) : (
                'Upload'
              )}
            </button>
          </div>
          {hasWrongFormat && (
            <p className="px-1 pt-1 text-xs text-red-800">
              File from url has wrong format has to be a{isAudio ? 'n Audio' : ' Video'}
            </p>
          )}

          {hasError ? (
            UrlErrors
          ) : (
            <p className="px-1 pt-1 text-xs text-gray-800">
              You may only upload videos that you have permission or own the rights to use.
            </p>
          )}
        </section>
      )}

      {uploadDone && <button> </button>}
    </>
  );
};

export default UploadDynamicAudioUrl;
