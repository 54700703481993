import create from 'zustand';

import {
  getFailedStatus,
  getCompletedStatus,
  getInProgressStatus,
  getMyVideos,
  setVideoViewed,
  deleteVideo,
  rateVideo,
  getSubtitles,
  setSubtitles,
  removeWatermark,
  removeWatermarkWithSurvey,
  cancelTrimming as doCancelTrimming,
  submitData,
  uploadedSuccess,
  uploadMediaWithoutToken,
  uploadTrimming,
} from '../services/dynamicZoom.service';

import { toTimeString } from '../views/Dashboard/utils/toTimeString';

// Zustand - Global state
import useAppStore from './useApp.store';
import useUserStore from './useUser.store';

import {
  dashboardUploadSuccessEvent,
  newUserStartNewJob,
  newUserEndNewJob,
  userTrimVideo,
} from '../utils/mixpanelEvents';

// const abort = new AbortController()

interface MediaStore {
  jobId: string;
  uploadStep: number;
  multicamHasError: boolean;
  multicamErrors: any[];
  filesToUpload: number;
  filesSuccessUploaded: number;
  isUploadCanceled: boolean;
  verticalizedHasError: boolean;
  chunkUploadRetries: Map<any, any>;
  uploadFailed: boolean;
  fileNames: (string | null)[];
  youtubeUrl: string;
  youtubeUrlData: any;
  fileUploaded: any;
  isDurationUrlInvalid: boolean;
  isResolutionUrlInvalid: boolean;
  isUrlInvalid: boolean;
  isYoutubeUrlInvalid: boolean;
  isLiveVideo: boolean;
  isYoutubeApiDown: boolean;
  uploadDone: boolean;
  submitResponse: any;
  currentFile: any;
  subtitles: unknown[];
  filesInProgress: any[];
  filesInProgressPagination: any;
  filesFailed: any[];
  filesFailedPagination: any;
  filesCompleted: any[];
  filesCompletedPagination: any;
  filesCompleteCurrentPage: number;
  myVideos: any[];
  myVideosPagination: any;
  myVideosCurrentPage: number;
  hasCallInterval: boolean;
  hasUploadedInterval: boolean;
  localVideoUrl: string;
  videoTrimParams: any[];
  videoTrimmingDuration: number;
  isTrimmingDurationInvalid: boolean;
  isInProgressTrimmed: boolean;

  addJobId: (jobId: string) => void;
  addMulticamError: (multicamError: any) => void;
  setVerticalizedError: (status: boolean) => void;
  removeMulticamError: (fileName: string) => void;
  addFilesToUpload: (filesToUpload: number) => void;

  addFilesSuccesUploaded: (filesSuccessUploaded: number) => void;
  cancelUpload: (isUploadCanceled: true) => void;
  addChunkUploadRetries: (chunkToRetry: number) => void;
  resetChunkUploadRetries: () => void;
  setUploadFailed: (uploadFailed: boolean) => void;
  setFileName: (fileName: string | null) => void;
  setYoutubeUrl: (youtubeUrl: string) => void;
  setYoutubeUrlData: (youtubeUrlData: any) => void;
  setFileUploaded: (fileUploaded: any) => void;
  setDurationUrlInvalid: (isDurationUrlInvalid: boolean) => void;
  setResolutionUrlInvalid: (isResolutionUrlInvalid: boolean) => void;
  setUrlInvalid: (isUrlInvalid: boolean) => void;
  setYoutubeUrlInvalid: (isYoutubeUrlInvalid: boolean) => void;
  setIsYoutubeApiDown: (isYoutubeApiDown: boolean) => void;
  setIsLiveVideo: (isLiveVideo: boolean) => void;
  setUploadDone: (uploadDone: boolean) => void;
  setUploadStep: (uploadStep: number) => void;
  submitFile: (options?: any) => void;
  uploadFile: (presignedUrl: string, media: any, options: any, setSuccess: any) => void;
  setUploadedSuccess: (file: any) => void;
  setCurrentFile: (file: any) => void;
  setFailedStatusFile: (file?: any) => void;
  setCompleteStatusFile: (file?: any) => void;
  setSubmitResponseForTrimming: (job?: any) => void;
  setFilesCompleteCurrentPage: (page: number) => void;
  setInProgressStatusFile: () => Promise<any[]>;
  setHasCallInterval: (hasCallInterval: boolean) => void;
  setIsInProgressTrimmed: (status: boolean) => void;
  setHasUploadedInterval: (hasUploadedInterval: boolean) => void;
  setMyVideos: (page?: number, pageSize?: number) => Promise<any[]>;
  setMyVideosCurrentPage: (myVideosCurrentPage: number) => void;
  rateMyVideo: (videoId: string, rating: number) => void;
  setFileViewed: (videoId: string) => void;
  setFileDeleted: (videoId: string) => void;
  resetPagination: () => void;
  setLocalVideoUrl: (localVideoUrl: string) => void;
  setVideoTrimming: (videoTrimParams: any[]) => void;
  setVideoTrimmingDuration: (videoTrimmingDuration: number) => void;
  setVideoTrimmingDurationInvalid: (isTrimmingDurationInvalid: boolean) => void;
  setUploadTrimming: () => void;
  setCancelTrimming: () => void;

  getSubtitles: (url: string) => void;
  setSubtitles: () => void;
  editSubtitles: (subtitles: unknown[]) => void;
  removeWatermark: (uid: string, data: unknown) => void;
}

const useDynamicZoomStore = create<MediaStore>(
  // persist(
  (set, get): MediaStore => ({
    // state
    jobId: '',
    uploadStep: 0,
    multicamHasError: false,
    isLiveVideo: false,
    localVideoUrl: '',
    multicamErrors: [],
    filesToUpload: 0,
    filesSuccessUploaded: 0,
    isUploadCanceled: false,
    verticalizedHasError: false,
    chunkUploadRetries: new Map(),
    uploadFailed: false,
    fileNames: [],
    youtubeUrl: '',
    youtubeUrlData: {},
    fileUploaded: {},
    videoTrimParams: [],
    videoTrimmingDuration: 0,
    isTrimmingDurationInvalid: false,
    isInProgressTrimmed: false,
    isDurationUrlInvalid: false,
    isResolutionUrlInvalid: false,
    isUrlInvalid: false,
    isYoutubeUrlInvalid: false,
    isYoutubeApiDown: false,
    uploadDone: false,

    submitResponse: {},
    currentFile: {},
    subtitles: [],

    filesInProgress: [],
    filesInProgressPagination: {},

    filesFailed: [],
    filesFailedPagination: {},

    filesCompleted: [],
    filesCompletedPagination: {},
    filesCompleteCurrentPage: 1,

    myVideos: [],
    myVideosPagination: {},
    myVideosCurrentPage: 1,

    hasCallInterval: false,
    hasUploadedInterval: false,

    // actions
    addJobId: (jobId) => set((state: MediaStore) => ({ ...state, jobId: jobId })),

    addMulticamError: (multicamError) => set((state: MediaStore) => ({ ...state, multicamErrors: multicamError })),

    setVerticalizedError: (status) => set((state: MediaStore) => ({ ...state, verticalizedHasError: status })),

    removeMulticamError: (fileName) => {
      set((state: MediaStore) => ({
        ...state,
        multicamErrors: state.multicamErrors.filter((err) => err.name !== fileName),
      }));
    },

    addFilesToUpload: (filesToUpload) => set((state: MediaStore) => ({ ...state, filesToUpload: filesToUpload })),

    addFilesSuccesUploaded: (filesSuccedUpload) =>
      set((state: MediaStore) => ({ ...state, filesSuccessUploaded: filesSuccedUpload })),

    cancelUpload: (cancel) => set((state: MediaStore) => ({ ...state, isUploadCanceled: cancel })),

    addChunkUploadRetries: (chunkToRetry) => {
      set((state: MediaStore) => {
        let retries = 1;
        if (state.chunkUploadRetries.has(chunkToRetry)) {
          retries = state.chunkUploadRetries.get(chunkToRetry) + 1;
        }

        return {
          ...state,
          chunkUploadRetries: state.chunkUploadRetries.set(chunkToRetry, retries),
        };
      });
    },

    resetChunkUploadRetries: () => set((state: MediaStore) => ({ ...state, chunkUploadRetries: new Map() })),

    setUploadFailed: (status) => set((state: MediaStore) => ({ ...state, uploadFailed: status })),

    setFileName: (fileName) => set((state: MediaStore) => ({ ...state, fileNames: [fileName] })),
    setYoutubeUrl: (youtubeUrl) => set((state: MediaStore) => ({ ...state, youtubeUrl: youtubeUrl })),
    setYoutubeUrlData: (youtubeUrlData) => set((state: MediaStore) => ({ ...state, youtubeUrlData })),
    setDurationUrlInvalid: (status) => set((state: MediaStore) => ({ ...state, isDurationUrlInvalid: status })),
    setResolutionUrlInvalid: (status) => set((state: MediaStore) => ({ ...state, isResolutionUrlInvalid: status })),
    setUrlInvalid: (status) => set((state: MediaStore) => ({ ...state, isUrlInvalid: status })),
    setYoutubeUrlInvalid: (status) => set((state: MediaStore) => ({ ...state, isYoutubeUrlInvalid: status })),
    setIsYoutubeApiDown: (status) => set((state: MediaStore) => ({ ...state, isYoutubeApiDown: status })),
    setIsLiveVideo: (status) => set((state: MediaStore) => ({ ...state, isLiveVideo: status })),
    setUploadDone: (status) => set((state: MediaStore) => ({ ...state, uploadDone: status })),
    setUploadStep: (step) => set((state: MediaStore) => ({ ...state, uploadStep: step })),
    setLocalVideoUrl: (localVideoUrl) => set((state: MediaStore) => ({ ...state, localVideoUrl })),
    setVideoTrimming: (videoTrimParams) => set((state: MediaStore) => ({ ...state, videoTrimParams })),
    setVideoTrimmingDuration: (videoTrimmingDuration) =>
      set((state: MediaStore) => ({ ...state, videoTrimmingDuration })),
    setVideoTrimmingDurationInvalid: (isInvalid) =>
      set((state: MediaStore) => ({ ...state, isTrimmingDurationInvalid: isInvalid })),

    // Verticalized upload
    submitFile: async (options = {}) => {
      const fileName = get().fileNames;
      const youtubeUrl = get().youtubeUrl;
      const setUploadDone = get().setUploadDone;
      const videoDuration = get().videoTrimmingDuration;
      const setVideoTrimmingDuration = get().setVideoTrimmingDuration;
      const setLocalVideoUrl = get().setLocalVideoUrl;
      const setUrlUploadingLoading = useAppStore.getState().setUrlUploadingLoading;

      const filesInProgress = get().filesInProgress;
      const myVideos = get().myVideos;

      const submit = {
        data: {
          name: fileName[0],
          input_media: {
            video_files: fileName[0] === null ? [] : fileName,
          },
          youtube_url: youtubeUrl || null,
          duration: !!videoDuration ? Math.ceil(videoDuration) : null,
        },
      };

      try {
        const response = await submitData(submit, options);
        if (response) {
          set((state: MediaStore) => ({ ...state, submitResponse: response }));
          if (youtubeUrl) {
            setUploadDone(true);
            setVideoTrimmingDuration(response.youtube_info.duration);
            setLocalVideoUrl(response.youtube_info.thumbnail);
            setUrlUploadingLoading(false);
          }
          if (!myVideos.length && !filesInProgress.length) {
            newUserStartNewJob(response.id);
          }
        }
        // useAppStore.setState({ isForgotPasswordLoading: false })
      } catch (error) {
        console.info('error submiting file');
      }
    },
    setSubmitResponseForTrimming: (job: any) => {
      const setUploadDone = get().setUploadDone;
      const setVideoTrimmingDuration = get().setVideoTrimmingDuration;
      const setLocalVideoUrl = get().setLocalVideoUrl;

      setUploadDone(true);
      setVideoTrimmingDuration(job.original_duration);
      setLocalVideoUrl(job.thumbnail);
      set((state) => ({ ...state, submitResponse: job, isInProgressTrimmed: true }));
    },
    setIsInProgressTrimmed: (status: boolean) => set((state) => ({ ...state, isInProgressTrimmed: status })),
    uploadFile: async (presignedUrl, media, options, setSuccess) => {
      try {
        await uploadMediaWithoutToken(presignedUrl, media, options, setSuccess);
      } catch (error) {
        console.info('error uploading file');
      }
    },
    setUploadedSuccess: async (id) => {
      const getInProgress = get().setInProgressStatusFile;
      const filesInProgress = get().filesInProgress;
      const myVideos = get().myVideos;
      try {
        const fileUploaded = await uploadedSuccess(id);
        set((state: MediaStore) => ({
          ...state,
          fileUploaded,
          filesInProgress: [fileUploaded, ...state.filesInProgress],
        }));

        if (!myVideos.length && filesInProgress.length > 1) {
          newUserEndNewJob(id);
        }

        dashboardUploadSuccessEvent(useUserStore.getState().user.id);
        await getInProgress();
      } catch (error) {
        console.info('error sending email');
      }
    },
    setCancelTrimming: async () => {
      const jobId = get().submitResponse.uid;
      const getInProgress = get().setInProgressStatusFile;
      const currentFileId = get().currentFile.uid;
      try {
        await doCancelTrimming(currentFileId ?? jobId);
        const filesInProgressWithoutCancelledJob = get().filesInProgress.filter((file) => file.uid !== jobId);
        set((state: MediaStore) => ({
          ...state,
          filesInProgress: filesInProgressWithoutCancelledJob,
          currentFile: {},
        }));
        getInProgress();
      } catch (error) {
        console.info(error);
      }
    },
    setUploadTrimming: async () => {
      const jobId = get().submitResponse.uid;
      const trimmingData = get().videoTrimParams;
      const originalDuration = get().videoTrimmingDuration;
      const data = {
        trim_done: true,
        trim_info: [
          {
            start_trim: toTimeString(trimmingData[0], false),
            end_trim: toTimeString(Math.ceil(trimmingData[1]), false),
            duration: Math.ceil(trimmingData[1]) - trimmingData[0],
          },
        ],
      };

      if (originalDuration > Math.ceil(trimmingData[1]) - trimmingData[0]) {
        userTrimVideo(useUserStore.getState().user.id, jobId);
      }

      try {
        await uploadTrimming(jobId, data);
      } catch (error) {
        console.info(error);
      }
    },
    setFileUploaded: (fileUploaded) => set((state: MediaStore) => ({ ...state, fileUploaded })),
    setCurrentFile: async (currentFile) => {
      set((state: MediaStore) => ({ ...state, currentFile }));
    },
    setFailedStatusFile: async () => {
      try {
        const { data, meta } = await getFailedStatus();
        set((state: MediaStore) => ({ ...state, filesFailed: data, filesFailedPagination: meta }));
      } catch (error) {}
    },
    setCompleteStatusFile: async (page) => {
      try {
        const { data, meta } = await getCompletedStatus(page);
        const completed = data.map((video: any) => ({
          ...video,
          video_info: Array.isArray(video.video_info) ? video.video_info[0] : video.video_info,
        }));
        set((state: MediaStore) => ({ ...state, filesCompleted: completed, filesCompletedPagination: meta }));
      } catch (error) {}
    },
    setFilesCompleteCurrentPage: (page) => {
      set((state: any) => ({ ...state, setFilesCompleteCurrentPage: page }));
    },
    setInProgressStatusFile: async () => {
      const setHasInProgressVideo = useUserStore.getState().setHasInProgressVideo;
      try {
        const { data, meta } = await getInProgressStatus();
        const hasInProgress = !!data.length || useDynamicZoomStore.getState().hasUploadedInterval;
        setHasInProgressVideo(hasInProgress);
        set((state: MediaStore) => ({
          ...state,
          filesInProgress: data,
          filesInProgressPagination: meta,
          hasCallInterval: hasInProgress,
        }));
        return data;
      } catch (error) {}
    },
    setHasCallInterval: (status) => {
      set((state: MediaStore) => ({ ...state, hasCallInterval: status }));
    },
    setHasUploadedInterval: (status) => {
      set((state: MediaStore) => ({ ...state, hasUploadedInterval: status }));
    },
    setMyVideos: async (page, pageSize) => {
      const setHasVideo = useUserStore.getState().setHasVideo;
      try {
        const { data, meta } = await getMyVideos(page, pageSize);
        const myVideos = data.map((video: any) => ({
          ...video,
          video_info: Array.isArray(video.video_info) ? video.video_info[0] : video.video_info,
        }));
        !!myVideos.length && setHasVideo();
        set((state: MediaStore) => ({ ...state, myVideos: myVideos, myVideosPagination: meta }));
        return myVideos;
      } catch (error) {
        console.info(error);
      }
    },
    setMyVideosCurrentPage: (page) => set((state: MediaStore) => ({ ...state, myVideosCurrentPage: page })),
    rateMyVideo: async (uid, rate) => {
      const getMyVideos = useDynamicZoomStore.getState().setMyVideos;
      const myVideosCurrentPage = useDynamicZoomStore.getState().myVideosCurrentPage;
      try {
        const response = await rateVideo(uid, rate);
        if (response) {
          const myVideo = { ...response, video_info: response.input_media.video_info[0] };
          set((state: MediaStore) => ({ ...state, currentFile: myVideo }));
          getMyVideos(myVideosCurrentPage);
        }
      } catch (error) {
        console.info(error);
      }
    },
    setFileViewed: async (uid) => {
      const getfilesCompleted = useDynamicZoomStore.getState().setCompleteStatusFile;
      const getMyVideos = useDynamicZoomStore.getState().setMyVideos;
      const myVideosCurrentPage = useDynamicZoomStore.getState().myVideosCurrentPage;
      const filesCompleteCurrentPage = useDynamicZoomStore.getState().filesCompleteCurrentPage;
      try {
        const response = await setVideoViewed(uid);
        if (response) {
          getfilesCompleted(filesCompleteCurrentPage);
          getMyVideos(myVideosCurrentPage);
        }
      } catch (error) {
        console.info(error);
      }
    },
    setFileDeleted: async (uid) => {
      const getfilesCompleted = useDynamicZoomStore.getState().setCompleteStatusFile;
      const getfilesFailed = useDynamicZoomStore.getState().setFailedStatusFile;
      const getMyVideos = useDynamicZoomStore.getState().setMyVideos;
      const myVideosCurrentPage = useDynamicZoomStore.getState().myVideosCurrentPage;
      const filesCompleteCurrentPage = useDynamicZoomStore.getState().filesCompleteCurrentPage;
      try {
        const response = await deleteVideo(uid);
        if (response) {
          getfilesCompleted(filesCompleteCurrentPage);
          getMyVideos(myVideosCurrentPage);
          getfilesFailed();
        }
      } catch (error) {
        console.info(error);
      }
    },
    resetPagination: () => {
      set((state: MediaStore) => ({ ...state, myVideosCurrentPage: 1, filesCompleteCurrentPage: 1 }));
    },
    getSubtitles: async (url) => {
      try {
        const subtitles = await getSubtitles(url);
        set((state) => ({ ...state, subtitles }));
        return subtitles;
      } catch (error) {
        console.info(error);
      }
    },
    setSubtitles: async () => {
      await setSubtitles(get().currentFile.uid, get().subtitles);
    },
    editSubtitles: (subtitles) => {
      set((state) => ({ ...state, subtitles }));
    },
    removeWatermark: async (uid, data = {}) => {
      const getMyVideos = useDynamicZoomStore.getState().setMyVideos;
      const user = useUserStore.getState().user;
      const setWatermark = useUserStore.getState().setWatermark;
      const myVideosCurrentPage = useDynamicZoomStore.getState().myVideosCurrentPage;
      const getInProgress = get().setInProgressStatusFile;

      try {
        let response = null;
        if (user.watermark) {
          response = await removeWatermarkWithSurvey(uid, data);
        } else {
          response = await removeWatermark(uid);
        }
        if (response) {
          getMyVideos(myVideosCurrentPage);
          getInProgress();
          setWatermark(false);
        }
      } catch (error) {
        console.info(error);
      }
    },
  })
);

export default useDynamicZoomStore;
