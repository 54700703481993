import { useEffect, useState } from 'react';

import { version } from '../../../utils/appVersion';

const Footer = () => {
  const [showFooter, setShowFooter] = useState(false);
  useEffect(() => {
    setTimeout(() => setShowFooter(true), 3000);
  }, []);

  return showFooter ? (
    <div className={'flex md:justify-around items-center bg-black w-full py-6 md:flex-row flex-col z-40'}>
      <div className="text-white">
        Contact us{' '}
        <a href="mailto:support@bigroom.tv" className="text-white underline">
          support@bigroom.tv
        </a>
      </div>
      <div className="mt-4 text-center text-white md:mt-0">
        Copyright © 2022 LiveLiveLive, Inc. All rights reserved.{' '}
        <a
          href="https://bigroom-cms.s3.us-east-2.amazonaws.com/BigRoomSoftwareLicense.pdf"
          className="text-white underline"
          target="_blank"
          rel="noreferrer"
        >
          EULA
        </a>{' '}
        {version}
      </div>
    </div>
  ) : null;
};

export default Footer;
