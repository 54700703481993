import React from 'react';

//Icons
import { GrFormNext, GrFormPrevious } from 'react-icons/gr';

import useDynamicZoomStore from '../../../../store/useDynamicZoom.store';

const FileListReadyPagination = () => {
  const paginationInfo = useDynamicZoomStore((state) => state.filesCompletedPagination);
  const doPaginate = useDynamicZoomStore((state) => state.setCompleteStatusFile);
  const setFilesCompleteCurrentPage = useDynamicZoomStore((state) => state.setFilesCompleteCurrentPage);
  const currentPage = paginationInfo.page;
  const hasNextPage = paginationInfo.page < paginationInfo.pageCount;
  const hasPreviusPage = paginationInfo.page > 1;
  const hasPagination = paginationInfo.pageCount > 1;

  const _handlePagination = (action: 'previous' | 'next') => {
    if (action === 'previous') {
      doPaginate(currentPage - 1);
      setFilesCompleteCurrentPage(currentPage - 1);
      return;
    }
    if (action === 'next') {
      doPaginate(currentPage + 1);
      setFilesCompleteCurrentPage(currentPage + 1);
      return;
    }
  };

  return hasPagination ? (
    <ol className="flex justify-center gap-1 text-xs font-medium">
      <li>
        <button
          disabled={!hasPreviusPage}
          onClick={() => _handlePagination('previous')}
          className="inline-flex items-center justify-center w-8 h-8 border border-gray-100 rounded"
        >
          <GrFormPrevious />
          <span className="sr-only">Prev Page</span>
        </button>
      </li>

      <li>
        <button className="block w-8 h-8 leading-8 text-center text-white bg-gray-900 border border-gray-100 rounded cursor-pointer">
          {currentPage}
        </button>
      </li>

      <li>
        <button
          disabled={!hasNextPage}
          onClick={() => _handlePagination('next')}
          className="inline-flex items-center justify-center w-8 h-8 border border-gray-100 rounded"
        >
          <span className="sr-only">Next Page</span>
          <GrFormNext />
        </button>
      </li>
    </ol>
  ) : null;
};

export default FileListReadyPagination;
