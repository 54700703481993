import React from 'react';

// Zustand - Global state
import useAppStore from '../../../../store/useApp.store';
import useVerticalizedStore from '../../../../store/useVerticalized.store';

// Components
import Modal from './Modal';
import OutsideClickDetector from '../../../Shared/outsideClickDetector/OutsideClickDetector';

// Icons
import { TfiClose } from 'react-icons/tfi';
import { Transition } from '@headlessui/react';

const CancelTrimmingModal = ({ cancel }: any) => {
  const setCancelTrimmingModal = useAppStore((state) => state.setCancelTrimmingModal);
  const setUploadModal = useAppStore((state) => state.setUploadDynamicZoomModal);
  const cancelTrimming = useVerticalizedStore((state) => state.setCancelTrimming);
  const isCancelTrimmingModalActive = useAppStore((state) => state.cancelTrimmingModal);

  const setUploadDone = useVerticalizedStore((state) => state.setUploadDone);
  const setUploadStep = useVerticalizedStore((state) => state.setUploadStep);
  const setUrlUploadingLoading = useAppStore((state) => state.setUrlUploadingLoading);

  const setResolutionUrlInvalid = useVerticalizedStore((state) => state.setResolutionUrlInvalid);
  const setUrlInvalid = useVerticalizedStore((state) => state.setUrlInvalid);
  const setIsDurationUrlInvalid = useVerticalizedStore((state) => state.setDurationUrlInvalid);
  const setFileUploaded = useVerticalizedStore((state) => state.setFileUploaded);
  const setLocalVideoUrl = useVerticalizedStore((state) => state.setLocalVideoUrl);
  const setYoutubeUrlInvalid = useVerticalizedStore((state) => state.setYoutubeUrlInvalid);

  const setIsYoutubeApiDown = useVerticalizedStore((state) => state.setIsYoutubeApiDown);

  const setVideoTrimming = useVerticalizedStore((state) => state.setVideoTrimming);
  const setVideoTrimmingDuration = useVerticalizedStore((state) => state.setVideoTrimmingDuration);

  const _handleCancelTrimming = () => {
    cancelTrimming();
    cancel();
    setCancelTrimmingModal(false);
    setUploadModal(false);
    setUploadDone(false);
    setIsDurationUrlInvalid(false);
    setResolutionUrlInvalid(false);
    setUploadStep(0);
    setVideoTrimming([]);
    setVideoTrimmingDuration(0);
    setUrlUploadingLoading(false);
    setUrlInvalid(false);
    setFileUploaded({});
    setLocalVideoUrl('');
    setYoutubeUrlInvalid(false);
    setIsYoutubeApiDown(false);
  };

  const _handleCancelTrimmingModal = () => {
    setCancelTrimmingModal(false);
    setUploadModal(true);
  };

  return (
    <Modal show={isCancelTrimmingModalActive}>
      <Transition
        appear={true}
        show={isCancelTrimmingModalActive}
        className="fixed left-0 transition-all duration-200 translate-x-[calc(50vw-50%)] translate-y-[calc(50vh-50%)]"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <OutsideClickDetector callback={() => _handleCancelTrimmingModal()}>
          <main className="relative max-w-96 w-80 md:w-[420px]">
            <div className="p-6 overflow-hidden bg-gray-100 border shadow-lg rounded-xl md:p-10 border-gray-50">
              <button onClick={_handleCancelTrimmingModal} className={`absolute top-2 right-2 p-1 cursor-pointer`}>
                <TfiClose className="w-6" />
              </button>
              <section className="pt-2 text-center">
                <h3 className="pb-2 font-medium">Are you sure you want to cancel?</h3>
                <p className="text-sm">
                  If you close out without trimming and submitting, your video won’t get converted.
                </p>
                <div className="flex items-center justify-between gap-6 pt-6">
                  <button
                    className="px-6 py-3 text-sm font-semibold border border-black rounded-xl w-44"
                    onClick={_handleCancelTrimmingModal}
                  >
                    Go back
                  </button>
                  <button
                    className="px-6 py-3 text-sm font-semibold text-white bg-black rounded-xl w-44"
                    onClick={_handleCancelTrimming}
                  >
                    Yes, cancel
                  </button>
                </div>
              </section>
            </div>
          </main>
        </OutsideClickDetector>
      </Transition>
    </Modal>
  );
};

export default CancelTrimmingModal;
