import { useEffect, useCallback } from 'react';

// UI Layout
import AuthLayout from './layout/AuthLayout';

// Libraries
import { useSearchParams } from 'react-router-dom';

// Components
import SuccessSubmitting from './components/SuccessSubmitting';

// Zustand Global State
import useUserStore from '../../store/useUser.store';

// Mixpanel Events
import { signupSuccessEvent } from '../../utils/mixpanelEvents';

const EmailVerification = () => {
  const [searchParams] = useSearchParams();
  const confirmation = searchParams.get('confirmation');
  const verifyEmail = useUserStore((state) => state?.verifyEmail);
  const trackingId = useUserStore((state) => state.trackingId);

  const signupSuccess = useCallback(() => {
    signupSuccessEvent(trackingId);
  }, [trackingId]);
  useEffect(() => {
    signupSuccess();
  }, [signupSuccess]);

  useEffect(() => {
    if (confirmation) {
      verifyEmail(confirmation);
    }
  }, [confirmation, verifyEmail]);

  return (
    <AuthLayout>
      <SuccessSubmitting
        title="Email verification success!"
        subtitle="Welcome to BigRoom"
        buttonText="Go to Login"
        redirectionPath="/login"
      />
    </AuthLayout>
  );
};

export default EmailVerification;
