// Auth tailwind enabled css
import '../index.scss';


const DashboardLayout = ({ children }: { children: React.ReactNode }) => (
  <div id="tailwind-base" className="">
    <div className="relative h-full px-2 py-4 bg-gray-50 md:p-8">
      <section className="object-fill h-full max-w-6xl m-auto mb-20">
        {children}
      </section>
    </div>
  </div>
);

export default DashboardLayout;
