// Libraries
import { useNavigate } from 'react-router-dom';

// Icons
import logo from '../../../assets/logo/logo.svg';
import pattern from '../../../assets/image/pattern-login-signup.svg';

const SideAuth = () => {
  const navigate = useNavigate();
  return (
    <div className="relative flex items-center object-fill overflow-hidden bg-gradient-to-r from-green-to-gradient to-blue-to-gradient hover:from-blue-to-gradient hover:to-green-to-gradient sm:bg-transparent sm:items-start md:w-2/5 md:bg-login-pattern md:bg-no-repeat lg:block">
      <section className="flex items-center">
        <img
          src={logo}
          alt="BigRoom logo"
          className="p-3 sm:min-w-[80px] w-20 cursor-pointer block"
          onClick={() => navigate('/')}
        />
        <p className="w-3 font-bold text-[9px] pt-3 -ml-1">EARLY ACCESS</p>
      </section>
      <img src={pattern} className="absolute hidden object-cover w-full h-full md:flex" alt="" />
    </div>
  );
};

export default SideAuth;
