import React from 'react'

import User1 from '../../../assets/image/user1.svg'
import User2 from '../../../assets/image/user2.svg'
import User3 from '../../../assets/image/user3.svg'
import User4 from '../../../assets/image/user4.svg'


type User = {
	image: string,
	title:string,
	platform:string
}


const Users:User[] = [
	{
		image: User1,
		title: 'First We Feast: Hot Ones with Sean Evans',
		platform: '11.8M Youtube subscribers'

	},
	{
		image: User2,
		title: 'Tasty: Make It Fancy with Rie',
		platform: '11.8M Youtube subscribers'

	},
	{
		image: User3,
		title: '360 with Speedy Morman',
		platform: '11.8M Youtube subscribers'

	},
	{
		image: User4,
		title: 'Past Your Bedtime with Drew Afualo & Spencewuah',
		platform: '23.5K Followers on TikTok'

	}
]


function WhosUsingBR() {
	return (
		<section className=''>
			<h2 className='text-3xl font-bold text-white text-center pt-16'>See who's using Big Room</h2>

			<section className='flex flex-col md:flex-row gap-4 justify-center px-12'>
				{Users.map((user:User) => (
					<article key={user.image} className='mt-20 text-white w-full md:w-1/2 lg:w-1/4 text-center text-sm font-semibold flex flex-col justify-between'>
						<img src={user.image} alt="" className='mx-auto rounded-xl w-40' />
						<p className='px-16 pt-3 h-12'>{user.title}</p>
						<p className='px-12 font-light pt-4 text-bg-sm'>{user.platform}</p>
						<div className='border border-blue-to-gradient h-1 w-16 mx-auto mt-2 rounded bg-gradient-to-r from-green-to-gradient to-blue-to-gradient hover:from-blue-to-gradient hover:to-green-to-gradient'></div>
					</article>
				))}
			</section>

			WhosUsingBR
		</section>
	)
}

export default WhosUsingBR