import React from 'react';

import useAppStore from '../../../../../store/useApp.store';
import useMulticameraStore from '../../../../../store/useMulticamera.store';

import Modal from '../../shared/Modal';
import { TfiClose } from 'react-icons/tfi';
import OutsideClickDetector from '../../../../Shared/outsideClickDetector/OutsideClickDetector';
import { Transition } from '@headlessui/react';

import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const schema = yup.object().shape({
  name: yup.string().required('Required').min(3),
});

const CreateProjectModal = () => {
  const setCreateMulticameraProjectModal = useAppStore((state) => state.setCreateMulticameraProjectModal);
  const setUploadMulticameraModal = useAppStore((state) => state.setUploadMulticameraModal);
  const isCreateMulticameraProjectModalOpen = useAppStore((state) => state.createMulticameraProjectModal);
  const createProject = useMulticameraStore((state) => state.createProject);

  const _handleCancelCreateProyectModal = () => {
    setCreateMulticameraProjectModal(false);
  };

  const _handleCreateProyect = (data:unknown) => {
    createProject(data);
    setCreateMulticameraProjectModal(false)
    resetField('name')
    setUploadMulticameraModal(true)

  };

  const {
    register,
    resetField,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    
  });

  return (
    <Modal show={isCreateMulticameraProjectModalOpen}>
      <Transition
        appear={true}
        show={isCreateMulticameraProjectModalOpen}
        className="fixed left-0 transition-all duration-200 translate-x-[calc(50vw-50%)] translate-y-[calc(50vh-50%)]"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <OutsideClickDetector callback={() => _handleCancelCreateProyectModal()}>
          <main className="relative max-w-96 w-80 md:w-[420px]">
            <div className="p-6 overflow-hidden bg-gray-100 border shadow-lg rounded-xl md:p-6 border-gray-50">
              <button onClick={_handleCancelCreateProyectModal} className={`absolute top-2 right-2 p-1 cursor-pointer`}>
                <TfiClose className="text-gray-700" size={20} />
              </button>
              <section className="pt-2">
                <h3 className="text-base">New project name</h3>
                <input 
									type="text"
									placeholder="Project name"

									{...register('name')}
									className={`w-full h-12 p-2 mt-1 border border-black rounded-xl focus:ring-0`} />

                <div className="flex items-center pt-3">
                  <button
                    className="px-6 py-3 text-sm font-semibold bg-black text-white rounded-xl w-full disabled:bg-gray-600"
                    disabled={errors.name}
                    onClick={handleSubmit(_handleCreateProyect)}
                  >
                    Create Project
                  </button>
                </div>
              </section>
            </div>
          </main>
        </OutsideClickDetector>
      </Transition>
    </Modal>
  );
};

export default CreateProjectModal;
