/* eslint-disable react-hooks/exhaustive-deps */
// Zustand - Global state
import useAppStore from '../../../../../store/useApp.store';

// Libraries
import { useDropzone } from 'react-dropzone';

// Icons
import { TfiClose } from 'react-icons/tfi';
import uploadFileIcon from '../../../../../assets/icon/uploadFileIcon.svg';
import MulticameraFile from './MulticameraFile';

// Custom Hooks
import { useMultifile } from '../hooks/useMultifile';

// Components
import Modal from '../../shared/Modal';
// import VideoLoaded from '../../Verticalized/VideoLoaded';
// import StepOne from '../../Upload/StepOne';

import { Transition } from '@headlessui/react';
import useMulticameraStore from '../../../../../store/useMulticamera.store';
import AudioDropzone from '../components/AudioDropzone';
import SelectAudioFromFile from '../components/SelectAudioFromFile';
import { useEffect, useMemo, useState } from 'react';
import MulticameraUploadUrl from '../components/MulticameraUploadUrl';

const UploadingSection = ({
  cancelUpload,
  canBeUploaded,
  hasConnectionError,
  uploadDone,
  totalVideosUploaded,
}: any) => {
  return canBeUploaded && !hasConnectionError && !uploadDone && !totalVideosUploaded ? (
    <section className="text-center">
      <p className="-mt-1 text-xs font-light text-gray-700 md:text-sm">
        Don't close the page or you'll lose your upload.
      </p>
      <p onClick={cancelUpload} className="text-xs font-medium text-gray-800 underline cursor-pointer md:text-sm">
        Cancel upload
      </p>
    </section>
  ) : null;
};

const UploadMulticameraModal = () => {
  const setUploadMulticameraModal = useAppStore((state) => state.setUploadMulticameraModal);
  const isUploadMulticameraActive = useAppStore((state) => state.uploadMulticameraModal);
  const setUploadMulticameraStep = useMulticameraStore((state) => state.setMulticameraUploadStep);
  const multicameraUploadStep = useMulticameraStore((state) => state.multicameraUploadStep);
  const files = useMulticameraStore((state) => state.files);
  const setFiles = useMulticameraStore((state) => state.setFiles);
  const audioFile = useMulticameraStore((state) => state.audioFile);
  const resetFiles = useMulticameraStore((state) => state.resetFiles);
  const resetAudio = useMulticameraStore((state) => state.resetAudio);
  const setIsAudioUploaded = useMulticameraStore((state) => state.setIsAudioUploaded);
  const currentProject = useMulticameraStore((state) => state.currentProject);
  const videosUploaded = useMulticameraStore((state) => state.videosUploaded);
  const setVideosUploaded = useMulticameraStore((state) => state.setVideosUploaded);
  const isJustUploadAudio = useMulticameraStore((state) => state.isJustUploadAudio);
  const setIsJustUploadAudio = useMulticameraStore((state) => state.setIsJustUploadAudio);
  const isAudioUploaded = useMulticameraStore((state) => state.isAudioUploaded);
  const setAllowContinue = useMulticameraStore((state) => state.setAllowContinue);
  const allowContinue = useMulticameraStore((state) => state.allowContinue);

  const [controller, setController] = useState(new AbortController());
  // const options = {
  //   signal: controller.signal,
  // };

  const {
    // Properties
    hasError,
    canShowButton,
    canBeUploaded,
    // isUploadable,
    hasConnectionError,
    uploadState,
    uploadDone,
    // Methods
    // Components
    ConnectionError,
    // UploadingSection,s
    setFile,
    setAudio,
    UploadingAudioSection,
    UploadedSection,
    UploadedAudioSection,
    FilesAllowedToUpload,
    // setIsUploadable,
    // cancelUpload,
  } = useMultifile();

  const _closeModal = () => {
    setUploadMulticameraModal(false);
    resetFiles();
    setUploadMulticameraStep(0);
    resetAudio();
    setIsAudioUploaded(false);
    setFile(undefined);
    setAudio(undefined);
    setVideosUploaded(0);
    setIsJustUploadAudio(false);
  };
  const atLeastTwoVideos = currentProject?.files?.filter((file: any) => file.type === 'video').length >= 2;
  const totalVideosUploaded = videosUploaded === files.length;
  const videoFilesInProject = currentProject?.files?.filter((file: any) => file.type === 'video').length;

  const dinamycButton = useMemo(() => {
    return currentProject?.master_audio_file && atLeastTwoVideos && (isAudioUploaded || allowContinue)
      ? 'Upload assets finished'
      : 'Continue';
  }, [currentProject?.files, currentProject?.master_audio_file]);
  const totalVideosAllowed = 4;
  const videoFilesAllowed = useMemo(() => totalVideosAllowed - videoFilesInProject, [videoFilesInProject]);
  const videoFilesAllowedConst = useMemo(() => totalVideosAllowed <= videoFilesInProject + files.length, [files]);

  const _cancelUpload = () => {
    setUploadMulticameraModal(false);
    resetFiles();
    setUploadMulticameraStep(0);
    resetAudio();
    setIsAudioUploaded(false);
    // setIsUploadable(false)
    setFile(undefined);
    setAudio(undefined);
    controller.abort();
    setController(new AbortController());
    setTimeout(() => {
      setUploadMulticameraModal(true);
    }, 300);
    setVideosUploaded(0);
  };

  const [uploadError, setUploadError] = useState(false);

  const { getRootProps, getInputProps, isDragAccept } = useDropzone({
    multiple: true,
    maxFiles: videoFilesAllowed,
    accept: 'video/mp4,video/x-m4v,video/mov,video/quicktime',
    onDrop(acceptedFiles, fileRejections) {
      if (!!fileRejections.length) {
        setUploadError(true);
        setUploadMulticameraStep(1);
        return;
      }
      if (!!acceptedFiles.length) {
        if (videoFilesAllowed < files.length) {
          setUploadMulticameraStep(1);
          setUploadError(true);
          return;
        }
        if (acceptedFiles.length === 1 && videoFilesInProject === 0) {
          setUploadMulticameraStep(1);
          setUploadError(true);
          return;
        }
        setUploadError(false);
        acceptedFiles.forEach((file) => {
          setFiles(file);
          setFile(file);
        });
      }
    },
    // validator: fileValidator,
  });

  useEffect(() =>{
    setAllowContinue(false)
  },[])

  const rootProps = canBeUploaded && !hasConnectionError && !uploadDone ? {} : getRootProps();

  return (
    <>
      <Modal show={isUploadMulticameraActive}>
        <Transition
          appear={true}
          show={isUploadMulticameraActive}
          className={`fixed left-0 transition-all duration-300 translate-x-[calc(50vw-50%)] translate-y-[calc(50vh-50%)]
        ${multicameraUploadStep === 3 ? 'w-80 md:w-[580px]' : 'md:w-upload-modal-md lg:w-upload-modal-lg w-96'}`}
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="relative px-2">
            <main
              className={`rounded-xl border pb-2 pt-3 overflow-hidden border-gray-50 shadow-lg bg-gray-100 ${
                isDragAccept && 'p-2 m-3'
              }`}
            >
              {multicameraUploadStep === 0 && <p className="absolute top-4 left-6">Upload Up to 4 videos</p>}
              {multicameraUploadStep === 2 && !audioFile && !isJustUploadAudio && (
                <p className="absolute top-4 left-6">Select master audio</p>
              )}
              <button
                onClick={_closeModal}
                className={`absolute top-4 right-6 p-1 cursor-pointer ${isDragAccept && 'hidden'}`}
              >
                <TfiClose className="w-7" />
              </button>

              {multicameraUploadStep <= 1 && (
                <>
                  <section
                    {...rootProps}
                    className={`mt-10 flex flex-col items-center pb-4 border border-gray-400 border-dashed rounded-xl p-5 mx-5 my-2
                    ${canBeUploaded || uploadDone ? 'pb-4 border-none rounded-xl p-0 mx-0 my-0' : ''}
                    ${isDragAccept ? 'mt-0 opacity-40 border border-gray-400 border-dashed rounded-xl p-3' : ''}
              `}
                  >
                    <input type="file" name="file" {...getInputProps()} disabled={canBeUploaded} />

                    <img src={uploadFileIcon} className="w-20 md:w-28" alt="upload icon" />

                    <p className="mt-4 text-sm font-semibold text-gray-800 md:text-lg">{uploadState}</p>

                    {canShowButton && !hasConnectionError && !uploadDone && (
                      <button
                        onClick={() => {
                          resetFiles();
                          setUploadMulticameraStep(0);
                          setFile(undefined);
                        }}
                        className="flex items-center justify-center py-3 mt-3 text-xs font-semibold text-gray-800 rounded-xl md:rounded-2xl bg-blue-to-gradient w-72 md:w-40 md:text-sm hover:text-gray-700"
                      >
                        Choose file
                      </button>
                    )}

                    {/* {hasError && FileErrors} */}
                    <FilesAllowedToUpload videosAllowed={videoFilesAllowed} />
                  </section>

                  {!uploadError ? (
                    <UploadingSection
                      cancelUpload={_cancelUpload}
                      canBeUploaded={canBeUploaded}
                      hasConnectionError={hasConnectionError}
                      uploadDone={uploadDone}
                      totalVideosUploaded={totalVideosUploaded}
                      hasError={hasError}
                    />
                  ) : null}
                  <UploadedSection />
                  <ConnectionError />

                  {multicameraUploadStep === 1 && (
                    <section className="pb-3">
                      {uploadError ? (
                        <p className="mx-auto text-red-800 text-center text-sm">
                          {uploadError && videoFilesInProject === 0
                            ? `You need to upload at least 2 videos`
                            : `You are trying to upload more than ${videoFilesAllowed} videos`}
                        </p>
                      ) : (
                        <>
                          {files.map((file) => (
                            <MulticameraFile
                              fileName={file.name}
                              cancelUpload={_cancelUpload}
                              hasConnectionError={hasConnectionError}
                              file={file}
                              key={file.name}
                              isAudio={false}
                              signal={controller}
                            />
                          ))}
                          <div className="px-3">
                            <button
                              {...getRootProps()}
                              disabled={videoFilesAllowedConst}
                              className="w-full p-3 text-bg-sm rounded-xl border border-black mt-4 font-medium disabled:border-gray-400 disabled:text-gray-400 px-3"
                            >
                              Add another video
                              <input type="file" name="file" {...getInputProps()} disabled={videoFilesAllowedConst} />
                            </button>
                          </div>
                        </>
                      )}
                    </section>
                  )}

                  {!files.length && <MulticameraUploadUrl options={{}} />}
                </>
              )}

              {multicameraUploadStep === 2 && (
                <>
                  {audioFile ? (
                    <div className="flex flex-col items-center px-8 pt-8">
                      <img src={uploadFileIcon} className="w-20 md:w-28" alt="upload icon" />
                      <p className="mt-4 text-sm font-semibold text-gray-800 md:text-lg">{uploadState}</p>
                      <UploadingAudioSection
                        cancelAudioUpload={() => {
                          resetFiles();
                          setUploadMulticameraStep(2);
                          resetAudio();
                          setIsAudioUploaded(false);
                          setFile(undefined);
                          setAudio(undefined);
                          controller.abort();
                          setController(new AbortController());
                          setUploadMulticameraModal(false);
                          setTimeout(() => {
                            setUploadMulticameraModal(true);
                          }, 200);
                          setVideosUploaded(0);
                        }}
                      />
                      <br />
                      <ConnectionError />
                      <UploadedAudioSection />
                    </div>
                  ) : null}
                  <AudioDropzone cancelUpload={_cancelUpload} signal={controller} />
                  {!audioFile && !isJustUploadAudio ? <SelectAudioFromFile /> : null}

                  {!audioFile && <MulticameraUploadUrl options={{}} isAudio />}
                </>
              )}

              {((files?.length !== 0 && !uploadError) ||
                (!isJustUploadAudio && multicameraUploadStep === 2) ||
                (!!videoFilesInProject && allowContinue)) && (
                <section className="pb-3 px-3">
                  <button
                    disabled={
                      uploadError ||
                      (multicameraUploadStep === 2 && !currentProject?.master_audio_file) ||
                      (multicameraUploadStep === 1 && videosUploaded !== files.length) ||
                      (multicameraUploadStep === 1 && currentProject?.master_audio_file && !atLeastTwoVideos)
                    }
                    onClick={() => {
                      if (currentProject?.master_audio_file && atLeastTwoVideos) {
                        // setConfirmMixModal(true)
                        _closeModal();
                        return;
                      }
                      if (allowContinue && !currentProject?.master_audio_file) {
                        setUploadMulticameraStep(2);
                        setAllowContinue(false)
                        return;
                      }
                      setUploadMulticameraStep(multicameraUploadStep + 1);
                    }}
                    className="w-full p-3 text-bg-sm mt-3 mb-1 rounded-xl bg-black text-white disabled:bg-gray-300 font-medium"
                  >
                    {dinamycButton}
                  </button>
                </section>
              )}
            </main>
          </div>
        </Transition>
      </Modal>
    </>
  );
};

export default UploadMulticameraModal;
