import React, { useState, useMemo } from 'react';

// Libraries
import { toast } from 'react-toastify';
import ReactRating from 'react-rating';
import 'react-toastify/dist/ReactToastify.css';

// Zustand - Global state
import useAppStore from '../../../../store/useApp.store';
import useVerticalizedStore from '../../../../store/useVerticalized.store';
import useUserStore from '../../../../store/useUser.store';

// Mixpanel Event
import {
  clickDownloadSequenceEvent,
  clickDownloadVideoEvent,
  clickWatermarkOff,
} from '../../../../utils/mixpanelEvents';

// Icons
import rateIcon from '../../../../assets/icon/rateIcon.png';
import checkIcon from '../../../../assets/icon/checkIcon.svg';
import deleteIcon from '../../../../assets/icon/delete_video.svg';
import playIcon from '../../../../assets/icon/play.svg';
import copyIcon from '../../../../assets/icon/copyId.svg';
import starIcon from '../../../../assets/icon/starIcon.svg';
import reloadIcon from '../../../../assets/icon/reloadIcon.svg';
// import verticalOption from '../../../assets/icon/vertical_option.svg'
// import mixedOption from '../../../assets/icon/mixed_option.svg'
import starFill from '../../../../assets/icon/starFill.svg';
import starEmpty from '../../../../assets/icon/starEmpty.svg';
import backArrow from '../../../../assets/icon/backArrow.svg';
import { TfiClose } from 'react-icons/tfi';
import { useNavigate } from 'react-router-dom';

// Components
import Modal from '../shared/Modal';
import OutsideClickDetector from '../../../Shared/outsideClickDetector/OutsideClickDetector';

// Hooks
import { useFile } from '../../hooks/useFile';
import useCopyToClipboard from '../../../../hooks/useCopyToClipboard';

// Utils
import { bytesToMegabytes } from '../../utils/math.utils';
import { Transition } from '@headlessui/react';
import Switch from '../shared/Switch';
// import Share from './shared/Share';

const Download = () => {
  const currentFunctionality = useAppStore((state) => state.currentFunctionality);
  const navigate = useNavigate();
  const flags: any = useAppStore((state) => state.flags);
  const user = useUserStore((state) => state.user);
  const [showThumbnail, setShowThumbnail] = useState(true);
  const [, copy] = useCopyToClipboard();
  const { getVideoDurationFormatted } = useFile({
    functionalityStore: currentFunctionality,
  });
  const setDownloadModal = useAppStore((state) => state.setDownloadModal);

  const setGeneratingCaptionModal = useAppStore((state) => state.setGeneratingCaptionModal);

  const isDownloadModalActive = useAppStore((state) => state.downloadModal);
  const isDeleteModalActive = useAppStore((state) => state.deleteModal);
  const setDeleteModal = useAppStore((state) => state.setDeleteModal);
  const currentFile = currentFunctionality((state) => state.currentFile);
  const rateMyVideo = currentFunctionality((state) => state.rateMyVideo);
  const setCurrentFile = currentFunctionality((state) => state.setCurrentFile);
  const [optionSelected, setOptionSelected] = useState(1);

  const [isHover, setIsHover] = useState(false);

  const _handleDeleteFile = () => {
    setDeleteModal(true);
  };

  const _handleDownloadModal = () => {
    setDownloadModal(false);
    setOptionSelected(1);
    setShowThumbnail(true);
    setTimeout(() => {
      setCurrentFile(null);
    }, 300);
  };

  const _currentVideoInfo = useMemo(
    () =>
      currentFile?.video_info?.hasOwnProperty('duration')
        ? `${getVideoDurationFormatted(currentFile?.duration ?? currentFile?.video_info?.duration)},${
            currentFile?.video_info?.width
          } x ${currentFile?.video_info?.height},${bytesToMegabytes(currentFile?.video_info?.format.size * 1).toFixed(
            2
          )}Mb`.replaceAll(',', '			')
        : 'File info not available',
    [currentFile?.duration, currentFile?.video_info, getVideoDurationFormatted]
  );
  const _currentVideoResolution = useMemo(
    () =>
      currentFile?.video_info?.hasOwnProperty('height')
        ? `${currentFile?.video_info?.width} x ${currentFile?.video_info?.height}`
        : 'Resolution not available',
    [currentFile?.video_info]
  );
  const _currentVideoSize = useMemo(
    () =>
      currentFile?.video_info?.hasOwnProperty('duration')
        ? `${bytesToMegabytes(currentFile?.video_info?.format.size * 1).toFixed(2)}Mb`
        : 'Size not available',
    [currentFile?.video_info]
  );
  const _currentVideoDuration = useMemo(
    () =>
      currentFile?.video_info
        ? getVideoDurationFormatted(currentFile?.duration ?? currentFile?.video_info?.duration)
        : 'Not available',
    [currentFile?.duration, currentFile?.video_info, getVideoDurationFormatted]
  );

  const _downloadVideo = () => {
    clickDownloadVideoEvent(user.id);
    const fileName = `bigroom_${currentFile?.name}`;
    const link = document.createElement('a');
    link.href = currentFile?.produced_video_portrait_url;
    // link.href = (optionSelected && optionSelected === 2) ? currentFile?.produced_video_portrait_all_url : currentFile?.produced_video_portrait_url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const _downloadVideoWithCaptions = () => {
    // clickDownloadVideoEvent(user.id);
    const fileName = `bigroom_${currentFile?.name}`;
    const link = document.createElement('a');
    link.href = currentFile?.subtitles_video;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const _downloadSequence = () => {
    clickDownloadSequenceEvent(user.id);
    const link = document.createElement('a');
    link.href = currentFile?.bigroom_fcp_portrait;
    link.download = `bigroom_${currentFile?.bigroom_fcp_portrait}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const _copyIdToClipBoard = (jobId: string) => {
    copy && copy(jobId);
    toast.success('ID copied!', {
      icon: ({ theme, type }) => <img src={checkIcon} alt="success icon" width="20px" height="20px" />,
    });
  };

  const _ratingChanged = (rate: number) => {
    rateMyVideo(currentFile.uid, rate);
    window.open(
      `https://share.hsforms.com/1GaIZvybrTr6QXU4uwElKOA54y0p?email=${user.email}&job_id=${currentFile.uid}`,
      '_blank',
      'noreferrer'
    );
  };

  const _playVideo = () => {
    const videoToPlay = document.getElementById('br-video') as HTMLVideoElement;
    videoToPlay!.play();
  };

  const _handlePlay = () => {
    setShowThumbnail(!showThumbnail);
    _playVideo();
  };

  const setShowRemoveWatermarkModal = useAppStore((state) => state.setShowRemoveWatermarkModal);
  const setRemoveWatermarkJobId = useAppStore((state) => state.setRemoveWatermarkJobId);

  const _handleWatermarkChange = () => {
    setTimeout(() => {
      setRemoveWatermarkJobId(currentFile?.uid);
      setShowRemoveWatermarkModal(true);
      clickWatermarkOff(user?.id, currentFile?.uid);
      _handleDownloadModal();
    }, 200);
  };

  const _getVideoSrc = useMemo(() => {
    let option =
      optionSelected === 2 ? currentFile?.produced_video_portrait_all_url : currentFile?.produced_video_portrait_url;

    if (currentFile?.subtitles_done) {
      return currentFile?.subtitles_video;
    }

    return option;
  }, [
    currentFile?.produced_video_portrait_all_url,
    currentFile?.produced_video_portrait_url,
    currentFile?.subtitles_done,
    currentFile?.subtitles_video,
    optionSelected,
  ]);

  const canHaveCaption = !!currentFile?.subtitles;

  return (
    <Modal show={isDownloadModalActive}>
      <Transition
        appear={true}
        show={isDownloadModalActive}
        className="absolute md:fixed left-0 transition-all duration-300 translate-x-[calc(50vw-50%)] translate-y-[calc(50vh-50%)]"
        enterFrom="translate-y-[100vh]"
        enterTo="translate-y-[calc(50vh-50%)]"
        leaveFrom="translate-y-[calc(50vh-50%)]"
        leaveTo="translate-y-[100vh]"
      >
        <OutsideClickDetector callback={() => !isDeleteModalActive && _handleDownloadModal()}>
          <main className="relative w-full h-screen m-auto lg:w-download-modal-md md:h-full md:w-screen">
            <div className="rounded-xl border p-2.5 md:p-6 px-3 overflow-hidden border-gray-50 shadow-lg bg-gray-100 md:min-h-download-modal-md md:max-screen mt-20 md:mt-2 w-full">
              <button onClick={_handleDownloadModal} className={`absolute top-2 right-2 p-1 cursor-pointer z-50`}>
                <TfiClose className="w-8 md:w-6" />
              </button>
              {optionSelected === 2 ? (
                <section className="flex items-center gap-2 pb-3 cursor-pointer" onClick={() => setOptionSelected(1)}>
                  <img src={backArrow} alt="" className="w-6" />
                  <p className="text-sm">Back to fully vertical version</p>
                </section>
              ) : null}

              <div className="flex flex-col pl-1 pr-2 text-base md:hidden">
                <div className="mt-4 mb-3">
                  <p className="font-medium truncate w-60">{currentFile?.name}</p>
                  <p className="text-xs">{_currentVideoInfo}</p>
                </div>
                <div className="flex items-center pb-2 cursor-pointer md:gap-4">
                  {/* <Share /> */}

                  <div
                    className="flex items-center gap-1 p-2 truncate transition duration-300 rounded hover:bg-gray-400/20"
                    onClick={() => _copyIdToClipBoard(currentFile?.uid)}
                  >
                    <img src={copyIcon} alt="" />
                    <p className="w-24 truncate">ID: {currentFile?.uid}</p>
                  </div>

                  <div
                    className="flex items-center gap-1 p-2 transition duration-300 rounded cursor-pointer hover:bg-gray-400/20"
                    onClick={_handleDeleteFile}
                  >
                    <img src={deleteIcon} alt="" className="w-4" />
                    <p className="pt-0.5">Delete video</p>
                  </div>
                </div>
              </div>

              <section className="flex flex-col h-full px-1 transition-all md:gap-2 lg:gap-4 md:flex-row">
                <section className="relative flex justify-center rounded-2xl md:w-video-desktop md:h-video-desktop h-video-mobile">
                  <div className={`object-fit w-full md:w-[280px] rounded-2xl h-full md:h-full relative md:p-0.5`}>
                    {showThumbnail ? (
                      <article
                        className={`rounded-2xl flex justify-center items-center cursor-pointer w-full object-cover`}
                      >
                        <img
                          src={
                            (currentFile?.thumbnail_portrait ?? currentFile?.thumbnail) +
                            '?watermark=' +
                            currentFile?.watermark
                          }
                          alt={currentFile?.name}
                          className="absolute top-0 z-20 block w-full h-full rounded-2xl"
                        />
                        <img
                          src={playIcon}
                          alt={currentFile?.name}
                          onClick={_handlePlay}
                          className="absolute w-40 md:w-28 bg-cover bg-center rounded-2xl z-20 top-56 right-20 md:top-48 md:right-[85px]"
                        />
                      </article>
                    ) : null}

                    <video
                      crossOrigin="anonymous"
                      id="br-video"
                      onChange={(e) => console.info(e)}
                      className={`object-fill rounded-2xl h-full absolute top-0 z-10 md:p-0.5 w-full md:object-cover`}
                      src={_getVideoSrc}
                      controls
                      playsInline
                    />
                  </div>
                </section>

                <section className="w-full mb:pt-2">
                  <div className="flex-col hidden md:flex">
                    <div className="flex items-center gap-3 pb-4 cursor-pointer">
                      {/* <Share /> */}
                      <div
                        className="flex items-center gap-1 p-2 truncate transition duration-300 rounded hover:bg-gray-400/20"
                        onClick={() => _copyIdToClipBoard(currentFile?.uid)}
                      >
                        <img src={copyIcon} alt="" />
                        <p className="truncate w-24 pt-0.5">ID: {currentFile?.uid}</p>
                      </div>

                      <div
                        className="flex items-center gap-1 p-2 transition duration-300 rounded cursor-pointer hover:bg-gray-400/20"
                        onClick={_handleDeleteFile}
                      >
                        <img src={deleteIcon} alt="" className="w-4" />
                        <p className="pt-0.5">Delete video</p>
                      </div>
                    </div>

                    <div className="flex items-center justify-between gap-4">
                      <p className="w-48 font-medium truncate md:text-sm">{currentFile?.name}</p>
                      <p className="text-bg-sm">
                        <span className="pr-2.5">{_currentVideoDuration}</span>
                        <span className="pr-2.5">{_currentVideoResolution}</span>
                        <span className="pr-2.5">{_currentVideoSize}</span>
                      </p>
                    </div>

                    {/* <div className="flex pt-3">
                      <div
                        className="flex gap-1 px-3 py-2 font-medium text-white bg-black rounded-lg cursor-pointer text-bg-sm hover:bg-gray-800"
                        onClick={() => {
                          navigate('/caption-editor')
                          setDownloadModal(false)
                        }}>
                        <img src={generateCaptionIcon} alt="" />
                        Generate captions
                      </div>
                    </div> */}
                  </div>
                  <div className="w-full px-12 m-auto mt-4 border rounded-full border-t-gray-500 md:px-4"></div>
                  <div className="mt-4 text-sm font-semibold">
                    {currentFile?.watermark && (
                      <div>
                        <Switch label="Watermark on" checked={true} onChange={_handleWatermarkChange} />
                      </div>
                    )}
                  </div>
                  <div className="flex items-center justify-between mt-4 text-sm">
                    <div>
                      <p className="font-semibold truncate md:w-64">Video (MP4, no captions)</p>
                      <p className="text-xs">{_currentVideoDuration}</p>
                    </div>
                    <button
                      onClick={_downloadVideo}
                      className="w-32 px-3 py-3 text-xs font-medium rounded-lg bg-blue-to-gradient md:w-36"
                    >
                      Download
                    </button>
                  </div>

                  {currentFile?.subtitles_done ? (
                    <div className="flex items-center justify-between mt-4 text-sm">
                      <div>
                        <p className="font-semibold truncate md:w-64">Video (MP4, with captions)</p>
                        <p className="text-xs">{_currentVideoDuration}</p>
                      </div>
                      <button
                        onClick={_downloadVideoWithCaptions}
                        className="w-32 px-3 py-3 text-xs font-medium text-white bg-black rounded-lg md:w-36"
                      >
                        Download
                      </button>
                    </div>
                  ) : currentFile?.edited_subtitles ? (
                    <div className="flex items-center justify-between mt-4 text-sm text-gray-400">
                      <div>
                        <p className="font-semibold truncate md:w-64">Video (MP4, with captions)</p>
                      </div>
                      <button
                        disabled={true}
                        className="flex items-center justify-center w-32 gap-2 px-3 py-3 text-xs font-medium text-white bg-gray-400 rounded-lg md:w-36"
                      >
                        <img src={reloadIcon} alt="" />
                        Adding Captions
                      </button>
                    </div>
                  ) : null}

                  {/* Generate captions */}
                  {flags.Subtitles ? (
                    <div
                      className={`flex items-center justify-between mt-4 text-sm ${!canHaveCaption && 'text-gray-700'}`}
                    >
                      <div className="flex flex-col items-start w-52 md:flex-row md:justify-between md:items-center md:w-64">
                        <p className="font-semibold">Add/edit captions</p>

                        <div
                          onMouseEnter={() => setIsHover(true)}
                          onMouseLeave={() => setIsHover(false)}
                          className="relative flex items-center gap-2 p-1 px-3 -ml-3 font-medium rounded-lg cursor-default text-bg-sm"
                        >
                          <img src={starIcon} alt="" />
                          <p className="flex underline">English Beta</p>

                          {isHover && (
                            <div className="absolute z-40 p-4 bg-white border rounded-3xl -top-24 -left-36 w-72">
                              Introducing our new Beta English-Only Captions feature. Try other languages at your own
                              risk. 👀
                              <div className="absolute w-5 h-5 rotate-45 bg-white border-b-0 border-l-0 border-gray-300 -bottom-45 right-20 lg:right-10"></div>
                            </div>
                          )}
                        </div>
                      </div>

                      <button
                        disabled={!canHaveCaption}
                        onClick={() => {
                          setGeneratingCaptionModal(true);
                          setTimeout(() => {
                            setGeneratingCaptionModal(false);
                            navigate('/caption-editor');
                          }, 2000);
                          setDownloadModal(false);
                        }}
                        className="w-32 px-3 py-3 text-xs font-medium text-white bg-black rounded-lg disabled:bg-gray-500 md:w-36"
                      >
                        Add/edit captions
                      </button>
                    </div>
                  ) : null}

                  <div className="flex items-center justify-between w-full mt-4 text-sm">
                    <div className="md:w-52 w-44">
                      <p className="font-medium">Sequence files (XML)</p>
                      <p className="text-xs">Adobe Premiere Pro, Final Cut Pro, DaVinci Resolve</p>
                    </div>
                    <button
                      onClick={_downloadSequence}
                      className="w-32 px-3 py-3 text-xs font-medium rounded-lg bg-blue-to-gradient md:w-36"
                    >
                      Download zip
                    </button>
                  </div>

                  {/* {optionSelected === 1 ? <div className='flex flex-col items-center gap-2 mt-5 md:flex-row'>
									<div className='flex gap-1'>
										<img src={verticalOption} alt="" className='w-5' />
										<img src={mixedOption} alt="" className='w-5' />
									</div>
									<div>
										<p
											className='text-xs font-semibold underline cursor-pointer'
											onClick={() => setOptionSelected(2)}>
											Want a version where non-optimized scenes stay in landscape?
										</p>
									</div>
								</div> : null} */}

                  {/* RATING VIDEO */}
                  <div
                    className={`${
                      optionSelected === 2 ? 'mt-6 md:mt-10' : 'mt-10'
                    } flex items-center gap-2 justify-center cursor-pointer mb-6 md:mb-0`}
                  >
                    <img src={rateIcon} alt="" className="hidden w-24 md:block" />
                    <div className="flex items-center w-full gap-2 md:flex-col md:justify-center md:w-auto justify-evenly">
                      <p className="font-medium md:mb-2">Rate your video</p>
                      <ReactRating
                        stop={5}
                        step={1}
                        start={0}
                        initialRating={currentFile?.rating}
                        emptySymbol={<img src={starEmpty} alt="start rating" className="mr-3 w-7" />}
                        fullSymbol={<img src={starFill} alt="start rating" className="mr-3 w-7" />}
                        onChange={_ratingChanged}
                      />
                    </div>
                  </div>
                </section>
              </section>
            </div>
          </main>
        </OutsideClickDetector>
      </Transition>
    </Modal>
  );
};

export default Download;
