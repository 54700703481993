import React, { useCallback, useEffect, useMemo, useState } from 'react';

import useMulticameraStore from '../../../../../store/useMulticamera.store';
import { useNavigate, useParams } from 'react-router-dom';
import DashboardLayout from '../../../layout/DashboardLayout';

import BackArrowIcon from '../../../../../assets/icon/backArrow.svg';
import audioIcon from '../../../../../assets/icon/audioIcon.svg';
import checked from '../../../../../assets/icon/checked.svg';
import error from '../../../../../assets/icon/error.svg';
import inProgress from '../../../../../assets/icon/inProgress.svg';
import VideoList from '../components/VideoList';
import SelectAudio from '../components/SelectAudio';
import { HiOutlineDotsVertical } from 'react-icons/hi';
import OutsideClickDetector from '../../../../Shared/outsideClickDetector/OutsideClickDetector';
import useAppStore from '../../../../../store/useApp.store';

const AudioCard = ({ isMasterAudioFile, audio, currentProject }: any) => {
  const removeMedia = useMulticameraStore((state) => state.removeMedia);
  const setMasterAudioFile = useMulticameraStore((state) => state.setMasterAudioFile);
  const [showMenu, setShowMenu] = useState<boolean>(false);

  return (
    <div className="relative border w-[300px] md:w-52 h-40 rounded-2xl flex justify-center items-center">
      <img src={audioIcon} alt="" className="rounded-2xl object-fill aspect-video w-28 m-auto" />
      {isMasterAudioFile(audio) && (
        <span className="absolute top-3 left-3 text-gray-900 bg-blue-to-gradient font-medium cursor-pointer rounded-full py-1 px-2 text-xs">
          Master audio
        </span>
      )}
      <HiOutlineDotsVertical
        onClick={() => setShowMenu(!showMenu)}
        className="absolute top-4 w-6 h-5 right-1 cursor-pointer"
      />
      {showMenu ? (
        <OutsideClickDetector callback={() => setShowMenu(!showMenu)}>
          <div className="absolute z-20 flex flex-col items-center w-[220px] gap-2 p-2 font-medium transition-all bg-gray-50 border border-gray-300 rounded-xl fadeIn left-40 top-10 text-bg-sm">
            <div
              onClick={() => {
                setMasterAudioFile(currentProject.uid, audio.uid);
                setTimeout(() => setShowMenu(false), 200);
              }}
              className="flex items-center w-full p-2 rounded-lg hover:bg-gray-100 cursor-pointer"
            >
              Select as master audio
            </div>
            {!currentProject?.started && (
              <div
                onClick={() => removeMedia(audio?.uid)}
                className="flex items-center w-full rounded-lg hover:bg-gray-100 p-2 cursor-pointer"
              >
                Delete audio
              </div>
            )}
          </div>
        </OutsideClickDetector>
      ) : null}
    </div>
  );
};

const Project = () => {
  const navigate = useNavigate();
  const { projectUid } = useParams();
  const currentProject = useMulticameraStore((state) => state.currentProject);
  const setCurrentProject = useMulticameraStore((state) => state.setCurrentProject);
  const getProject = useMulticameraStore((state) => state.getProject);
  const cancelJob = useMulticameraStore((state) => state.cancelJob);
  const setConfirmMixModal = useAppStore((state) => state.setConfirmMixModal);
  const setCurrentTab = useAppStore((state) => state.setCurrentTab);
  const setUploadMulticameraModal: any = useAppStore((state) => state.setUploadMulticameraModal);
  const setMulticameraUploadStep: any = useMulticameraStore((state) => state.setMulticameraUploadStep);

  const setIsJustUploadAudio = useMulticameraStore((state) => state.setIsJustUploadAudio);

  const status = useMemo(() => {
    if (currentProject.is_error) {
      return { message: 'Failed', icon: error, isFailed: true };
    }
    if (currentProject.complete) {
      return { message: 'Done', icon: checked };
    }
    if (currentProject.started && !currentProject.complete) {
      return { message: 'Processing', icon: inProgress };
    }
    // return {message:'Processing', icon: inProgress}
  }, [currentProject]);

  const audioFiles = useMemo(
    () => currentProject?.files?.filter((file: any) => file.type === 'audio'),
    [currentProject]
  );
  const videosQuantityValidation = currentProject?.files?.filter((file: any) => file.type === 'video').length < 2;

  const canBeMixed = useMemo(() => {
    return videosQuantityValidation || !currentProject?.master_audio_file;
  }, [currentProject?.master_audio_file, videosQuantityValidation]);

  const isMasterAudioFile = useCallback(
    (file) => currentProject?.master_audio_file === file.uid,
    [currentProject?.master_audio_file]
  );

  useEffect(() => {
    getProject(projectUid!);
  }, [getProject, projectUid]);

  if (!currentProject.uid) {
    return (
      <main className="h-screen flex justify-center items-center">
        <p>Loading...</p>
      </main>
    );
  }

  const showStatus = currentProject?.is_error || currentProject?.complete || currentProject?.started;

  const _handleBack = () => {
    setCurrentProject({});
    setCurrentTab(1);
    navigate('/dashboard');
  };

  const _downloadVideo = () => {
    const fileName = `bigroom_${currentProject?.name}`;
    const link = document.createElement('a');
    link.href = currentProject?.produced_video_url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <DashboardLayout>
      <main className="h-full lg:min-h-[920px]">
        <section className="flex justify-between">
          <article onClick={_handleBack} className="flex items-center gap-3">
            <img src={BackArrowIcon} alt="" className="w-7 cursor-pointer" />
            <p className="text-2xl font-normal">{currentProject.name}</p>
          </article>
          <article className="flex gap-4 items-center justify-center">
            <div className="flex items-center gap-4">
              {!currentProject?.cancel && !currentProject?.complete && currentProject?.started && (
                <button
                  onClick={() => cancelJob(currentProject?.uid)}
                  className={`px-6 py-3 font-semibold text-bg-sm rounded-xl border border-black`}
                >
                  Cancel process
                </button>
              )}

              {currentProject?.complete && currentProject?.started && (
                <button
                  onClick={_downloadVideo}
                  className={`px-6 py-3 font-semibold text-bg-sm rounded-xl border border-black`}
                >
                  Download
                </button>
              )}
              {showStatus && (
                <div
                  className={`${
                    status?.isFailed ? 'bg-red-100 border-red-300 text-red-800' : 'border-gray-400 bg-white'
                  } border rounded-xl px-6 py-3 flex items-center gap-2 text-bg-sm font-semibold`}
                >
                  <img src={status?.icon} alt="" className="w-4 h-4" />
                  <span>{status?.message}</span>
                </div>
              )}
            </div>

            {(!currentProject?.started || currentProject?.complete || currentProject?.is_error) && (
              <button
                disabled={canBeMixed}
                onClick={() => setConfirmMixModal(true)}
                className={`border px-6 py-3 font-semibold text-sm rounded-xl bg-gradient-to-r from-green-to-gradient to-blue-to-gradient hover:from-blue-to-gradient cursor-pointer hover:to-green-to-gradient disabled:bg-gray-200 disabled:from-gray-200 disabled:to-gray-200 disabled:text-white disabled:hover:cursor-default`}
              >
                Mix Video
              </button>
            )}
          </article>
        </section>

        <section>
          <article className="mt-6">
            <p className="font-bold mb-4">Video files</p>
            <VideoList />
          </article>
        </section>

        {!!audioFiles?.length && (
          <section className="">
            <p className="font-bold mb-4 mt-20">Master audio</p>
            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-8 justify-between">
              {audioFiles?.map((audio: any) => (
                <section key={audio?.uid} className="rounded-xl max-w-[300px] mx-auto">
                  <AudioCard isMasterAudioFile={isMasterAudioFile} audio={audio} currentProject={currentProject} />

                  <div className="text-xs w-52">
                    <p className="text-sm pt-0 font-semibold truncate">{audio.name}</p>
                  </div>
                </section>
              ))}

              <section
                className={`${
                  audioFiles?.length === 2 ? 'opacity-30' : 'cursor-pointer'
                } rounded-xl max-w-[300px] mx-auto flex items-start justify-center w-full min-w-[200px] h-44`}
              >
                <div
                  onClick={() => {
                    if (audioFiles?.length < 2) {
                      setIsJustUploadAudio(true);
                      setMulticameraUploadStep(2);
                      setUploadMulticameraModal(true);
                    }
                  }}
                  className="relative flex justify-center flex-col items-center border border-gray-600 w-full h-40 rounded-2xl"
                >
                  <img src={audioIcon} alt="" className="rounded-2xl object-fill aspect-video w-16" />
                  <div className="text-xs">
                    <p className="text-sm pt-0 font-semibold truncate">Upload new audio</p>
                  </div>
                </div>
              </section>
            </div>
          </section>
        )}

        {!currentProject?.master_audio_file && audioFiles?.length === 0 && (
          <section className="flex flex-col justify-center items-center mt-28">
            <p className="text-sm font-medium">You need a master audio to process the video</p>
            <SelectAudio />
          </section>
        )}
      </main>
    </DashboardLayout>
  );
};

export default Project;
